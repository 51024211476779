import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import React, { ChangeEventHandler } from 'react';
import { TextArea, TextField } from 'modules/ui';
import { OnValueChange } from 'types/global';
import { TextSettingInterface } from 'types/types';

enum TextSettingsType {
  Text = 'text',
  Textarea = 'textarea',
}

type TextPropsType = { value: string; onChange: ChangeEventHandler<HTMLInputElement> };

export interface TextSettingsProps extends OnValueChange<TextSettingInterface> {
  titleText: string;
  type?: TextSettingsType;
  withoutSwitcher?: boolean;
}

export const TextSettingsGroup = ({
  value: { isShow, text },
  titleText,
  onChange,
  type = TextSettingsType.Text,
  withoutSwitcher,
}: TextSettingsProps) => {
  const textProps: TextPropsType = {
    value: text,
    onChange: (e) => onChange({ isShow, text: e.target.value }),
  };

  return (
    <GroupContainerSettings
      titleText={titleText}
      switcherState={isShow}
      switcherChange={withoutSwitcher ? undefined : () => onChange({ isShow: !isShow, text })}
    >
      <ElementContainerSettings>
        {type === TextSettingsType.Text ? (
          <TextField name="h3_text" width="100%" needBackground={false} needBorderBottom={false} {...textProps} />
        ) : (
          <TextArea
            name="text"
            width="100%"
            needBackground={true}
            needBorderBottom={true}
            placeholder="Текст"
            rows={5}
            {...textProps}
          />
        )}
      </ElementContainerSettings>
    </GroupContainerSettings>
  );
};
