import { FC, NoopType, OnValueChange } from 'types/global';
import { getConditionOptions } from 'modules/settingsContainer/common/ColorBySettings/constants';
import React, { useMemo, useRef } from 'react';
import { ColorByInterface, DefaultDataSettingsInterface } from 'store/reducers/visualisations/types';
import { WrapperBySettings, WrapperByTabsType } from 'modules/settingsContainer/common/WrapperBySettings';
import { ConditionEditorField } from 'modules/settingsContainer/common/ConditionEditorField';
import { FixedAreaSettings } from 'modules/settingsContainer/common/FixedAreaSettings';
import { useModalState } from 'utils/hooks/modalState';
import { ColorByCondition } from '../ColorByCondition';
import { ColorByValue } from 'modules/settingsContainer/common/ColorByValue';
import { colorAlias, colorSpecificAlias } from 'store/reducers/visualisations/constants';
import { ColorBySpecificValue } from 'modules/settingsContainer/common/ColorBySpecificValue';

export interface ColorBySettingsProps
  extends Partial<Pick<DefaultDataSettingsInterface, 'indicators'>>,
    OnValueChange<ColorByInterface> {
  isMainContainerSettings?: boolean;
  disabledComponentByValue?: boolean;
  disabledComponentByCondition?: boolean;
  disabledComponentBySpecificValue?: boolean;
  title?: string;
  titleMainContainerText?: string;
  showBottomLine?: boolean;
  switcherColorBy?: {
    switcherChangeColorBy?: NoopType;
    switcherStateColorBy?: boolean;
  };
}

export const ColorBySettings: FC<ColorBySettingsProps> = ({
  disabledComponentByValue,
  disabledComponentByCondition,
  disabledComponentBySpecificValue = false,
  value,
  onChange,
  indicators,
  children,
  isMainContainerSettings = false,
  title,
  titleMainContainerText,
  showBottomLine,
  switcherColorBy,
}) => {
  const mainContainerRef = useRef<HTMLDivElement | null>(null);

  const { isOpen, onOpen, onClose } = useModalState();

  const usingByValue = useMemo(() => !disabledComponentByValue, [disabledComponentByValue]);
  const usingBySpecificValue = useMemo(() => !disabledComponentBySpecificValue, [disabledComponentBySpecificValue]);
  const usingByCondition = useMemo(() => !disabledComponentByCondition, [disabledComponentByCondition]);
  const conditionOptions = useMemo(
    () => getConditionOptions(usingByValue, usingBySpecificValue, usingByCondition),
    [usingByValue, usingBySpecificValue, usingByCondition],
  );

  const colorByTabs: WrapperByTabsType = {
    condition: {
      Activator: () =>
        usingByCondition ? <ConditionEditorField value={value.byCondition.sqlCondition || ''} onOpenChange={onOpen} /> : <>/</>,
      Settings: () =>
        usingByCondition ? (
          <FixedAreaSettings
            onClose={onClose}
            open={isOpen}
            headerText={settingsText || ''}
            width={width}
            minWidth={minWidth}
            anchorEl={mainContainerRef.current}
          >
            <ColorByCondition metric={value.byCondition} onChange={(byCondition) => onChange({ ...value, byCondition })} />
          </FixedAreaSettings>
        ) : null,
      settingsText: 'Изменить условие',
      width: '565px',
    },
    value: {
      Activator: () => (
        <ColorByValue
          isGroup={false}
          metric={value.byValue}
          onChange={(byValue) => onChange({ ...value, byValue })}
          indicators={indicators}
        />
      ),
      settingsText: 'Изменить диапазон',
      minWidth: '180px',
    },
    valueSpecific: {
      Activator: () =>
        usingBySpecificValue ? (
          <ColorBySpecificValue
            colors={value?.byValueSpecific?.colors?.[colorSpecificAlias] || []}
            colorAlias={colorAlias}
            onChange={(newColors) =>
              onChange({
                ...value,
                byValueSpecific: {
                  ...value.byValueSpecific,
                  colors: {
                    [colorSpecificAlias]: newColors,
                  },
                },
              })
            }
          />
        ) : null,
    },
    default: {
      Activator: () => <>{children}</>,
    },
  };

  const { width, minWidth, settingsText } = colorByTabs[value.type];

  return (
    <WrapperBySettings
      mainContainerRef={mainContainerRef}
      colorByTabs={colorByTabs}
      conditionOptions={conditionOptions}
      value={value}
      isMainContainerSettings={isMainContainerSettings}
      title={title}
      titleMainContainerText={titleMainContainerText}
      onChange={onChange}
      showBottomLine={showBottomLine}
      switcherColorBy={switcherColorBy}
    />
  );
};
