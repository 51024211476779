import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { AddColorButton } from 'modules/ui/buttons/AddColorButton';
import { ColorByPicker } from 'modules/ui/ColorByPicker';
import { GradientBar } from 'modules/ui/colors/GradientBar';
import React, { useMemo } from 'react';
import { ColorByItem, ColorValuesByThemeType } from 'store/reducers/visualisations/types';
import { FlexContainer } from 'styles/FlexContainer';
import { addColorByValueMarginTop, gradientLineHeight, gradientLineMarginBottom } from '../ColorByValue/constants';

interface ColorManagementSectionProps {
  colors: ColorByItem[];
  colorAlias: string;
  onChange: (updatedColors: ColorByItem[]) => void;
}

export const ColorBySpecificValue: React.FC<ColorManagementSectionProps> = ({ colors, onChange }) => {
  const { getColorValues, defaultColor } = useColorValues();
  const colorGradient = useMemo(
    () => (Array.isArray(colors) ? colors.map(({ value }) => String(getColorValues(value)) || defaultColor) : []),
    [colors, getColorValues, defaultColor],
  );

  const onChangeColor = (index: number) => (change: ColorValuesByThemeType | null) => {
    if (!Array.isArray(colors)) {
      return;
    }
    const newColors: ColorByItem[] = colors.map((color, indexColor) => {
      if (index === indexColor) {
        return { value: change };
      }
      return color;
    });
    onChange(newColors);
  };

  const onAddColor = () => {
    const newColors = colors.length ? [...colors, { value: null }] : [{ value: null }];
    onChange(newColors);
  };

  return (
    <>
      {!!colors?.length && (
        <FlexContainer marginRight="8px" flexDirection="column" overflow="auto">
          <FlexContainer marginBottom={gradientLineMarginBottom} width="100%" height={gradientLineHeight}>
            <GradientBar colors={colorGradient} />
          </FlexContainer>
          <FlexContainer gap="12px">
            {colors.map(({ value }, index) => (
              <ColorByPicker
                onDelete={() => onChange(colors.filter((_, indexEl) => indexEl !== index))}
                onChange={onChangeColor(index)}
                value={value}
                key={index}
              />
            ))}
          </FlexContainer>
        </FlexContainer>
      )}
      <FlexContainer margin={`${addColorByValueMarginTop} 0 0 0`}>
        <AddColorButton onAdd={onAddColor} />
      </FlexContainer>
    </>
  );
};
