import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'store';
import { unwrapResult } from '@reduxjs/toolkit';
import { loadUserInfoAction } from 'store/reducers/adminUsers/actions';
import { UserInfo } from 'store/reducers/adminUsers/types';

export const useUserInfo = (activeUserId: string | undefined) => {
  const dispatch = useAppDispatch();

  const [editUser, setEditUser] = useState<UserInfo | null>(null);

  const getUserInfo = useCallback(async () => {
    try {
      if (activeUserId) {
        const actionResult = await dispatch(loadUserInfoAction(activeUserId));
        const unwrappedResult = unwrapResult(actionResult);
        setEditUser(unwrappedResult);
      }
    } catch (error) {
      console.error(error);
    }
  }, [activeUserId, dispatch]);

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  return { editUser };
};
