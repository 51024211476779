import {
  ContentWrapper,
  DefaultViewSettingsWrapper,
  VisualisationContainer,
} from 'modules/visualisations/components/VisualisationLayout/styles';
import { FC } from 'types/global';
import { DefaultVisualisationOptionsType } from 'store/reducers/visualisations/types';
import { FlexContainer, FlexContainerProps } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import React, { useMemo, useRef } from 'react';
import { useVisualisationEvents } from 'modules/visualisations/hooks/visualisationEvents';
import { StyledTooltip } from 'modules/ui/StyledTooltip';
import { InfoIcon } from 'assets/icons/withContainer';
import { useResizeObserver } from 'utils/hooks/resizeObserver';
import { defaultViewWrapperMarginBottom } from 'modules/visualisations/components/VisualisationLayout/constants';

import isUndefined from 'lodash/isUndefined';
import { defaultTextViewSettings } from 'store/reducers/visualisations/constants';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { TextSettingColorInterface, TextSettingInterface } from '../../../../types/types';
interface VisualisationLayoutProps {
  data: DefaultVisualisationOptionsType;
}

export const VisualisationLayout: FC<VisualisationLayoutProps> = ({
  children,
  data: {
    viewSettings: { header, paddingSettings, description, headerPosition, hint },
    events,
  },
}) => {
  const { getColorValues } = useColorValues();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const viewSettingsRef = useRef<HTMLDivElement | null>(null);

  const [, containerHeight] = useResizeObserver({
    ref: containerRef,
  });
  const [, viewSettingsHeight] = useResizeObserver({
    ref: viewSettingsRef,
  });

  const { onClick } = useVisualisationEvents(events);
  const HeaderData: TextSettingColorInterface = { ...defaultTextViewSettings.text, ...header };
  const DescriptionData: TextSettingColorInterface = { ...defaultTextViewSettings.text, ...description };
  const isShowHeader = !!header?.isShow,
    isShowDescription = !!description?.isShow,
    isShowHint = !!hint?.isShow;
  const isDefaultViewVisible = isShowHeader || isShowDescription || isShowHint;

  const contentHeight = useMemo(() => {
    const height = containerHeight - viewSettingsHeight - (isDefaultViewVisible ? parseInt(defaultViewWrapperMarginBottom) : 0);

    return height > 0 ? height : 0;
  }, [containerHeight, viewSettingsHeight, isDefaultViewVisible]);

  const shouldShowHeaderOrDescription = isShowHeader || isShowDescription;

  const styleHint: FlexContainerProps = !shouldShowHeaderOrDescription
    ? { position: 'absolute', right: '16px', zIndex: '1', cursor: 'pointer' }
    : {};

  return (
    <VisualisationContainer
      ref={containerRef}
      onClick={onClick}
      padding={
        paddingSettings?.unsetPaddings
          ? `${paddingSettings?.top}px ${paddingSettings?.right}px ${paddingSettings?.bottom}px ${paddingSettings?.left}px`
          : '0px'
      }
    >
      <DefaultViewSettingsWrapper
        marginBottom={shouldShowHeaderOrDescription ? defaultViewWrapperMarginBottom : '0'}
        isVisible={isDefaultViewVisible}
        ref={viewSettingsRef}
      >
        {shouldShowHeaderOrDescription && (
          <FlexContainer overflow="hidden" flexDirection="column" alignItems={headerPosition} flex="1 1 0">
            {isShowHeader && (
              <FlexContainer width="fit-content">
                <PrimaryTextSpan
                  wordSpacing={HeaderData?.wordSpacing + '%' || '0'}
                  fontSize={HeaderData?.fontSize + 'px' || '16px'}
                  fontWeight={HeaderData?.fontStyle?.bold ? 'bold' : 'normal'}
                  fontStyle={HeaderData?.fontStyle?.italic ? 'italic' : 'normal'}
                  textDecoration={HeaderData?.fontStyle?.underline ? 'underline' : undefined}
                  letterSpacing={HeaderData?.letterSpacing + 'px'}
                  lineHeight={HeaderData?.lineHeight + '%'}
                  color={HeaderData?.fontColor ? `${getColorValues(HeaderData?.fontColor)}` : `var(${ColorVarsEnum.Level_1})`}
                  opacity={!isUndefined(HeaderData?.opacity) ? HeaderData?.opacity / 100 : 1}
                >
                  {HeaderData.text}
                </PrimaryTextSpan>
              </FlexContainer>
            )}
            {isShowDescription && (
              <FlexContainer width="fit-content">
                <PrimaryTextSpan
                  wordSpacing={DescriptionData?.wordSpacing + '%' || '0'}
                  fontSize={DescriptionData?.fontSize + 'px' || '16px'}
                  fontWeight={DescriptionData?.fontStyle?.bold ? 'bold' : 'normal'}
                  fontStyle={DescriptionData?.fontStyle?.italic ? 'italic' : 'normal'}
                  letterSpacing={DescriptionData?.letterSpacing + 'px'}
                  textDecoration={DescriptionData?.fontStyle?.underline ? 'underline' : undefined}
                  lineHeight={DescriptionData?.lineHeight + '%'}
                  color={
                    DescriptionData?.fontColor ? `${getColorValues(DescriptionData?.fontColor)}` : `var(${ColorVarsEnum.Level_2})`
                  }
                  opacity={!isUndefined(DescriptionData?.opacity) ? DescriptionData.opacity / 100 : 1}
                >
                  {DescriptionData.text}
                </PrimaryTextSpan>
              </FlexContainer>
            )}
          </FlexContainer>
        )}
        <FlexContainer>
          {isShowHint && (
            <FlexContainer {...styleHint}>
              <StyledTooltip title={hint.text}>
                <InfoIcon />
              </StyledTooltip>
            </FlexContainer>
          )}
        </FlexContainer>
      </DefaultViewSettingsWrapper>
      <ContentWrapper height={contentHeight}>{children}</ContentWrapper>
    </VisualisationContainer>
  );
};
