import { CallbackDataParams } from 'echarts/types/dist/shared';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { onActiveNextIncisionIdChange, onDeleteFiltersForDrillDownVisualisation } from 'modules/visualisations/common/constants';
import { formatResult } from 'modules/visualisations/common/onChangeFunctions';
import { SingleIncisionLayout } from 'modules/visualisations/components/SingleIncisionLayout';
import { WrappedReactECharts } from 'modules/visualisations/components/WrappedReactECharts';
import { useActiveIncision } from 'modules/visualisations/hooks/activeIncision';
import { useDefaultIndicator } from 'modules/visualisations/hooks/defaultIndicator';
import { useManualResize } from 'modules/visualisations/hooks/manualResize';
import { useVisualisation } from 'modules/visualisations/hooks/visualisation';
import {
  getFormatSeries,
  getPieLegendData,
  getPieMapData,
  legendPositionAtGrid,
  onActiveIncisionIdChange,
} from 'modules/visualisations/Pie/visualisation/constants';
import { PieEChartsOption, PieNode } from 'modules/visualisations/Pie/visualisation/types';
import { VisualisationOriginInterface } from 'modules/workspace/components/VisualisationArea/types';
import React, { memo, useCallback, useMemo } from 'react';
import { defaultPieDataSettings } from 'store/reducers/visualisations/constants';
import { ColorAndImageByEnum, ColorAndImageByType, PieVisualisationType } from 'store/reducers/visualisations/types';
import { ItemValueColor } from 'types/echarts';
import { useIncisionColors } from 'modules/visualisations/hooks/useIncisionColors';

export const PieVisualisationComponent: VisualisationOriginInterface<PieVisualisationType> = ({ data, sqlRequest }) => {
  const { id, dataSettings, viewSettings, sqlData, events } = data;

  const { isDrillDown } = dataSettings,
    {
      shadowColorSettings: { shadowColorBy },
    } = viewSettings.shadowSettings;

  const {
    styleSettings: {
      properties: propertiesGlobal,
      colorBySettings: { elementColorBy: elementGlobalColorBy, elementColor: elementGlobalColor },
      labelFormat: labelFormatGlobal,
      outerRadius: outerRadiusGlobal,
      innerRadius: innerRadiusGlobal,
      borderRadius: borderRadiusGlobal,
      borderWidth: borderWidthGlobal,
      pieAnimationElement: pieAnimationElementGlobal,
      centerCoordinates: {
        centerYCoordinates: centerYCoordinatesGlobal,
        centerXCoordinates: centerXCoordinatesGlobal,
        isActive: isActiveCenterCoordinatesGlobal,
      },
      arcStartAngle: arcStartAngleGlobal,
      arcEndAngle: arcEndAngleGlobal,
    },
    legendSettings,
  } = viewSettings;

  const { fontColorBy, fontColor } = propertiesGlobal;

  const incisionSegmentColorsBy = useMemo(
    () => dataSettings.incisions.map(({ colorBySettings }) => colorBySettings.elementColorBy),
    [dataSettings.incisions],
  );

  const {
    visualisationNormalizedValues,
    updateFilter,
    eChartRef,
    getColorBySpecificValue,
    getVisualisationColorsAndImagesData,
    isColorByCondition,
    formattingParams: { formatting },
    enabledFilters,
  } = useVisualisation({
    sqlData,
    id,
    dataSettings,
    colorsBy: [elementGlobalColorBy, fontColorBy, ...incisionSegmentColorsBy, shadowColorBy],
    events,
    limit: dataSettings.limit,
    sqlRequest,
  });

  const { getColorValues, defaultColor, activeThemeSchema } = useColorValues();

  const activeIncision = useActiveIncision({
    activeIncisionId: dataSettings.activeIncisionId,
    defaultIncision: defaultPieDataSettings.incisions[0],
    incisions: dataSettings.incisions,
  });

  const mainIndicator = useDefaultIndicator({
    indicators: dataSettings.indicators,
    defaultIndicator: defaultPieDataSettings.indicators[0],
  });

  const {
    data: {
      colorBySettings: {
        elementColor: activeIncisionColor,
        elementColorBy: activeIncisionColorBy,
        isActive: activeIncisionIsActive,
      },
    },
  } = activeIncision;

  const pieMapData = useMemo(
    () => getPieMapData(dataSettings, visualisationNormalizedValues),
    [dataSettings, visualisationNormalizedValues],
  );

  const getGlobalIncisionColorSegment = useIncisionColors({
    activeIncisionSum: pieMapData?.activeIncisionSum,
    activeIncision,
    colors: elementGlobalColor,
  });

  const getIncisionColorSegment = useIncisionColors({
    activeIncisionSum: pieMapData?.activeIncisionSum,
    activeIncision,
    colors: activeIncisionColor,
  });

  const legendData = useMemo(
    () => getPieLegendData(dataSettings, visualisationNormalizedValues),
    [dataSettings, visualisationNormalizedValues],
  );

  useManualResize({ eChartRef: eChartRef.current, deps: [viewSettings.description, viewSettings.header] });

  const circlePosition = useMemo(() => {
    if (legendSettings.isShow) {
      return legendPositionAtGrid[legendSettings.location.type] || legendPositionAtGrid['default'];
    }

    return legendPositionAtGrid['default'];
  }, [legendSettings.isShow, legendSettings.location]);

  const legendPositionInGrid = useCallback(
    (vertical: boolean) => {
      return legendSettings.position.type !== 'flex-end' ? legendSettings.position.type : vertical ? 'right' : 'bottom';
    },
    [legendSettings.position],
  );

  const positionLegend = useMemo(() => {
    switch (legendSettings.location.type) {
      case 'top': {
        return {
          top: 0,
          left: legendPositionInGrid(true),
        };
      }
      case 'bottom': {
        return {
          bottom: 0,
          left: legendPositionInGrid(true),
        };
      }
      case 'left': {
        return {
          left: 0,
          top: legendPositionInGrid(false),
        };
      }
      case 'right': {
        return {
          right: 0,
          top: legendPositionInGrid(false),
        };
      }
    }
  }, [legendPositionInGrid, legendSettings.location.type]);

  const valueFormat = mainIndicator.data.settings.valueFormat;

  const innerRadiusGlobalResult = formatResult(innerRadiusGlobal);
  const outerRadiusGlobalResult = formatResult(outerRadiusGlobal);
  const centerXCoordinatesGlobalResult = formatResult(centerXCoordinatesGlobal);
  const centerYCoordinatesGlobalResult = formatResult(centerYCoordinatesGlobal);

  const formattingFunction = formatting[mainIndicator.fieldName];

  const formatSeries = useMemo(() => getFormatSeries(labelFormatGlobal, valueFormat), [labelFormatGlobal, valueFormat]);

  const isHorizontalLegendLocation = useMemo(
    () => legendSettings.location.type === 'left' || legendSettings.location.type === 'right',
    [legendSettings.location],
  );

  const getItemSegmentColor = useCallback(
    (alias: string, type: ColorAndImageByType) =>
      type !== ColorAndImageByEnum.Default
        ? (params: CallbackDataParams) => {
            const { value: dataValue } = params.data as PieNode;

            let value: string | number | null | undefined = dataValue;

            if (isColorByCondition(alias)) {
              value = getVisualisationColorsAndImagesData(alias)[params.dataIndex];
            }

            return getColorBySpecificValue({ value, indicatorName: params.seriesName || '', alias, defaultColor });
          }
        : undefined,
    [isColorByCondition, getColorBySpecificValue, defaultColor, getVisualisationColorsAndImagesData],
  );

  const getItemGlobalValueColor = useMemo(
    () =>
      fontColorBy.type !== ColorAndImageByEnum.Default
        ? (params: ItemValueColor) => {
            const { data, dataIndex } = params,
              alias = fontColorBy.byCondition.alias;

            let value: string | number | null | undefined = data as number;

            if (isColorByCondition(alias)) {
              value = getVisualisationColorsAndImagesData(alias)[dataIndex];
            }

            return getColorBySpecificValue({ value, indicatorName: mainIndicator.fieldName || '', alias, defaultColor });
          }
        : undefined,
    [
      fontColorBy.type,
      fontColorBy.byCondition.alias,
      isColorByCondition,
      getColorBySpecificValue,
      mainIndicator.fieldName,
      defaultColor,
      getVisualisationColorsAndImagesData,
    ],
  );

  const pieDataResult = useMemo(
    () =>
      pieMapData?.pieNodes.map(({ value, tableColumn, name }, index) => {
        const itemValueColor =
          getItemGlobalValueColor && getItemGlobalValueColor({ data: value as number, dataIndex: index, seriesName: name });

        const color = itemValueColor || getColorValues(fontColor) || activeThemeSchema[ColorVarsEnum.Level_1];

        return {
          name,
          value,
          tableColumn,
          label: {
            color,
          },
        };
      }),
    [pieMapData, getItemGlobalValueColor, getColorValues, fontColor, activeThemeSchema],
  );

  const propertiesLegend = legendSettings.properties;
  const fontWeightLegend = propertiesLegend.fontStyle.bold ? 'bold' : 'normal',
    fontSizeLegend = propertiesLegend.fontSize,
    fontStyleLegend = propertiesLegend.fontStyle.italic ? 'italic' : 'normal',
    fontColorLegend = propertiesLegend.fontColor;

  const getItemSegmentColorResult = activeIncisionIsActive
    ? getItemSegmentColor(activeIncisionColorBy.byCondition.alias, activeIncisionColorBy.type) || getIncisionColorSegment
    : getItemSegmentColor(elementGlobalColorBy.byCondition.alias, elementGlobalColorBy.type) || getGlobalIncisionColorSegment;

  const option = useMemo<PieEChartsOption>(
    () => ({
      textStyle: {
        color: activeThemeSchema[ColorVarsEnum.Level_2],
      },

      tooltip: {
        trigger: 'item',
        show: viewSettings.showTips,
        formatter: formattingFunction
          ? (data: any) => {
              const valueType = String(valueFormat.includes('percent') ? data.percent : data.value);
              const name = labelFormatGlobal.includes('name') ? data.name : '';
              const value = labelFormatGlobal.includes('value') ? data.value : '';
              const space = name && value ? '  ' : ' ';

              return name + space + formattingFunction?.(valueType) + `(${data.percent}%)`;
            }
          : '{b}: {c} ({d}%)',
        axisPointer: {
          lineStyle: {
            color: activeThemeSchema[ColorVarsEnum.Level_4],
          },
          crossStyle: {
            color: activeThemeSchema[ColorVarsEnum.Level_4],
          },
        },
      },

      toolbox: {
        iconStyle: {
          normal: {
            borderColor: activeThemeSchema[ColorVarsEnum.Level_4],
          },
        },
      },

      // color: colorsGlobal,

      yAxis: {
        show: false,
      },

      xAxis: {
        show: false,
      },

      grid: {
        show: false,
      },

      legend: {
        data: legendData,
        show: legendSettings.isShow,
        type: 'scroll',
        orient: isHorizontalLegendLocation ? 'vertical' : 'horizontal',
        textStyle: {
          color: getColorValues(fontColorLegend) || activeThemeSchema[ColorVarsEnum.Level_2],
          fontSize: fontSizeLegend,
          fontStyle: fontStyleLegend,
          fontWeight: fontWeightLegend,
          overflow: 'breakAll',
          width: isHorizontalLegendLocation ? legendSettings.width - 32 : undefined,
        },
        pageTextStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_2],
        },
        ...positionLegend,
      },

      series: [
        {
          name: mainIndicator.fieldName,
          type: 'pie',

          roseType: dataSettings.roseType === 'pie' ? undefined : dataSettings.roseType,
          center: isActiveCenterCoordinatesGlobal
            ? [centerXCoordinatesGlobalResult, centerYCoordinatesGlobalResult]
            : circlePosition,
          hoverAnimation: pieAnimationElementGlobal,
          avoidLabelOverlap: false,
          yAxisLabel: 0,
          radius: [innerRadiusGlobalResult, outerRadiusGlobalResult],

          itemStyle: {
            borderColor: viewSettings.showBackground
              ? activeThemeSchema[ColorVarsEnum.Level_4_widget]
              : activeThemeSchema[ColorVarsEnum.Level_5_application],
            borderRadius: borderRadiusGlobal,
            borderWidth: borderWidthGlobal,
            color: getItemSegmentColorResult || defaultColor,
          },

          startAngle: arcStartAngleGlobal,
          endAngle: arcEndAngleGlobal,

          markPoint: {
            symbol: 'circle',
            symbolSize: 200,
            symbolOffset: [13, 13],
            symbolRotate: 23,
          },

          lineStyle: {
            width: 3,
            type: 'solid',
            opacity: 1,
          },

          label: {
            overflow: 'truncate',
            trigger: 'item',
            position: 'outside',

            formatter: formattingFunction
              ? (data) => {
                  const valueType = String(valueFormat.includes('percent') ? data.percent : data.value);
                  const value = labelFormatGlobal.includes('value') ? data.value : '';
                  const name = labelFormatGlobal.includes('name') ? data.name : '';
                  const space = name && value ? ' - ' : ' ';
                  const formattedValue = value ? formattingFunction?.(valueType) : '';

                  return name + space + formattedValue;
                }
              : formatSeries,

            show: labelFormatGlobal.includes('name') || labelFormatGlobal.includes('value'),

            /* Font settings */
            lineHeight: 12,
            fontSize: propertiesGlobal.fontSize,
            fontWeight: propertiesGlobal.fontStyle.bold ? 'bold' : 'normal',
            fontStyle: propertiesGlobal.fontStyle.italic ? 'italic' : 'normal',
          },

          labelLine: {
            show: true,
          },

          axisLine: {
            lineStyle: {
              color: activeThemeSchema[ColorVarsEnum.Level_4],
            },
          },
          axisTick: {
            lineStyle: {
              color: activeThemeSchema[ColorVarsEnum.Level_4],
            },
          },
          axisLabel: {
            textStyle: {
              color: activeThemeSchema[ColorVarsEnum.Level_2],
            },
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: activeThemeSchema[ColorVarsEnum.Level_4],
            },
          },
          splitArea: {
            areaStyle: {
              color: activeThemeSchema[ColorVarsEnum.Level_4],
            },
          },

          data: pieDataResult,
        },
      ],
    }),
    [
      activeThemeSchema,
      viewSettings.showTips,
      viewSettings.showBackground,
      formattingFunction,
      legendData,
      legendSettings.isShow,
      legendSettings.width,
      isHorizontalLegendLocation,
      getColorValues,
      fontColorLegend,
      fontSizeLegend,
      fontStyleLegend,
      fontWeightLegend,
      positionLegend,
      mainIndicator.fieldName,
      dataSettings.roseType,
      isActiveCenterCoordinatesGlobal,
      centerXCoordinatesGlobalResult,
      centerYCoordinatesGlobalResult,
      circlePosition,
      pieAnimationElementGlobal,
      innerRadiusGlobalResult,
      outerRadiusGlobalResult,
      borderRadiusGlobal,
      borderWidthGlobal,
      getItemSegmentColorResult,
      defaultColor,
      arcStartAngleGlobal,
      arcEndAngleGlobal,
      formatSeries,
      labelFormatGlobal,
      propertiesGlobal.fontSize,
      propertiesGlobal.fontStyle.bold,
      propertiesGlobal.fontStyle.italic,
      pieDataResult,
      valueFormat,
    ],
  );

  const onChartClick = useCallback(
    (params: CallbackDataParams) => {
      const node = params.data as PieNode;
      onActiveNextIncisionIdChange({
        id,
        dataSettings,
        events,
        activeIncisionId: dataSettings.activeIncisionId,
        onChange: onActiveIncisionIdChange,
      });
      updateFilter({ selectedValue: params.name, fieldName: node.tableColumn });
    },
    [updateFilter, dataSettings, events, id],
  );

  const onChangeActiveIncision = (activeIncisionId: string | null) => {
    if (enabledFilters && dataSettings.activeIncisionId && activeIncisionId) {
      onDeleteFiltersForDrillDownVisualisation({
        dataSettings,
        enabledFilters,
        lastActiveIncisionId: dataSettings.activeIncisionId,
        nextActiveIncisionId: activeIncisionId,
      });
    }

    activeIncisionId && onActiveIncisionIdChange({ activeIncisionId, id });
  };

  const onEvents = {
    click: onChartClick,
  };

  return (
    <SingleIncisionLayout
      incisions={dataSettings.incisions}
      value={dataSettings.activeIncisionId}
      incisionSelectorPosition={viewSettings.incisionSelectorPosition}
      isDrillDown={!!isDrillDown}
      isVisible={viewSettings.isVisible}
      onChange={onChangeActiveIncision}
    >
      <WrappedReactECharts
        onEvents={onEvents}
        notMerge
        ref={(e) => {
          eChartRef.current = e?.getEchartsInstance();
        }}
        style={{ width: '100%', height: '100%' }}
        option={option}
      />
    </SingleIncisionLayout>
  );
};

export const PieVisualisation = memo(PieVisualisationComponent) as VisualisationOriginInterface<PieVisualisationType>;
