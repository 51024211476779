import React from 'react';
import { useGetActiveFilter } from 'modules/filters/hooks/getActiveFilter';
import { DefaultFilterEventsTabSettings } from 'modules/settingsContainer/common/data/DefaultFilterEventsTabSettings';

export const EventsTabMultiple = (isMultipleChoiceSettings: boolean) => {
  const { data, modelIdValue } = useGetActiveFilter({ type: 'single' });

  return (
    <DefaultFilterEventsTabSettings
      modelIdValue={modelIdValue}
      defaultIsMultipleMode={true}
      data={data}
      isMultipleChoiceSettings={isMultipleChoiceSettings}
    />
  );
};
