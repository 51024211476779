import { defaultScrollbarMixin } from 'constants/styles';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import styled, { css } from 'styled-components';

export const VisualizationItem = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  max-height: 32px;
  overflow: auto;
  font-weight: normal;
  font-family: Roboto, sans-serif;
  transition: all 200ms ease 0s;
  font-size: 14px;
  line-height: 17px;
  min-height: 35px;
  color: var(${ColorVarsEnum.Level_1});
  padding: 6px 8px;
  gap: 6px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${defaultScrollbarMixin};

  &:hover::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(${ColorVarsEnum.Accent});
    opacity: 0.2;
    pointer-events: none;
    z-index: -1;
  }

  ${({ selected }) =>
    selected &&
    css`
      ::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(${ColorVarsEnum.Accent});
        opacity: 0.3;
        pointer-events: none;
        z-index: -1;
      }
    `}
`;

export const PageWrapper = styled.div`
  min-height: 30%;
  flex-grow: 0;
  overflow: auto;
  ${defaultScrollbarMixin};
`;

export const ListWrapper = styled.div`
  flex-grow: 0;
  overflow: auto;
  ${defaultScrollbarMixin};
`;

export const Divider = styled.div`
  width: 100%;
  border-top: 1px solid var(${ColorVarsEnum.Level_5});
`;
