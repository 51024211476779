import React from 'react';
import { updateViewSettingsAction } from 'store/reducers/visualisations/actions';
import { useAppDispatch } from 'store';
import { TextSettingsControlInterface } from 'modules/settingsContainer/common/TextSettingsGroup/types';
import { TextSettingInterface, TextSettingColorInterface } from 'types/types';
import { TextSettingsGroup } from 'modules/settingsContainer/common/TextSettingsGroup';
import { GroupContainerSettings } from 'modules/settingsContainer//GroupContainerSettings';
import { PropertiesSettings } from 'modules/settingsContainer/common/data/PropertiesSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { defaultTextViewSettings } from 'store/reducers/visualisations/constants';
import { DefaultPropertiesInterface } from 'store/reducers/visualisations/types';
type TitleSettingsProps = TextSettingsControlInterface;

export const TitleSettings = ({ value, onChange: onExternalChange }: TitleSettingsProps) => {
  const dispatch = useAppDispatch();

  const onLocalChange = (newSettings: TextSettingInterface | TextSettingColorInterface) => {
    dispatch(updateViewSettingsAction({ header: newSettings }));
  };

  const onChange = onExternalChange || onLocalChange;

  const newValue: TextSettingColorInterface = { ...defaultTextViewSettings.text, ...value };

  return (
    <>
      <TextSettingsGroup
        value={value}
        onChange={(newSettings) => {
          onChange({ ...value, ...newSettings });
        }}
        titleText="Основной"
      />
      <GroupContainerSettings>
        <ElementContainerSettings>
          <PropertiesSettings
            title="Свойства текста"
            value={newValue as DefaultPropertiesInterface}
            onChange={(newSettings) => {
              onChange({ ...value, ...newSettings });
            }}
            isMainContainerSettings
            disabledBackgroundColorBy
            disabledPadding
            disabledFontColorByBlock
          />
        </ElementContainerSettings>
      </GroupContainerSettings>
    </>
  );
};
