import styled from 'styled-components';
import { InfoIcon } from 'assets/icons/withContainer';
import { defaultScrollbarMixin } from 'constants/styles';

export const StyledInfoIcon = styled(InfoIcon)`
  width: 16px;
`;

export const SqlContainer = styled.div<{
  codeEditorTheme: string;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 75vh;
  background: ${({ codeEditorTheme }) => (codeEditorTheme === 'dark' ? '#24292f' : '#ffffff')};
  overflow: auto;

  ${defaultScrollbarMixin};
`;

export const SqlEditorContainer = styled.div`
  flex-shrink: 0;
  width: 100%;
  overflow: hidden;
`;

export interface LocalValuesInterface {
  incisionRequest?: string;
  indicatorRequest?: string;
  serviceRequest?: string;
  filterAndGroupRequest?: string;
}
