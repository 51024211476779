import {
  ACTIVE_BOARD_AREA_LABEL,
  ACTIVE_BOARD_AREA_LABEL_IN_VIEW_MODE,
  ERROR_BOARD_AREA_LABEL,
  SHOW_MODE,
} from 'modules/workspace/constans';
import { handleClasses } from 'modules/workspace/components/VisualisationArea/constants';
import { RndDragCallback, RndResizeCallback } from 'react-rnd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { setDefaultSettingsComponentAction, setSettingsComponentAction } from 'store/reducers/visualSettings/actions';
import { RndBoardAreaContainer, StyledBoardAreaContainerInterface } from 'modules/workspace/components/BoardAreaContainer/styles';
import { FC, ReactFCNoop } from 'types/global';
import { BoardPositionConfigInterface, IdInterface } from 'types/store';
import { useAppDispatch } from 'store';
import { setActiveBoardElement, setActiveBoardElementInViewMode } from 'store/reducers/board';
import { useSelector } from 'react-redux';
import { getLayerIndexById } from 'store/reducers/board/getters';
import { getProjectSettings } from 'store/reducers/projectSettings/getters';
import { VisualizationErrorPopup } from 'modules/workspace/components/VisualizationErrorPopup';
import { ErrorWrapper } from 'modules/visualisations/components/ErrorWrapper';
import { useHoverVisible } from 'modules/visualisations/hooks/useHoverVisible';
import { enableActivateObjectFilters } from 'store/reducers/filters/actions';

export interface BoardAreaContainerProps extends IdInterface, Omit<StyledBoardAreaContainerInterface, 'zIndex'> {
  isShowMode: boolean;
  isActive: boolean;
  scale: number;
  config: BoardPositionConfigInterface;
  onChange: (config: Partial<BoardPositionConfigInterface>) => void;
  isError?: string | null;
  isInfluenceEditing: boolean;
  SettingComponent: ReactFCNoop;
  isActiveInViewMode?: boolean;
  isBlock: boolean;
}

export const BoardAreaContainer: FC<BoardAreaContainerProps> = ({
  id,
  isActive,
  isActiveInViewMode,
  isShowMode,
  isError,
  isInfluenceEditing,
  scale,
  SettingComponent,
  onChange,
  config,
  children,
  disableDragging,
  isBlock,
  ...containerSettings
}) => {
  const layerIndex = useSelector(getLayerIndexById(id));
  const { gridSpacing } = useSelector(getProjectSettings);
  const dispatch = useAppDispatch();
  const [hasError, setHasError] = useState(false);
  const boardRef = useRef(null);

  const { isVisibleError, onMouseElementEnter, onMouseElementLeave, setIsVisibleError } = useHoverVisible(!!isError);

  const boardAreaClassNames = `${isActive ? ACTIVE_BOARD_AREA_LABEL : ''} ${
    isActiveInViewMode ? ACTIVE_BOARD_AREA_LABEL_IN_VIEW_MODE : ''
  } ${hasError ? ERROR_BOARD_AREA_LABEL : ''} ${isShowMode ? SHOW_MODE : ''}`;

  const onBoardAreaClick = useCallback(() => {
    if (isInfluenceEditing) {
      return;
    }

    if (!(isActive || isShowMode)) {
      dispatch(setActiveBoardElement(id));
    } else if (isShowMode) {
      dispatch(setActiveBoardElementInViewMode(id));
      dispatch(enableActivateObjectFilters(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, isInfluenceEditing, isShowMode, id]);

  const onDragStop: RndDragCallback = (_, { x, y }) => {
    onChange({ x: Math.round(x / gridSpacing) * gridSpacing, y: Math.round(y / gridSpacing) * gridSpacing });
  };

  const onResizeStop: RndResizeCallback = (e, direction, ref, delta, position) => {
    if (!isShowMode) {
      onChange({
        width: Math.round(parseInt(ref.style.width)),
        height: Math.round(parseInt(ref.style.height)),
        ...position,
      });
    }
  };

  useEffect(() => {
    if (isActive) {
      dispatch(
        setSettingsComponentAction({
          SettingComponent,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  useEffect(() => {
    return () => {
      dispatch(setDefaultSettingsComponentAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RndBoardAreaContainer
      tabIndex="0"
      ref={boardRef}
      id={id}
      bounds="parent"
      resizeGrid={[gridSpacing, gridSpacing]}
      dragGrid={[gridSpacing, gridSpacing]}
      onMouseDown={onBoardAreaClick}
      size={{ width: config.width, height: config.height }}
      position={{
        x: config?.x !== undefined ? config.x : 10,
        y: config?.y !== undefined ? config.y : 10,
      }}
      scale={scale}
      onDragStop={onDragStop}
      onResizeStop={onResizeStop}
      disableDragging={!isActive || isShowMode || disableDragging || isBlock}
      resizeHandleClasses={handleClasses}
      className={boardAreaClassNames}
      zIndex={layerIndex}
      isError={isError}
      isInfluenceEditing={isInfluenceEditing}
      onMouseEnter={onMouseElementEnter}
      onMouseLeave={onMouseElementLeave}
      {...containerSettings}
    >
      <VisualizationErrorPopup
        hasError={isError}
        visibleError={isVisibleError}
        setVisibleError={setIsVisibleError}
        isShowMode={isShowMode}
        boardRef={boardRef}
        size={{ width: config.width, height: config.height }}
      />
      <ErrorWrapper onError={(error) => error && setHasError(true)}>{children}</ErrorWrapper>
    </RndBoardAreaContainer>
  );
};
