import { FC, NoopType, OnValueChange } from 'types/global';
import React, { useMemo, useRef } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { Button } from 'modules/ui';
import { DefaultDataSettingsInterface, GradientColorByInterface } from 'store/reducers/visualisations/types';
import { WrapperBySettings, WrapperByTabsType } from 'modules/settingsContainer/common/WrapperBySettings';
import { ConditionEditorField } from 'modules/settingsContainer/common/ConditionEditorField';
import { FixedAreaSettings } from 'modules/settingsContainer/common/FixedAreaSettings';
import { useModalState } from 'utils/hooks/modalState';
import { ColorByValue } from '../ColorByValue';
import { getHeatmapConditionOptions } from './constants';
import { ColorByCondition } from '../ColorByCondition';

export interface GradientColorBySpecificSettingsProps
  extends Partial<Pick<DefaultDataSettingsInterface, 'indicators'>>,
    OnValueChange<GradientColorByInterface> {
  isMainContainerSettings?: boolean;
  disabledComponentByCondition?: boolean;
  disabledComponentByGradientValue?: boolean;
  disabledComponentStepsValue?: boolean;
  disabledComponentBySpecificGradientValue?: boolean;
  disabledComponentByValue?: boolean;
  disabledComponentBySpecificSteps?: boolean;
  title?: string;
  titleMainContainerText?: string;
  switcherColorBy?: {
    switcherChangeColorBy?: NoopType;
    switcherStateColorBy?: boolean;
  };
}

export const GradientColorBySpecificSettings: FC<GradientColorBySpecificSettingsProps> = ({
  disabledComponentByGradientValue,
  disabledComponentByCondition,
  disabledComponentStepsValue,
  disabledComponentBySpecificSteps = false,
  disabledComponentByValue = false,
  disabledComponentBySpecificGradientValue = false,
  value,
  onChange,
  children,
  indicators,
  isMainContainerSettings = false,
  title,
  titleMainContainerText,
  switcherColorBy,
}) => {
  const mainContainerRef = useRef<HTMLDivElement | null>(null);

  const { isOpen, onOpen, onClose } = useModalState();

  const usingByCondition = useMemo(() => !disabledComponentByCondition, [disabledComponentByCondition]);
  const usingByValue = useMemo(() => !disabledComponentByValue, [disabledComponentByValue]);
  const usingByGradientValue = useMemo(() => !disabledComponentByGradientValue, [disabledComponentByGradientValue]);
  const usingByStepsValue = useMemo(() => !disabledComponentStepsValue, [disabledComponentStepsValue]);
  const usingBySpecificGradientValue = useMemo(
    () => !disabledComponentBySpecificGradientValue,
    [disabledComponentBySpecificGradientValue],
  );
  const usingBySpecificStepsValue = useMemo(() => !disabledComponentBySpecificSteps, [disabledComponentBySpecificSteps]);
  const conditionOptions = useMemo(
    () =>
      getHeatmapConditionOptions(
        usingByCondition,
        usingByGradientValue,
        usingByStepsValue,
        usingBySpecificGradientValue,
        usingBySpecificStepsValue,
        usingByValue,
      ),
    [
      usingByCondition,
      usingByGradientValue,
      usingBySpecificGradientValue,
      usingBySpecificStepsValue,
      usingByStepsValue,
      usingByValue,
    ],
  );

  const colorByTabs: WrapperByTabsType = {
    condition: {
      Activator: () => <ConditionEditorField value={value.byCondition.sqlCondition || ''} onOpenChange={onOpen} />,
      Settings: () => (
        <FixedAreaSettings
          onClose={onClose}
          open={isOpen}
          headerText={settingsText || ''}
          width={width}
          minWidth={minWidth}
          anchorEl={mainContainerRef.current}
        >
          <ColorByCondition metric={value.byCondition} onChange={(byCondition) => onChange({ ...value, byCondition })} />
        </FixedAreaSettings>
      ),
      settingsText: 'Изменить условие',
      width: '565px',
    },
    valueGradient: {
      Activator: () =>
        usingByGradientValue ? (
          <FlexContainer width="100%" alignItems="center" justifyContent="center">
            <Button text="Настроить" onClick={onOpen} />
          </FlexContainer>
        ) : null,
      Settings: () =>
        usingByGradientValue ? (
          <FixedAreaSettings
            onClose={onClose}
            open={isOpen}
            headerText={settingsText || ''}
            width={width}
            minWidth={minWidth}
            anchorEl={mainContainerRef.current}
          >
            <ColorByValue
              metric={value.byValueGradient}
              onChange={(byValueGradient) => onChange({ ...value, byValueGradient })}
              indicators={indicators}
            />
          </FixedAreaSettings>
        ) : null,
      settingsText: 'Изменить диапазон',
      minWidth: '180px',
    },
    valueSteps: {
      Activator: () =>
        usingByStepsValue ? (
          <FlexContainer width="100%" alignItems="center" justifyContent="center">
            <Button text="Настроить" onClick={onOpen} />
          </FlexContainer>
        ) : null,

      Settings: () =>
        usingByStepsValue ? (
          <FixedAreaSettings
            onClose={onClose}
            open={isOpen}
            headerText={settingsText || ''}
            width={width}
            minWidth={minWidth}
            anchorEl={mainContainerRef.current}
          >
            <ColorByValue
              metric={value.byValueSteps}
              onChange={(byValueSteps) => onChange({ ...value, byValueSteps })}
              indicators={indicators}
            />
          </FixedAreaSettings>
        ) : null,
      settingsText: 'Изменить диапазон',
      minWidth: '180px',
    },
    valueSpecificGradient: {
      Activator: () =>
        usingBySpecificGradientValue ? (
          <ColorByValue
            isGroup={false}
            metric={value.byValueSpecificGradient}
            onChange={(byValueSpecificGradient) => onChange({ ...value, byValueSpecificGradient })}
            indicators={indicators}
          />
        ) : null,
    },
    value: {
      Activator: () =>
        usingByValue ? (
          <ColorByValue
            isGroup={false}
            metric={value.byValue}
            onChange={(byValue) => onChange({ ...value, byValue })}
            indicators={indicators}
          />
        ) : null,
    },
    valueSpecificSteps: {
      Activator: () =>
        usingBySpecificStepsValue ? (
          <ColorByValue
            isGroup={false}
            metric={value.byValueSpecificSteps}
            onChange={(byValueSpecificSteps) => onChange({ ...value, byValueSpecificSteps })}
            indicators={indicators}
          />
        ) : null,
    },
    default: {
      Activator: () => <>{children}</>,
    },
  };

  const { width, minWidth, settingsText } = colorByTabs[value.type];

  return (
    <WrapperBySettings
      mainContainerRef={mainContainerRef}
      colorByTabs={colorByTabs}
      conditionOptions={conditionOptions}
      value={value}
      isMainContainerSettings={isMainContainerSettings}
      title={title}
      titleMainContainerText={titleMainContainerText}
      onChange={onChange}
      switcherColorBy={switcherColorBy}
    />
  );
};
