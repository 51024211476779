import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorPicker } from 'modules/settingsContainer/ColorPicker';
import Select from 'modules/ui/Select';
import React from 'react';
import { NoopType, OnValueChange } from 'types/global';
import { ConditionsMarkerType, CustomMarkersInterface } from 'store/reducers/visualisations/types';
import {
  conditionsMarkerDynamicOptions,
  markersOptions,
} from 'modules/settingsContainer/common/data/DynamicsMarkerSettings/constants';
import { ConditionAndValueMarker } from './ConditionAndValueMarker';
import { Button } from 'modules/ui';
import { DeleteIcon } from 'assets/icons/withContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

interface MarkerSettingsRowProps extends OnValueChange<CustomMarkersInterface> {
  onDelete: NoopType;
}

export const MarkerSettingsRow = ({ value, onChange, onDelete }: MarkerSettingsRowProps) => {
  const { values, conditions, color, marker } = value;
  const [min, max] = values;
  const [firstCondition, twoCondition] = conditions;

  return (
    <FlexContainer
      justifyContent="space-between"
      gap="16px"
      width="100%"
      padding="16px"
      borderBottom={`1px solid var(${ColorVarsEnum.Level_5})`}
    >
      <FlexContainer gap="8px" alignItems="center">
        <PrimaryTextSpan>{'Значение'}</PrimaryTextSpan>
        <ConditionAndValueMarker
          value={min}
          condition={firstCondition}
          options={conditionsMarkerDynamicOptions}
          onChangeCondition={(condition: ConditionsMarkerType) => onChange({ ...value, conditions: [condition, twoCondition] })}
          onChangeValue={(firstConditionValue: number) =>
            onChange({
              ...value,
              values: [firstConditionValue, max],
            })
          }
        />
        <PrimaryTextSpan>{'и'}</PrimaryTextSpan>
        <ConditionAndValueMarker
          value={max}
          condition={twoCondition}
          options={conditionsMarkerDynamicOptions}
          onChangeCondition={(condition) => onChange({ ...value, conditions: [firstCondition, condition] })}
          onChangeValue={(twoConditionValue) => onChange({ ...value, values: [min, twoConditionValue] })}
        />
        <PrimaryTextSpan>{'то'}</PrimaryTextSpan>
      </FlexContainer>
      <FlexContainer gap="8px">
        <FlexContainer gap="4px" alignItems="center">
          <PrimaryTextSpan>{'Цвет'}</PrimaryTextSpan>
          <ColorPicker value={color} onChange={(color) => onChange({ ...value, color })} closeOnClick={true} />
        </FlexContainer>
        <FlexContainer gap="4px" alignItems="center">
          <PrimaryTextSpan>{'Фигура'}</PrimaryTextSpan>
          <Select
            name="Marker"
            needBackground={false}
            width="100%"
            value={marker}
            options={markersOptions}
            onChange={(marker) => onChange({ ...value, marker })}
            needBorder={false}
          />
        </FlexContainer>
        <Button needBackground={false} leftIcon={<DeleteIcon />} onClick={onDelete} />
      </FlexContainer>
    </FlexContainer>
  );
};
