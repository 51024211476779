import { ColorValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { BubbleSizeModeSettings } from 'modules/settingsContainer/common/data/BubbleSizeModeSettings';
import { DefaultDataSettings } from 'modules/settingsContainer/common/data/DefaultDataSettings';
import { FormattingSettings } from 'modules/settingsContainer/common/data/FormattingSettings';
import { LimitSettings } from 'modules/settingsContainer/common/data/LimitSettings';
import { LineAndBarShowValueSettings } from 'modules/settingsContainer/common/data/LineAndBarShowValueSettings';
import { MetricSettings } from 'modules/settingsContainer/common/data/MetricSettings';
import { FieldSettingsRenderType, MetricsRenderType } from 'modules/settingsContainer/common/data/MetricSettings/types';
import { MinAndMaxSettings } from 'modules/settingsContainer/common/data/MinAndMaxSettings';
import { NameFromDatabaseSettings } from 'modules/settingsContainer/common/data/NameFromDatabaseSettings';
import { SqlSettings } from 'modules/settingsContainer/common/data/SqlSettings';
import { FictionalMetricField } from 'modules/settingsContainer/common/FictionalMetricField';
import { IndicatorMetricField } from 'modules/settingsContainer/common/IndicatorMetricField';
import { ModelSelectorSettings } from 'modules/settingsContainer/common/ModelSelectorSettings';
import { SettingsFieldEntry } from 'modules/settingsContainer/SettingsFieldEntry';
import {
  onChangBubbleSettings,
  onChangeIndicatorFormatting,
  onChangeIndicatorNameFromDataBase,
  onChangeIndicatorSizeMode,
  onChangeShowValue,
  onColorIncisionChange,
  onColorIndicatorChange,
  onMinAndMaxChange,
  onMoveIncision,
} from 'modules/visualisations/Bubble/settings/DataTab/constants';
import {
  onAddNewIncision,
  onChangeEmptyValue,
  onChangeFictionalData,
  onChangeIncisionCustomRequest,
  onChangeIncisionFieldName,
  onChangeIncisionName,
  onChangeIncisionNameFromDataBase,
  onChangeIndicatorCustomRequest,
  onChangeIndicatorName,
  onDeleteIncision,
  onLimitChange,
  onModelIdChange,
  onSqlSettingsSave,
} from 'modules/visualisations/common/onChangeFunctions';
import { useDataSettingsMetric } from 'modules/visualisations/hooks/dataSettingsMetric';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  defaultBubbleDataSettings,
  defaultBubbleViewSettings,
  defaultSqlDataSettings,
  getVisualisationFieldName,
} from 'store/reducers/visualisations/constants';
import { getAstForSqlGenerationQueryById } from 'store/reducers/visualisations/getters';
import {
  BubbleIncisionInterface,
  BubbleIndicatorInterface,
  DefaultIndicatorInterface,
} from 'store/reducers/visualisations/types';
import { getColumnsWithoutSelect } from 'utils/SQL/genereteAst';
import { formatSql } from 'utils/SQL/formatSQL';
import { EmptyValuesElementSettings } from 'modules/settingsContainer/common/data/EmptyValuesElementSettings';
import { useGetActiveVisualisationSettings } from 'utils/hooks/visualisation/getActiveVisualisationSettings';
import { getIncisionAndIndicatorsCompletions } from 'utils/sqlSettings';
import { BubbleSettings } from 'modules/settingsContainer/common/data/BubbleSettings';

export const DataTab = () => {
  const { id, dataSettings, viewSettings, astOfVisualisation, codeEditorData } = useGetActiveVisualisationSettings({
    defaultData: defaultBubbleDataSettings,
    defaultView: defaultBubbleViewSettings,
    defaultSqlData: defaultSqlDataSettings,
  });

  const { indicators } = useSelector(getAstForSqlGenerationQueryById(id));

  const defaultSizeIndicators = indicators.filter((item, index) => index !== 2);
  const viewRangeIndicatorId = viewSettings.elementDesign.rangeIndicator.rangeIndicatorId;

  const [indicatorXAxis, indicatorYAxis, indicatorSizeData] = dataSettings.indicators,
    sizeSettingsType = indicatorSizeData?.settings.sizeSettings.type,
    isDefaultSize = sizeSettingsType === 'default';

  const { isRealData, modelId } = dataSettings,
    { tableFields, controls, onMetricClick, onSetupClick, setupIsOpen, metricIsSelected, modelMetaData } = useDataSettingsMetric(
      dataSettings.modelId,
    );

  const filteredAstOfVisualisation = {
    ...astOfVisualisation,
    indicators: isDefaultSize ? defaultSizeIndicators : indicators,
  };

  let indicatorSqlString;

  try {
    indicatorSqlString = getColumnsWithoutSelect(defaultSizeIndicators);
  } finally {
    indicatorSqlString = formatSql(indicatorSqlString);
  }

  const codeEditorDataFiltered = {
    ...codeEditorData,
    indicatorSqlString: isDefaultSize ? indicatorSqlString : codeEditorData.indicatorSqlString,
  };

  const validatedIndicatorsCallback = (
    validatedIndicators: DefaultIndicatorInterface[],
    indicators: DefaultIndicatorInterface[],
  ) => {
    const isIndicatorSizeIndex = validatedIndicators.length === 2,
      defaultSizeIndicator = dataSettings.indicators[2],
      [indicatorXAxis, indicatorY] = indicators;

    if (!indicatorXAxis || !indicatorY) {
      return validatedIndicators;
    }

    if (isDefaultSize) {
      return [indicatorXAxis, indicatorY, defaultSizeIndicator] as DefaultIndicatorInterface[];
    }

    if (isIndicatorSizeIndex) {
      return [...validatedIndicators, defaultSizeIndicator] as DefaultIndicatorInterface[];
    }

    return validatedIndicators;
  };

  const incisionRender: MetricsRenderType<BubbleIncisionInterface> = ({ metrics }) => (
    <>
      {metrics.map(
        ({
          fieldName,
          name,
          fictionalData,
          id,
          settings: {
            nameFromDatabase,
            customRequest,
            bubbleSettings: {
              gradientValueColorSettings: { elementColor },
            },
          },
        }) => {
          const onUpClick = () => onMoveIncision(dataSettings, id, 'up');
          const onDownClick = () => onMoveIncision(dataSettings, id, 'down');
          const onChangeColors = (colors: ColorValuesByThemeType | null) => {
            onColorIncisionChange(dataSettings, colors, id);
          };

          return (
            <SettingsFieldEntry
              setupIsOpen={setupIsOpen(id)}
              onSetupClick={onSetupClick(id)}
              onClick={onMetricClick(id)}
              isSelected={metricIsSelected(id)}
              fieldValue={getVisualisationFieldName({ nameFromDatabase, fieldName, name })}
              canChangeField={!nameFromDatabase}
              onFieldChange={(name) => onChangeIncisionName(dataSettings, name, id)}
              onChangeColors={onChangeColors}
              onDownClick={onDownClick}
              onUpClick={onUpClick}
              colorsValue={elementColor}
              key={id}
            >
              <FictionalMetricField
                onCustomRequestChange={(customRequest) => onChangeIncisionCustomRequest(dataSettings, customRequest, id)}
                customRequest={customRequest || ''}
                disabled={!!customRequest}
                isRealData={isRealData}
                options={tableFields}
                value={{ fictionalData, fieldName }}
                onChange={({ fictionalData, fieldName }) => {
                  fieldName && onChangeIncisionFieldName(dataSettings, fieldName || '', id);
                  fictionalData && onChangeFictionalData(dataSettings, fictionalData, id);
                }}
                modelMetaData={modelMetaData}
              />
            </SettingsFieldEntry>
          );
        },
      )}
    </>
  );

  const incisionFieldSettingsRender: FieldSettingsRenderType<BubbleIncisionInterface> = ({ metric: incision }) => {
    const {
      id,
      settings: {
        emptyValues: { isEmptyValue, value },
        showValue,
        nameFromDatabase,
        bubbleSettings,
      },
    } = incision;

    const {
      rangeIndicator: { rangeIndicatorId, isShow: isShowRangeIndicator },
    } = bubbleSettings;

    const indicatorRangeId = isShowRangeIndicator ? rangeIndicatorId : viewRangeIndicatorId;

    return (
      <>
        <EmptyValuesElementSettings
          switcherValue={isEmptyValue}
          value={value}
          onChange={(value) => onChangeEmptyValue(dataSettings, value, id)}
        />
        <NameFromDatabaseSettings
          value={nameFromDatabase}
          onChange={(nameFromDatabase: boolean) => onChangeIncisionNameFromDataBase(dataSettings, nameFromDatabase, id)}
        />
        <BubbleSettings
          value={bubbleSettings}
          onChange={(bubbleSettings) => onChangBubbleSettings(dataSettings, bubbleSettings, id)}
          indicators={dataSettings.indicators}
          rangeIndicatorId={indicatorRangeId}
        />
        <LineAndBarShowValueSettings
          type="bar"
          disabledFontColorBy
          disabledComponentByValue={false}
          disabledComponentBySpecificValue
          indicators={dataSettings.indicators.filter((indicator) => indicator.id === indicatorRangeId)}
          value={showValue}
          onChange={(showValue) => onChangeShowValue(dataSettings, showValue, id)}
        />
      </>
    );
  };

  const indicatorRender: MetricsRenderType<BubbleIndicatorInterface> = ({ metrics }) => (
    <>
      {metrics.map(({ id, fieldName, name, operationType, customRequest, settings: { nameFromDatabase } }) => {
        const onChangeColors = (colors: ColorValuesByThemeType | null) => onColorIndicatorChange(dataSettings, colors, id);

        return (
          <SettingsFieldEntry
            setupIsOpen={setupIsOpen(id)}
            onSetupClick={onSetupClick(id)}
            onClick={onMetricClick(id)}
            isSelected={metricIsSelected(id)}
            fieldValue={getVisualisationFieldName({ name, nameFromDatabase, fieldName })}
            canChangeField={!nameFromDatabase}
            onFieldChange={(name) => onChangeIndicatorName(dataSettings, name, id)}
            onChangeColors={onChangeColors}
            disableColorPicker
            key={id}
            disableChangePriory
          >
            <IndicatorMetricField
              onCustomRequestChange={(customRequest) => onChangeIndicatorCustomRequest(dataSettings, customRequest, id)}
              customRequest={customRequest}
              options={tableFields}
              dataSettings={dataSettings}
              id={id}
              isRealData={isRealData}
              fieldName={fieldName}
              operationType={operationType}
              modelMetaData={modelMetaData}
            />
          </SettingsFieldEntry>
        );
      })}
    </>
  );

  const indicatorFieldSettingsRender: FieldSettingsRenderType<BubbleIndicatorInterface> = ({ metric: indicator }) => {
    const {
      id,
      settings: { formatting, minAndMax },
    } = indicator;

    return (
      <>
        <FormattingSettings value={formatting} onChange={(value) => onChangeIndicatorFormatting(dataSettings, value, id)} />
        <MinAndMaxSettings
          title="Мин/Макс для основных"
          value={minAndMax}
          onChange={(value) => onMinAndMaxChange(dataSettings, value, id)}
        />
      </>
    );
  };

  const indicatorSizeRender: MetricsRenderType<BubbleIndicatorInterface> = ({ metrics }) => (
    <>
      {metrics.map(({ id, fieldName, name, operationType, customRequest, settings: { nameFromDatabase } }) => {
        const onChangeColors = (colors: ColorValuesByThemeType | null) => onColorIndicatorChange(dataSettings, colors, id);

        return (
          <SettingsFieldEntry
            setupIsOpen={setupIsOpen(id)}
            onSetupClick={onSetupClick(id)}
            onClick={onMetricClick(id)}
            isSelected={metricIsSelected(id)}
            fieldValue={getVisualisationFieldName({ name, nameFromDatabase, fieldName })}
            canChangeField={!nameFromDatabase}
            onFieldChange={(name) => onChangeIndicatorName(dataSettings, name, id)}
            onChangeColors={onChangeColors}
            disableColorPicker
            key={id}
            disableChangePriory
          >
            {!isDefaultSize && (
              <IndicatorMetricField
                onCustomRequestChange={(customRequest) => onChangeIndicatorCustomRequest(dataSettings, customRequest, id)}
                customRequest={customRequest}
                options={tableFields}
                dataSettings={dataSettings}
                id={id}
                isRealData={isRealData}
                fieldName={fieldName}
                operationType={operationType}
                modelMetaData={modelMetaData}
              />
            )}
          </SettingsFieldEntry>
        );
      })}
    </>
  );

  const indicatorSizeFieldSettingsRender: FieldSettingsRenderType<BubbleIndicatorInterface> = ({ metric: indicator }) => {
    const {
      id,
      settings: { nameFromDatabase, sizeSettings },
    } = indicator;

    return (
      <>
        <NameFromDatabaseSettings
          value={nameFromDatabase}
          onChange={(nameFromDatabase: boolean) => onChangeIndicatorNameFromDataBase(dataSettings, nameFromDatabase, id)}
        />

        <BubbleSizeModeSettings
          value={sizeSettings}
          onChange={(sizeSettings) => onChangeIndicatorSizeMode(dataSettings, sizeSettings, id)}
        />
      </>
    );
  };

  return (
    <>
      <DefaultDataSettings dataSettings={dataSettings} />
      <ModelSelectorSettings value={dataSettings.modelId} onChange={onModelIdChange} />
      <MetricSettings
        titleText="Разрезы"
        addButtonText="Добавить разрез"
        fieldSettingsRender={incisionFieldSettingsRender}
        metricRender={incisionRender}
        metrics={dataSettings.incisions}
        onAdd={() => onAddNewIncision(dataSettings, 'bubble')}
        onDelete={(id) => id && onDeleteIncision(dataSettings, id)}
        controls={controls}
      />
      <MetricSettings
        titleText="Показатели"
        fieldSettingsRender={indicatorFieldSettingsRender}
        metricRender={indicatorRender}
        metrics={[indicatorXAxis, indicatorYAxis]}
        controls={controls}
        disableAddingMetric
      />
      <MetricSettings
        titleText="Размер пузырька"
        fieldSettingsRender={indicatorSizeFieldSettingsRender}
        metricRender={indicatorSizeRender}
        metrics={[indicatorSizeData]}
        controls={controls}
        disableAddingMetric
      />
      <SqlSettings
        astData={filteredAstOfVisualisation}
        sqlData={codeEditorDataFiltered}
        adviceEditorIncision={getIncisionAndIndicatorsCompletions(dataSettings.incisions)}
        adviceEditorIndicator={getIncisionAndIndicatorsCompletions(dataSettings.indicators)}
        modelMetaData={modelMetaData}
        modelId={modelId}
        onSave={(sqlSettingsChanges) =>
          onSqlSettingsSave(
            dataSettings,
            sqlSettingsChanges,
            'bubble',
            {
              indicator: { minValue: 2, maxValue: 3 },
              incision: { minValue: 1 },
            },
            validatedIndicatorsCallback,
          )
        }
      />

      <LimitSettings value={dataSettings.limit} onChange={onLimitChange} />
    </>
  );
};
