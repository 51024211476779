import React from 'react';
import { OnValueChange } from 'types/global';
import { BubbleIndicatorInterface, BubbleSettingsInterface } from 'store/reducers/visualisations/types';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import SubSection from 'shared/ui/subSection';
import { IndicatorSelectorSettings } from 'modules/settingsContainer/common/IndicatorSelectorSettings';
import { GradientColorBySpecificSettings } from 'modules/settingsContainer/common/GradientColorBySpecificSettings';
import { CircleActivator, ColorPicker } from 'modules/settingsContainer/ColorPicker';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';

type BubbleSettingsProps = OnValueChange<BubbleSettingsInterface> & {
  rangeIndicatorId: string;
  indicators: BubbleIndicatorInterface[];
};

export const BubbleSettings = ({ value, indicators, rangeIndicatorId, onChange }: BubbleSettingsProps) => {
  const { defaultColor } = useColorValues();

  const { gradientValueColorSettings, rangeIndicator } = value;

  return (
    <MainContainerSettings titleText="Пузырьки">
      <SubSection>
        <IndicatorSelectorSettings
          title="Построить диапазон"
          indicators={indicators}
          value={rangeIndicator}
          onChange={(rangeIndicator) => onChange({ ...value, rangeIndicator })}
        />
      </SubSection>
      <SubSection>
        <GradientColorBySpecificSettings
          isMainContainerSettings
          disabledComponentStepsValue
          disabledComponentByGradientValue
          disabledComponentByValue
          titleMainContainerText="Изменить цвет пузырьков"
          indicators={indicators?.filter((indicator) => indicator.id === rangeIndicatorId)}
          value={gradientValueColorSettings.elementColorBy}
          onChange={(elementColorBy) =>
            onChange({ ...value, gradientValueColorSettings: { ...gradientValueColorSettings, elementColorBy } })
          }
          switcherColorBy={{
            switcherStateColorBy: gradientValueColorSettings.isActive,
            switcherChangeColorBy: () =>
              onChange({
                ...value,
                gradientValueColorSettings: {
                  ...gradientValueColorSettings,
                  isActive: !gradientValueColorSettings.isActive,
                },
              }),
          }}
        >
          <ColorPicker
            Activator={CircleActivator}
            closeOnClick
            type="color"
            onChange={(elementColor) =>
              onChange({ ...value, gradientValueColorSettings: { ...gradientValueColorSettings, elementColor } })
            }
            value={gradientValueColorSettings.elementColor}
            defaultActivatorBackgroundColor={defaultColor}
          />
        </GradientColorBySpecificSettings>
      </SubSection>
    </MainContainerSettings>
  );
};
