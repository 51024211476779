import {
  AliasInterface,
  ColorAndImageByEnum,
  ColorByConditionInterface,
  ColorByValueInterface,
  DefaultByInterface,
  DefaultIndicatorInterface,
  VisualisationDataInterface,
} from 'store/reducers/visualisations/types';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { getGradientInterpolator, getMaxAndMinFromArray, isColor } from 'utils/utils';
import { useCallback, useMemo } from 'react';
import { colorSpecificAlias, getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

interface GetColorByValueParams extends AliasInterface {
  indicatorName: string;
  value?: number | string | null;
  defaultColor?: string;
}

export type GetColorByValueType = (params: GetColorByValueParams) => string;

interface ColorByInterface extends DefaultByInterface<string> {
  byCondition: ColorByConditionInterface;
  byValue?: ColorByValueInterface;
  byValueSpecific?: ColorByValueInterface;
}

export interface ColorByParams extends VisualisationDataInterface {
  colorsBy?: ColorByInterface[];
  indicators?: DefaultIndicatorInterface[];
}

export const useColorBy = ({ visualisationValues, colorsBy = [], indicators }: ColorByParams) => {
  const { getColorValues, defaultColor, activeThemeSchema } = useColorValues();

  const colorsByConditionMapper = useMemo(() => {
    return colorsBy?.reduce<Record<string, Record<string, string>>>(
      (result, { byCondition: { alias, colors } }) => ({
        ...result,
        [alias]: colors.reduce((result, { value }, index) => ({ ...result, [index + 1]: getColorValues(value) }), {}),
      }),
      {},
    );
  }, [colorsBy, getColorValues]);

  const colorByValue: (ColorByInterface['byValue'] | undefined)[] = useMemo(
    () => colorsBy.map((color) => color?.byValue),
    [colorsBy],
  );

  const colorByValueSpecific: (ColorByInterface['byValueSpecific'] | undefined)[] = useMemo(
    () => colorsBy.map((color) => color.byValueSpecific),
    [colorsBy],
  );

  // Собираем все значения из всех показателей для вычисления глобальных min и max
  const globalMinMax = useMemo(() => {
    const allValues = (indicators || [])
      .flatMap(({ fieldName, name, settings: { nameFromDatabase } }) => {
        const indicatorName = getVisualisationFieldName({ fieldName, name, nameFromDatabase });
        const values = visualisationValues?.[indicatorName] || [];
        return values.map((value) => (value !== null ? value.toString() : ''));
      })
      .filter((value): value is string => value !== '');

    const numberValues = allValues.map((value) => parseFloat(value)).filter((value) => !isNaN(value));

    const min = numberValues.length > 0 ? Math.min(...numberValues) : 0;
    const max = numberValues.length > 0 ? Math.max(...numberValues) : 0;

    return { min, max };
  }, [indicators, visualisationValues]);

  // Глобальный цветовой маппер для byValueSpecific
  const globalColorMapper = useMemo(() => {
    if (!colorByValueSpecific.length) return null;

    return colorByValueSpecific.map((color) => ({
      alias: color?.alias,
      mapper: getGradientInterpolator({
        colors: (color?.colors?.[colorSpecificAlias] || []).map(({ value }) => getColorValues(value) || defaultColor),
        minValue: globalMinMax.min,
        maxValue: globalMinMax.max,
      }),
    }));
  }, [colorByValueSpecific, globalMinMax, getColorValues, defaultColor]);

  const colorByIndicatorsMapper = useMemo<Record<string, Record<string, ReturnType<typeof getGradientInterpolator>>>>(
    () =>
      (indicators || [])?.reduce((result, { id, name, fieldName, settings: { nameFromDatabase } }) => {
        const indicatorName = getVisualisationFieldName({ fieldName, name, nameFromDatabase }),
          indicatorData = visualisationValues?.[indicatorName],
          { max, min } = getMaxAndMinFromArray(indicatorData);

        const colors = colorByValue.map((color) => {
          return (
            color?.colors?.[id]?.map(({ value }) => {
              return { alias: color?.alias, color: getColorValues(value) || defaultColor };
            }) || []
          );
        });

        const indicatorColors = colors?.reduce((result, value) => {
          return {
            ...result,
            [value[0]?.alias]: getGradientInterpolator({
              colors: value.map((value) => value?.color),
              minValue: min,
              maxValue: max,
            }),
          };
        }, {});

        return {
          ...result,
          [indicatorName]: indicatorColors,
        };
      }, {}),
    [indicators, visualisationValues, colorByValue, defaultColor, getColorValues],
  );

  const getColorValueByCondition = useCallback(
    (alias: string, value?: number | string | null) => {
      if (isColor(value)) {
        return value as string;
      }

      return colorsByConditionMapper[alias]?.[value || ''] || defaultColor;
    },
    [colorsByConditionMapper, defaultColor],
  );

  const getColorValueByValue = useCallback<GetColorByValueType>(
    ({ indicatorName, value, alias }) => {
      if (!value || typeof value === 'string') return defaultColor;

      return (
        (colorByIndicatorsMapper?.[indicatorName] && colorByIndicatorsMapper?.[indicatorName][alias]?.(value)) || defaultColor
      );
    },
    [colorByIndicatorsMapper, defaultColor],
  );

  const getColorValueByValueSpecific = useCallback(
    (alias: string) => {
      const mapper = globalColorMapper?.find((m) => m.alias === alias);

      return mapper
        ? (value: number | string | null) => {
            if (value === null) return defaultColor;
            return mapper.mapper(typeof value === 'number' ? value : parseFloat(value));
          }
        : () => defaultColor;
    },
    [globalColorMapper, defaultColor],
  );

  const isColorByCondition = useCallback(
    (aliasByCondition: string) =>
      colorsBy.some(({ byCondition: { alias }, type }) => alias === aliasByCondition && type === ColorAndImageByEnum.Condition),
    [colorsBy],
  );

  const getColorByValue = useCallback<GetColorByValueType>(
    ({ indicatorName, value, alias }): string => {
      if (isColorByCondition(alias)) {
        return getColorValueByCondition(alias, value);
      } else {
        return getColorValueByValue({ indicatorName, value, alias });
      }
    },
    [isColorByCondition, getColorValueByCondition, getColorValueByValue],
  );

  const getColorBySpecificValue = useCallback<GetColorByValueType>(
    ({ value, alias, defaultColor }): string => {
      if (isColorByCondition(alias)) {
        return getColorValueByCondition(alias, value);
      } else {
        const globalMapper = getColorValueByValueSpecific(alias);
        return globalMapper(value || 0) || defaultColor || activeThemeSchema[ColorVarsEnum.Level_1];
      }
    },
    [isColorByCondition, getColorValueByCondition, getColorValueByValueSpecific, activeThemeSchema],
  );

  const getVisualisationColorsAndImagesData = (alias: string) => visualisationValues?.[alias] || [];

  return {
    getColorByValue,
    getColorValueByCondition,
    getVisualisationColorsAndImagesData,
    getColorValueByValue,
    getColorValueByValueSpecific,
    getColorBySpecificValue,
    isColorByCondition,
  };
};
