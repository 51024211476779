import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DefaultVisualisationsTypeProps } from 'assets/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const FlatTable: FC<DefaultVisualisationsTypeProps> = ({
  backgroundFill = `var(${ColorVarsEnum.Level_4_widget})`,
  fillDefault = `var(${ColorVarsEnum.Level_1})`,
  symbolSize = 70,
}) => {
  return (
    <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
      <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="70" height="70" rx="5" fill={backgroundFill} />
        <path
          opacity="0.75"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M62 8H8V19H62V8ZM56 12H48V15H56V12ZM31 12H39V15H31V12ZM22 12H14V15H22V12Z"
          fill={fillDefault}
        />
        <rect opacity="0.15" x="8" y="27" width="54" height="3" fill={fillDefault} />
        <rect opacity="0.15" x="8" y="38" width="54" height="3" fill={fillDefault} />
        <rect opacity="0.15" x="8" y="49" width="54" height="3" fill={fillDefault} />
        <rect opacity="0.15" x="8" y="59" width="54" height="3" fill={fillDefault} />
        <rect opacity="0.15" x="59" y="62" width="43" height="3" transform="rotate(-90 59 62)" fill={fillDefault} />
        <rect opacity="0.15" x="42" y="62" width="43" height="3" transform="rotate(-90 42 62)" fill={fillDefault} />
        <rect opacity="0.15" x="8" y="19" width="19" height="43" fill={fillDefault} />
      </svg>
    </FlexContainer>
  );
};
