import { VisualisationTypeType } from 'store/reducers/visualisations/types';
import { FilterType } from 'store/reducers/filters/types';
import { FilterDateDefault, FilterSingleIcon, FilterMultipleDefault, FilterSingleDefault } from 'assets/icons/filterType';
import {
  BubbleDefault,
  ButtonText,
  FlatTable,
  GroupedBar,
  HalfPie,
  HeatmapDefault,
  HyperlinkText,
  ImageText,
  KPITwoText,
  LineAndBarDefault,
  LineAndBarWithDeviation,
  MonthlyLine,
  MonthlyLineAndBar,
  OctantPie,
  PieDefault,
  PivotTable,
  TableDefault,
  TextDefault,
  TreemapDefault,
  WaterfallDefault,
} from 'assets/icons/visualisationsType';
import { NoopType, ReactFC } from 'types/global';
import { DefaultVisualisationsTypeProps } from 'assets/types';
import { KPIOneText } from 'assets/icons/visualisationsType/text/KPIOneText';
import { IconType } from 'assets/icons/withContainer';

export type RenderFooterProps = {
  onClose?: NoopType;
};

export const visualisations: {
  visualisationType: VisualisationTypeType;
  Icon: ReactFC<DefaultVisualisationsTypeProps>;
  title: string;
}[] = [
  { visualisationType: 'lineAndBar', Icon: LineAndBarDefault, title: 'Комбинированная' },
  { visualisationType: 'pie', Icon: PieDefault, title: 'Круговая' },
  { visualisationType: 'waterfall', Icon: WaterfallDefault, title: 'Водопад (Мост)' },
  { visualisationType: 'tree', Icon: TreemapDefault, title: 'Дерево' },
  { visualisationType: 'bubble', Icon: BubbleDefault, title: 'Пузырьковая' },
  { visualisationType: 'table', Icon: TableDefault, title: 'Таблица' },
  { visualisationType: 'heatmap', Icon: HeatmapDefault, title: 'Тепловая карта' },
  { visualisationType: 'text', Icon: TextDefault, title: 'Медиа-блок' },
];

export const filters: { type: FilterType; Icon: typeof FilterSingleIcon; title: string }[] = [
  { type: 'single', Icon: FilterSingleDefault, title: 'Фильтр-кнопки' },
  { type: 'multiple', Icon: FilterMultipleDefault, title: 'Фильтр-список' },
  { type: 'date', Icon: FilterDateDefault, title: 'Фильтр по периоду' },
];

export const visualisationsPreviewIcons: Record<string, IconType> = {
  MonthlyLineAndBar: MonthlyLineAndBar,
  MonthlyLine: MonthlyLine,
  LineAndBarWithDeviation: LineAndBarWithDeviation,
  GroupedBar: GroupedBar,
  HalfPie: HalfPie,
  OctantPie: OctantPie,
  PivotTable: PivotTable,
  FlatTable: FlatTable,
  KPIOneText: KPIOneText,
  KPITwoText: KPITwoText,
  ImageText: ImageText,
  HyperlinkText: HyperlinkText,
  ButtonText: ButtonText,
};
