import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DefaultVisualisationsTypeProps } from 'assets/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const KPIOneText: FC<DefaultVisualisationsTypeProps> = ({
  backgroundFill = `var(${ColorVarsEnum.Level_4_widget})`,
  fillDefault = `var(${ColorVarsEnum.Level_1})`,
  symbolSize = 70,
}) => {
  return (
    <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
      <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="70" height="70" rx="5" fill={backgroundFill} />
        <path
          d="M11.0996 22.1477V18.8906H9.63477V26H11.0996V23.95L11.8627 23.1296L13.6924 26H15.4258L12.8328 22.0366L15.3574 18.8906H13.5605L11.749 21.2588L11.0996 22.1477Z"
          fill={fillDefault}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.457 23.4658H18.7607C19.3174 23.4658 19.7943 23.3746 20.1914 23.1924C20.5918 23.0101 20.8978 22.7513 21.1094 22.416C21.321 22.0807 21.4268 21.6836 21.4268 21.2246C21.4268 20.7721 21.321 20.3701 21.1094 20.0186C20.8978 19.667 20.5918 19.392 20.1914 19.1934C19.7943 18.9916 19.3174 18.8906 18.7607 18.8906H15.9922V26H17.457V23.4658ZM17.457 22.3232V20.0381H18.7607C19.0407 20.0381 19.2686 20.0934 19.4443 20.2041C19.6201 20.3148 19.7487 20.4613 19.8301 20.6436C19.8936 20.7858 19.9324 20.9371 19.9463 21.0972C19.9502 21.1422 19.9521 21.188 19.9521 21.2344C19.9521 21.4427 19.9114 21.6299 19.8301 21.7959C19.7487 21.9619 19.6201 22.0922 19.4443 22.1865C19.2686 22.2777 19.0407 22.3232 18.7607 22.3232H17.457Z"
          fill={fillDefault}
        />
        <path d="M23.9951 26V18.8906H22.5352V26H23.9951Z" fill={fillDefault} />
        <path
          d="M26.6074 25.7559L26.6123 24.8135H25.4307V25.8242C25.4307 26.0521 25.4046 26.2555 25.3525 26.4346C25.3005 26.6136 25.2354 26.778 25.1572 26.9277C25.0791 27.0775 24.9993 27.2272 24.918 27.377L25.6504 27.7529C25.9271 27.5186 26.155 27.2174 26.334 26.8496C26.5163 26.4818 26.6074 26.1172 26.6074 25.7559Z"
          fill={fillDefault}
        />
        <g opacity="0.55">
          <path d="M32.2373 26V21.8057H33.9365V20.7168H29.1514V21.8057H30.8262V26H32.2373Z" fill={fillDefault} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.1592 22.2842H36.1582V20.7168H34.7471V26H37.1592C37.5205 26 37.8428 25.9545 38.126 25.8633C38.4092 25.7721 38.6484 25.6436 38.8438 25.4775C39.0391 25.3115 39.1872 25.1162 39.2881 24.8916C39.3923 24.6637 39.4443 24.4164 39.4443 24.1494C39.4443 23.7816 39.3532 23.4576 39.1709 23.1777C38.9919 22.8978 38.7314 22.6797 38.3896 22.5234C38.0511 22.3639 37.6409 22.2842 37.1592 22.2842ZM36.1582 23.3828V24.9062H37.1592C37.2605 24.9062 37.3521 24.8984 37.434 24.8828C37.5232 24.8657 37.6008 24.8394 37.667 24.8037C37.7972 24.7321 37.8916 24.6393 37.9502 24.5254C38.012 24.4115 38.043 24.2877 38.043 24.1543C38.043 24.1001 38.0383 24.048 38.0291 23.9978C38.0144 23.9181 37.9881 23.8433 37.9502 23.7734C37.8916 23.6562 37.7972 23.5619 37.667 23.4902C37.54 23.4186 37.3708 23.3828 37.1592 23.3828H36.1582Z"
            fill={fillDefault}
          />
          <path d="M41.7002 26V20.7168H40.2939V26H41.7002Z" fill={fillDefault} />
          <path
            d="M45.5771 24.9014C45.4437 24.9664 45.2907 24.999 45.1182 24.999C44.9099 24.999 44.7405 24.9567 44.6104 24.8721C44.4802 24.7875 44.3776 24.6719 44.3027 24.5254C44.2311 24.3789 44.1823 24.2113 44.1562 24.0225C44.1302 23.8336 44.1172 23.6367 44.1172 23.4316V23.29C44.1172 23.085 44.1302 22.8896 44.1562 22.7041C44.1705 22.6122 44.1898 22.5253 44.2141 22.4435C44.2397 22.3572 44.2709 22.2764 44.3076 22.2012C44.333 22.1481 44.3621 22.0991 44.3949 22.0542C44.4545 21.9724 44.5264 21.9042 44.6104 21.8496C44.6935 21.7935 44.7919 21.7552 44.9055 21.735C44.9698 21.7235 45.0391 21.7178 45.1133 21.7178C45.233 21.7178 45.3408 21.7349 45.4365 21.769C45.4943 21.7897 45.5477 21.8165 45.5967 21.8496C45.7302 21.9342 45.8311 22.0482 45.8994 22.1914C45.971 22.3346 46.0085 22.4941 46.0117 22.6699H47.335C47.3317 22.25 47.2357 21.8871 47.0469 21.5811C46.8613 21.2719 46.6042 21.0342 46.2754 20.8682C45.9499 20.7021 45.5706 20.6191 45.1377 20.6191C44.7308 20.6191 44.376 20.6875 44.0732 20.8242C43.7705 20.9609 43.5182 21.1514 43.3164 21.3955C43.1146 21.6364 42.9632 21.9196 42.8623 22.2451C42.7614 22.5674 42.7109 22.9156 42.7109 23.29V23.4316C42.7109 23.8027 42.7614 24.151 42.8623 24.4766C42.9632 24.7988 43.1146 25.082 43.3164 25.3262C43.5182 25.567 43.7705 25.7559 44.0732 25.8926C44.3792 26.0293 44.7373 26.0977 45.1475 26.0977C45.5544 26.0977 45.9222 26.0162 46.251 25.8535C46.5798 25.6875 46.8418 25.4613 47.0371 25.1748C47.2324 24.8851 47.3317 24.5547 47.335 24.1836H46.0117C46.01 24.2711 45.9979 24.3528 45.9753 24.4285C45.9551 24.4965 45.9266 24.5597 45.8896 24.6182C45.8148 24.7386 45.7106 24.833 45.5771 24.9014Z"
            fill={fillDefault}
          />
          <path
            d="M48.4336 24.7988C48.2903 24.9388 48.2188 25.1129 48.2188 25.3213C48.2188 25.5297 48.2903 25.7037 48.4336 25.8438C48.5801 25.9838 48.7737 26.0537 49.0146 26.0537C49.2556 26.0537 49.4476 25.9838 49.5908 25.8438C49.7373 25.7037 49.8105 25.5297 49.8105 25.3213C49.8105 25.1129 49.7373 24.9388 49.5908 24.7988C49.4476 24.6588 49.2556 24.5889 49.0146 24.5889C48.7737 24.5889 48.5801 24.6588 48.4336 24.7988Z"
            fill={fillDefault}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.7041 23.4658H56.9932C57.5433 23.4658 58.0169 23.3763 58.4141 23.1973C58.8145 23.0182 59.1237 22.7594 59.3418 22.4209C59.5632 22.0824 59.6738 21.6771 59.6738 21.2051C59.6738 20.7559 59.5664 20.3571 59.3516 20.0088C59.1367 19.6605 58.8291 19.3871 58.4287 19.1885C58.0316 18.9899 57.553 18.8906 56.9932 18.8906H54.2393V22.3232H53.2529V23.4658H54.2393V23.8662H53.2529V25.0088H54.2393V26H55.7041V25.0088H57.2617V23.8662H55.7041V23.4658ZM55.7041 22.3232H56.959C57.2617 22.3232 57.5026 22.276 57.6816 22.1816C57.864 22.0873 57.9958 21.957 58.0771 21.791C58.1585 21.6217 58.1992 21.4297 58.1992 21.2148C58.1992 21.1292 58.192 21.0464 58.1776 20.9666C58.1564 20.8492 58.1197 20.7384 58.0674 20.6338C57.9827 20.4547 57.8509 20.3115 57.6719 20.2041C57.4961 20.0934 57.2715 20.0381 56.998 20.0381H55.7041V22.3232Z"
            fill={fillDefault}
          />
        </g>
        <path
          d="M19.2607 47.9995V45.5874H13.9355L15.6797 43.5742C16.1868 43.0732 16.6384 42.6001 17.0342 42.1548C17.4362 41.7095 17.7764 41.2765 18.0547 40.856C18.333 40.4292 18.5464 39.9994 18.6948 39.5664C18.8433 39.1334 18.9175 38.682 18.9175 38.2119C18.9175 37.3893 18.7443 36.6874 18.3979 36.106C18.0578 35.5184 17.5506 35.0699 16.8765 34.7607C16.2023 34.4515 15.3674 34.2969 14.3716 34.2969C13.4191 34.2969 12.5811 34.504 11.8574 34.9185C11.1338 35.3267 10.5679 35.874 10.1597 36.5605C9.75763 37.2471 9.55664 38.0048 9.55664 38.8335H12.6924C12.6924 38.4315 12.7573 38.0697 12.8872 37.748C12.9754 37.5396 13.0858 37.3596 13.2181 37.2083C13.29 37.1261 13.3683 37.0525 13.4531 36.9873C13.6943 36.8018 13.9758 36.709 14.2974 36.709C14.619 36.709 14.888 36.777 15.1045 36.9131C15.3271 37.0492 15.4941 37.2501 15.6055 37.5161C15.723 37.7759 15.7817 38.1006 15.7817 38.4902C15.7817 38.7191 15.7261 38.9757 15.6147 39.2603C15.5096 39.5448 15.3395 39.8633 15.1045 40.2158C14.8694 40.5621 14.5602 40.955 14.1768 41.394L9.83496 45.9585V47.9995H19.2607Z"
          fill={fillDefault}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.4771 45.1328V42.7207H29.1133V34.4917H25.9868V34.5066L20.5688 43.2217L20.7544 45.1328H25.9868V47.9995H29.1133V45.1328H30.4771ZM25.9868 38.5458L25.7827 38.8799L23.5562 42.7207H25.9868V38.5458Z"
          fill={fillDefault}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.2573 42.4795V40.0117C41.2573 39.2324 41.1831 38.5366 41.0347 37.9243C40.8862 37.3058 40.6729 36.7709 40.3945 36.3193C40.1224 35.8678 39.7915 35.4937 39.4019 35.1968C39.0122 34.8937 38.5762 34.671 38.0938 34.5288C37.6175 34.3804 37.1011 34.3062 36.5444 34.3062C35.8517 34.3062 35.2178 34.4205 34.6426 34.6494C34.0674 34.8783 33.5695 35.2277 33.1489 35.6978C32.7283 36.1678 32.4036 36.7616 32.1748 37.479C31.9521 38.1903 31.8408 39.0345 31.8408 40.0117V42.4795C31.8408 43.2588 31.915 43.9576 32.0635 44.5762C32.2119 45.1885 32.4253 45.7203 32.7036 46.1719C32.9819 46.6234 33.3128 47.0006 33.6963 47.3037C34.0859 47.6006 34.522 47.8232 35.0044 47.9717C35.4868 48.1139 36.0063 48.1851 36.563 48.1851C37.2557 48.1851 37.8896 48.0707 38.4648 47.8418C39.04 47.6129 39.5349 47.2635 39.9492 46.7935C40.3698 46.3234 40.6914 45.7296 40.9141 45.0122C41.1429 44.2948 41.2573 43.4506 41.2573 42.4795ZM38.1309 39.6128V42.8599C38.1309 43.4042 38.0938 43.8649 38.0195 44.2422C37.9515 44.6133 37.8464 44.9132 37.7041 45.1421C37.5681 45.3647 37.4041 45.5255 37.2124 45.6245C37.0207 45.7235 36.8042 45.7729 36.563 45.7729C36.3651 45.7729 36.1826 45.7421 36.0156 45.6802C35.9009 45.6377 35.7949 45.5776 35.6976 45.5001C35.6533 45.4648 35.6109 45.4259 35.5703 45.3833C35.498 45.3042 35.4315 45.2125 35.3707 45.1085C35.3223 45.0255 35.2775 44.9347 35.2363 44.8359C35.1497 44.6071 35.0817 44.3319 35.0322 44.0103C34.989 43.6825 34.9673 43.299 34.9673 42.8599V39.6128C34.9673 39.0747 35.0013 38.6201 35.0693 38.249C35.1436 37.8779 35.2487 37.5811 35.3848 37.3584C35.5208 37.1357 35.6848 36.975 35.8765 36.876C36.0744 36.7709 36.2971 36.7183 36.5444 36.7183C36.6608 36.7183 36.7707 36.73 36.8742 36.7535C36.9468 36.77 37.0162 36.7922 37.0825 36.8203C37.2495 36.8822 37.3979 36.9843 37.5278 37.1265C37.6577 37.2626 37.7659 37.4419 37.8525 37.6646C37.9453 37.8872 38.0134 38.1593 38.0566 38.481C38.1061 38.8026 38.1309 39.1798 38.1309 39.6128Z"
          fill={fillDefault}
        />
        <path
          d="M43.6323 45.4204C43.3107 45.7173 43.1499 46.0884 43.1499 46.5337C43.1499 46.979 43.3107 47.3531 43.6323 47.6562C43.9539 47.9531 44.3683 48.1016 44.8755 48.1016C45.3889 48.1016 45.8032 47.9531 46.1187 47.6562C46.4402 47.3531 46.6011 46.979 46.6011 46.5337C46.6011 46.0884 46.4402 45.7173 46.1187 45.4204C45.8032 45.1173 45.3889 44.9658 44.8755 44.9658C44.3683 44.9658 43.9539 45.1173 43.6323 45.4204Z"
          fill={fillDefault}
        />
        <path
          d="M48.6514 47.9995H58.0771V45.5874H52.752L54.4961 43.5742C55.0032 43.0732 55.4548 42.6001 55.8506 42.1548C56.2526 41.7095 56.5928 41.2765 56.8711 40.856C57.1494 40.4292 57.3628 39.9994 57.5112 39.5664C57.6597 39.1334 57.7339 38.682 57.7339 38.2119C57.7339 37.3893 57.5607 36.6874 57.2144 36.106C56.8742 35.5184 56.367 35.0699 55.6929 34.7607C55.0187 34.4515 54.1838 34.2969 53.188 34.2969C52.2355 34.2969 51.3975 34.504 50.6738 34.9185C49.9502 35.3267 49.3843 35.874 48.9761 36.5605C48.574 37.2471 48.373 38.0048 48.373 38.8335H51.5088C51.5088 38.4315 51.5737 38.0697 51.7036 37.748C51.8397 37.4264 52.0283 37.1729 52.2695 36.9873C52.5107 36.8018 52.7922 36.709 53.1138 36.709C53.4354 36.709 53.7044 36.777 53.9209 36.9131C54.1436 37.0492 54.3105 37.2501 54.4219 37.5161C54.5394 37.7759 54.5981 38.1006 54.5981 38.4902C54.5981 38.7191 54.5425 38.9757 54.4312 39.2603C54.326 39.5448 54.1559 39.8633 53.9209 40.2158C53.6859 40.5621 53.3766 40.955 52.9932 41.394L48.6514 45.9585V47.9995Z"
          fill={fillDefault}
        />
      </svg>
    </FlexContainer>
  );
};
