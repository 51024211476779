import { OnValueChange } from 'types/global';
import {
  DefaultDataSettingsInterface,
  LineAndBarIndicatorType,
  ShowValuePositionGeneric,
  ShowValueSettingsInterface,
} from 'store/reducers/visualisations/types';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import React from 'react';
import { OrientationGroupSettings } from 'modules/settingsContainer/common/OrientationGroupSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { positionOptions } from 'modules/settingsContainer/common/data/LineAndBarShowValueSettings/constants';
import { PropertiesSettings } from 'modules/settingsContainer/common/data/PropertiesSettings';
import { settingsLayoutWidthSecondLevel } from 'modules/settingsContainer/SettingsLayout/constants';
import SubSection from 'shared/ui/subSection';
import { ColorBySettings } from 'modules/settingsContainer/common/ColorBySettings';
import { CircleActivator, ColorPicker } from 'modules/settingsContainer/ColorPicker';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';

interface LineAndBarShowValueSettingsProps<IndicatorType extends LineAndBarIndicatorType>
  extends OnValueChange<ShowValueSettingsInterface<IndicatorType>>,
    Partial<Pick<DefaultDataSettingsInterface, 'indicators'>> {
  type: IndicatorType;
  isRotated?: boolean;
  disabledPosition?: boolean;
  disabledFontColorBy?: boolean;
  disabledColorBy?: boolean;
  disabledPropertiesSwitcher?: boolean;
  disabledComponentByValue?: boolean;
  disabledComponentBySpecificValue?: boolean;
  isBarIndicator?: boolean;
}

/* TODO: Will need to merge Waterfall and LineAndBar and Heatmap and Bubble  */
export const LineAndBarShowValueSettings = <IndicatorType extends LineAndBarIndicatorType>({
  value,
  onChange,
  indicators,
  type,
  isRotated = false,
  disabledPosition = false,
  disabledFontColorBy,
  disabledColorBy = false,
  disabledPropertiesSwitcher = false,
  disabledComponentBySpecificValue = false,
  isBarIndicator = false,
  disabledComponentByValue = true,
}: LineAndBarShowValueSettingsProps<IndicatorType>) => {
  const { defaultThemePalette } = useColorValues();

  const { isShow, position, orientation, properties, colorBySettings } = value;

  const positionOptionsValue = positionOptions(type, isRotated);

  return (
    <MainContainerSettings
      titleText="Показать значения"
      switcherState={isShow}
      switcherChange={() => onChange({ ...value, isShow: !isShow })}
    >
      <SubSection>
        {!disabledPosition && (
          <GroupContainerSettings titleText="Расположение">
            <ElementContainerSettings>
              <SettingsRadio
                onChange={({ value: position }) =>
                  onChange({ ...value, position: position as ShowValuePositionGeneric<IndicatorType> })
                }
                activeValue={getActiveRadioValue(positionOptionsValue, position)}
                options={positionOptionsValue}
              />
            </ElementContainerSettings>
          </GroupContainerSettings>
        )}

        <OrientationGroupSettings value={orientation} onChange={(orientation) => onChange({ ...value, orientation })} />
      </SubSection>
      <SubSection>
        <PropertiesSettings
          rightPositionModal={settingsLayoutWidthSecondLevel}
          value={properties}
          disabledFontColorBy={disabledFontColorBy}
          indicators={indicators}
          onChange={(properties) => onChange({ ...value, properties })}
          switcherStyle={
            !disabledPropertiesSwitcher
              ? {
                  switcherStateStyle: properties.isActiveStyle,
                  switcherChangeStyle: () =>
                    onChange({ ...value, properties: { ...properties, isActiveStyle: !properties.isActiveStyle } }),
                }
              : undefined
          }
          isMainContainerSettings
          isMainContainerColorSettings
          disabledOpacity
          disabledPadding
          disabledFontColorByBlock
          disabledLineHeight
          disabledBackgroundColorBy
          disabledUnderline
          disabledLetterSpacing
        />
      </SubSection>
      {!disabledColorBy && (
        <SubSection>
          <ColorBySettings
            indicators={indicators}
            titleMainContainerText="Цвет"
            isMainContainerSettings
            disabledComponentBySpecificValue={disabledComponentBySpecificValue}
            disabledComponentByValue={disabledComponentByValue || !isBarIndicator}
            disabledComponentByCondition={!isBarIndicator}
            value={colorBySettings.elementColorBy}
            onChange={(elementColorBy) => onChange({ ...value, colorBySettings: { ...colorBySettings, elementColorBy } })}
            switcherColorBy={{
              switcherStateColorBy: colorBySettings.isActive,
              switcherChangeColorBy: () =>
                onChange({ ...value, colorBySettings: { ...colorBySettings, isActive: !colorBySettings.isActive } }),
            }}
          >
            <ColorPicker
              Activator={CircleActivator}
              closeOnClick
              type="color"
              onChange={(elementColor) => onChange({ ...value, colorBySettings: { ...colorBySettings, elementColor } })}
              value={colorBySettings.elementColor}
              defaultActivatorBackgroundColor={defaultThemePalette[0]}
            />
          </ColorBySettings>
        </SubSection>
      )}
    </MainContainerSettings>
  );
};
