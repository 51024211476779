import { ModalUniversal } from 'modules/ui/ModalUniversal';
import { SqlSection } from '../SqlSettings';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { SqlContainer, SqlEditorContainer } from '../SqlSettings/styles';
import { SqlAutocomplete } from 'modules/ui';
import { FilterSqlEditorProps, FilterSqlModalProps } from './types';
import { FlexContainer } from 'styles/FlexContainer';
import React from 'react';

const FilterSqlEditor = ({ localValues, setLocalValues, onEditorFocus, fromQuery, codeEditorTheme }: FilterSqlEditorProps) => {
  return (
    <>
      <PrimaryTextSpan fontSize="12px" lineHeight="10px" color={`var(${ColorVarsEnum.Level_3})`} marginBottom="12px">
        Выражение
      </PrimaryTextSpan>
      <SqlContainer codeEditorTheme={codeEditorTheme}>
        <SqlEditorContainer>
          <FlexContainer>
            <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_3})`} fontSize="14px" marginLeft="4px">
              {'SELECT'}
            </PrimaryTextSpan>
          </FlexContainer>
          <SqlAutocomplete value={`Выражение для разреза\n`} autoHeight readOnly />
          <SqlAutocomplete
            handleChange={(incisionRequest) => setLocalValues({ ...localValues, incisionRequest })}
            value={localValues?.incisionRequest}
            onFocus={onEditorFocus}
            autoHeight
          />
        </SqlEditorContainer>
        <SqlEditorContainer>
          <FlexContainer>
            <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_3})`} fontSize="14px" marginLeft="4px" marginTop="20px">
              {fromQuery?.replace(/"/g, '')}
            </PrimaryTextSpan>
          </FlexContainer>
          <SqlAutocomplete value={`Опишите группировки и фильтры\n`} readOnly autoHeight />
          <SqlAutocomplete
            handleChange={(filterAndGroupRequest) => setLocalValues({ ...localValues, filterAndGroupRequest })}
            value={localValues?.filterAndGroupRequest}
            onFocus={onEditorFocus}
            autoHeight
          />
        </SqlEditorContainer>
      </SqlContainer>
    </>
  );
};

export const FilterSqlModal = ({
  isOpen,
  onCloseEditor,
  localValues,
  setLocalValues,
  onFormatClick,
  modelMetaData,
  onSaveEditor,
  hasChanges,
  codeEditorTheme,
  fromQuery,
}: FilterSqlModalProps) => {
  return (
    <ModalUniversal
      open={isOpen}
      onClose={onCloseEditor}
      headerText="Редактор SQL-запроса"
      width="95vw"
      maxHeight="95vh"
      disableEscapeKeyDown
    >
      <SqlSection
        sqlEditorSlot={({ onEditorFocus }) => (
          <FilterSqlEditor
            localValues={localValues}
            setLocalValues={setLocalValues}
            onEditorFocus={onEditorFocus}
            codeEditorTheme={codeEditorTheme}
            fromQuery={fromQuery}
          />
        )}
        onFormatClick={onFormatClick}
        modelMetaData={modelMetaData}
        onSaveEditor={onSaveEditor}
        hasChanges={hasChanges}
        onCloseEditor={onCloseEditor}
      />
    </ModalUniversal>
  );
};
