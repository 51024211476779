import React, { useMemo } from 'react';
import { SettingsLayoutWrapper } from 'modules/settingsContainer/SettingsLayout/styles';
import { SettingsTabs } from 'modules/ui/tabs/SettingsTabs';
import { FC, ReactFCNoop } from 'types/global';
import { Tab } from 'modules/ui/tabs/types';
import { Events } from 'modules/visualisations/components/EventsComponent';
import { ACTIVE_BOARD_AREA_LABEL } from 'modules/workspace/constans';
import { ErrorWrapper } from 'modules/visualisations/components/ErrorWrapper';
import { BackgroundIcon } from 'assets/visualisationsSettings';
import { TextIcon } from 'assets/TextSettings';
import { BodyWrapper } from 'modules/ui/tabs/SettingsTabs/styles';
import { DataIcon } from 'assets/DataSettings';
import { EventIcon } from 'assets/EventSettings';

import { TabType } from 'store/reducers/board/types';
import { ViewIcon } from 'assets/ViewSettings';
interface SettingsLayoutProps {
  ViewComponent?: ReactFCNoop;
  DataComponent?: ReactFCNoop;
  BackgroundComponent?: ReactFCNoop;
  EventsComponent?: ReactFCNoop;
  TextComponent?: ReactFCNoop;
  EventComponent?: ReactFCNoop;
}

export const SettingsLayout: FC<SettingsLayoutProps> = ({
  DataComponent,
  ViewComponent,
  BackgroundComponent,
  children,
  TextComponent,
  EventComponent = Events,
}) => {
  const tabs: Tab[] = useMemo(
    () =>
      [
        {
          content: DataIcon,
          prompt: 'Данные',
          type: TabType.ICON,
          id: 'data',
          Component: DataComponent,
        },
        {
          content: TextIcon,
          prompt: 'Текст',
          type: TabType.ICON,
          id: 'text',
          Component: TextComponent,
        },
        {
          content: ViewIcon,
          prompt: 'Вид',
          type: TabType.ICON,
          id: 'view',
          Component: ViewComponent,
        },
        {
          content: BackgroundIcon,
          prompt: 'Фон',
          type: TabType.ICON,
          id: 'background',
          Component: BackgroundComponent,
        },
        {
          content: EventIcon,
          prompt: 'События',
          type: TabType.ICON,
          id: 'events',
          Component: EventComponent,
        },
      ].filter(({ Component }) => Component) as Tab[],
    [BackgroundComponent, DataComponent, ViewComponent, TextComponent, EventComponent],
  );

  return (
    <SettingsLayoutWrapper id={ACTIVE_BOARD_AREA_LABEL}>
      {children ? (
        <ErrorWrapper>
          <BodyWrapper>{children}</BodyWrapper>
        </ErrorWrapper>
      ) : (
        <SettingsTabs tabs={tabs} />
      )}
    </SettingsLayoutWrapper>
  );
};
