import { FlexContainer } from 'styles/FlexContainer';
import { FixedAreaSettings } from 'modules/settingsContainer/common/FixedAreaSettings';
import React, { forwardRef, useCallback } from 'react';
import { NoopType, OnValueChange } from 'types/global';
import { CustomMarkersInterface, ManualModeDynamicMarkerInterface } from 'store/reducers/visualisations/types';
import { SaveCancelButtons } from 'modules/ui/blocks/SaveCancelButtons';
import { useLocalValues } from 'utils/hooks/localValues';
import { MarkerSettingsRow } from './MarkerSettingsRow';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { Button, Select, Switcher } from 'modules/ui';
import { ColorPicker } from 'modules/settingsContainer/ColorPicker';
import { defaultCustomMarker } from 'store/reducers/visualisations/constants';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { markersOptions } from '../constants';

interface ManualSettingsDynamicMarkerInterface extends OnValueChange<ManualModeDynamicMarkerInterface> {
  isOpen: boolean;
  onClose: NoopType;
}

// eslint-disable-next-line react/display-name
export const ManualSettingsDynamicMarker = forwardRef<HTMLDivElement | null, ManualSettingsDynamicMarkerInterface>(
  ({ isOpen, onClose, value, onChange }, ref) => {
    const { localValues, setLocalValues, onSave, onCancel, hasChanges } = useLocalValues({ value, onChange });

    const { defaultMarker, customMarkers } = localValues;
    const { isShow, marker, color } = defaultMarker;

    const onCloseModal = () => {
      setLocalValues(value);
      onClose();
    };

    const saveMarkerSettings = useCallback(
      (valueMarker: CustomMarkersInterface, index: number) => {
        setLocalValues((prev) => {
          const newListCustomMarkers = prev.customMarkers.map((markerSettings, indexMarker) => {
            if (indexMarker === index) {
              return valueMarker;
            }
            return markerSettings;
          });
          return {
            ...prev,
            customMarkers: newListCustomMarkers,
          };
        });
      },
      [setLocalValues],
    );

    const addMarker = useCallback(() => {
      setLocalValues((prev) => {
        return {
          ...prev,
          customMarkers: [...prev.customMarkers, defaultCustomMarker],
        };
      });
    }, [setLocalValues]);

    const deleteMarker = useCallback(
      (index: number) => {
        setLocalValues((prev) => {
          const newListCustomMarkers = prev.customMarkers.filter((_, indexMarker) => indexMarker !== index);

          return {
            ...prev,
            customMarkers: newListCustomMarkers,
          };
        });
      },
      [setLocalValues],
    );

    return (
      <FixedAreaSettings
        onClose={onCloseModal}
        open={isOpen}
        headerText="Настроить маркер динамики"
        minWidth="590px"
        // @ts-ignore
        anchorEl={ref.current}
      >
        {customMarkers.map((marker, index) => {
          return (
            <MarkerSettingsRow
              key={marker.id}
              value={marker}
              onChange={(value) => saveMarkerSettings(value, index)}
              onDelete={() => deleteMarker(index)}
            />
          );
        })}
        <FlexContainer
          justifyContent="space-between"
          width="100%"
          padding="16px"
          borderBottom={`1px solid var(${ColorVarsEnum.Level_5})`}
        >
          <FlexContainer gap="20px" alignItems="center">
            <PrimaryTextSpan>{'Показывать по умолчанию'}</PrimaryTextSpan>
            <Switcher
              id="isShowDefault"
              value={isShow}
              onClick={() => setLocalValues({ ...localValues, defaultMarker: { ...defaultMarker, isShow: !isShow } })}
            />
          </FlexContainer>
          <FlexContainer gap="26px">
            <FlexContainer gap="16px" alignItems="center">
              <PrimaryTextSpan>{'Цвет'}</PrimaryTextSpan>
              <ColorPicker
                value={color}
                onChange={(color) => setLocalValues({ ...localValues, defaultMarker: { ...defaultMarker, color } })}
                closeOnClick={true}
              />
            </FlexContainer>
            <FlexContainer gap="16px" alignItems="center">
              <PrimaryTextSpan>{'Фигура'}</PrimaryTextSpan>
              <Select
                name="Marker"
                needBackground={false}
                width="100%"
                value={marker}
                options={markersOptions}
                onChange={(marker) => setLocalValues({ ...localValues, defaultMarker: { ...defaultMarker, marker } })}
                needBorder={false}
              />
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer justifyContent="space-between" width="100%" padding="16px">
          <Button text="Добавить условие" heightSize="small" onClick={addMarker} />

          <FlexContainer gap="12px">
            <SaveCancelButtons onCancel={onCancel} onSave={onSave} hasChanges={hasChanges} />
          </FlexContainer>
        </FlexContainer>
      </FixedAreaSettings>
    );
  },
);
