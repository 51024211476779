import { createAsyncThunk } from '@reduxjs/toolkit';
import { getActivePage, getActivePageId } from 'store/reducers/projectPages/getters';
import { TState } from 'store/index';
import { v4 } from 'uuid';
import {
  BoardActionsTypes,
  LayersMap,
  MoveLayersPayload,
  MoveType,
  UpdateLayersPayload,
  UpdateMoveXYInterface,
} from 'store/reducers/board/types';
import {
  addToLayer,
  deleteFromLayer,
  deleteLayer,
  setActiveBoardElement,
  setBuffer,
  setSlice,
  updateLayers,
} from 'store/reducers/board/index';
import { FilterDataType } from 'store/reducers/filters/types';
import {
  addVisualisationByData,
  removeVisualisationByIdAction,
  updatePositionConfigByIdAction,
} from 'store/reducers/visualisations/actions';
import { addFilterByDataAction, removeFilterByIdAction, updateFilterAction } from 'store/reducers/filters/actions';
import { getActiveBoardElement, getBuffer, getLayerAlreadyLoaded, getLayerByPageId } from 'store/reducers/board/getters';
import { DefaultVisualisationOptionsType } from 'store/reducers/visualisations/types';
import { getVisualisationById } from 'store/reducers/visualisations/getters';
import { getFilterById } from 'store/reducers/filters/getters';
import { moveArrayItem } from 'utils/utils';
import { AxiosError } from 'axios';
import { serverErrorText } from 'constants/ServerCode';
import Snackbar from 'services/Snackbar';
import { loadLayersByPageId } from 'store/reducers/board/api';
import { initialBoardStoreState } from 'store/reducers/board/constants';
import { BoardPositionConfigInterface, PageIdInterface, ProjectIdWithType } from 'types/store';
import { SettingsSnapshotType } from 'store/reducers/projectSettings/settingsSnapshotService';
import { getProjectSettings } from 'store/reducers/projectSettings/getters';

const validateError = (err: AxiosError, rejectWithValue: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');
  return rejectWithValue(errorMessage);
};

export const setActiveBoardElementAction = createAsyncThunk<void, string | null>(
  BoardActionsTypes.SET_ACTIVE_BOARD_ELEMENT,
  (activeBoardElement, { dispatch }) => {
    dispatch(setActiveBoardElement(activeBoardElement));
  },
);

export const removeBoardElementByIdAction = createAsyncThunk(
  BoardActionsTypes.REMOVE_BOARD_ELEMENT_BY_ID,
  (id: string, { dispatch }) => {
    dispatch(removeFilterByIdAction(id));
    dispatch(removeVisualisationByIdAction(id));
  },
);

export const removeBoardElementAction = createAsyncThunk(BoardActionsTypes.REMOVE_BOARD_ELEMENT, (_, { dispatch, getState }) => {
  const id = getActiveBoardElement(getState() as TState);
  if (id) {
    dispatch(removeBoardElementByIdAction(id));
  }
});

export const copyToBufferAction = createAsyncThunk<void>(BoardActionsTypes.COPY_TO_BUFFER, (_, { dispatch, getState }) => {
  const state = getState() as TState,
    activeBoardElement = getActiveBoardElement(state);

  if (activeBoardElement) {
    const activeVisualisation = getVisualisationById(activeBoardElement)(state),
      activeFilter = getFilterById(activeBoardElement)(state),
      buffer = activeVisualisation || activeFilter;

    buffer && dispatch(setBuffer(buffer));
  }
});

export const cutToBufferAction = createAsyncThunk(BoardActionsTypes.CUT_TO_BUFFER, (buffer, { dispatch }) => {
  dispatch(copyToBufferAction());

  dispatch(removeBoardElementAction());
});

export const pasteFromBufferAction = createAsyncThunk(BoardActionsTypes.PASTE_FROM_BUFFER, (_, { dispatch, getState }) => {
  const state = getState() as TState,
    pageId = getActivePageId(state) || '',
    buffer = getBuffer(state),
    id = v4();

  if (buffer) {
    if ((buffer as FilterDataType)?.type) {
      dispatch(addFilterByDataAction({ ...(buffer as FilterDataType), pageId, id }));
    } else {
      dispatch(addVisualisationByData({ ...(buffer as DefaultVisualisationOptionsType), id, pageId }));
    }

    dispatch(addToLayerAction(id));
    setActiveBoardElementAction(id);
  }
});

export const addToLayerAction = createAsyncThunk(BoardActionsTypes.ADD_TO_LAYER, (id: string, { dispatch, getState }) => {
  const state = getState() as TState,
    pageId = getActivePageId(state) || '';

  dispatch(addToLayer({ id, pageId }));
});

export const deleteFromLayerByIdAction = createAsyncThunk(
  BoardActionsTypes.DELETE_FROM_LAYER,
  (id: string, { dispatch, getState }) => {
    const state = getState() as TState,
      pageId = getActivePageId(state) || '';

    dispatch(deleteFromLayer({ id, pageId }));
  },
);

export const deleteLayerByPageIdAction = createAsyncThunk(
  BoardActionsTypes.DELETE_LAYER_BY_PAGE_ID,
  (pageId: string, { dispatch }) => {
    dispatch(deleteLayer(pageId));
  },
);

export const updatesLayersAction = createAsyncThunk<void, UpdateLayersPayload>(
  BoardActionsTypes.UPDATE_LAYERS,
  (payload, { dispatch }) => {
    dispatch(updateLayers(payload));
  },
);

export const addLayerByPageIdAction = createAsyncThunk(BoardActionsTypes.ADD_LAYER_BY_PAGE_ID, (pageId: string, { dispatch }) => {
  dispatch(updatesLayersAction({ pageId, layers: [] }));
});

export const updateLayersByIdsAction = createAsyncThunk<void, MoveLayersPayload>(
  BoardActionsTypes.UPDATE_BY_ID_LAYERS,
  ({ id, moveTo }, { dispatch, getState }) => {
    const state = getState() as TState,
      pageId = getActivePageId(state) || '',
      layers = getLayerByPageId(pageId)(state),
      indexOfLayers = layers.findIndex((layerId) => id === layerId),
      { newArray } = moveArrayItem(layers, indexOfLayers, moveTo);

    dispatch(updatesLayersAction({ pageId, layers: newArray }));
  },
);

export const loadLayersAction = createAsyncThunk(
  BoardActionsTypes.LOAD_LAYERS,
  async ({ pageId, projectId }: ProjectIdWithType<PageIdInterface>, { getState, dispatch, signal }) => {
    const layerAlreadyLoaded = getLayerAlreadyLoaded(pageId)(getState() as TState);

    if (!layerAlreadyLoaded) {
      const request = dispatch(loadLayersByPageIdAction({ pageId, projectId }));

      signal.addEventListener('abort', () => {
        request.abort();
      });
    }
  },
);

export const loadLayersByPageIdAction = createAsyncThunk<LayersMap, ProjectIdWithType<PageIdInterface>>(
  BoardActionsTypes.LOAD_LAYERS_BY_PAGE_ID,
  async ({ pageId, projectId }, { rejectWithValue, signal }) => {
    try {
      const response = await loadLayersByPageId({ pageId, projectId }, { signal });

      return { [pageId]: response.data.layers };
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return {};
    }
  },
);

export const loadLayersFromSnapshotAction = createAsyncThunk<LayersMap, SettingsSnapshotType['layers']>(
  BoardActionsTypes.LOAD_LAYERS_FROM_SNAPSHOT,
  (layers) => layers.reduce<LayersMap>((result, { pageId, layers }) => ({ ...result, [pageId]: layers }), {}),
);

export const clearBoardStore = createAsyncThunk(BoardActionsTypes.CLEAR_BOARD_STORE, (_, { dispatch }) => {
  dispatch(setSlice(initialBoardStoreState));
});

export const moveVisualization = createAsyncThunk<
  unknown,
  {
    typeMove: MoveType;
  }
>(BoardActionsTypes.MOVE_VISUALIZATION, ({ typeMove }, { dispatch, getState }) => {
  const state = getState() as TState;
  const activeBoardElement = getActiveBoardElement(state);
  const activePage = getActivePage(state);

  if (activeBoardElement && activePage) {
    const activeVisualisation = getVisualisationById(activeBoardElement)(state),
      activeFilter = getFilterById(activeBoardElement)(state),
      buffer = activeVisualisation || activeFilter,
      isDisabledMoving = activeVisualisation?.viewSettings.disableDragging || activeFilter?.disableDragging,
      { gridSpacing } = getProjectSettings(state),
      sizePage = activePage.boardSettings.sizes;

    if (buffer && !isDisabledMoving) {
      const newPositionConfig = updateMoveXY({ positionConfig: buffer.positionConfig, type: typeMove, gridSpacing, sizePage });

      if (activeVisualisation) {
        return dispatch(updatePositionConfigByIdAction({ id: activeBoardElement, positionConfig: newPositionConfig }));
      }

      return dispatch(updateFilterAction({ positionConfig: newPositionConfig }));
    }
  }
});

const updateMoveXY = ({ positionConfig, type, gridSpacing, sizePage }: UpdateMoveXYInterface): BoardPositionConfigInterface => {
  const isAxisX = type === 'left' || type === 'right';
  const add = type === 'down' || type === 'right';
  const { width, height } = sizePage;

  let newX = positionConfig.x;
  let newY = positionConfig.y;
  const widthWidget = positionConfig.width;
  const heightWidget = positionConfig.height;

  if (isAxisX) {
    newX = add ? newX + gridSpacing : newX - gridSpacing;
  } else {
    newY = add ? newY + gridSpacing : newY - gridSpacing;
  }

  newX = Math.round(newX / gridSpacing) * gridSpacing;
  newY = Math.round(newY / gridSpacing) * gridSpacing;

  if (newX < 0) {
    newX = 0;
  } else if (newX + widthWidget > width) {
    newX = width - widthWidget;
  }

  if (newY < 0) {
    newY = 0;
  } else if (newY + heightWidget > height) {
    newY = height - heightWidget;
  }

  return {
    ...positionConfig,
    x: newX,
    y: newY,
  };
};
