import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DefaultVisualisationsTypeProps } from 'assets/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const GroupedBar: FC<DefaultVisualisationsTypeProps> = ({
  backgroundFill = `var(${ColorVarsEnum.Level_4_widget})`,
  fillDefault = `var(${ColorVarsEnum.Level_1})`,
  fill = `var(${ColorVarsEnum.Accent})`,
  symbolSize = 70,
}) => {
  return (
    <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
      <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="70" height="70" rx="5" fill={backgroundFill} />
        <rect x="16" y="14" width="41" height="5" fill={fill} />
        <rect opacity="0.75" x="16" y="23" width="26" height="5" fill={fillDefault} />
        <rect opacity="0.75" x="16" y="32" width="33" height="5" fill={fillDefault} />
        <rect opacity="0.75" x="16" y="41" width="16" height="5" fill={fillDefault} />
        <rect opacity="0.75" x="16" y="50" width="10" height="5" fill={fillDefault} />
        <rect opacity="0.15" x="12" y="8" width="54" height="4" transform="rotate(90 12 8)" fill={fillDefault} />
      </svg>
    </FlexContainer>
  );
};
