import { ElementContainerSettings, GroupContainerSettings } from 'modules/settingsContainer';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { ImagesSourceModeOptions } from '../ImagesSettings/constants';
import { SourceEnum, SourceInterface } from 'store/reducers/visualisations/types';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { Combobox } from 'modules/ui/Combobox';
import { ConditionEditorField } from 'modules/settingsContainer/common/ConditionEditorField';
import { FixedAreaSettings } from 'modules/settingsContainer/common/FixedAreaSettings';
import { SQLTextArea } from 'components/shared/SQLTextArea';
import React, { useRef } from 'react';
import { NoopType, NoopValueType, OnValueChange } from 'types/global';
import { SelectItemInterface } from 'modules/ui/Select';
import { useModalState } from 'utils/hooks/modalState';
import TextField from 'modules/ui/TextField';

interface SourceSelectionProps extends OnValueChange<SourceInterface> {
  onChangeSqlByCondition: NoopValueType<string>;
  onSave: NoopType;
  onCancel: NoopType;
  hasChanges: boolean;
  options: SelectItemInterface[];
}

export const SourceSelection = ({
  value,
  onChange,
  onChangeSqlByCondition,
  onSave,
  onCancel,
  hasChanges,
  options,
}: SourceSelectionProps) => {
  const mainContainerRef = useRef<HTMLDivElement | null>(null);
  const { fieldName, sqlCondition, textLink } = value.value;
  const { isOpen, onOpen, onClose } = useModalState();

  return (
    <GroupContainerSettings ref={mainContainerRef} titleText="Источник">
      <ElementContainerSettings>
        <SettingsRadio
          onChange={(mode) => onChange({ ...value, type: mode.value })}
          activeValue={getActiveRadioValue(ImagesSourceModeOptions, value.type)}
          options={ImagesSourceModeOptions}
        />
      </ElementContainerSettings>
      <ElementContainerSettings>
        {value.type === SourceEnum.DATABASE && (
          <FlexContainer display={'flex'} gap={'16px'} alignItems={'center'} width={'100%'}>
            <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_3})`}>{'URL'}</PrimaryTextSpan>
            <Combobox
              disableClearable
              placeholder="Field name links"
              options={options}
              name="incisionHyperLinkFieldName"
              width="100%"
              minWidth="300px"
              value={
                fieldName && {
                  name: fieldName,
                  value: fieldName,
                }
              }
              onChange={({ value: fieldName }) => onChange({ ...value, value: { ...value.value, fieldName } })}
            />
          </FlexContainer>
        )}
        {value.type === SourceEnum.BYCONDITION && <ConditionEditorField value={sqlCondition || ''} onOpenChange={onOpen} />}
        {value.type === SourceEnum.BYCONDITION && (
          <FixedAreaSettings
            onClose={onClose}
            open={isOpen}
            headerText="Изменить выражение"
            width="565px"
            minWidth="180px"
            anchorEl={mainContainerRef.current}
          >
            <SQLTextArea
              sqlCondition={sqlCondition}
              onChange={onChangeSqlByCondition}
              onSave={onSave}
              onCancel={onCancel}
              hasChanges={hasChanges}
            />
          </FixedAreaSettings>
        )}
        {value.type === SourceEnum.MANUAL && (
          <TextField
            onChange={(event) => onChange({ ...value, value: { ...value.value, textLink: event.target.value } })}
            value={textLink || ''}
            name="linkImage"
            type="text"
            width="100%"
          />
        )}
      </ElementContainerSettings>
    </GroupContainerSettings>
  );
};
