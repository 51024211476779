import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { GradientByType } from 'store/reducers/visualisations/types';

export const getHeatmapConditionOptions = (
  usingByCondition?: boolean,
  usingByGradientValue?: boolean,
  usingByStepsValue?: boolean,
  usingBySpecificGradientValue?: boolean,
  usingBySpecificStepsValue?: boolean,
  usingByValue?: boolean,
) => {
  const byCondition = usingByCondition
    ? [
        {
          value: 'condition',
          label: 'По условию',
        },
      ]
    : [];

  const byValueGradient = usingByGradientValue
    ? [
        {
          value: 'valueGradient',
          label: 'Градиент',
        },
      ]
    : [];

  const byValueSteps = usingByStepsValue
    ? [
        {
          value: 'valueSteps',
          label: 'Градиент',
        },
      ]
    : [];

  const bySpecificGradient = usingBySpecificGradientValue
    ? [
        {
          value: 'valueSpecificGradient',
          label: 'Градиент',
        },
      ]
    : [];

  const byValueSpecificSteps = usingBySpecificStepsValue
    ? [
        {
          value: 'valueSpecificSteps',
          label: 'Ступенями',
        },
      ]
    : [];

  const byValue = usingByValue
    ? [
        {
          value: 'value',
          label: 'По значению',
        },
      ]
    : [];

  return [
    {
      value: 'default',
      label: 'Вручную',
    },
    ...byValueSteps,
    ...byValueGradient,
    ...bySpecificGradient,
    ...byValueSpecificSteps,
    ...byValue,
    ...byCondition,
  ] as SettingsRadioItem<GradientByType>[];
};
