import { CallbackDataParams } from 'echarts/types/dist/shared';
import { useCallback, useMemo } from 'react';
import {
  LineAndBarIncisionInterface,
  PieIncisionInterface,
  WaterfallIncisionInterface,
} from 'store/reducers/visualisations/types';
import { getArrayItemByCountlessIndex } from 'utils/utils';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { PaletteValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';

type IncisionColorsType = {
  activeIncisionSum: Record<string, number>;
  activeIncision: { fieldName: string; data: WaterfallIncisionInterface | LineAndBarIncisionInterface | PieIncisionInterface };
  colors: PaletteValuesByThemeType | null;
};

export const useIncisionColors = ({
  activeIncisionSum,
  colors,
}: IncisionColorsType): ((params: CallbackDataParams) => string) | undefined => {
  const { getColorValues, defaultColor } = useColorValues();

  const incisionValues = useMemo(() => Object.keys(activeIncisionSum), [activeIncisionSum]);

  const colorByIncisionMapper = useCallback(
    (colors: PaletteValuesByThemeType | null) => {
      const colorsValues = getColorValues(colors) || [];

      return incisionValues.reduce<Record<string, string>>(
        (result, incisionValue, index) => ({
          ...result,
          [incisionValue]: getArrayItemByCountlessIndex(colorsValues, index) || defaultColor,
        }),
        {},
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(incisionValues), getColorValues, defaultColor],
  );

  const getIncisionColor = useMemo(
    () =>
      colors
        ? (params: CallbackDataParams) => {
            return colorByIncisionMapper(colors)[params.name] || defaultColor;
          }
        : undefined,
    [colorByIncisionMapper, colors, defaultColor],
  );

  return getIncisionColor;
};
