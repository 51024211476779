import React from 'react';
import { useGetActiveFilter } from 'modules/filters/hooks/getActiveFilter';
import { FilterValuesList } from 'modules/settingsContainer/FilterValuesList';
import { onLimitChange, onSelectedDataChange } from 'modules/filters/Single/settings/DataTab/constants';
import { useSelector } from 'react-redux';
import { getFiltersValuesStateByFilterId } from 'store/reducers/filters/getters';
import { FilterElementModeEnum, SqlDataRequestInterface } from 'types/store';
import {
  onFilterElementsChange as dispatchedFilterElementsChange,
  onModelIdChange,
  onRealDataChange,
  onSqlDataChange,
} from 'modules/settingsContainer/common/data/DefaultFilterEventSettings/constants';
import { LimitSettings } from 'modules/settingsContainer/common/data/LimitSettings';
import { FilterIncisionsSettings } from 'modules/settingsContainer/FIlterIncisionsSettings';
import { useDataSettingsMetric } from 'modules/visualisations/hooks/dataSettingsMetric';
import { sqlParser } from 'utils/SQL/genereteAst';
import { FilterElementsInterface } from 'modules/settingsContainer/FIlterIncisionsSettings/types';
import { RealDataSettings } from 'modules/settingsContainer/common/data/RealDataSettings';
import { ModelSelectorSettings } from 'modules/settingsContainer/common/ModelSelectorSettings';
import { SqlFilterSettings } from 'modules/settingsContainer/common/data/SqlFilterSettings';

export const DataTab = (isLimitSettings?: boolean) => {
  const { data, modelIdValue, tableFields } = useGetActiveFilter({ type: 'single' });
  const { modelMetaData } = useDataSettingsMetric(data.modelId);
  const { isRealData, nameSettings, fictionalData, selectedData, id, limit, sqlData } = data;
  const { filterElementMode, fieldName } = nameSettings;

  const { filterValues, loadingFilterValue } = useSelector(getFiltersValuesStateByFilterId(id));

  const onFilterElementsChange = (filterElements: FilterElementsInterface) => {
    const { fieldName } = filterElements.nameSettings;

    dispatchedFilterElementsChange(filterElements);

    if (fieldName !== nameSettings.fieldName) {
      onSelectedDataChange([]);
    }
  };

  const onFilterElementModeChange = (filterElementMode: FilterElementModeEnum) => {
    dispatchedFilterElementsChange({ ...data, nameSettings: { ...nameSettings, filterElementMode } });
  };

  const onSaveSql = ({ incisionRequest, filterAndGroupRequest }: SqlDataRequestInterface) => {
    const isValidIncisionAst = !incisionRequest ? true : sqlParser.astify(`SELECT ${incisionRequest}`);
    const isValidFilterAst = sqlParser.astify(`SELECT * ${filterAndGroupRequest}`);

    if (isValidIncisionAst && isValidFilterAst) {
      const fieldNameMatch = tableFields.some((field) => field.value === incisionRequest);

      if (fieldNameMatch) {
        onFilterElementsChange({ fictionalData, nameSettings: { ...nameSettings, fieldName: incisionRequest } });
      }

      if (!incisionRequest && incisionRequest !== fieldName) {
        onFilterElementsChange({ fictionalData, nameSettings: { ...nameSettings, fieldName: null } });
      }

      onSqlDataChange({
        sqlData: {
          incisionRequest,
          filterAndGroupRequest,
        },
      });
    }
  };

  return (
    <>
      <RealDataSettings value={isRealData} onChange={onRealDataChange} />
      <ModelSelectorSettings value={modelIdValue} onChange={onModelIdChange} />
      <FilterIncisionsSettings
        onChange={onFilterElementsChange}
        isRealData={isRealData}
        options={tableFields}
        value={{ fictionalData, nameSettings }}
        modelMetaData={modelMetaData}
        onChangeSqlData={onSaveSql}
        data={data}
      >
        <FilterValuesList
          onChange={onSelectedDataChange}
          onFilterModeChange={onFilterElementModeChange}
          value={selectedData}
          filterValues={filterValues}
          filterElementMode={filterElementMode}
          fieldName={fieldName}
          loading={loadingFilterValue}
        />
      </FilterIncisionsSettings>
      <SqlFilterSettings sqlData={sqlData} onSave={onSaveSql} modelMetaData={modelMetaData} fieldName={fieldName} />
      {isLimitSettings && <LimitSettings value={limit} onChange={onLimitChange} width="auto" />}
    </>
  );
};
