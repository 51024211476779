import styled from 'styled-components';
import { defaultScrollbarMixin } from 'constants/styles';

interface MetricWrapperProps {
  maxHeight?: string;
}

export const MetricWrapper = styled.div<MetricWrapperProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-height: ${({ maxHeight }) => maxHeight || '100%'};
  overflow-y: auto;
  flex-grow: 1;

  ${defaultScrollbarMixin};
`;
