import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import isArray from 'lodash/isArray';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { useVisualisationTable } from 'modules/visualisations/hooks/Table/visualisationTable';
import { PropertiesRecordType, useProperties } from 'modules/visualisations/hooks/useProperties';
import {
  colorInvertInHEX,
  getCalculatedTotalValues,
  getColors,
  getColumnsSettings,
  getDataForPivotTable,
  getGrouping,
  getIncisionsInHeaderNames,
  getPadding,
  getTableData,
  getTotalSqlRequest,
  lastRowClass,
  loadRowTxt,
  onSortClick,
  transformLoadedValuesToTotalValues,
} from 'modules/visualisations/Table/visualisation/constants';
import {
  ColumnSettingsInterface,
  DataTableInterface,
  MinMaxFuncInterface,
  OnChartClickByConditionInterface,
  SqlRequestForPivotInterface,
  TableDataInterface,
  TotalValuesType,
} from 'modules/visualisations/Table/visualisation/types';
import { VisualisationOriginInterface } from 'modules/workspace/components/VisualisationArea/types';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { loadVisualisationValuesAction } from 'store/reducers/visualisations/actions';
import { colorAlias, getVisualisationFieldName, hyperLinksAlias, imageLinksAlias } from 'store/reducers/visualisations/constants';
import {
  getAstForSqlGenerationQueryById,
  getSqlRequestForGroupingRowTable,
  getSqlRequestForHeaderTable,
} from 'store/reducers/visualisations/getters';
import { ColorByItem, TableVisualisationType, VisualisationValuesInterface } from 'store/reducers/visualisations/types';
import { chainFormatter, getCustomAliasName } from 'utils/formatting';
import { generateUnionWhereIn } from 'utils/SQL/genereteAst';
import { getColorByValueMinMax, getRandomInt } from 'utils/utils';
import { GetSqlRequestForHeaderPivotTable, GetSqlRequestForHyperOrImageLinksPivotTable } from 'utils/visualisations';
import {
  Observe,
  StyledTableWrapper,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TanStackTableStyled,
} from './styles';
import { TotalComponent } from './TotalComponent';
import { SortingValueEnum } from 'components/shared/SortingPanel/types';
import { TableCellComponent } from './TableCell';
import { TopAndBottomEnum } from 'types/styles';
import isUndefined from 'lodash/isUndefined';
import { useDataSettingsMetric } from 'modules/visualisations/hooks/dataSettingsMetric';

export const TableVisualisationComponent: VisualisationOriginInterface<TableVisualisationType> = ({ data, sqlRequest }) => {
  const { id, dataSettings, viewSettings, sqlData, events } = data;
  const { colorByValueSettings, backgroundByValueSettings, isRealData, limitGrouping, limit } = dataSettings;
  const { bodySettings, headerSettings, subtotalsSettings, totalRowSettings, shadowSettings } = viewSettings;
  const {
    shadowColorSettings: { shadowColorBy },
  } = shadowSettings;
  const getSqlRequestForGroupingRow = useSelector(getSqlRequestForGroupingRowTable);
  const getSqlRequestForHeader = useSelector(getSqlRequestForHeaderTable);

  /*TODO: Comment on RLS and wait for the server */
  // const { rls } = useRLS();

  const {
    from,
    whereAstData,
    filtersAndGroups: { where },
  } = useSelector(getAstForSqlGenerationQueryById(id));
  const { getColorValues, activeThemeSchema } = useColorValues();

  const [offsetSqlRequest, setOffsetSqlRequest] = useState(25);
  const [sqlRequestForPivot, setSqlRequestForPivot] = useState<SqlRequestForPivotInterface | null>(null);
  const [sqlRequestForTotalRowPivot, setSqlRequestForTotalRowPivot] = useState<SqlRequestForPivotInterface | null>(null);
  const [minMaxIndicators, setMinMaxIndicators] = useState<Record<string, [number, number]>>({});
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);
  const [columnVisibility, setColumnVisibility] = useState<string[]>([]);
  const [loadedTotalValues, setLoadedTotalValues] = useState<TotalValuesType>({});
  const tableRef = useRef<HTMLDivElement | null>(null);
  const { ref: observeDownRef, inView: inViewDownObserve } = useInView({
    threshold: 0,
  });
  const [tableData, setTableData] = useState<TableDataInterface>({
    columns: [],
    data: [],
  });

  const incisionsInHeaderNames: string[] = useMemo(
    () => getIncisionsInHeaderNames(dataSettings.incisionsInHeader),
    [dataSettings.incisionsInHeader],
  );
  const grouping = useMemo(
    () => getGrouping([...dataSettings.incisions, ...dataSettings.incisionsInHeader], dataSettings.hasAllGroupIncision),
    [dataSettings.incisions, dataSettings.incisionsInHeader, dataSettings.hasAllGroupIncision],
  );
  const sqlRequestForHeader = useMemo(
    () =>
      getSqlRequestForHeader({
        id,
        incisionsInHeaderNames,
        limitData: limit.isActive
          ? {
              seperator: ',',
              value: [
                {
                  type: 'number',
                  value: limit.value || 1,
                },
              ],
            }
          : undefined,
      }),
    [getSqlRequestForHeader, id, incisionsInHeaderNames, limit.isActive, limit.value],
  );

  const sqlRequestTable =
    grouping.length > 0
      ? getSqlRequestForGroupingRow({
          id,
          columnNextIndex: 1,
          incisionsInHeaderNames,
          isPivotTable: incisionsInHeaderNames.length !== 0,
        })
      : sqlRequest;

  const countIncisions = useMemo(
    () => columnVisibility.length || dataSettings.incisions.filter((incision) => incision.fieldName).length,
    [dataSettings.incisions, columnVisibility.length],
  );

  const minMaxFunc = ({ columns, payloadRequest, parentsChain = 'BigDad' }: MinMaxFuncInterface) => {
    columns[columns.length - 1]?.forEach(({ isIncision, Header }) => {
      if (!isIncision) {
        let array = payloadRequest[Header] as number[];

        if (isUndefined(array)) {
          array = [];
          Object.keys(payloadRequest).map((hashName) => {
            if (hashName.includes(Header) && isArray(payloadRequest[hashName])) {
              array.push(...(payloadRequest[hashName] as number[]));
            }
          });
        }
        const filteredArray = array.filter((value) => isFinite(value));
        const min = Math.min.apply(null, filteredArray);
        const max = Math.max.apply(null, filteredArray);

        setMinMaxIndicators((prev) => {
          const keyName = Header + parentsChain;
          if (prev[keyName]) {
            prev[keyName][0] = prev[keyName][0] < min ? prev[keyName][0] : min;
            prev[keyName][1] = prev[keyName][1] > max ? prev[keyName][1] : max;
            return prev;
          } else {
            return {
              ...prev,
              [keyName]: [min, max],
            };
          }
        });
      }
    });
  };

  const {
    getColorByValue,
    visualisationNormalizedValues,
    formattingParams: { formatting },
    updateFilter,
    projectId,
    modelId,
    dispatch,
  } = useVisualisationTable({
    sqlData,
    id,
    colorsBy: [colorByValueSettings, backgroundByValueSettings, shadowColorBy],
    dataSettings,
    limit: { isActive: true, value: 25 },
    events,
    sqlRequest: sqlRequestTable,
  });

  const { modelMetaData } = useDataSettingsMetric(modelId);

  const columnsSettings = useMemo<ColumnSettingsInterface>(
    () => getColumnsSettings([...dataSettings.incisions, ...dataSettings.incisionsInHeader, ...dataSettings.indicators]),
    [dataSettings.indicators, dataSettings.incisionsInHeader, dataSettings.incisions],
  );

  const propertiesData = useMemo(() => {
    const properties = [...dataSettings.incisions, ...dataSettings.incisionsInHeader, ...dataSettings.indicators];

    return properties.reduce<PropertiesRecordType>((data, { name, fieldName, settings: { properties, nameFromDatabase } }) => {
      const propertiesName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });
      data[propertiesName] = properties;
      return { ...data, [propertiesName]: properties };
    }, {});
  }, [dataSettings.indicators, dataSettings.incisionsInHeader, dataSettings.incisions]);

  const properties = useProperties(propertiesData);

  useEffect(() => {
    let lengthOpenRows = 0;

    someName: for (let x = dataSettings.incisions.length; x > 0; x--) {
      for (let i = 0; i < tableData.data.length; i++) {
        if (tableData.data[i][x - 1]?.value !== null) {
          lengthOpenRows = x;
          break someName;
        }
      }
    }

    setColumnVisibility(
      dataSettings.incisions
        .slice(0, lengthOpenRows)
        .reduce<string[]>((acc, { fieldName, name, settings: { nameFromDatabase, customRequest } }) => {
          if (fieldName || customRequest || !isRealData) {
            acc.push(getVisualisationFieldName({ name, fieldName, nameFromDatabase }));
          }
          return acc;
        }, []),
    );
  }, [tableData.data, tableData.data.length, dataSettings.incisions, isRealData]);

  useEffect(() => {
    tableRef.current?.scrollTo(0, 0);
    setOffsetSqlRequest(25);
    setHasMoreData(true);
    setIsLoadingData(false);
    setSqlRequestForTotalRowPivot(null);
    const incisionsHeader =
      incisionsInHeaderNames.length && !isRealData
        ? incisionsInHeaderNames.reduce((object, name) => {
            return { ...object, [name]: visualisationNormalizedValues[name] };
          }, {})
        : {};
    const visualisationValuesForPivot =
      incisionsInHeaderNames.length && !isRealData
        ? Object.keys(visualisationNormalizedValues).reduce((object, name) => {
            if (!incisionsInHeaderNames.includes(name)) {
              return { ...object, [name]: visualisationNormalizedValues[name] };
            }
            return object;
          }, {})
        : {};

    const { data, columns } =
      incisionsInHeaderNames.length && !isRealData
        ? getDataForPivotTable({
            incisionsHeader,
            visualisationValues: visualisationValuesForPivot,
            allColumns: dataSettings,
            formatting,
            properties,
            grouping,
            hashNames: incisionsInHeaderNames,
            isRealData,
          })
        : getTableData({
            allColumns: dataSettings,
            visualisationValues: visualisationNormalizedValues,
            properties,
            formatting,
            countIncisions: dataSettings.incisions.length,
            grouping,
            isRealData,
          });

    setMinMaxIndicators({});
    minMaxFunc({ columns, payloadRequest: visualisationNormalizedValues });
    setTableData({ data, columns });
    setSqlRequestForPivot(null);
  }, [isRealData, incisionsInHeaderNames, visualisationNormalizedValues, properties, formatting, grouping, dataSettings]);

  useEffect(() => {
    const {
      isShow,
      location: { position },
    } = subtotalsSettings;

    setTableData((prev) => {
      const allIndexSubtotals: number[] = [];
      const allIndexTotalRows: number[] = [];
      let countAddOrDeleteSubtotalRows = 0;

      prev.data.forEach((row, indexRow) => {
        if (row[0].isSubtotal) {
          allIndexSubtotals.push(indexRow);
        }
        if (row[0].isExpanded && !row[0].isTotalRow) {
          allIndexTotalRows.push(indexRow);
        }
      });

      allIndexTotalRows.forEach((totalIndex, index) => {
        if (isShow && !allIndexSubtotals.length) {
          const indexTotalRow = totalIndex + countAddOrDeleteSubtotalRows;
          const totalRow = prev.data[indexTotalRow];
          const subtotalRow: DataTableInterface[] = JSON.parse(JSON.stringify(totalRow));
          subtotalRow.forEach((cell, index) => {
            cell.value = index < countIncisions && cell.value ? `Итог ${cell.value}` : cell.value;
            cell.isTotalRow = true;
            cell.isSubtotal = true;
          });

          totalRow.forEach((cell, index) => {
            cell.value = index >= countIncisions ? null : cell.value;
          });

          position?.value === TopAndBottomEnum.TOP && prev.data.splice(indexTotalRow + 1, 0, subtotalRow);
          position?.value === TopAndBottomEnum.BOTTOM &&
            prev.data.splice(indexTotalRow + totalRow[0].countChildren + 1, 0, subtotalRow);
          countAddOrDeleteSubtotalRows += 1;
        }
        if (!isShow && allIndexSubtotals.length) {
          const indexTotalRow = totalIndex - countAddOrDeleteSubtotalRows;
          const totalRow = prev.data[indexTotalRow];
          const indexSubtotalRow = allIndexSubtotals[index] - countAddOrDeleteSubtotalRows;
          const subtotalRow = prev.data.splice(indexSubtotalRow, 1);
          subtotalRow[0].forEach((cell, index) => {
            totalRow[index].value = index >= countIncisions ? cell.value : totalRow[index].value;
          });
          countAddOrDeleteSubtotalRows += 1;
        }
        if (isShow && allIndexSubtotals.length) {
          const subtotalRow = prev.data.splice(allIndexSubtotals[index], 1);
          if (position?.value === TopAndBottomEnum.TOP) {
            prev.data.splice(totalIndex + 1, 0, subtotalRow[0]);
          }
          if (position?.value === TopAndBottomEnum.BOTTOM) {
            const totalRow = prev.data[totalIndex];
            prev.data.splice(totalIndex + totalRow[0].countChildren + 1, 0, subtotalRow[0]);
          }
        }
      });

      return prev;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subtotalsSettings.isShow, subtotalsSettings.location.position]);

  /* Generate Total Data */

  const isTotalShow = totalRowSettings.isShow;
  const isTotalTop = totalRowSettings.location.position.value === 'top';

  const totalValues = useMemo<TotalValuesType>(() => {
    if (!isTotalShow) {
      return {};
    }

    if (dataSettings.isRealData) {
      return loadedTotalValues;
    }

    return getCalculatedTotalValues({
      indicators: dataSettings.indicators,
      visualisationValues: visualisationNormalizedValues,
      formatting: formatting,
    });
  }, [
    formatting,
    isTotalShow,
    visualisationNormalizedValues,
    dataSettings.indicators,
    dataSettings.isRealData,
    loadedTotalValues,
  ]);

  const totalSqlRequest = useMemo(() => {
    if (dataSettings.isRealData && isTotalShow) {
      const totalSql = getTotalSqlRequest({
        indicators: dataSettings.indicators,
        from,
        where: generateUnionWhereIn([whereAstData, where].filter((where) => !!where)),
      });

      if (sqlRequestForTotalRowPivot && totalSql) {
        const regex = /(SELECT\s+)/i;
        return totalSql.replace(regex, `$1${sqlRequestForTotalRowPivot.sqlRequest} `);
      }

      return totalSql;
    }
  }, [isTotalShow, dataSettings.isRealData, from, whereAstData, dataSettings.indicators, where, sqlRequestForTotalRowPivot]);

  const loadTotalValues = useCallback(
    (sqlRequest: string) => {
      const abortController = new AbortController();

      setLoadedTotalValues({});

      const request = dispatch(
        loadVisualisationValuesAction({
          projectId,
          sqlRequest,
          modelId,
          /*TODO: Comment on RLS and wait for the server */
          // rls
        }),
      );

      abortController.signal.addEventListener('abort', () => {
        request.abort();
      });

      request
        .then(({ payload }) => {
          if (payload) {
            const totalValues = transformLoadedValuesToTotalValues(payload as VisualisationValuesInterface);

            Object.keys(totalValues).forEach((accessor) => {
              let name = accessor;
              if (incisionsInHeaderNames.length) {
                if (accessor.split('_')[1]) {
                  name = accessor.split('_')[1];
                }
              }
              const totalSettings = dataSettings.indicators.find((indicator) => indicator.name === name)?.settings.totalSettings;
              const formattingFunction = formatting && formatting[name];
              const value = totalValues[accessor];
              const totalValue = formattingFunction && formattingFunction(value);
              const customValue =
                !totalSettings?.isAutoAggregation && totalSettings?.formatting?.isShow
                  ? chainFormatter(totalSettings.formatting.formats, String(value))
                  : null;

              if (totalValue) {
                totalValues[accessor] = customValue || totalValue;
              }
            });

            setLoadedTotalValues(totalValues);
          }
        })
        .catch((e) => {
          /* TODO: Добавить адекватный логер */
          console.log(e);
        });

      return abortController;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      projectId,
      modelId,
      incisionsInHeaderNames.length,
      dataSettings.indicators,
      formatting,
      /*TODO: Comment on RLS and wait for the server */
      // rls
    ],
  );

  useEffect(() => {
    if (totalSqlRequest) {
      const request = loadTotalValues(totalSqlRequest);

      return () => {
        request.abort();
      };
    } else {
      setLoadedTotalValues({});
    }
  }, [totalSqlRequest, loadTotalValues]);

  useEffect(() => {
    async function fetchPivotTableData() {
      if (!sqlRequestForHeader || !sqlRequestTable) {
        return;
      }
      let allSqlRequest = '';

      const request = await dispatch(
        loadVisualisationValuesAction({
          projectId,
          sqlRequest: sqlRequestForHeader,
          modelId,
          /*TODO: Comment on RLS and wait for the server */
          // rls,
        }),
      );

      if (!request.payload) {
        return;
      }

      const payloadRequest = request.payload as VisualisationValuesInterface;

      const hyperLinksByConditionInHeader: Record<string, unknown> = {};
      const imageLinksByConditionInHeader: Record<string, unknown> = {};

      incisionsInHeaderNames.forEach((el) => {
        const hyperLinksName = getCustomAliasName({ nameIncision: el, alias: hyperLinksAlias });
        const imageLinksName = getCustomAliasName({ nameIncision: el, alias: imageLinksAlias });

        if (payloadRequest[hyperLinksName]) {
          hyperLinksByConditionInHeader[hyperLinksName] = payloadRequest[hyperLinksName];
        }
        if (payloadRequest[imageLinksName]) {
          imageLinksByConditionInHeader[imageLinksName] = payloadRequest[imageLinksName];
        }
        delete payloadRequest[hyperLinksName];
        delete payloadRequest[imageLinksName];
      });

      const { sqlRequest, hashNames } = GetSqlRequestForHeaderPivotTable({
        incisionsInHeader: dataSettings.incisionsInHeader,
        valuesWithHeader: payloadRequest,
        indicators: dataSettings.indicators,
      });
      const { sqlRequest: sqlRequestImagesLinks, hashNames: hashNamesImagesLinks } = GetSqlRequestForHyperOrImageLinksPivotTable({
        incisionsInHeader: dataSettings.incisionsInHeader,
        valuesWithHeader: payloadRequest,
        indicators: dataSettings.indicators,
        keyLinks: 'imagesSettings',
        alias: imageLinksAlias,
      });
      const { sqlRequest: sqlRequestHyperLinks, hashNames: hashNamesHyperLinks } = GetSqlRequestForHyperOrImageLinksPivotTable({
        incisionsInHeader: dataSettings.incisionsInHeader,
        valuesWithHeader: payloadRequest,
        indicators: dataSettings.indicators,
        keyLinks: 'hyperLink',
        alias: hyperLinksAlias,
      });

      allSqlRequest += `${sqlRequest} ${sqlRequestImagesLinks} ${sqlRequestHyperLinks}`;

      const regex = /(SELECT\s+)/i;
      const allSQLForPivot = sqlRequestTable.replace(regex, `$1${allSqlRequest} `);

      const requestAllValues = await dispatch(
        loadVisualisationValuesAction({
          projectId,
          sqlRequest: allSQLForPivot,
          modelId,
          /*TODO: Comment on RLS and wait for the server */
          // rls,
        }),
      );

      if (!requestAllValues.payload || !Object.keys(requestAllValues.payload).length) {
        return;
      }

      const { columns, data } = getDataForPivotTable({
        incisionsHeader: payloadRequest,
        visualisationValues: {
          ...requestAllValues.payload,
          ...hyperLinksByConditionInHeader,
          ...imageLinksByConditionInHeader,
        } as VisualisationValuesInterface,
        allColumns: dataSettings,
        formatting,
        properties,
        grouping,
        hashNames,
        hashNamesImagesLinks,
        hashNamesHyperLinks,
        isRealData,
      });
      setSqlRequestForTotalRowPivot({ sqlRequest, hashNames });
      setSqlRequestForPivot({ sqlRequest: allSQLForPivot, hashNames });
      minMaxFunc({ columns, payloadRequest: requestAllValues.payload as VisualisationValuesInterface });
      setTableData({ columns, data: data });
    }

    fetchPivotTableData();
  }, [
    /*TODO: Comment on RLS and wait for the server */
    // rls,
    isRealData,
    sqlRequestForHeader,
    dataSettings,
    sqlRequestTable,
    dispatch,
    projectId,
    modelId,
    columnsSettings,
    properties,
    incisionsInHeaderNames,
    grouping,
    formatting,
  ]);

  // =============== fetch data in group ===============
  const addDataWithGroup = ({
    column,
    valueColumn,
    isExpanded,
    indexRow,
    indexColumn,
  }: {
    column: string;
    valueColumn: string;
    isExpanded: boolean;
    indexRow: number;
    indexColumn: number;
  }) => {
    setIsLoadingData(true);
    if (isExpanded) {
      setTableData((prev) => {
        let indexColumnInGroup = indexColumn;
        const newData = [...prev.data];
        const row = prev.data[indexRow];
        const cell = row[indexColumn];

        row.forEach((cell) => (cell.isSubtotal = false));

        const {
          isShow,
          location: { position },
        } = subtotalsSettings;

        if (isShow && position?.value === TopAndBottomEnum.TOP) {
          const totalRow = newData.splice(indexRow + 1, 1);
          totalRow[0].forEach((cell, index) => {
            row[index].value = index >= countIncisions ? cell.value : row[index].value;
          });
        }

        for (let i = indexRow + 1; i < newData.length; i) {
          if (newData[i][indexColumnInGroup + 1]?.isExpanded) {
            newData[i][indexColumnInGroup + 1].isExpanded = false;
            newData.splice(i, 1);
            indexColumnInGroup = indexColumnInGroup + 1;
            continue;
          }

          if (newData[i][indexColumnInGroup + 1]?.value !== null) {
            newData.splice(i, 1);
          } else if (indexColumnInGroup !== indexColumn) {
            indexColumnInGroup = indexColumnInGroup - 1;
          } else {
            break;
          }
        }
        cell.isExpanded = false;

        if (isShow && position?.value === TopAndBottomEnum.BOTTOM) {
          const totalRow = newData.splice(indexRow + 1, 1);

          totalRow[0].forEach((cell, index) => {
            row[index].value = index >= countIncisions ? cell.value : row[index].value;
          });
        }

        return {
          columns: prev.columns,
          data: newData,
        };
      });
      setIsLoadingData(false);
      return;
    }

    let hasLoadingRow = false;

    const timeoutGroupLoading = setTimeout(() => {
      if (!isRealData) {
        return;
      }

      setTableData((prev) => {
        const newData = [...prev.data];
        const row = newData[indexRow];
        hasLoadingRow = true;

        const loadRow: DataTableInterface[] = row.map((cell, index) => ({
          ...cell,
          value: index < countIncisions && cell.value ? loadRowTxt : '',
          isTotalRow: true,
          isSubtotal: true,
        }));

        newData.splice(indexRow + 1, 0, loadRow);
        return { ...prev, data: newData };
      });
    }, 500);

    const updateData = (payload: VisualisationValuesInterface) => {
      const excludeGroups = columnNextIndex ? grouping.slice(0, columnNextIndex - 1) : grouping,
        { data, columns } =
          sqlRequestForTotalRowPivot?.sqlRequest || (!isRealData && incisionsInHeaderNames.length)
            ? getDataForPivotTable({
                incisionsHeader: payload,
                visualisationValues: payload,
                allColumns: dataSettings,
                properties,
                grouping,
                formatting,
                excludeGroups,
                parentsChain: parentsChainTotal,
                hashNames:
                  isRealData && sqlRequestForTotalRowPivot ? sqlRequestForTotalRowPivot.hashNames : incisionsInHeaderNames,
                isRealData,
              })
            : getTableData({
                allColumns: dataSettings,
                visualisationValues: payload,
                properties,
                formatting,
                countIncisions: dataSettings.incisions.length,
                grouping,
                excludeGroups,
                parentsChain: parentsChainTotal,
                isRealData,
              });

      minMaxFunc({ columns, payloadRequest: payload, parentsChain: parentsChainTotal.join('>') });

      setTableData((prev) => {
        const newData = [...prev.data];
        const row = newData[indexRow];
        const cell = row[indexColumn];

        const {
          location: { position },
          isShow,
        } = subtotalsSettings;
        cell.isExpanded = true;
        cell.countChildren = data.length;

        hasLoadingRow && newData.splice(indexRow + 1, 1);
        newData.splice(indexRow + 1, 0, ...data);

        if (isShow) {
          const totalRow: DataTableInterface[] = JSON.parse(JSON.stringify(row));
          totalRow.forEach((cell, index) => {
            cell.value = index < countIncisions && cell.value ? `Итог ${cell.value}` : cell.value;
            cell.isTotalRow = true;
            cell.isSubtotal = true;
          });

          row.forEach((cell, index) => {
            cell.value = index >= countIncisions ? null : cell.value;
          });

          position?.value === TopAndBottomEnum.TOP && newData.splice(indexRow + 1, 0, totalRow);
          position?.value === TopAndBottomEnum.BOTTOM && newData.splice(indexRow + data.length + 1, 0, totalRow);
        }

        return {
          columns: prev.columns,
          data: newData,
        };
      });
      setIsLoadingData(false);
    };

    const columnNext = grouping[grouping.indexOf(column) + 1];
    const columnNextIndex = columnNext ? grouping.indexOf(column) + 2 : dataSettings.incisions.length;
    const { parentsChain } = tableData.data[indexRow][indexColumn];
    const parentsChainTotal = parentsChain ? [...parentsChain, valueColumn] : [valueColumn];

    if (!isRealData) {
      dataSettings.incisions.map((incision) => {
        incision.settings.isGroup;
      });

      const fictionalDataColumnNext = dataSettings.incisions[columnNextIndex - 1]?.fictionalData;

      const incisionsValues = dataSettings.incisions
        .slice(0, columnNextIndex)
        .reduce((values, { fieldName, name, fictionalData, settings: { nameFromDatabase } }) => {
          const incisionName = getVisualisationFieldName({ fieldName, name, nameFromDatabase });

          return { ...values, [incisionName]: fictionalData.slice(0, fictionalDataColumnNext?.length) };
        }, {});

      const indicatorsValues = dataSettings.indicators.reduce((values, { fieldName, name, settings: { nameFromDatabase } }) => {
        const indicatorName = getVisualisationFieldName({ fieldName, name, nameFromDatabase });

        return {
          ...values,
          [indicatorName]: fictionalDataColumnNext.map(() => getRandomInt(0, 100)),
        };
      }, {});

      updateData({
        ...incisionsValues,
        ...indicatorsValues,
      });
      setIsLoadingData(false);
    }

    let sqlRequest = getSqlRequestForGroupingRow({
      id,
      columnNextIndex,
      parentsChain: parentsChainTotal,
      limitData: limitGrouping.isActive
        ? {
            seperator: ',',
            value: [
              {
                type: 'number',
                value: limitGrouping.value || 1,
              },
            ],
          }
        : undefined,
      incisionsInHeaderNames,
    });

    if (!sqlRequest) {
      return;
    }

    if (sqlRequestForTotalRowPivot) {
      const regex = /(SELECT\s+)/i;
      sqlRequest = sqlRequest.replace(regex, `$1${sqlRequestForTotalRowPivot.sqlRequest} `);
    }

    const request = dispatch(
      loadVisualisationValuesAction({
        projectId,
        sqlRequest,
        modelId,
        /*TODO: Comment on RLS and wait for the server */
        // rls
      }),
    );

    request
      .then(({ payload }) => {
        clearTimeout(timeoutGroupLoading);
        if (payload) {
          updateData(payload as VisualisationValuesInterface);
        }
      })
      .catch((e) => {
        /* TODO: Добавить адекватный логер */
        console.log(e);
      });
  };

  // =============== infinity scroll fetch ===============
  const fetchData = useCallback(
    () => {
      setIsLoadingData(true);

      if (!sqlRequestTable) {
        return;
      }

      const request = dispatch(
        loadVisualisationValuesAction({
          projectId,
          sqlRequest: `${sqlRequestForPivot?.sqlRequest || sqlRequestTable} OFFSET ${offsetSqlRequest}`,
          modelId,
          /*TODO: Comment on RLS and wait for the server */
          // rls,
        }),
      );

      request
        .then(({ payload }) => {
          if (payload) {
            const payloadRequest = payload as VisualisationValuesInterface;

            if (Object.keys(payloadRequest).length === 0) {
              setHasMoreData(false);
              setIsLoadingData(false);
              return;
            }

            const { data, columns } = sqlRequestForPivot?.sqlRequest
              ? getDataForPivotTable({
                  incisionsHeader: payloadRequest,
                  visualisationValues: payloadRequest,
                  allColumns: dataSettings,
                  formatting,
                  properties,
                  grouping,
                  hashNames: sqlRequestForPivot.hashNames,
                  isRealData,
                })
              : getTableData({
                  allColumns: dataSettings,
                  visualisationValues: payloadRequest,
                  properties,
                  formatting,
                  countIncisions: dataSettings.incisions.length,
                  grouping,
                  isRealData,
                });

            minMaxFunc({ columns, payloadRequest });

            setTableData((prev) => {
              prev.data.push(...data);

              return {
                columns: prev.columns,
                data: prev.data,
              };
            });
            setOffsetSqlRequest(offsetSqlRequest + 25);
            setIsLoadingData(false);
          }
        })
        .catch(() => {
          setHasMoreData(false);
          setIsLoadingData(false);
        });
    }, // eslint-disable-next-line
    [
      sqlRequestTable,
      /*TODO: Comment on RLS and wait for the server */
      // rls,
      offsetSqlRequest,
      sqlRequestForPivot,
    ],
  );

  useEffect(() => {
    if (inViewDownObserve && hasMoreData && !isLoadingData) {
      fetchData();
    }
  }, [inViewDownObserve, fetchData, hasMoreData, isLoadingData]);

  const onChartClick = useCallback(
    (value: string, nameIncision: string) => {
      const incision = [...dataSettings.incisions, ...dataSettings.incisionsInHeader].find(
        ({ name, fieldName, settings: { nameFromDatabase } }) =>
          getVisualisationFieldName({
            name,
            fieldName,
            nameFromDatabase,
          }) === nameIncision,
      );

      const fieldName = incision?.fieldName;
      const customRequest = incision?.settings.customRequest;

      const finalFieldName = customRequest || fieldName;

      if (finalFieldName) {
        updateFilter({ selectedValue: value, fieldName: finalFieldName });
      }
    },
    [updateFilter, dataSettings],
  );

  const onChartByCondition = useCallback(
    ({ isIncision, hyperLink, value, accessorKey, isFilteringHyperLink }: OnChartClickByConditionInterface) => {
      if (isIncision && !hyperLink) {
        return onChartClick(String(value), accessorKey);
      }
      if (isIncision && hyperLink && isFilteringHyperLink) {
        return onChartClick(String(value), accessorKey);
      }
    },
    [onChartClick],
  );

  return (
    <StyledTableWrapper
      showBackground={viewSettings.showBackground}
      isAdaptive={viewSettings.isAdaptive}
      verticalLine={colorInvertInHEX(bodySettings.verticalLines, getColorValues)}
      horizontalLine={colorInvertInHEX(bodySettings.horizontalLines, getColorValues)}
      externalBeat={colorInvertInHEX(bodySettings.externalBeat, getColorValues)}
      beatColumnLine={viewSettings.incisionBeat.includes('line')}
      beatHeaderBackground={viewSettings.headerBeat.includes('background')}
      beatHeaderLine={colorInvertInHEX(headerSettings.headerBeatLine, getColorValues)}
      beatTotalBackground={viewSettings.totalBeat.includes('background')}
      beatTotalLine={viewSettings.totalBeat.includes('line')}
      isTotalTop={isTotalTop}
      isTotalShow={isTotalShow}
      incisionsCount={countIncisions}
      countRowsInHeader={tableData.columns.length}
      ref={tableRef}
    >
      <TanStackTableStyled>
        <TableHead>
          {headerSettings.isShow &&
            tableData.columns.map((columns, index) => {
              return (
                <TableRow key={index}>
                  {columns.map((column, indexRow) => {
                    /*TODO раскомментировать всё когда появится группировка в хедере*/
                    const {
                      id: columnId,
                      Header,
                      colSpan,
                      rowSpan,
                      isIncision,
                      isIncisionInHeader,
                      properties,
                      dataAccessor,
                      hyperLink,
                      imageLink,
                      /* isGroup, */
                      /* isExpanded, */
                    } = column;
                    const { columnWidth, hasIndentation, indentation, openInThisWindow, isFilteringHyperLink, imagesSettings } =
                      columnsSettings[dataAccessor] || columnsSettings[Object.keys(columnsSettings)[0]];
                    const {
                      size: { horizontal: horizontalImageSize, vertical: verticalImageSize },
                      alignmentAndPosition: {
                        absolutePositionSetting,
                        horizontal: horizontalImageAlignment,
                        vertical: verticalImageAlignment,
                      },
                      textIndent,
                    } = imagesSettings;
                    const sortItsColumn = dataSettings.orderBy.filter((el) => el.columnName === Header)?.[0];
                    const isLowerLevel = index === tableData.columns.length - 1;
                    /* const isLastRowIncisionsInHeader = index === tableData.columns.length - 2; */
                    const isFirstBlockInPivot = !!incisionsInHeaderNames.length && index === 0 && indexRow === 0;
                    const {
                      alignment: { horizontal, vertical },
                    } = properties?.isActiveStyle
                      ? properties
                      : isIncision
                      ? bodySettings.propertiesIncisions
                      : bodySettings.propertiesIndicators;
                    const propertiesCell =
                      isIncisionInHeader && properties?.isActiveStyle ? properties : headerSettings.properties;
                    const { backgroundOpacity } = headerSettings.properties;
                    const { left, right } = hasIndentation ? indentation : headerSettings.indentation;
                    const { top, bottom } = headerSettings.hasIndentation ? headerSettings.indentation : bodySettings.indentation;
                    const indentationCell = { left, right, top, bottom };
                    const backgroundHeader = headerSettings.overfill ? headerSettings.properties.backgroundColor : undefined;
                    const { fontColor } =
                      isIncisionInHeader && column.properties.isActiveFontColor ? column.properties : headerSettings.properties;
                    const backgroundBody =
                      isIncisionInHeader && column.properties.isActiveBackgroundColor
                        ? column.properties.backgroundColor
                        : isIncision
                        ? bodySettings.propertiesIncisions.backgroundColor
                        : bodySettings.propertiesIndicators.backgroundColor;

                    const backgroundColorBody = getColorValues(backgroundBody) || undefined;
                    const backgroundColorHeader = backgroundHeader && getColorValues(backgroundHeader);

                    if (!!columnVisibility.length && !columnVisibility.includes(Header) && isIncision) {
                      return null;
                    }

                    return (
                      <TableHeader
                        key={columnId}
                        width={columnWidth}
                        onClick={() => {
                          isLowerLevel
                            ? onSortClick({
                                dataSettings,
                                columnName: Header,
                                sortingColumn: sortItsColumn,
                                isSorted: sortItsColumn?.columnName === Header,
                                id,
                              })()
                            : undefined;
                          Header &&
                            onChartByCondition({
                              isIncision: !!isIncisionInHeader,
                              hyperLink: !!hyperLink,
                              value: Header,
                              accessorKey: dataAccessor,
                              isFilteringHyperLink,
                            });
                        }}
                        colSpan={!isFirstBlockInPivot ? colSpan : columnVisibility.length}
                        rowSpan={rowSpan}
                        backgroundColor={backgroundColorBody}
                      >
                        <TableCellComponent
                          value={Header}
                          backgroundColor={backgroundColorBody}
                          fontColor={getColorValues(fontColor)}
                          imageLink={imageLink}
                          hyperLink={hyperLink}
                          openHyperLinkInThisWindow={openInThisWindow}
                          horizontalImageSize={horizontalImageSize}
                          verticalImageSize={verticalImageSize}
                          paddingBody={getPadding(
                            indentationCell,
                            headerSettings.hasIndentation,
                            bodySettings.indentation,
                            hasIndentation,
                          )}
                          properties={propertiesCell}
                          horizontalAlignment={horizontal}
                          verticalAlignment={vertical}
                          hasFirstLevelBackgroundAbsolute={!!backgroundColorHeader}
                          firstLevelBackgroundColor={backgroundColorHeader}
                          firstLevelOpacity={backgroundOpacity}
                          absolutePositionSetting={!isLowerLevel ? absolutePositionSetting : undefined}
                          horizontalImageAlignment={horizontalImageAlignment}
                          verticalImageAlignment={verticalImageAlignment}
                          textIndent={textIndent}
                          isSortCell={isLowerLevel && sortItsColumn?.columnName === Header}
                          isDESCSort={sortItsColumn?.type === SortingValueEnum.DESC}
                          getColorValues={getColorValues}
                        />
                      </TableHeader>
                    );
                  })}
                </TableRow>
              );
            })}
          {isTotalShow && isTotalTop && (
            <TotalComponent
              columns={tableData.columns[tableData.columns.length - 1] || []}
              columnVisibility={columnVisibility}
              totalValues={totalValues}
              hashName={sqlRequestForTotalRowPivot?.hashNames}
              countIncisions={dataSettings.incisions.length}
              settings={totalRowSettings}
              bodySettings={bodySettings}
              columnsSettings={columnsSettings}
            />
          )}
        </TableHead>
        {/* @ts-ignore */}
        <TransitionGroup component={TableBody}>
          {tableData.data.map((rows, indexRow) => {
            const lastRowClassName = indexRow === tableData.data.length - 1 ? lastRowClass : undefined;

            return (
              <CSSTransition key={indexRow} timeout={200} classNames="fade">
                <TableRow className={lastRowClassName}>
                  {rows.map((row, index) => {
                    const column = tableData.columns[tableData.columns.length - 1][index] || tableData.columns[0][0];
                    const { properties, isIncision, Header, isGroup, dataAccessor } = column;
                    const { hasIndentation, indentation, openInThisWindow, isFilteringHyperLink, imagesSettings } =
                      columnsSettings[dataAccessor] || columnsSettings[Object.keys(columnsSettings)[0]];
                    const {
                      size: { horizontal: horizontalImageSize, vertical: verticalImageSize },
                      alignmentAndPosition: {
                        absolutePositionSetting,
                        horizontal: horizontalImageAlignment,
                        vertical: verticalImageAlignment,
                      },
                      textIndent,
                    } = imagesSettings;
                    const {
                      id,
                      value,
                      isExpanded,
                      accessorKey,
                      hyperLink,
                      imageLink,
                      dynamicMarker,
                      strParentsChain,
                      isTotalRow,
                      isSubtotal,
                    } = row;
                    const propertiesCell = isIncision ? bodySettings.propertiesIncisions : bodySettings.propertiesIndicators;

                    const { fontColor, background } = getColors({
                      column,
                      rowData: row,
                      parentsChain: strParentsChain,
                      properties: propertiesCell,
                      getColorValues,
                      backgroundByValueSettings,
                      colorByValueSettings,
                      minMaxIndicators,
                      getColorByValue,
                      subtotalsSettings,
                    });
                    const {
                      alignment: { horizontal, vertical },
                    } = properties?.isActiveStyle ? properties || propertiesCell : propertiesCell;
                    const indentationCell = hasIndentation
                      ? indentation
                      : isSubtotal
                      ? subtotalsSettings.indentation
                      : bodySettings.indentation;
                    const { backgroundOpacity: bodyBackgroundOpacity } = propertiesCell;
                    const priorityIndentation = isSubtotal ? subtotalsSettings.hasIndentation : false;
                    const rowsBeatColor = bodySettings.rowsBeat.isBeat ? getColorValues(bodySettings.rowsBeat.color) : null;
                    const nextIncisionFieldName =
                      dataSettings.incisions[index + 1]?.fieldName || dataSettings.incisions[index + 1]?.settings.customRequest;
                    const commonConditionForGrouping =
                      !isTotalRow && isGroup && isIncision && index !== dataSettings.incisions.length - 1;
                    const conditionForGrouping =
                      ((isRealData && nextIncisionFieldName) || !isRealData) && commonConditionForGrouping;
                    const stylePropertiesCell = isSubtotal
                      ? subtotalsSettings.properties
                      : properties.isActiveStyle
                      ? properties || propertiesCell
                      : propertiesCell;
                    if (dynamicMarker?.colorsByValue?.[colorAlias] && (row.valueFromBD || row.valueFromBD === 0)) {
                      dynamicMarker.color = getColorByValueMinMax<ColorByItem>({
                        min: minMaxIndicators[Header + strParentsChain][0],
                        max: minMaxIndicators[Header + strParentsChain][1],
                        colors: dynamicMarker?.colorsByValue[colorAlias],
                        value: row.valueFromBD,
                      }).value;
                    }

                    if (!!columnVisibility.length && !columnVisibility.includes(Header) && isIncision) {
                      return null;
                    }

                    return (
                      <TableCell
                        key={id}
                        backgroundColor={background}
                        opacity={bodyBackgroundOpacity}
                        onClick={() => {
                          value &&
                            onChartByCondition({
                              isIncision: !!isIncision,
                              hyperLink: !!hyperLink,
                              value,
                              accessorKey,
                              isFilteringHyperLink,
                            });
                        }}
                      >
                        <TableCellComponent
                          value={value}
                          backgroundColor={background}
                          backgroundOpacity={bodyBackgroundOpacity}
                          fontColor={fontColor}
                          imageLink={imageLink}
                          hyperLink={hyperLink}
                          openHyperLinkInThisWindow={openInThisWindow}
                          horizontalImageSize={horizontalImageSize}
                          verticalImageSize={verticalImageSize}
                          paddingBody={getPadding(indentationCell, priorityIndentation, bodySettings.indentation, hasIndentation)}
                          isExpandedGroup={isExpanded}
                          hasGroupingButton={!!conditionForGrouping}
                          onClickButtonGrouping={() => {
                            !isLoadingData &&
                              value &&
                              addDataWithGroup({
                                column: accessorKey,
                                valueColumn: value,
                                isExpanded,
                                indexRow,
                                indexColumn: index,
                              });
                          }}
                          properties={stylePropertiesCell}
                          horizontalAlignment={horizontal}
                          verticalAlignment={vertical}
                          hasFirstLevelBackgroundAbsolute={isSubtotal && subtotalsSettings.overfill}
                          firstLevelBackgroundColor={getColorValues(subtotalsSettings.properties.backgroundColor)}
                          firstLevelOpacity={stylePropertiesCell.backgroundOpacity}
                          hasTwoLevelBackgroundAbsolute={bodySettings.rowsBeat.isBeat && indexRow % 2 === 0}
                          twoLevelBackgroundColor={
                            (!isArray(rowsBeatColor) && rowsBeatColor) || activeThemeSchema[ColorVarsEnum.Level_1]
                          }
                          twoLevelOpacity={bodySettings.rowsBeat.countUnit}
                          horizontalImageAlignment={horizontalImageAlignment}
                          verticalImageAlignment={verticalImageAlignment}
                          absolutePositionSetting={absolutePositionSetting}
                          paddingText={isTotalRow && isIncision ? '0 0 0 20px' : undefined}
                          textIndent={textIndent}
                          disableDynamicMarker={!(!isIncision && !!dynamicMarker)}
                          settingsDynamicMarker={dynamicMarker}
                          getColorValues={getColorValues}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              </CSSTransition>
            );
          })}
          {isRealData && (
            <CSSTransition key={`${isRealData}`} timeout={200} classNames="fade">
              <Observe ref={observeDownRef} />
            </CSSTransition>
          )}
          {isTotalShow && !isTotalTop && (
            <CSSTransition key={`${isTotalShow}_${!isTotalTop}`} timeout={0} classNames="fade">
              <TotalComponent
                columns={tableData.columns[tableData.columns.length - 1] || []}
                columnVisibility={columnVisibility}
                totalValues={totalValues}
                hashName={sqlRequestForTotalRowPivot?.hashNames}
                countIncisions={dataSettings.incisions.length}
                settings={totalRowSettings}
                bodySettings={bodySettings}
                columnsSettings={columnsSettings}
              />
            </CSSTransition>
          )}
        </TransitionGroup>
      </TanStackTableStyled>
    </StyledTableWrapper>
  );
};

export const TableVisualisation = memo(TableVisualisationComponent) as VisualisationOriginInterface<TableVisualisationType>;
