import styled, { css } from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

interface SetupIconProps {
  isActive?: boolean;
}

export const SetupIconWrapper = styled.div<SetupIconProps>`
  transition: all 450ms;
  ${({ isActive }) =>
    isActive &&
    css`
      svg {
        path {
          stroke: ${`var(${ColorVarsEnum.Accent})`};
        }
      }
    `}
`;

interface SetupButtonContainerProps {
  disabledViewSetup: boolean;
  isSelected?: boolean;
}

export const SetupButtonContainer = styled.div<SetupButtonContainerProps>`
  display: none;
  ${({ disabledViewSetup, isSelected }) =>
    !disabledViewSetup &&
    isSelected &&
    css`
      display: flex;
    `}
`;

export const SettingsFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
