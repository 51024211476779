import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { OnValueChange } from 'types/global';
import { FormattingInterface } from 'store/reducers/visualisations/types';
import React from 'react';
import { Formattings } from './Formattings';

type FormattingProps = OnValueChange<FormattingInterface>;

export const FormattingSettings = ({ value: formatting, onChange }: FormattingProps) => {
  const { isShow } = formatting;

  return (
    <MainContainerSettings
      titleText="Форматирование значения"
      switcherState={isShow}
      switcherChange={() => onChange({ ...formatting, isShow: !isShow })}
    >
      <Formattings value={formatting} onChange={onChange} />
    </MainContainerSettings>
  );
};
