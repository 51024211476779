import { GroupsListInterface } from 'store/reducers/adminGroups/types';
import { EntityType, GroupType, IdInterface, LoadingInterface } from 'types/store';
import {
  ColorByInterface,
  ColorByItem,
  ColorValuesByThemeType,
  GradientColorByInterface,
  IsShowInterface,
} from 'store/reducers/visualisations/types';
import { PaletteValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';

export interface AccessInterface extends IdInterface {
  name: string;
  entity: EntityType;
  type: GroupType;
}

export interface AddUsersGroupsSourceAccessPayload {
  sourceId: string;
  groups?: string[];
  users?: string[];
  type: 'owner' | 'reader';
}

interface UpdateSourceAccess {
  sourceId: string;
  type: 'owner' | 'reader';
}

export interface UpdateGroupSourceAccessByIdPayload extends UpdateSourceAccess {
  groupId: string;
}

export interface UpdateUserSourceAccessByIdPayload extends UpdateSourceAccess {
  userId: string;
}

export interface AvatarProjectInterface {
  avatar: File | null;
  projectId: string;
}

export type GetUsersAccessNoGroupPayload = {
  groupId: string;
};

export interface GroupsInterface extends LoadingInterface {
  groupsList: GroupsListInterface[];
}

export enum AstOperatorEnum {
  IN = 'IN',
  BETWEEN = 'BETWEEN',
}

export type CronHour = FastBoard.API.CronHour;
export type CronDay = FastBoard.API.CronDay;
export type CronWeek = FastBoard.API.CronWeek;
export type CronMonth = FastBoard.API.CronMonth;
export type CronSchedule = FastBoard.API.CronSchedule;

export type CronDateTypes = CronHour | CronDay | CronWeek | CronMonth | CronSchedule;

export interface RLSInterface {
  type: EntityType;
  entityId: string;
}

export enum WidgetRuNameEnum {
  single = 'Одиночный',
  multiple = 'Множественный',
  date = 'Дата',
  bubble = 'Пузырьковая диаграмма',
  heatmap = 'Тепловая карта',
  lineAndBar = 'Линейная диаграмма',
  pie = 'Круговая диаграмма',
  table = 'Таблица',
  text = 'Текст',
  tree = 'Дерево',
  waterfall = 'Водопадная диаграмма',
}

export interface ColorSettingsBase {
  elementColorBy: ColorByInterface;
  isActive: boolean;
}

export interface ColorSettingsIncision extends ColorSettingsBase {
  elementColor: PaletteValuesByThemeType | null;
}

export interface ColorSettingsIndicator extends ColorSettingsBase {
  elementColor: ColorValuesByThemeType | null;
}

export type TableDataValue = string | number | (string | number | null)[];

export interface GradientColorSettingsBase {
  elementColorBy: GradientColorByInterface;
  isActive: boolean;
}

export interface GradientColorSettings extends GradientColorSettingsBase {
  elementColor: ColorValuesByThemeType | null;
}

export interface TextSettingInterface extends IsShowInterface {
  text: string;
  isShow: boolean;
}
export interface TextSettingColorInterface extends IsShowInterface {
  text: string;
  isShow: boolean;
  fontStyle?: {
    bold: boolean;
    italic: boolean;
    underline: boolean;
  };
  fontSize?: number;
  lineHeight?: number;
  letterSpacing?: number;
  wordSpacing?: number;
  fontColor?:
    | PaletteValuesByThemeType
    | ColorValuesByThemeType
    | (PaletteValuesByThemeType | null)[]
    | (ColorValuesByThemeType | null)[]
    | null;
  backgroundColor?: string | ColorValuesByThemeType | null;
  backgroundOpacity?: number;
  isActiveStyle?: boolean;
  isActiveFontColor?: boolean;
  isActiveBackgroundColor?: boolean;
  opacity?: number;
  padding?: {
    horizontal: number;
    vertical: number;
    isConnection: boolean;
  };
  changeFillColor?: boolean;
  alignment?: {
    horizontal: 'flex-start' | 'center' | 'flex-end';
    vertical: 'flex-start' | 'center' | 'flex-end';
  };
  fontColorBy?: {
    type: 'default' | 'custom';
    isActive: boolean;
    byCondition: {
      colors?: ColorByItem[] | null;
      sqlCondition: string | null;
      alias: string;
    };
    byValue: {
      alias: string;
      colors: string | null;
    };
    byValueSpecific: {
      alias: string;
      colors: string | null;
    };
  };
  backgroundColorBy?: {
    type: 'default' | 'custom';
    isActive: boolean;
    byCondition: {
      colors: ColorByItem[] | null;
      sqlCondition: string | null;
      alias: string;
    };
    byValue: {
      alias: string;
      colors: string | null;
    };
    byValueSpecific: {
      alias: string;
      colors: string | null;
    };
  };
}
