import { AbsoluteColorBlock, ButtonWrapper, ImageBlockTable, ImageInTable, TableTextBody } from '../styles';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { formattingNameTextAlign } from 'utils/formatting';
import { PrimaryTextSpan, TextLink } from 'styles/TextsElements';
import { FlexContainer } from 'styles/FlexContainer';
import { addProtocolToLink } from 'utils/utils';
import React from 'react';
import { NoopType } from 'types/global';
import { AbsolutePositionSettingType, PositionSettingEnum, PositionSettingType } from 'types/store';
import { DefaultPropertiesInterface, SettingsOneDynamicMarkerInterface } from 'store/reducers/visualisations/types';
import { DownFilterIcon, UpFilterIcon } from 'assets/icons/filter';
import { markersOptions } from 'modules/settingsContainer/common/data/DynamicsMarkerSettings/constants';
import { defaultSettingsOneDynamicMarker } from 'store/reducers/visualisations/constants';
import { GetColorsFnType } from 'modules/settingsContainer/ColorPicker/hooks';
import { IconBlock } from 'modules/ui/Select';

interface TableCellProps {
  value: string | null;
  paddingBody: string;
  paddingText?: string;
  isExpandedGroup?: boolean;
  isSortCell?: boolean;
  isDESCSort?: boolean;
  properties: DefaultPropertiesInterface;
  absolutePositionSetting?: AbsolutePositionSettingType;
  imageLink?: string;
  horizontalImageAlignment?: PositionSettingType;
  verticalImageAlignment?: PositionSettingType;
  horizontalImageSize?: number;
  verticalImageSize?: number;
  hyperLink?: string;
  openHyperLinkInThisWindow?: boolean;
  hasGroupingButton?: boolean;
  onClickButtonGrouping?: NoopType;
  horizontalAlignment: PositionSettingType;
  verticalAlignment: PositionSettingType;
  backgroundOpacity?: number;
  backgroundColor?: string | null;
  fontColor?: string | null;
  textIndent?: number;
  hasFirstLevelBackgroundAbsolute?: boolean;
  hasTwoLevelBackgroundAbsolute?: boolean;
  firstLevelBackgroundColor?: string | null;
  twoLevelBackgroundColor?: string | null;
  firstLevelOpacity?: number;
  twoLevelOpacity?: number;
  settingsDynamicMarker?: SettingsOneDynamicMarkerInterface;
  disableDynamicMarker?: boolean;
  getColorValues: GetColorsFnType;
}

export const TableCellComponent = (props: TableCellProps) => {
  const {
    value,
    properties,
    imageLink,
    hyperLink,
    isSortCell,
    isDESCSort,
    openHyperLinkInThisWindow,
    horizontalImageSize,
    verticalImageSize,
    paddingBody,
    isExpandedGroup = false,
    horizontalAlignment,
    verticalAlignment,
    onClickButtonGrouping,
    fontColor,
    paddingText,
    backgroundColor,
    backgroundOpacity,
    textIndent,
    absolutePositionSetting,
    firstLevelBackgroundColor,
    twoLevelBackgroundColor,
    firstLevelOpacity,
    twoLevelOpacity,
    hasGroupingButton = false,
    hasFirstLevelBackgroundAbsolute = false,
    hasTwoLevelBackgroundAbsolute = false,
    horizontalImageAlignment = PositionSettingEnum.FLEXSTART,
    settingsDynamicMarker = defaultSettingsOneDynamicMarker,
    disableDynamicMarker = true,
    getColorValues,
    verticalImageAlignment,
  } = props;

  const { indent, position, type, sizeMarker, color: markerColor } = settingsDynamicMarker;
  const { Icon } = markersOptions.find((marker) => marker.value === type) || markersOptions[0];
  const { fontSize, lineHeight, opacity, fontStyle, letterSpacing } = properties;

  return (
    <>
      {hasFirstLevelBackgroundAbsolute && (
        <AbsoluteColorBlock backgroundColor={firstLevelBackgroundColor} opacity={firstLevelOpacity} />
      )}
      {hasTwoLevelBackgroundAbsolute && (
        <AbsoluteColorBlock backgroundColor={twoLevelBackgroundColor} opacity={twoLevelOpacity} />
      )}
      <TableTextBody
        justifyContent={horizontalAlignment}
        alignItems={verticalAlignment}
        textAlign={formattingNameTextAlign(horizontalAlignment)}
        padding={paddingBody}
      >
        {value === null ? (
          ''
        ) : (
          <>
            {hasGroupingButton && (
              <ButtonWrapper
                onClick={(event) => {
                  onClickButtonGrouping && onClickButtonGrouping();
                  event.stopPropagation();
                }}
                backgroundColor={backgroundColor}
                opacity={backgroundOpacity}
                color={fontColor || undefined}
              >
                <PrimaryTextSpan lineHeight="12px" color={fontColor || undefined}>
                  {isExpandedGroup ? '–' : '+'}
                </PrimaryTextSpan>
              </ButtonWrapper>
            )}
            <FlexContainer
              flexDirection={absolutePositionSetting}
              gap={`${textIndent}px`}
              alignItems={
                absolutePositionSetting === 'column' || absolutePositionSetting === 'column-reverse'
                  ? horizontalAlignment
                  : verticalAlignment
              }
              textAlign={formattingNameTextAlign(horizontalAlignment)}
            >
              {imageLink && (
                <ImageBlockTable
                  textAlign={formattingNameTextAlign(horizontalImageAlignment)}
                  horizontalAlignment={horizontalImageAlignment}
                  margin={verticalImageAlignment === PositionSettingEnum.CENTER ? 'auto 0' : undefined}
                  marginBottom={verticalImageAlignment === PositionSettingEnum.FLEXSTART ? 'auto' : undefined}
                  marginTop={verticalImageAlignment === PositionSettingEnum.FLEXEND ? 'auto' : undefined}
                >
                  <ImageInTable src={addProtocolToLink(imageLink)} maxWidth={horizontalImageSize} maxHeight={verticalImageSize} />
                </ImageBlockTable>
              )}
              <FlexContainer gap={`${indent}px`} flexDirection={position === 'left' ? 'row-reverse' : 'row'}>
                <PrimaryTextSpan
                  fontSize={`${fontSize}px`}
                  lineHeight={`${lineHeight}%`}
                  color={fontColor || undefined}
                  opacity={opacity}
                  letterSpacing={`${letterSpacing}px`}
                  fontWeight={fontStyle.bold ? 'bold' : 'normal'}
                  fontStyle={fontStyle.italic ? 'italic' : 'normal'}
                  textDecoration={fontStyle.underline ? 'underline' : undefined}
                  padding={paddingText}
                >
                  {hyperLink ? (
                    <TextLink
                      fontSize={`${fontSize}px`}
                      lineHeight={`${lineHeight}%`}
                      letterSpacing={`${letterSpacing}px`}
                      fontWeight={fontStyle.bold ? 'bold' : 'normal'}
                      fontStyle={fontStyle.italic ? 'italic' : 'normal'}
                      textDecoration={fontStyle.underline ? 'underline' : undefined}
                      color={`var(${ColorVarsEnum.Accent})`}
                      href={addProtocolToLink(hyperLink)}
                      target={openHyperLinkInThisWindow ? '_self' : '_blank'}
                    >
                      {value}
                    </TextLink>
                  ) : (
                    value
                  )}
                </PrimaryTextSpan>
                {!disableDynamicMarker && Icon && (
                  <IconBlock colorSvg={getColorValues(markerColor) || undefined} size={sizeMarker}>
                    <Icon />
                  </IconBlock>
                )}
              </FlexContainer>

              {isSortCell && (
                <PrimaryTextSpan lineHeight="12px">{isDESCSort ? <DownFilterIcon /> : <UpFilterIcon />}</PrimaryTextSpan>
              )}
            </FlexContainer>
          </>
        )}
      </TableTextBody>
    </>
  );
};
