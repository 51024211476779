import ace from 'ace-builds';

export type AdviceEditor = {
  caption: string;
  meta?: string;
  value: string;
};

export enum AceEditorThemeEnum {
  GITHUB_DARK = 'github_dark',
  SQLSERVER = 'sqlserver',
}

export interface ExtendedRenderer extends ace.Ace.VirtualRenderer {
  $cursorLayer: {
    element: HTMLElement;
  };
}
