import React, { FC } from 'react';
import { DefaultVisualisationsTypeProps } from 'assets/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { FlexContainer } from 'styles/FlexContainer';

export const OctantPie: FC<DefaultVisualisationsTypeProps> = ({
  backgroundFill = `var(${ColorVarsEnum.Level_4_widget})`,
  fillDefault = `var(${ColorVarsEnum.Level_1})`,
  symbolSize = 70,
}) => {
  return (
    <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
      <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="70" height="70" rx="5" fill={backgroundFill} />
        <path
          d="M35 12C41.1 12 46.9501 14.4232 51.2635 18.7365L46.3844 23.6156C43.3651 20.5962 39.27 18.9 35 18.9V12Z"
          fill={fillDefault}
        />
        <path
          opacity="0.75"
          d="M51.2635 18.7365C55.5768 23.0499 58 28.9 58 35L51.1 35C51.1 30.73 49.4038 26.6349 46.3844 23.6156L51.2635 18.7365Z"
          fill={fillDefault}
        />
        <path
          opacity="0.55"
          d="M58 35C58 41.1 55.5768 46.9501 51.2635 51.2635L46.3844 46.3844C49.4038 43.3651 51.1 39.27 51.1 35H58Z"
          fill={fillDefault}
        />
        <path
          opacity="0.45"
          d="M51.2635 51.2635C46.9501 55.5768 41.1 58 35 58V51.1C39.27 51.1 43.3651 49.4038 46.3844 46.3844L51.2635 51.2635Z"
          fill={fillDefault}
        />
        <path
          opacity="0.35"
          d="M35 58C28.9 58 23.0499 55.5768 18.7366 51.2635L23.6156 46.3844C26.6349 49.4038 30.73 51.1 35 51.1L35 58Z"
          fill={fillDefault}
        />
        <path
          opacity="0.25"
          d="M18.7365 51.2635C14.4232 46.9501 12 41.1 12 35L18.9 35C18.9 39.27 20.5962 43.3651 23.6156 46.3844L18.7365 51.2635Z"
          fill={fillDefault}
        />
        <path
          opacity="0.15"
          d="M12 35C12 28.9 14.4232 23.0499 18.7365 18.7365L23.6156 23.6156C20.5962 26.6349 18.9 30.73 18.9 35H12Z"
          fill={fillDefault}
        />
        <path
          opacity="0.05"
          d="M18.7365 18.7365C23.0499 14.4232 28.9 12 35 12L35 18.9C30.73 18.9 26.6349 20.5962 23.6156 23.6156L18.7365 18.7365Z"
          fill={fillDefault}
        />
      </svg>
    </FlexContainer>
  );
};
