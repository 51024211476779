import isArray from 'lodash/isArray';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import { onChangeOrderById } from 'modules/visualisations/common/onChangeFunctions';
import { FormattingRecordType } from 'modules/visualisations/hooks/useFormat';
import { PropertiesRecordType } from 'modules/visualisations/hooks/useProperties';
import {
  Column,
  DataTableInterface,
  OnSortChangeInterface,
  TotalValuesType,
} from 'modules/visualisations/Table/visualisation/types';
import { IndicatorAstType } from 'store/reducers/ast/types';
import {
  backgroundByValueAlias,
  backgroundValueAlias,
  colorAlias,
  colorValueAlias,
  colorValueByAlias,
  dynamicMarkerColorAlias,
  dynamicMarkerFormAlias,
  getVisualisationFieldName,
  hyperLinksAlias,
  imageLinksAlias,
} from 'store/reducers/visualisations/constants';
import {
  ColorAndImageByEnum,
  ColorByInterface,
  ColorByItem,
  DefaultPropertiesInterface,
  DynamicMarkerModeEnum,
  IndentationInterface,
  IsBeatInterface,
  SettingsOneDynamicMarkerInterface,
  TableDataSettings,
  TableIncisionInterface,
  TableIndicatorInterface,
  TotalRowSettingsInterface,
  TransitionModeEnum,
  VisualisationDataInterface,
  VisualisationValuesInterface,
} from 'store/reducers/visualisations/types';
import { AST } from 'types/ast';
import { generateSqlSelectValue } from 'utils/SQL/generateSQL';
import { defaultSelectAST, generateBasicFunctionColumn, getSelectColumnsFromSqlString, sqlParser } from 'utils/SQL/genereteAst';
import {
  checkInRange,
  getColorByValueMinMax,
  getRandomInt,
  getSumOfArrayValues,
  replaceEmptyValuesForTableData,
} from 'utils/utils';
import { ColorByConditionUtils } from 'utils/visualisations';
import { v4 } from 'uuid';
import { chainFormatter, getCustomAliasName } from 'utils/formatting';
import { SortingValueEnum } from 'components/shared/SortingPanel/types';
import { GetColorsFnType } from 'modules/settingsContainer/ColorPicker/hooks';
import { GetColorByValueType } from 'modules/visualisations/hooks/colorBy';

export const loaderSize = '40px';
export const lastRowClass = 'last-row';
export const loadRowTxt = 'загрузка...';

interface GetTableDataParams {
  visualisationValues: VisualisationValuesInterface;
  allColumns: TableDataSettings;
  properties: PropertiesRecordType;
  formatting?: FormattingRecordType;
  countIncisions?: number;
  grouping: string[];
  excludeGroups?: string[];
  parentsChain?: string[];
  isRealData?: boolean;
}

export const getColumnData: (params: GetTableDataParams) => Column[][] = ({
  allColumns,
  properties,
  countIncisions,
  grouping,
}) => {
  const columns = [...allColumns.incisions, ...allColumns.indicators].map((column) =>
    column.settings.nameFromDatabase ? column.fieldName || '' : column.name,
  );

  return [
    columns.reduce<Column[]>(
      (columns, indicatorName, index) =>
        ![colorValueAlias, backgroundValueAlias, colorValueByAlias, backgroundByValueAlias].includes(indicatorName)
          ? [
              ...columns,
              {
                Header: indicatorName,
                id: v4(),
                dataAccessor: indicatorName,
                parentAccessor: '',
                isGroup: grouping.includes(indicatorName),
                isIncision: countIncisions ? index < countIncisions : false,
                properties: properties[indicatorName],
                colSpan: 1,
                rowSpan: 1,
              },
            ]
          : columns,
      [],
    ),
  ];
};

export const getTableData = ({
  allColumns,
  visualisationValues,
  properties,
  formatting,
  countIncisions,
  grouping,
  excludeGroups,
  parentsChain,
  isRealData,
}: GetTableDataParams) => {
  const columns = getColumnData({
    allColumns,
    visualisationValues,
    properties,
    countIncisions,
    grouping,
  });
  const accessors = columns[columns.length - 1]?.map(({ dataAccessor }) => dataAccessor);
  const defaultColumnName = accessors[0];
  const tableValues = replaceEmptyValuesForTableData(allColumns, visualisationValues);
  const defaultVisualisationValues = tableValues[defaultColumnName] || [];

  const data = defaultVisualisationValues.map((_, index) =>
    accessors.reduce<DataTableInterface[]>((data, accessor, indexAccessor) => {
      const visualisationValuesOfColumn = tableValues[accessor] || [];
      const { backgroundColorBy, fontColorBy } = properties[accessor];
      const value = visualisationValuesOfColumn[index];
      const formattingFunction = formatting && formatting[accessor];
      const totalValue = formattingFunction && value ? formattingFunction(value) : value !== undefined ? String(value) : null;
      const backgroundColor =
        isRealData && visualisationValues[backgroundByValueAlias]
          ? ColorByConditionUtils({
              visualisationValues,
              colorBy: backgroundColorBy,
              alias: backgroundByValueAlias,
              index,
              indexAccessor,
            })
          : null;
      const fontColor =
        isRealData && visualisationValues[colorValueByAlias]
          ? ColorByConditionUtils({
              visualisationValues,
              colorBy: fontColorBy,
              alias: colorValueByAlias,
              index,
              indexAccessor,
            })
          : null;
      const valueBackgroundColorByCondition = visualisationValues[backgroundValueAlias]?.[index] || null;
      const valueFontColorByCondition = visualisationValues[colorValueAlias]?.[index] || null;

      const colorDynamicMarkerColorValuesByCondition = visualisationValues[dynamicMarkerColorAlias]?.[index];
      const colorDynamicMarkerByCondition =
        (isArray(colorDynamicMarkerColorValuesByCondition) &&
          colorDynamicMarkerColorValuesByCondition[indexAccessor - allColumns.incisions.length]) ||
        null;
      const formDynamicMarkerColorValuesByCondition = visualisationValues[dynamicMarkerFormAlias]?.[index];
      const formDynamicMarkerByCondition =
        (isArray(formDynamicMarkerColorValuesByCondition) &&
          formDynamicMarkerColorValuesByCondition[indexAccessor - allColumns.incisions.length]) ||
        null;

      const uniqueKey = columns
        .slice(0, columns.length - 1)
        .map((row) => row[index].Header)
        .join('>');
      const hyperLinkValues =
        visualisationValues[
          getCustomAliasName({
            nameIncision: accessor,
            alias: hyperLinksAlias,
          })
        ];
      const hyperLink = hyperLinkValues ? `${hyperLinkValues[index]}` : undefined;
      const imageLinkValues =
        visualisationValues[
          getCustomAliasName({
            nameIncision: accessor,
            alias: imageLinksAlias,
          })
        ];
      const imageLink = imageLinkValues ? `${imageLinkValues[index]}` : undefined;
      const dynamicMarker = getDynamicMarker({
        allColumns,
        accessor,
        value,
        colorDynamicMarkerByCondition,
        formDynamicMarkerByCondition,
      });

      return [
        ...data,
        {
          id: `${accessor}>${uniqueKey}>${indexAccessor}`,
          accessorKey: accessor,
          value: excludeGroups?.includes(accessor) ? null : totalValue || null,
          valueFromBD: Number(value),
          isExpanded: false,
          hyperLink,
          imageLink,
          dynamicMarker: dynamicMarker,
          countChildren: 0,
          backgroundColorBy: backgroundColor,
          fontColorBy: fontColor,
          valueBackgroundColorByCondition,
          valueFontColorByCondition,
          parentsChain,
          strParentsChain: parentsChain ? parentsChain.join('>') : 'BigDad',
          isTotalRow: false,
        },
      ];
    }, []),
  );

  return { columns, data };
};

export const getColumnDataForPivotTable = ({
  visualisationValues,
  incisionsHeader,
  allColumns,
  properties,
  grouping,
  isRealData,
}: {
  visualisationValues: VisualisationValuesInterface;
  incisionsHeader: VisualisationValuesInterface;
  allColumns: TableDataSettings;
  properties: PropertiesRecordType;
  grouping: string[];
  formatting?: FormattingRecordType;
  isRealData?: boolean;
}) => {
  const columnsNameIncisions = allColumns.incisions
    .filter((incision) =>
      isRealData
        ? getVisualisationFieldName({
            fieldName: incision.fieldName,
            name: incision.name,
            nameFromDatabase: incision.settings.nameFromDatabase,
          })
        : true,
    )
    .map((incision) => (incision.settings.nameFromDatabase ? incision.fieldName || '' : incision.name));
  const columnsNameIndicators = allColumns.indicators
    .filter((indicator) =>
      isRealData
        ? getVisualisationFieldName({
            fieldName: indicator.fieldName,
            name: indicator.name,
            nameFromDatabase: indicator.settings.nameFromDatabase,
          })
        : true,
    )
    .map((indicator) => (indicator.settings.nameFromDatabase ? indicator.fieldName || '' : indicator.name));
  const columns: Column[][] = [];

  Object.keys(incisionsHeader)?.forEach((incisionName, index) => {
    if (isArray(incisionsHeader[incisionName])) {
      const dataInHeader: Column[] | undefined = incisionsHeader[incisionName]?.map((value, indexCell) => {
        const hyperLinkValues =
          visualisationValues[
            getCustomAliasName({
              nameIncision: incisionName,
              alias: hyperLinksAlias,
            })
          ];
        const hyperLink = hyperLinkValues ? `${hyperLinkValues[indexCell]}` : undefined;
        const imageLinkValues =
          visualisationValues[
            getCustomAliasName({
              nameIncision: incisionName,
              alias: imageLinksAlias,
            })
          ];
        const imageLink = imageLinkValues ? `${imageLinkValues[indexCell]}` : undefined;

        return {
          Header: String(value) || '',
          id: v4(),
          dataAccessor: incisionName,
          parentAccessor: index === 0 ? '' : Object.keys(incisionsHeader)[index - 1],
          isGroup: grouping.includes(incisionName),
          isExpanded: false,
          isIncisionInHeader: true,
          properties: properties[incisionName],
          hyperLink,
          imageLink,
          colSpan: allColumns.indicators.length,
          rowSpan: 1,
        };
      });

      index === 0 &&
        !!columnsNameIncisions.length &&
        dataInHeader?.unshift({
          Header: '',
          id: v4(),
          dataAccessor: incisionName,
          parentAccessor: '',
          isGroup: false,
          properties: properties[incisionName],
          colSpan: columnsNameIncisions.length,
          rowSpan: allColumns.incisionsInHeader.filter((el) => (isRealData ? el.fieldName : true)).length,
        });
      dataInHeader && columns.push(dataInHeader);
    }
  });

  if (columns.length !== 0) {
    const columnsIncisions: Column[] = columnsNameIncisions.map((columnName) => {
      return {
        Header: String(columnName) || 'null',
        id: v4(),
        dataAccessor: columnName,
        parentAccessor: '',
        isGroup: grouping.includes(columnName),
        isIncision: true,
        properties: properties[columnName],
        colSpan: 1,
        rowSpan: 1,
      };
    });
    const columnsIndicators = columns[columns.length - 1]?.reduce<Column[]>((columnsData, { dataAccessor, Header }) => {
      if (!Header) {
        return columnsData;
      }
      const indicators = columnsNameIndicators.map((columnName) => {
        return {
          Header: String(columnName) || 'null',
          id: v4(),
          dataAccessor: columnName,
          parentAccessor: `${dataAccessor}>${Header}`,
          isGroup: grouping.includes(columnName),
          properties: properties[columnName],
          colSpan: 1,
          rowSpan: 1,
        };
      });
      return [...columnsData, ...indicators];
    }, []);
    columnsIndicators && columns.push([...columnsIncisions, ...columnsIndicators]);
  }

  return { columns };
};

interface GetDataForPivotTableInterface extends GetTableDataParams {
  incisionsHeader: VisualisationValuesInterface;
  allColumns: TableDataSettings;
  hashNames: string[];
  hashNamesImagesLinks?: Record<string, string[]>;
  hashNamesHyperLinks?: Record<string, string[]>;
}

export const getDataForPivotTable = ({
  incisionsHeader,
  visualisationValues,
  allColumns,
  properties,
  grouping,
  formatting,
  hashNames,
  hashNamesImagesLinks,
  hashNamesHyperLinks,
  excludeGroups,
  parentsChain,
  isRealData,
}: GetDataForPivotTableInterface) => {
  const data: DataTableInterface[][] = [];

  const { columns } = getColumnDataForPivotTable({
    visualisationValues,
    incisionsHeader,
    allColumns,
    properties,
    grouping,
    formatting,
    isRealData,
  });

  const namesIncisions = allColumns.incisions
    .filter((incision) => (isRealData ? incision.fieldName : true))
    .map(({ name, fieldName, settings: { nameFromDatabase } }) =>
      getVisualisationFieldName({
        name,
        nameFromDatabase,
        fieldName,
      }),
    );

  namesIncisions.forEach((key) => {
    if (!visualisationValues[key]) {
      const cellData: DataTableInterface = {
        id: `${key}>${v4()}`,
        accessorKey: key,
        value: null,
        valueFromBD: null,
        isExpanded: false,
        countChildren: 0,
        backgroundColorBy: null,
        fontColorBy: null,
        valueBackgroundColorByCondition: null,
        valueFontColorByCondition: null,
        parentsChain,
        strParentsChain: 'BigDad',
        isTotalRow: false,
      };
      data.forEach((_, indexData) => {
        if (!data[indexData]) {
          data.push([]);
        }
        data[indexData].push(cellData);
      });
    }
    visualisationValues[key]?.forEach((value, indexValue) => {
      const hyperLinkValues =
        visualisationValues[
          getCustomAliasName({
            nameIncision: key,
            alias: hyperLinksAlias,
          })
        ];
      const hyperLink = hyperLinkValues ? `${hyperLinkValues[indexValue]}` : undefined;
      const imageLinkValues =
        visualisationValues[
          getCustomAliasName({
            nameIncision: key,
            alias: imageLinksAlias,
          })
        ];
      const imageLink = imageLinkValues ? `${imageLinkValues[indexValue]}` : undefined;

      const cellData: DataTableInterface = {
        id: `${key}>${value}>${v4()}`,
        accessorKey: key,
        value: excludeGroups?.includes(key) ? null : String(value) || null,
        valueFromBD: Number(value),
        isExpanded: false,
        countChildren: 0,
        backgroundColorBy: null,
        fontColorBy: null,
        valueBackgroundColorByCondition: null,
        valueFontColorByCondition: null,
        parentsChain,
        hyperLink,
        imageLink,
        strParentsChain: 'BigDad',
        isTotalRow: false,
      };
      if (!data[indexValue]) {
        data.push([]);
      }
      data[indexValue].push(cellData);
    });
  });

  const indicatorsMap: Record<string, TableIndicatorInterface> = {};

  allColumns.indicators.forEach((indicator) => (indicatorsMap[indicator.name] = indicator));

  isRealData
    ? hashNames.forEach((key, index) => {
        const lengthValues = visualisationValues[key]?.length;
        if (lengthValues) {
          const accessor = key.split('_')[1];
          const formattingFunction = formatting && formatting[accessor];

          for (let i = 0; i < lengthValues; i++) {
            const value = visualisationValues[key]?.[i];
            let totalValue = formattingFunction && value ? formattingFunction(value) : !isUndefined(value) ? String(value) : null;

            if (isNull(totalValue) || totalValue === 'null') {
              const indicator = indicatorsMap[accessor];
              if (indicator && indicator.emptyValues?.isEmptyValue) {
                totalValue = indicator.emptyValues.value;
              }
            }
            const hyperLinkValues =
              (hashNamesHyperLinks && visualisationValues[hashNamesHyperLinks[accessor]?.[index]]) ||
              visualisationValues[
                getCustomAliasName({
                  nameIncision: accessor,
                  alias: hyperLinksAlias,
                })
              ] ||
              undefined;
            const hyperLink = hyperLinkValues && !isNull(hyperLinkValues[i]) ? `${hyperLinkValues[i]}` : undefined;
            const imageLinkValues =
              (hashNamesImagesLinks && visualisationValues[hashNamesImagesLinks[accessor]?.[index]]) ||
              visualisationValues[
                getCustomAliasName({
                  nameIncision: accessor,
                  alias: imageLinksAlias,
                })
              ] ||
              undefined;
            const imageLink = imageLinkValues && !isNull(imageLinkValues[i]) ? `${imageLinkValues[i]}` : undefined;
            const dynamicMarker = !isUndefined(value) ? getDynamicMarker({ allColumns, accessor, value }) : undefined;

            const cellData: DataTableInterface = {
              id: `${key}>${value}>${v4()}`,
              accessorKey: key,
              value: excludeGroups?.includes(accessor) ? null : totalValue || null,
              valueFromBD: Number(value),
              isExpanded: false,
              countChildren: 0,
              backgroundColorBy: null,
              fontColorBy: null,
              dynamicMarker,
              valueBackgroundColorByCondition: null,
              valueFontColorByCondition: null,
              parentsChain,
              hyperLink,
              imageLink,
              strParentsChain: 'BigDad',
              isTotalRow: false,
            };
            if (!data[i]) {
              data.push([]);
            }
            data[i].push(cellData);
          }
        }
      })
    : columns[columns.length - 1]
        .filter((cell) => !namesIncisions.includes(cell.dataAccessor))
        .forEach(({ dataAccessor }) => {
          const lengthValues = visualisationValues[dataAccessor]?.length;
          if (lengthValues) {
            const formattingFunction = formatting && formatting[dataAccessor];

            for (let i = 0; i < lengthValues; i++) {
              const value = getRandomInt(0, 100);
              const totalValue =
                formattingFunction && value ? formattingFunction(value) : !isUndefined(value) ? String(value) : null;
              const dynamicMarker = value ? getDynamicMarker({ allColumns, accessor: dataAccessor, value }) : undefined;

              const cellData: DataTableInterface = {
                id: `${dataAccessor}>${value}>${v4()}`,
                accessorKey: dataAccessor,
                value: excludeGroups?.includes(dataAccessor) ? null : totalValue || null,
                valueFromBD: Number(value),
                isExpanded: false,
                countChildren: 0,
                backgroundColorBy: null,
                fontColorBy: null,
                dynamicMarker,
                valueBackgroundColorByCondition: null,
                valueFontColorByCondition: null,
                parentsChain,
                strParentsChain: 'BigDad',
                isTotalRow: false,
              };
              if (!data[i]) {
                data.push([]);
              }
              data[i].push(cellData);
            }
          }
        });

  return { columns, data };
};

interface GetTotalSqlRequestParams extends Pick<TableDataSettings, 'indicators'> {
  from: AST.FromFromParser;
  where: AST.WhereIn | AST.WhereBetween | AST.UnionAndExpressions | AST.WhereLike | null;
}

export const getTotalSqlRequest = ({ indicators, from, where }: GetTotalSqlRequestParams) => {
  const columns = indicators.reduce<IndicatorAstType[]>(
    (
      selectValues,
      {
        name,
        fieldName,
        customRequest,
        operationType,
        settings: {
          nameFromDatabase,
          totalSettings: { isShow, operationType: operationTypeManual, customRequest: totalCustomRequest, isAutoAggregation },
        },
      },
    ) => {
      if (isShow) {
        let selectValue: IndicatorAstType | null = null;
        const indicatorName = getVisualisationFieldName({ name, fieldName, nameFromDatabase }),
          allCustomRequest = isAutoAggregation ? customRequest : totalCustomRequest,
          totalOperationType = isAutoAggregation ? operationType : operationTypeManual;

        if (totalOperationType === 'other') {
          const selectQuery = generateSqlSelectValue(allCustomRequest, indicatorName);

          selectValue = getSelectColumnsFromSqlString(selectQuery)?.[0];
        } else if (fieldName) {
          selectValue = generateBasicFunctionColumn({ alias: indicatorName, fieldName, functionName: totalOperationType });
        }

        return selectValue ? [...selectValues, selectValue] : selectValues;
      }

      return selectValues;
    },
    [],
  );

  if (columns.length) {
    return sqlParser.sqlify({
      ...defaultSelectAST,
      columns,
      from,
      where,
    });
  }
};

interface GetCalculatedTotalValuesParams extends Pick<TableDataSettings, 'indicators'>, Required<VisualisationDataInterface> {
  formatting: FormattingRecordType;
}

export const getCalculatedTotalValues: (params: GetCalculatedTotalValuesParams) => TotalValuesType = ({
  indicators,
  visualisationValues,
  formatting,
}) => {
  return indicators.reduce(
    (
      result,
      {
        name,
        fieldName,
        settings: {
          nameFromDatabase,
          totalSettings: {
            isShow,
            formatting: { isShow: isShowTotalFormatting, formats },
            isAutoAggregation,
          },
        },
      },
    ) => {
      if (isShow) {
        const indicatorName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });

        const indicatorData = visualisationValues[indicatorName] || [];
        const value = getSumOfArrayValues(indicatorData);
        const formattingFunction = formatting && formatting[indicatorName];
        const totalValue = formattingFunction && formattingFunction(value);
        const customValue = !isAutoAggregation && isShowTotalFormatting ? chainFormatter(formats, String(value)) : null;

        return { ...result, [indicatorName]: customValue || totalValue };
      }
      return result;
    },
    {},
  );
};

export const transformLoadedValuesToTotalValues = (loadedValues: VisualisationValuesInterface) =>
  Object.keys(loadedValues).reduce<TotalValuesType>((result, key) => {
    const values = loadedValues[key],
      value = Array.isArray(values) ? values[0] : 0,
      mayBeNumber = typeof value === 'string' && !Number.isNaN(parseInt(value)) ? parseInt(value) : value || 0,
      normalizedValue = typeof mayBeNumber === 'string' ? 0 : mayBeNumber;

    return { ...result, [key]: normalizedValue };
  }, {});

export const onSortClick =
  ({ dataSettings, columnName, id, sortingColumn, isSorted }: OnSortChangeInterface) =>
  () => {
    const order = dataSettings.orderBy.filter((el) => el.columnName !== columnName);

    if (!isSorted) {
      return onChangeOrderById(
        dataSettings,
        [
          ...order,
          {
            columnName,
            type: SortingValueEnum.DESC,
          },
        ],
        id,
      );
    }

    if (sortingColumn?.type === SortingValueEnum.ASC) {
      return onChangeOrderById(dataSettings, order, id);
    }

    return onChangeOrderById(
      dataSettings,
      [
        ...order,
        {
          columnName,
          type: SortingValueEnum.ASC,
        },
      ],
      id,
    );
  };

export const colorInvertInHEX = (objectIsBeat: IsBeatInterface, getColorValues: GetColorsFnType) => {
  return { ...objectIsBeat, color: getColorValues(objectIsBeat.color) as string };
};

export const getIncisionsInHeaderNames = (incisionsInHeader: TableIncisionInterface[]) => {
  return incisionsInHeader
    .filter(({ fieldName, settings: { customRequest } }) => fieldName || customRequest)
    .map(({ fieldName, name, settings: { nameFromDatabase } }) =>
      getVisualisationFieldName({
        fieldName,
        name,
        nameFromDatabase,
      }),
    );
};

export const getGrouping = (allIncisions: TableIncisionInterface[], hasAllGroupIncision: boolean) => {
  if (hasAllGroupIncision) {
    return allIncisions.map((incision) => (incision.settings.nameFromDatabase ? incision.fieldName || '' : incision.name));
  } else {
    return allIncisions
      .filter((incision) => incision.settings.isGroup)
      .map((incision) => (incision.settings.nameFromDatabase ? incision.fieldName || '' : incision.name));
  }
};

export const getColumnsSettings = (allColumns: (TableIncisionInterface | TableIndicatorInterface)[]) => {
  return allColumns.reduce(
    (
      data,
      {
        fieldName,
        name,
        settings: {
          columnWidthSettings: { isActive, width },
          nameFromDatabase,
          hasIndentation,
          indentation,
          hyperLink,
          imagesSettings,
        },
      },
    ) => {
      const columnName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });

      return {
        ...data,
        [columnName]: {
          columnWidth: isActive ? width : undefined,
          hasIndentation,
          indentation,
          openInThisWindow: hyperLink.transitionMode === TransitionModeEnum.HERE,
          isFilteringHyperLink: hyperLink.isFiltering,
          imagesSettings,
        },
      };
    },
    {},
  );
};

export const getColors = ({
  column,
  rowData,
  parentsChain,
  properties,
  getColorValues,
  backgroundByValueSettings,
  colorByValueSettings,
  minMaxIndicators,
  getColorByValue,
  subtotalsSettings,
}: {
  column: Column;
  rowData: DataTableInterface;
  parentsChain: string;
  properties: DefaultPropertiesInterface;
  getColorValues: GetColorsFnType;
  backgroundByValueSettings: ColorByInterface;
  colorByValueSettings: ColorByInterface;
  minMaxIndicators: Record<string, [number, number]>;
  getColorByValue: GetColorByValueType;
  subtotalsSettings: TotalRowSettingsInterface;
}) => {
  const {
    Header,
    dataAccessor,
    properties: {
      isActiveBackgroundColor,
      isActiveFontColor,
      backgroundColor: backgroundColorColumn,
      fontColor: fontColorColumn,
      backgroundColorBy: { type: columnBackgroundType, byValue: byValueBackgroundColor },
      fontColorBy: { type: columnFontType, byValue: byValueFontColor },
    },
  } = column;
  const {
    valueFromBD,
    valueBackgroundColorByCondition,
    valueFontColorByCondition,
    backgroundColorBy: backgroundBy,
    fontColorBy: fontBy,
    isSubtotal,
  } = rowData;
  const { backgroundColor, fontColor: fontColorCell } = properties;
  const minMax = minMaxIndicators[Header + parentsChain];

  const isDefaultTypeBackground = isActiveBackgroundColor
    ? columnBackgroundType === 'default'
    : backgroundByValueSettings.type === 'default';
  const isDefaultTypeFont = isActiveFontColor ? columnFontType === 'default' : colorByValueSettings.type === 'default';

  const backgroundColorDefault =
    (isActiveBackgroundColor && getColorValues(backgroundColorColumn)) || getColorValues(backgroundColor);
  const fontColorDefault = (isActiveFontColor && getColorValues(fontColorColumn)) || getColorValues(fontColorCell);

  const backgroundColorByValue =
    minMax &&
    columnBackgroundType === 'value' &&
    (valueFromBD || valueFromBD === 0) &&
    byValueBackgroundColor.colors?.[colorAlias]
      ? getColorByValueMinMax<ColorByItem>({
          min: minMax[0],
          max: minMax[1],
          colors: byValueBackgroundColor.colors[colorAlias],
          value: valueFromBD,
        })
      : null;
  const fontColorByValue =
    minMax && columnFontType === 'value' && (valueFromBD || valueFromBD === 0) && byValueFontColor.colors?.[colorAlias]
      ? getColorByValueMinMax<ColorByItem>({
          min: minMax[0],
          max: minMax[1],
          colors: byValueFontColor.colors[colorAlias],
          value: valueFromBD,
        })
      : null;

  const commonBackgroundColorByCondition =
    !isDefaultTypeBackground && valueBackgroundColorByCondition
      ? getColorByValue({
          value: valueBackgroundColorByCondition,
          indicatorName: dataAccessor,
          alias: backgroundByValueSettings?.byCondition.alias,
        })
      : backgroundColorDefault;
  const commonFontColorByCondition =
    !isDefaultTypeFont && valueFontColorByCondition
      ? getColorByValue({
          value: valueFontColorByCondition,
          indicatorName: dataAccessor,
          alias: colorByValueSettings?.byCondition.alias,
        })
      : fontColorDefault;

  const background =
    (isActiveBackgroundColor && !isDefaultTypeBackground) || properties.isActiveBackgroundColor
      ? getColorValues(backgroundBy) || (backgroundColorByValue && getColorValues(backgroundColorByValue.value))
      : commonBackgroundColorByCondition;
  const fontColor = isSubtotal
    ? getColorValues(subtotalsSettings.properties.fontColor)
    : (isActiveFontColor && !isDefaultTypeFont) || properties.isActiveFontColor
    ? getColorValues(fontBy) || (fontColorByValue && getColorValues(fontColorByValue.value))
    : commonFontColorByCondition;

  return { fontColor, background };
};

export const getPadding = (
  paddings: IndentationInterface,
  priority: boolean,
  bodyIndentation: IndentationInterface,
  priorityColumn?: boolean,
) => {
  const { top, bottom, left, right } = paddings;
  const { top: defaultTop, left: defaultLeft, bottom: defaultBottom, right: defaultRight } = bodyIndentation;

  return priorityColumn
    ? `${top}px ${right}px ${bottom}px ${left}px`
    : `${priority ? top : defaultTop}px ${defaultRight}px ${priority ? bottom : defaultBottom}px ${defaultLeft}px`;
};

export const getDynamicMarker = ({
  allColumns,
  accessor,
  value,
  colorDynamicMarkerByCondition,
  formDynamicMarkerByCondition,
}: {
  allColumns: TableDataSettings;
  accessor: string;
  value: string | number | null;
  colorDynamicMarkerByCondition?: string | number | null;
  formDynamicMarkerByCondition?: string | number | null;
}): SettingsOneDynamicMarkerInterface | undefined => {
  const column = allColumns.indicators.find(
    ({ name, fieldName, settings: { nameFromDatabase } }) =>
      getVisualisationFieldName({
        name,
        fieldName,
        nameFromDatabase,
      }) === accessor,
  );

  if (!column) {
    return;
  }

  const {
    isShow,
    sizeMarker,
    settingsMarker: {
      mode,
      manual: { defaultMarker, customMarkers },
      sql: markerSettingsWithSql,
    },
    indent,
    position,
  } = column.settings.dynamicsMarkerSettings;
  const { isShow: showDefaultMarker, marker, color: defaultColor } = defaultMarker;

  if (!isShow) {
    return;
  }

  if (mode === DynamicMarkerModeEnum.MANUAL) {
    const customMarker = customMarkers.find((markerSettings) => {
      const {
        values: [min, max],
        conditions: [minCondition, maxCondition],
      } = markerSettings;

      return !isNull(value) && checkInRange({ value: +value, min, max, maxCondition, minCondition });
    });

    if (customMarker) {
      const { color, marker } = customMarker;
      return {
        type: marker,
        color: color,
        indent,
        sizeMarker,
        position,
      };
    }

    if (showDefaultMarker) {
      return {
        type: marker,
        color: defaultColor,
        indent,
        position,
        sizeMarker,
      };
    }
  }

  if (mode === DynamicMarkerModeEnum.SQL) {
    const { colorMarker, formMarker } = markerSettingsWithSql;

    const {
      type: colorType,
      byCondition: { colors },
      byValue: { colors: colorsByValue },
      colorManual,
    } = colorMarker;

    const {
      type: formType,
      manualMarker,
      byCondition: { markers },
    } = formMarker;

    const color =
      colorType === ColorAndImageByEnum.Default
        ? colorManual
        : colorType === ColorAndImageByEnum.Condition && colorDynamicMarkerByCondition
        ? colors[+colorDynamicMarkerByCondition - 1]?.value
        : null;
    const typeMarker =
      formType === ColorAndImageByEnum.Default
        ? manualMarker
        : formType === ColorAndImageByEnum.Condition && formDynamicMarkerByCondition
        ? markers[+formDynamicMarkerByCondition - 1]
        : markers[0];

    return {
      type: typeMarker,
      color: color,
      colorsByValue: colorType === ColorAndImageByEnum.Value ? colorsByValue : undefined,
      indent,
      position,
      sizeMarker,
    };
  }
};
