import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React from 'react';
import { BubbleElementDesignSettingsInterface, BubbleIndicatorInterface } from 'store/reducers/visualisations/types';
import { OnValueChange } from 'types/global';
import SubSection from 'shared/ui/subSection';
import { PropertiesSettings } from 'modules/settingsContainer/common/data/PropertiesSettings';
import { CircleActivator, ColorPicker } from 'modules/settingsContainer/ColorPicker';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { IndicatorSelectorSettings } from 'modules/settingsContainer/common/IndicatorSelectorSettings';
import { GradientColorBySpecificSettings } from 'modules/settingsContainer/common/GradientColorBySpecificSettings';
import { colorBubbleNameTitle } from 'constants/global';

type BubbleElementDesignSettingsProps = OnValueChange<BubbleElementDesignSettingsInterface> & {
  indicators: BubbleIndicatorInterface[];
};

export const BubbleElementDesignSettings = ({ value, indicators, onChange }: BubbleElementDesignSettingsProps) => {
  const { defaultColor } = useColorValues();

  const {
    gradientElementColorSettings,
    colorValueColorSettings,
    rangeIndicator: { rangeIndicatorId },
    properties,
  } = value;

  return (
    <MainContainerSettings titleText="Оформление элементов">
      <SubSection>
        <IndicatorSelectorSettings
          title="Построить диапазон"
          disabledSwitcher
          indicators={indicators}
          value={value.rangeIndicator}
          onChange={(rangeIndicator) => onChange({ ...value, rangeIndicator })}
        />
      </SubSection>
      <SubSection>
        <GradientColorBySpecificSettings
          titleMainContainerText={colorBubbleNameTitle}
          isMainContainerSettings
          disabledComponentStepsValue
          disabledComponentByGradientValue
          disabledComponentByValue
          indicators={indicators?.filter((indicator) => indicator.id === rangeIndicatorId)}
          value={gradientElementColorSettings.elementColorBy}
          onChange={(elementColorBy) =>
            onChange({ ...value, gradientElementColorSettings: { ...gradientElementColorSettings, elementColorBy } })
          }
        >
          <ColorPicker
            Activator={CircleActivator}
            closeOnClick
            type="color"
            onChange={(elementColor) =>
              onChange({ ...value, gradientElementColorSettings: { ...gradientElementColorSettings, elementColor } })
            }
            value={gradientElementColorSettings.elementColor}
            defaultActivatorBackgroundColor={defaultColor}
          />
        </GradientColorBySpecificSettings>
      </SubSection>

      <SubSection>
        <PropertiesSettings
          title="Свойства текста значений"
          value={properties}
          onChange={(properties) => onChange({ ...value, properties })}
          disabledComponentBySpecificValue
          isMainContainerSettings
          isMainContainerColorSettings
          disabledOpacity
          disabledFontColorByBlock
          disabledFontColorBy
          disabledBackgroundColorBy
          disabledComponentColorByValue
          disabledPadding
          disabledLineHeight
          disabledUnderline
          disabledLetterSpacing
        />

        <GradientColorBySpecificSettings
          isMainContainerSettings
          disabledComponentStepsValue
          disabledComponentBySpecificSteps
          disabledComponentByGradientValue
          disabledComponentBySpecificGradientValue
          disabledComponentByValue={false}
          titleMainContainerText="Цвет значений"
          indicators={indicators?.filter((indicator) => indicator.id === rangeIndicatorId)}
          value={colorValueColorSettings.elementColorBy}
          onChange={(elementColorBy) =>
            onChange({ ...value, colorValueColorSettings: { ...colorValueColorSettings, elementColorBy } })
          }
        >
          <ColorPicker
            Activator={CircleActivator}
            closeOnClick
            type="color"
            onChange={(elementColor) =>
              onChange({ ...value, colorValueColorSettings: { ...colorValueColorSettings, elementColor } })
            }
            value={colorValueColorSettings.elementColor}
            defaultActivatorBackgroundColor={defaultColor}
          />
        </GradientColorBySpecificSettings>
      </SubSection>
    </MainContainerSettings>
  );
};
