import { updatePieDataSettingsAction } from 'store/reducers/visualisations/actions';
import { store } from 'store';
import { OnChangeValue } from 'modules/visualisations/Pie/settings/DataTab/types';
import {
  DefaultPropertiesInterface,
  GroupByDateInterface,
  PieDataSettings,
  PieIndicatorValueFormatType,
  PieRoseTypeTypes,
} from 'store/reducers/visualisations/types';
import { moveArrayItem, MoveToType } from 'utils/utils';
import { PaletteValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { ColorSettingsIncision } from 'types/types';

const dispatch = store.dispatch;

/* Incision change */

export const onChangeIncisionDateSettings: OnChangeValue<GroupByDateInterface> = (dataSettings, dateValue, id) =>
  dispatch(
    updatePieDataSettingsAction({
      incisions: dataSettings.incisions.map((incision) =>
        id === incision.id
          ? {
              ...incision,
              settings: { ...incision.settings, groupByDateSettings: { ...incision.settings.groupByDateSettings, ...dateValue } },
            }
          : incision,
      ),
    }),
  );

export const onColorBySegmentSettingsChange: OnChangeValue<ColorSettingsIncision> = (dataSettings, colorBySettings, id) =>
  dispatch(
    updatePieDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) => (id === incision.id ? { ...incision, colorBySettings } : incision)),
    }),
  );

export const onColorIncisionChange: OnChangeValue<PaletteValuesByThemeType | null> = (dataSettings, colors, id) =>
  dispatch(
    updatePieDataSettingsAction({
      incisions: dataSettings.incisions.map((incision) =>
        id === incision.id
          ? { ...incision, colorBySettings: { ...incision.colorBySettings, elementColor: colors, isActive: true } }
          : incision,
      ),
    }),
  );

export const onMoveIncision = (dataSettings: PieDataSettings, incisionId: string, moveTo: MoveToType) => {
  const incisions = dataSettings.incisions,
    indexOfIncision = incisions.findIndex(({ id }) => id === incisionId),
    { newArray } = moveArrayItem(incisions, indexOfIncision, moveTo);
  const newIncisions = [...newArray];

  dispatch(
    updatePieDataSettingsAction({
      incisions: newIncisions,
    }),
  );
};

/* Indicator change */

export const onChangeIndicatorLabelProperties: OnChangeValue<DefaultPropertiesInterface> = (dataSettings, properties, id) =>
  dispatch(
    updatePieDataSettingsAction({
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, properties } } : indicator,
      ),
    }),
  );

export const onChangeIndicatorValueFormat: OnChangeValue<PieIndicatorValueFormatType> = (dataSettings, valueFormat, id) =>
  dispatch(
    updatePieDataSettingsAction({
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, valueFormat } } : indicator,
      ),
    }),
  );

export const onChangeRoseType = (roseType: PieRoseTypeTypes) =>
  dispatch(
    updatePieDataSettingsAction({
      roseType,
    }),
  );
