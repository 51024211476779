import styled from 'styled-components';

import { defaultViewWrapperMarginBottom } from 'modules/visualisations/components/VisualisationLayout/constants';

interface VisualisationContainerProps {
  padding?: string;
}

export const VisualisationContainer = styled.div<VisualisationContainerProps>`
  background-color: transparent;
  padding: ${({ padding }) => padding || '0'};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

interface DefaultViewSettingsWrapperProps {
  isVisible: boolean;
  marginBottom?: string;
}

export const DefaultViewSettingsWrapper = styled.div<DefaultViewSettingsWrapperProps>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  width: 100%;
  margin-bottom: ${({ marginBottom }) => marginBottom || `${defaultViewWrapperMarginBottom}`};
  gap: 10px;
`;

interface ContentWrapperProps {
  height: number;
}

export const ContentWrapper = styled.div<ContentWrapperProps>`
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : '100%')};
`;
