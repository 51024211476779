import React from 'react';
import { ElementContainerSettings } from 'modules/settingsContainer';
import { openWidowTypeOptions } from 'modules/settingsContainer/common/TextEventsSettings/constants';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { TextArea } from 'modules/ui';
import Select from 'modules/ui/Select';
import { useSelector } from 'react-redux';
import { getFilterByProject } from 'store/reducers/filters/getters';
import { getPagesAtSelectItems } from 'store/reducers/projectPages/getters';
import { ActivateObjectInterface, EventsSettingsInterface } from 'store/reducers/visualisations/types';
import { OnValueChange } from 'types/global';
import { FilterActivation } from '../FilterActivation';

type EventsSettingsProps = OnValueChange<EventsSettingsInterface>;

export const TextEventsSettings = ({ value: events, onChange }: EventsSettingsProps) => {
  const projectFilters = useSelector(getFilterByProject);

  const { activateObject, goTo, goToHref, isReactingToFilter } = events,
    { isActive } = activateObject;

  const pages = useSelector(getPagesAtSelectItems);

  const onActivateObjectChange = (payload: ActivateObjectInterface) => {
    onChange({ ...events, activateObject: payload });
  };

  const toggleActivateObjectStatus = () => {
    onChange({
      ...events,
      activateObject: {
        ...activateObject,
        isActive: !isActive,
      },
    });
  };

  return (
    <>
      <MainContainerSettings
        titleText="Переход к объекту"
        switcherState={goTo.isActive}
        switcherChange={() => onChange({ ...events, goTo: { ...goTo, isActive: !goTo.isActive } })}
      >
        <Select
          needBackground
          placeholder="Выбрать объект"
          name="goToObject"
          options={pages}
          width="100%"
          value={goTo.to?.id || pages[0]}
          onChange={(id) => onChange({ ...events, goTo: { ...goTo, to: { type: 'page', id } } })}
        />
      </MainContainerSettings>
      <MainContainerSettings
        titleText="Переход по гиперссылке"
        switcherState={goToHref.isActive}
        switcherChange={() => onChange({ ...events, goToHref: { ...goToHref, isActive: !goToHref.isActive } })}
      >
        <GroupContainerSettings>
          <ElementContainerSettings>
            <SettingsRadio
              onChange={({ value: openWindowType }) => onChange({ ...events, goToHref: { ...goToHref, openWindowType } })}
              activeValue={getActiveRadioValue(openWidowTypeOptions, goToHref.openWindowType)}
              options={openWidowTypeOptions}
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
        <GroupContainerSettings>
          <ElementContainerSettings>
            <TextArea
              name="href_to"
              needBackground
              value={goToHref.to || ''}
              onChange={(e) => onChange({ ...events, goToHref: { ...goToHref, to: e.target.value } })}
              width="100%"
              placeholder="https://"
              rows={3}
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
      </MainContainerSettings>

      <MainContainerSettings
        titleText="Реагировать на фильтры"
        switcherState={isReactingToFilter}
        switcherChange={() => onChange({ ...events, isReactingToFilter: !isReactingToFilter })}
      />

      <MainContainerSettings
        titleText="Активировать фильтры"
        switcherState={isActive}
        switcherChange={toggleActivateObjectStatus}
      >
        <FilterActivation data={projectFilters} onChange={onActivateObjectChange} />
      </MainContainerSettings>
    </>
  );
};
