import { AddAndDeleteButtons } from 'modules/settingsContainer/AddAndDeleteButtons';
import { MetricWrapper } from 'modules/settingsContainer/common/data/MetricSettings/styles';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React from 'react';
import { VisualisationIdInterface } from 'store/reducers/visualisations/types';
import { IncisionSettingsProps } from './types';
import useControls from 'utils/hooks/useControl';
import { Divider } from 'modules/workspace/components/PagesModal/styles';

export const MetricListSettings = <Metric extends VisualisationIdInterface>({
  metrics,
  metricRender,
  listRender,
  onDelete,
  onAdd,
  disableAddingMetric,
  titleText,
  addButtonText,
  controls,
  disableDeleteButton = metrics.length === 1,
  maxMetricListHeight,
  activePageId,
  listObjects,
}: IncisionSettingsProps<Metric>) => {
  const { activeMetricId, setActiveMetricId, selectedMetricId, setSelectedMetricId, onCloseFieldSettings, onUnselectMetric } =
    useControls({ controls, activePageId });

  const activeMetric = metrics?.find(({ id }) => id === activeMetricId);
  const selectMetric = metrics?.find(({ id }) => id === selectedMetricId);
  const isEmptyListObjects = !!Object.keys(listObjects).length;

  const onLocalDelete = () => {
    onUnselectMetric();
    onCloseFieldSettings();
    onDelete && onDelete(selectedMetricId);
  };

  const onDropDownClick = () => {
    selectMetric && onUnselectMetric();
    activeMetric && onCloseFieldSettings();
  };

  return (
    <MainContainerSettings
      onDropDownClick={titleText ? onDropDownClick : undefined}
      titleText={titleText || ''}
      isOpenDefault={true}
    >
      <MetricWrapper maxHeight={maxMetricListHeight}>
        {metricRender({
          metrics: metrics,
          activeMetricId,
          setActiveMetricId,
          selectedMetricId,
          setSelectedMetricId,
        })}
        {!disableAddingMetric && (
          <AddAndDeleteButtons
            addButtonText={addButtonText}
            disable={{ deleteButton: !selectedMetricId || disableDeleteButton }}
            onDelete={onLocalDelete}
            onAdd={onAdd}
          />
        )}
        {isEmptyListObjects && <Divider />}
        {selectedMetricId && listRender && (
          <>
            {listRender({
              widgets: listObjects,
              activePageId,
            })}
          </>
        )}
      </MetricWrapper>
    </MainContainerSettings>
  );
};
