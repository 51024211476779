import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DefaultVisualisationsTypeProps } from 'assets/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const HalfPie: FC<DefaultVisualisationsTypeProps> = ({
  backgroundFill = `var(${ColorVarsEnum.Level_4_widget})`,
  fillDefault = `var(${ColorVarsEnum.Level_1})`,
  symbolSize = 70,
}) => {
  return (
    <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
      <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="70" height="70" rx="5" fill={backgroundFill} />
          <path
            opacity="0.35"
            d="M35 12C38.0204 12 41.0112 12.5949 43.8017 13.7508C46.5922 14.9066 49.1277 16.6008 51.2635 18.7365C53.3992 20.8723 55.0934 23.4078 56.2492 26.1983C57.4051 28.9888 58 31.9796 58 35L43.28 35C43.28 33.9127 43.0658 32.836 42.6497 31.8314C42.2336 30.8268 41.6237 29.914 40.8548 29.1452C40.086 28.3763 39.1732 27.7664 38.1686 27.3503C37.164 26.9342 36.0873 26.72 35 26.72V12Z"
            fill={fillDefault}
          />
          <path
            opacity="0.75"
            d="M35 12C30.451 12 26.0042 13.3489 22.2219 15.8762C18.4396 18.4035 15.4916 21.9956 13.7508 26.1983C12.01 30.401 11.5545 35.0255 12.4419 39.4871C13.3294 43.9486 15.5199 48.0468 18.7365 51.2635C21.9532 54.4801 26.0514 56.6706 30.5129 57.5581C34.9745 58.4455 39.599 57.99 43.8017 56.2492C48.0044 54.5084 51.5965 51.5604 54.1238 47.7781C56.6511 43.9958 58 39.549 58 35H43.28C43.28 36.6376 42.7944 38.2385 41.8846 39.6001C40.9748 40.9618 39.6816 42.023 38.1686 42.6497C36.6556 43.2764 34.9908 43.4404 33.3847 43.1209C31.7785 42.8014 30.3031 42.0128 29.1452 40.8548C27.9872 39.6969 27.1986 38.2215 26.8791 36.6153C26.5596 35.0092 26.7236 33.3444 27.3503 31.8314C27.977 30.3184 29.0382 29.0252 30.3999 28.1154C31.7615 27.2056 33.3624 26.72 35 26.72V12Z"
            fill={fillDefault}
          />
        </svg>
      </FlexContainer>
    </FlexContainer>
  );
};
