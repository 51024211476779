import React, { memo, useCallback, useEffect, useState } from 'react';
import { FlexContainerProps } from 'styles/FlexContainer';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { NoopType } from 'types/global';
import { HistoryVersionProjectUI } from 'modules/ui/ManagerOrAdminComponentsUI/HistoryVersionProjectUI';
import { closeModalAction, openConfirmationModalAction, openModalTypedAction } from 'store/reducers/modals/actions';
import { ProjectHistoryVersionList } from 'modules/ui/ManagerOrAdminComponentsUI/HistoryVersionProjectUI/types';
import {
  loadUserDraftProjectHistoryVersionAction,
  restoreHistoryUserDraftProjectAction,
} from 'store/reducers/adminUsers/actions';
import { getUsersDraftProjectHistoryVersion } from 'store/reducers/adminUsers/getters';
import { CopyUserDraftProjectModal } from '../Modals/CopyUserDraftProjectModal';
import { createUserDraftCopyOfVersion } from './constants';

interface HistoryVersionUserDraftProjectProps {
  projectId: string;
  flowId: string;
  projectName?: string;
  padding?: string;
  footerPosition?: FlexContainerProps['justifyContent'];
  onClose?: NoopType;
}

export const HistoryVersionUserDraftProjectComponent = ({
  projectId,
  flowId,
  projectName,
  footerPosition,
  padding = '0 24px',
  onClose,
}: HistoryVersionUserDraftProjectProps) => {
  const dispatch = useAppDispatch();
  const { flowProjectHistoryVersionList: data, loading: isLoading } = useSelector(getUsersDraftProjectHistoryVersion);
  const [selectedVersion, setSelectedVersion] = useState<ProjectHistoryVersionList | null>(null);

  const onRestoreVersion = useCallback(
    async (time: string) => {
      try {
        const action = await dispatch(restoreHistoryUserDraftProjectAction({ projectId, dateTimeHistory: time })).unwrap();
        if (action) {
          await dispatch(loadUserDraftProjectHistoryVersionAction({ projectId }));
        }
      } catch (e) {
        console.error(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId],
  );

  useEffect(() => {
    if (projectId) {
      dispatch(loadUserDraftProjectHistoryVersionAction({ projectId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const onRestoreVersionModal = useCallback(
    async () => {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Откатить',
          subTitleText: `Откатить проект «${projectName}» до выбранной версии?`,
          onConfirm: () => onRestoreVersion(selectedVersion?.dateTimeHistory || ''),
          titleText: 'Откат проекта',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onRestoreVersion, projectName, selectedVersion?.dateTimeHistory],
  );

  const onCloseCreateCopyOfVersion = () => dispatch(closeModalAction(createUserDraftCopyOfVersion));

  const onCreateCopyOfVersion = useCallback(
    () =>
      dispatch(
        openModalTypedAction({
          Component: CopyUserDraftProjectModal,
          componentProps: {
            onClose: () => {
              onCloseCreateCopyOfVersion();
              onClose && onClose();
            },
            projectId: projectId || '',
            projectName: projectName || '',
            flowId,
          },
          modalSettings: {
            position: 'static',
            width: '320px',
            headerText: 'Создание копии',
          },
          name: createUserDraftCopyOfVersion,
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, projectName, selectedVersion?.dateTimeHistory, flowId, onCloseCreateCopyOfVersion, onClose],
  );

  return (
    <HistoryVersionProjectUI
      data={data}
      isLoading={isLoading}
      onRestoreVersionModal={onRestoreVersionModal}
      onItemSelect={setSelectedVersion}
      onCreateCopyOfVersion={onCreateCopyOfVersion}
      selectedVersion={selectedVersion}
      padding={padding}
      footerPosition={footerPosition}
    />
  );
};
export const HistoryVersionUserDraftProject = memo(HistoryVersionUserDraftProjectComponent);
