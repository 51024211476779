import { FilterViewInterface } from 'modules/workspace/components/FilterArea/types';
import {
  FilterSortingContainer,
  FilterValuesWrapper,
  InnerContentWrapper,
  MultipleFilterWrapper,
  RotatedDownIcon,
} from 'modules/filters/Multiple/view/Filter/styles';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextSpan } from 'styles/TextsElements';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { getFilterFieldName } from 'store/reducers/filters/constants';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { ClearIcon, DownIcon } from 'assets/icons/withContainer';
import { useModalState } from 'utils/hooks/modalState';
import { Button } from 'modules/ui';
import { useLocalValues } from 'utils/hooks/localValues';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { enableFilterAction, updateEnabledFilterAction } from 'store/reducers/filters/actions';
import { disableFilterById } from 'store/reducers/filters';
import {
  UpdateEnableFilterDataFnType,
  useChangedFilterData,
  useEnabledFilter,
  useFilterQuery,
} from 'modules/filters/hooks/filter';
import { MultipleEnabledFilterInterface, MultipleFilterInterface } from 'store/reducers/filters/types';
import { SearchInput } from 'modules/ui/inputs/SearchInput';
import { SortingPanel } from 'components/shared/SortingPanel';
import { SelectedItemCounter } from 'components/shared/SelectedItemCounter';
import { VirtualizedList } from 'shared/ui/VirtualizedList';
import { FilterValuesRow } from './FilterValuesRow';

export const MultipleFilterComponent: FilterViewInterface<MultipleFilterInterface> = ({ data, whereQuery }) => {
  const {
    nameSettings,
    position,
    isRealData,
    fictionalData,
    id,
    type,
    isGlobal,
    modelId,
    filterInfluences,
    sqlData,
    limit,
    isMultipleMode,
    isAlwaysOpen,
    positionConfig,
  } = data;
  const { incisionRequest } = sqlData;

  const { height: modalHeight, width: modalWidth } = positionConfig;

  const isMultiple = isMultipleMode !== false;

  const [searchString, setSearchString] = useState('');

  const [selectedFilterValues, setSelectedFilterValues] = useState<string[]>([]);
  const [sortingStatus, setSortingStatus] = useState<string | null>(null);

  const selectedValues = useMemo(() => selectedFilterValues, [selectedFilterValues]);

  const { dispatch, filterValues, modelIdValue, isValuesOverLimit } = useFilterQuery({
    nameSettings,
    id,
    modelId,
    whereQuery,
    searchString,
    sqlData,
    limit,
    sortingStatus,
    incisionRequest,
  });

  const updateEnableFilterDataFn: UpdateEnableFilterDataFnType<MultipleEnabledFilterInterface> = (enableFilterData) => {
    if (enabledFilter?.id && enableFilterData.selectedValues.length) {
      dispatch(
        updateEnabledFilterAction<MultipleEnabledFilterInterface>({
          id: enabledFilter?.id,
          data: { ...enableFilterData },
        }),
      );
    }
  };

  const { enabledFilter, enableFilterData } = useEnabledFilter({
    id,
    type,
    modelIdValue,
    selectedValues,
    isRealData,
    filterInfluences,
    isGlobal,
    nameSettings,
    updateEnableFilterDataFn,
    incisionRequest,
  });

  const { isRealDataIsChanged, fieldNameIsChanged } = useChangedFilterData({ nameSettings, isRealData });

  const { onClose, toggleOpen, isOpen } = useModalState();

  const isModalOpen = isAlwaysOpen || isOpen;

  const { localValues, setLocalValues, onSave, onCancel, hasChanges } = useLocalValues({
    value: selectedFilterValues,
    onChange: setSelectedFilterValues,
  });

  const normalizedFilterValues = useMemo(
    () =>
      isRealData
        ? filterValues
        : fictionalData.map((value) => ({
            value,
          })),
    [isRealData, fictionalData, filterValues],
  );

  const values = useMemo(() => {
    if (searchString) {
      return normalizedFilterValues.map(({ value }) => value);
    }

    const result = normalizedFilterValues.map(({ value }) => value);

    localValues.forEach((value) => {
      if (!result.includes(value)) {
        result.push(value);
      }
    });

    return result;
  }, [localValues, searchString, normalizedFilterValues]);

  const isSelectedFilterValue = useCallback(
    (value: string) => (isMultiple ? localValues.includes(value) : localValues[0] === value),
    [localValues, isMultiple],
  );

  const onEnableFilter = useCallback(() => {
    onClose();
    onSave();

    if (!enabledFilter) {
      return dispatch(enableFilterAction({ ...enableFilterData, isRealData, selectedValues: localValues }));
    }

    if (!localValues.length && enabledFilter) {
      return dispatch(disableFilterById(enabledFilter.id));
    }
  }, [onSave, enabledFilter, enableFilterData, localValues, isRealData, dispatch, onClose]);

  const onSelectFilterValue = useCallback(
    (value: string) => () => {
      setLocalValues((prevLocalValues) => {
        let newLocalValues;
        if (isMultiple) {
          const valueSet = new Set(prevLocalValues);
          valueSet.has(value) ? valueSet.delete(value) : valueSet.add(value);
          newLocalValues = Array.from(valueSet);
        } else {
          newLocalValues = [value];
        }

        return newLocalValues;
      });
    },
    [setLocalValues, isMultiple],
  );

  const filterName = getFilterFieldName(nameSettings);

  const onUnselectAll = useCallback(() => setLocalValues([]), [setLocalValues]);

  const onSelectAll = useCallback(() => {
    setLocalValues((prevValues) => {
      if (isMultiple) {
        return prevValues === values ? prevValues : [...values];
      } else {
        return [values[0]];
      }
    });
  }, [values, setLocalValues, isMultiple]);

  const onSelectNone = () => {
    onUnselectAll();
  };

  const onInvertSelection = useCallback(() => {
    if (isMultiple) {
      const localValuesSet = new Set(localValues);
      const newSelectedValues = [];

      for (const value of values) {
        if (!localValuesSet.has(value)) {
          newSelectedValues.push(value);
        }
      }

      setLocalValues(newSelectedValues);
    }
  }, [values, localValues, setLocalValues, isMultiple]);

  useEffect(() => {
    if (isRealDataIsChanged || fieldNameIsChanged) {
      setSelectedFilterValues([]);
      if (enabledFilter) {
        dispatch(disableFilterById(enabledFilter.id));
      }
    }
  }, [isRealDataIsChanged, fieldNameIsChanged, enabledFilter, dispatch]);

  useEffect(() => {
    if (enabledFilter && enabledFilter?.selectedValues) {
      setSelectedFilterValues(enabledFilter.selectedValues);
    } else {
      setSelectedFilterValues([]);
    }
  }, [enabledFilter]);

  const totalCount = useMemo(() => {
    if (searchString) {
      const filteredValues = normalizedFilterValues.map(({ value }) => value);
      const uniqueFilteredValues = filteredValues.filter((value) => !localValues.includes(value));
      return localValues.length + uniqueFilteredValues.length;
    }
    return values.length;
  }, [values, localValues, searchString, normalizedFilterValues]);

  useEffect(() => {
    setLocalValues([]);
  }, [isMultiple, setLocalValues]);

  const onDiscard = () => {
    onClose();
    onCancel();
  };

  const onDisableFilter = () => enabledFilter && dispatch(disableFilterById(enabledFilter.id));

  const createVirtualizedProps = ({
    values,
    isSelectedFilterValue,
    onSelectFilterValue,
  }: {
    values: string[];
    isSelectedFilterValue: (value: string) => boolean;
    onSelectFilterValue: (value: string) => () => void;
  }) => ({
    values,
    isSelectedFilterValue,
    onSelectFilterValue,
  });

  const listProps = createVirtualizedProps({ values, isSelectedFilterValue, onSelectFilterValue });

  return (
    <ClickAwayListener onClickAway={onClose}>
      <MultipleFilterWrapper isOpen={isModalOpen} isAlwaysOpen={isAlwaysOpen} height={modalHeight}>
        <InnerContentWrapper isOpen={isModalOpen} isAlwaysOpen={isAlwaysOpen} height={modalHeight}>
          <FlexContainer gap="5px" justifyContent={position} width="100%">
            <FlexContainer gap="10px" alignItems="center" justifyContent="center" cursor="pointer" onClick={toggleOpen}>
              <PrimaryTextSpan fontSize="18px" lineHeight="18px" color={`var(${ColorVarsEnum.Level_1})`}>
                {filterName}
              </PrimaryTextSpan>
              {!isModalOpen && (
                <IconWrapper
                  Icon={isModalOpen ? RotatedDownIcon : DownIcon}
                  colorVar={ColorVarsEnum.Accent}
                  iconHeight="18px"
                  iconWidth="18px"
                  hoverColorVar={ColorVarsEnum.Accent}
                  opacity={0}
                />
              )}
            </FlexContainer>
            {enabledFilter && !isModalOpen && (
              <FlexContainer>
                <IconWrapper
                  onClick={onDisableFilter}
                  Icon={ClearIcon}
                  colorVar={ColorVarsEnum.Level_1}
                  iconHeight="15px"
                  iconWidth="15px"
                />
              </FlexContainer>
            )}
          </FlexContainer>
          {isModalOpen && (
            <>
              <FlexContainer width="100%">
                <SearchInput
                  useDebounce
                  name="filter"
                  needBackground={false}
                  type="text"
                  width="100%"
                  value={searchString}
                  onClear={() => setSearchString('')}
                  onChange={(e) => setSearchString(e.target.value)}
                />
              </FlexContainer>
              <FilterSortingContainer isAlwaysOpen={isAlwaysOpen} modalWidth={modalWidth}>
                <SelectedItemCounter
                  selectedCounter={localValues.length}
                  listCounter={totalCount}
                  isOverLimit={isValuesOverLimit}
                />
                <SortingPanel
                  onSelectAllClick={isMultiple ? onSelectAll : undefined}
                  onSelectNoneClick={onSelectNone}
                  onSelectInvertClick={isMultiple ? onInvertSelection : undefined}
                  onSortingClick={setSortingStatus}
                  selectedSorting={sortingStatus}
                />
              </FilterSortingContainer>
              <FilterValuesWrapper isAlwaysOpen={isAlwaysOpen}>
                {values.length ? (
                  <VirtualizedList data={listProps}>{FilterValuesRow}</VirtualizedList>
                ) : (
                  <FlexContainer
                    height="100%"
                    width="100%"
                    justifyContent="center"
                    flex="1"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <PrimaryTextSpan textAlign="center">Нет подходящих данных, либо они уже выбраны</PrimaryTextSpan>
                  </FlexContainer>
                )}
              </FilterValuesWrapper>
            </>
          )}
        </InnerContentWrapper>
        {isModalOpen && (
          <FilterSortingContainer justifyContent="flex-end">
            <Button text="Отменить" needBackground={false} heightSize="small" width="80px" onClick={onDiscard} />
            <Button
              disabled={!hasChanges}
              text="Сохранить"
              needBackground={false}
              heightSize="small"
              width="80px"
              onClick={onEnableFilter}
            />
          </FilterSortingContainer>
        )}
      </MultipleFilterWrapper>
    </ClickAwayListener>
  );
};

export const MultipleFilter = memo(MultipleFilterComponent) as FilterViewInterface;
