import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { GradientByType } from 'store/reducers/visualisations/types';

export const getHeatmapConditionOptions = (usingByCondition?: boolean) => {
  const byCondition = usingByCondition
    ? [
        {
          value: 'condition',
          label: 'По условию',
        },
      ]
    : [];

  return [
    {
      value: 'default',
      label: 'Вручную',
    },
    {
      value: 'valueGradient',
      label: 'Градиент',
    },
    {
      value: 'valueSteps',
      label: 'Ступенями',
    },
    ...byCondition,
  ] as SettingsRadioItem<GradientByType>[];
};
