import styled from 'styled-components';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import React from 'react';
import { TOOLTIP } from 'modules/workspace/constans';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    slotProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -10],
            },
          },
        ],
      },
    }}
    {...props}
    classes={{ popper: `${className}  ${TOOLTIP}` }}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `var(${ColorVarsEnum.Sub_Level_bg_1})`,
    borderRadius: '4px',
    padding: '4px 8px',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.15)',
    color: `var(${ColorVarsEnum.Sub_Level_1})`,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    margin: 0,
    whiteSpace: 'pre-wrap',
  },
}));
