import { PieDesignInterface } from 'store/reducers/visualisations/types';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React from 'react';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { degUnit, pxUnit } from 'constants/global';
import { BooleanElementSettings } from 'modules/settingsContainer/common/BooleanElementSettings';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { OnValueChange } from 'types/global';
import { NumberPropertiesSelectBlock } from 'components/shared/ui/NumberPropertieSelectBlock';
import { unitOptions } from 'modules/visualisations/common/constants';
import SubSection from 'shared/ui/subSection';
import { ColorBySettings } from 'modules/settingsContainer/common/ColorBySettings';
import { ColorPicker } from 'modules/settingsContainer/ColorPicker';
import { SettingsCheckbox } from 'modules/settingsContainer/SettingsCheckbox';
import { pieLabelOptions } from 'modules/settingsContainer/common/data/PieLabelFormatSettings/constants';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsCheckbox/constants';
import { PropertiesSettings } from 'modules/settingsContainer/common/data/PropertiesSettings';

type PieStyleSettingsProps = OnValueChange<PieDesignInterface>;

export const PieStyleSettings = ({ value: styleProperties, onChange }: PieStyleSettingsProps) => {
  const {
    borderRadius,
    borderWidth,
    pieAnimationElement,
    outerRadius,
    innerRadius,
    arcStartAngle,
    arcEndAngle,
    centerCoordinates: { centerYCoordinates, centerXCoordinates, isActive },
    colorBySettings,
    properties,
    labelFormat,
  } = styleProperties;

  return (
    <MainContainerSettings titleText="Оформление">
      <SubSection>
        <GroupContainerSettings>
          <BooleanElementSettings
            titleText="Выдвигать доли при наведении"
            value={pieAnimationElement}
            onChange={() => onChange({ ...styleProperties, pieAnimationElement: !pieAnimationElement })}
          />
          <ElementContainerSettings>
            <NumberPropertiesBlock
              name="borderRadius"
              properties="Радиус скругления"
              unit={pxUnit}
              value={borderRadius}
              heightSize="small"
              onChange={(borderRadius) => onChange({ ...styleProperties, borderRadius })}
            />
          </ElementContainerSettings>
          <ElementContainerSettings>
            <NumberPropertiesBlock
              name="borderWidth"
              properties="Расстояние между долями"
              unit={pxUnit}
              value={borderWidth}
              heightSize="small"
              onChange={(borderWidth) => onChange({ ...styleProperties, borderWidth })}
            />
          </ElementContainerSettings>
          <ElementContainerSettings>
            <NumberPropertiesSelectBlock
              name="outerRadius"
              properties="Внешний радиус"
              unitOptions={unitOptions}
              value={outerRadius}
              onChange={(outerRadius) => onChange({ ...styleProperties, outerRadius })}
              heightSize="small"
            />
          </ElementContainerSettings>
          <ElementContainerSettings>
            <NumberPropertiesSelectBlock
              name="innerRadius"
              properties="Внутренний радиус"
              unitOptions={unitOptions}
              value={innerRadius}
              onChange={(innerRadius) => onChange({ ...styleProperties, innerRadius })}
              heightSize="small"
            />
          </ElementContainerSettings>
          <ElementContainerSettings>
            <NumberPropertiesBlock
              name="startAngle"
              heightSize="small"
              properties="Угол начала дуги"
              unit={degUnit}
              value={arcStartAngle}
              onChange={(arcStartAngle) => onChange({ ...styleProperties, arcStartAngle })}
            />
          </ElementContainerSettings>
          <ElementContainerSettings>
            <NumberPropertiesBlock
              name="startAngle"
              properties="Угол конца дуги"
              heightSize="small"
              unit={degUnit}
              value={arcEndAngle}
              onChange={(arcEndAngle) => onChange({ ...styleProperties, arcEndAngle })}
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
        <GroupContainerSettings
          titleText="Изменить координаты центра вручную"
          switcherState={styleProperties.centerCoordinates.isActive}
          switcherChange={() =>
            onChange({
              ...styleProperties,
              centerCoordinates: { centerYCoordinates, isActive: !isActive, centerXCoordinates },
            })
          }
        >
          <ElementContainerSettings>
            <NumberPropertiesSelectBlock
              name="centerXCoordinates"
              properties="Координаты центра по X"
              unitOptions={unitOptions}
              value={centerXCoordinates}
              onChange={(centerXCoordinates) =>
                onChange({ ...styleProperties, centerCoordinates: { centerYCoordinates, centerXCoordinates, isActive } })
              }
              heightSize="small"
            />
          </ElementContainerSettings>
          <ElementContainerSettings>
            <NumberPropertiesSelectBlock
              name="centerYCoordinates"
              properties="Координаты центра по Y"
              unitOptions={unitOptions}
              value={centerYCoordinates}
              onChange={(centerYCoordinates) =>
                onChange({ ...styleProperties, centerCoordinates: { centerYCoordinates, centerXCoordinates, isActive } })
              }
              heightSize="small"
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
        <ColorBySettings
          titleMainContainerText="Цвет сегментов"
          isMainContainerSettings
          disabledComponentByValue
          value={colorBySettings.elementColorBy}
          onChange={(elementColorBy) => onChange({ ...styleProperties, colorBySettings: { ...colorBySettings, elementColorBy } })}
        >
          <ColorPicker
            closeOnClick
            type="palette"
            onChange={(elementColor) => onChange({ ...styleProperties, colorBySettings: { ...colorBySettings, elementColor } })}
            value={colorBySettings.elementColor}
          />
        </ColorBySettings>
      </SubSection>
      <SubSection>
        <GroupContainerSettings titleText="Форма подписи">
          <SettingsCheckbox
            onChange={(value) => onChange({ ...styleProperties, labelFormat: value.map(({ value }) => value) })}
            options={pieLabelOptions}
            activeValue={getActiveRadioValue(pieLabelOptions, labelFormat)}
          />
        </GroupContainerSettings>

        <PropertiesSettings
          value={properties}
          titleColorMainContainerText="Цвет"
          onChange={(properties) => onChange({ ...styleProperties, properties })}
          isMainContainerSettings
          isMainContainerColorSettings
          disabledOpacity
          disabledBackgroundColorBy
          disabledComponentColorByValue
          disabledPadding
          disabledLineHeight
          disabledUnderline
          disabledLetterSpacing
          disabledFontColorBy
        />
      </SubSection>
    </MainContainerSettings>
  );
};
