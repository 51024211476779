import { interpolateRgb, scaleLinear } from 'd3';
import domtoimage, { Options } from 'dom-to-image';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import {
  TextVariablesEnum,
  TextVariablesType,
  TransformationTextVariablesElementInterface,
} from 'modules/visualisations/Text/visualisation/types';
import { TAG_INPUT, TAG_TEXTAREA } from 'modules/workspace/constans';
import {
  ConditionsMarkerType,
  DefaultVisualisationOptionsType,
  TableDataSettings,
  TableIncisionInterface,
  TableIndicatorInterface,
  TextVariablesInterface,
} from 'store/reducers/visualisations/types';
import { MapRecordType } from 'types/global';
import { IdInterface, PositionConfigSettingsInterface } from 'types/store';
import { defaultSelectAST, generateILike, getWhereString, sqlParser } from './SQL/genereteAst';
import { AST } from 'types/ast';
import { generateSqlFullQuery } from './SQL/generateSQL';
import { combineSqlStrings } from './SQL/formatSQL';
import { Column, Select } from 'node-sql-parser';
import { FilterDataType, GenerateFilterSqlStringInterface } from 'store/reducers/filters/types';
import { SortingValueEnum } from 'components/shared/SortingPanel/types';
import { ModelFromMetaType } from 'store/reducers/models/types';
import { WidgetRuNameEnum } from 'types/types';
import { IncisionsAstType } from 'store/reducers/ast/types';
import { parseCustomAliasName } from './formatting';

export const getTimeFromTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);

  return (
    ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2)
  );
};

export type MoveToType = 'up' | 'down' | 'top' | 'bottom';

export enum MoveToEnum {
  UP = 'up',
  DOWN = 'down',
  TOP = 'top',
  BOTTOM = 'bottom',
}

export const moveArrayItem = <T>(array: T[], index: number, moveTo: MoveToType) => {
  const newArray = [...array];
  let newIndex: number | null = null;

  if (moveTo === MoveToEnum.UP && index > 0) {
    newIndex = index - 1;
    const temp = newArray[index];
    newArray[index] = newArray[newIndex];
    newArray[newIndex] = temp;
  }

  if (moveTo === MoveToEnum.DOWN && index < array.length - 1) {
    newIndex = index + 1;
    const temp = newArray[index];
    newArray[index] = newArray[newIndex];
    newArray[newIndex] = temp;
  }

  if (moveTo === MoveToEnum.TOP && index > 0) {
    newIndex = 0;
    const [removed] = newArray.splice(index, 1);
    newArray.splice(newIndex, 0, removed);
  }

  if (moveTo === MoveToEnum.BOTTOM && index < array.length - 1) {
    newIndex = array.length - 1;
    const [removed] = newArray.splice(index, 1);
    newArray.splice(newIndex, 0, removed);
  }

  return { newArray, oldIndex: index, newIndex };
};

export const getMaximumStringLengthOfArrayData = (array?: Array<string | null> | Array<number | null>) =>
  (array as Array<string | number>)?.reduce<string | number>(
    (max, current) => (String(current).length > String(max).length ? current : max),
    '',
  ) || '';

interface GetGradientInterpolatorParams {
  colors: string[];
  maxValue?: number;
  minValue?: number;
}

export const getGradientInterpolator = ({ colors, minValue = 0, maxValue = 1 }: GetGradientInterpolatorParams) => {
  if (!colors.length) {
    return () => null;
  }

  let domain: number[];

  if (colors.length === 1) {
    domain = [minValue, maxValue];
  } else {
    domain = colors.map((_, index) => ((maxValue - minValue) / (colors.length - 1)) * index);
  }

  return scaleLinear<string>().domain(domain).range(colors).interpolate(interpolateRgb);
};

export type CreateGradientOrStripesFromColorsType =
  | undefined
  | string
  | { type: 'linear'; x: number; y: number; x2: number; y2: number; colorStops: { offset: number; color: string }[] };

export const createGradientOrStripesFromColors = (
  colors: string[],
  useStripes = false,
): CreateGradientOrStripesFromColorsType => {
  if (!colors || !colors.length) {
    return;
  }

  if (colors.length === 1) {
    return colors[0];
  }

  if (useStripes) {
    const colorStops: { offset: number; color: string }[] = [];
    const step = 1 / colors.length;
    colors.forEach((color, index) => {
      const offsetStart = index * step;
      const offsetEnd = (index + 1) * step;
      colorStops.push({ offset: offsetStart, color }, { offset: offsetEnd, color });
    });

    return {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 1,
      y2: 0,
      colorStops,
    };
  } else {
    return {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 1,
      y2: 0,
      colorStops: colors.map((color, index) => ({
        offset: index / (colors.length - 1),
        color,
      })),
    };
  }
};

export const getColorByValueMinMax = <ColorsType = unknown[]>({
  min,
  max,
  colors,
  value,
}: {
  min: number;
  max: number;
  colors: ColorsType[];
  value: number;
}): ColorsType => {
  if (value <= min || value <= 0) {
    return colors[0];
  }

  if (value >= max) {
    return colors[colors.length - 1];
  }

  const interval = (max - min) / colors.length,
    colorIndex = Math.floor((value - min) / interval);

  return colors[colorIndex];
};

export const isColor = (stringColor?: string | number | null) => {
  if (typeof stringColor === 'number' || !stringColor) return false;

  const styleOption = new Option().style;
  styleOption.color = stringColor;
  return styleOption.color == stringColor || !!styleOption.color.match('^rgb');
};

export const getMaxAndMinFromArray: (array?: Array<string | null> | Array<number | null> | null) => {
  max: number;
  min: number;
} = (array = []) =>
  (array as (string | number)[]).reduce(
    (result, value) => {
      const comparedValue = typeof value === 'string' ? 1 : value;

      const max = comparedValue > result.max ? comparedValue : result.max,
        min = comparedValue < result.min ? comparedValue : result.min;

      return { max, min };
    },
    { max: 0, min: 0 },
  ) || { max: 0, min: 0 };

export const getRandomArrayItem: <T>(array: T[]) => T | undefined = (array) => array[Math.floor(Math.random() * array.length)];

export const getArrayItemByCountlessIndex: <T>(array: T[], index: number) => T = (array, index) => {
  const arrayLength = array.length;
  let arrayIndex = index % arrayLength;

  if (index < 0) {
    arrayIndex = arrayLength + arrayIndex;
  }

  return array[arrayIndex];
};

export const getSumOfArrayValues: (array: Array<number | string | null | undefined>) => number = (array) =>
  array.reduce<number>((sum, value) => {
    const normalizedValue = typeof value === 'string' ? 0 : value || 0;

    return normalizedValue + sum;
  }, 0);

interface ObjectInterface {
  [key: string]:
    | boolean
    | string
    | null
    | number
    | undefined
    | Array<boolean | string | null | number | ObjectInterface>
    | ObjectInterface;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const deepMergeByReference = <T extends Object>(
  target: any,
  reference: T,
  options?: { keyChanger?: MapRecordType<() => any> },
) => {
  const keyChanger = options?.keyChanger || {};

  let result = {};

  Object.keys(reference).forEach((key) => {
    const referenceValue = (reference as any)[key],
      isReferenceArray = Array.isArray(referenceValue),
      keyChangerFunction = keyChanger[key],
      targetValue = target[key];

    let changedReferenceValue = referenceValue;

    /* Using key Changer Function - key from Function instead of reference value */
    if (typeof keyChangerFunction === 'function') {
      changedReferenceValue = keyChangerFunction();
    }

    /* No key in target */
    if (targetValue === undefined) {
      result = { ...result, [key]: changedReferenceValue };
      return;
    }

    /* Logic for array reference data */
    if (isReferenceArray) {
      /* Value of target is not array */
      if (!Array.isArray(targetValue)) {
        result = { ...result, [key]: referenceValue };
        return;
      }

      /* Value of target empty array */
      if (targetValue.length === 0) {
        result = { ...result, [key]: [] };
        return;
      }

      const firstArrayValue = referenceValue?.[0],
        typeOfArrayValue = typeof firstArrayValue;

      /* Structure of reference array values are null or undefined  */
      if (firstArrayValue === undefined || firstArrayValue === null) {
        result = { ...result, [key]: targetValue };
        return;
      }

      /* Structure of reference array values are string / number / boolean   */
      if (['string', 'boolean', 'number'].includes(typeOfArrayValue)) {
        const keyResult = targetValue.reduce<Array<ObjectInterface | string | boolean | number>>((targetArray, value) => {
          if (value !== null && typeof value === (typeOfArrayValue as 'string' | 'boolean' | 'number')) {
            return [...targetArray, value];
          }
          return targetArray;
        }, []);

        result = {
          ...result,
          [key]: keyResult,
        };
        return;
      }

      /* Structure of reference array values are Object */
      if (typeOfArrayValue === 'object') {
        const keyResult = targetValue.reduce<ObjectInterface[]>((resultArray, value) => {
          if (
            value === null ||
            typeof value === 'string' ||
            typeof value === 'number' ||
            typeof value === 'boolean' ||
            Array.isArray(value)
          ) {
            return resultArray;
          }

          const deep = deepMergeByReference(value, firstArrayValue as ObjectInterface, options);
          return [...resultArray, deep];
        }, []);

        result = { ...result, [key]: keyResult };
        return;
      }
    }

    /* Logic for Primitive (null / string / number / boolean) reference data */
    if (
      referenceValue === null ||
      typeof referenceValue === 'string' ||
      typeof referenceValue === 'number' ||
      typeof referenceValue === 'boolean'
    ) {
      if (typeof targetValue === typeof referenceValue || referenceValue === null) {
        result = { ...result, [key]: targetValue };
        return;
      }

      result = { ...result, [key]: changedReferenceValue };
      return;
    }

    /* Logic for Object reference data */
    if (typeof referenceValue === 'object') {
      /* If target Value are Primitive using Reference Value */
      if (
        targetValue === null ||
        typeof targetValue === 'string' ||
        typeof targetValue === 'number' ||
        typeof targetValue === 'boolean' ||
        Array.isArray(targetValue)
      ) {
        result = { ...result, [key]: changedReferenceValue };
        return;
      }

      const deepObject = deepMergeByReference(targetValue, referenceValue as ObjectInterface, options);

      result = { ...result, [key]: { ...deepObject } };
      return;
    }
  });

  return result as T;
};

export const mergeByReference = <OriginData extends Partial<IdInterface>, ReferenceData extends Partial<IdInterface>>({
  originData,
  typeKey,
  getReferenceByType,
}: {
  originData: OriginData[];
  typeKey: keyof OriginData;
  getReferenceByType: (type: string) => ReferenceData;
}) => {
  const mergedData = originData.reduce((result, originData) => {
    const type = originData[typeKey];
    const reference = type && getReferenceByType(type as string);

    const merged = reference ? deepMergeByReference(originData, reference) : undefined;

    if (merged && merged?.id) {
      return { ...result, [merged.id]: merged };
    }

    return { ...result };
  }, {} as Record<string, ReferenceData>);

  return mergedData || {};
};

export const mergeRightMapOfSetWithLeft = <T>(left: MapRecordType<Set<T>>, right: MapRecordType<Set<T>>) => {
  const merged = Object.keys(right).reduce<MapRecordType<Set<T>>>((result, key) => {
    const leftState = left[key] || new Set<T>(),
      rightState = right[key] || new Set<T>();

    return { ...result, [key]: new Set([...leftState, ...rightState]) };
  }, {});

  return { ...left, ...merged };
};

export const isField = (eventTagName: string) => [TAG_INPUT, TAG_TEXTAREA].includes(eventTagName);

export const isHasParentWithClassName = (target: Element, className: string): boolean => {
  if (target.classList.contains(className)) {
    return true;
  }

  if (!target.parentElement) {
    return false;
  }

  return isHasParentWithClassName(target.parentElement, className);
};

export const findNextIndex = (currentNames: string[], pattern: string) => {
  const indexTable: number[] = [];
  currentNames.forEach((name) => {
    const mayBeIndex = name.replace(pattern, ''),
      mayBeNumberIndex = Number(mayBeIndex);
    if (!isNaN(mayBeNumberIndex)) {
      indexTable[mayBeNumberIndex] = mayBeNumberIndex;
    }
  });

  const index = indexTable.findIndex((value) => value === undefined);

  return index === -1 ? currentNames.length : index;
};

export const getRandomInt = (min: any, max: any) => {
  const range = max - min + 1;
  return Math.round(Math.floor(Math.random() * range) + min);
};

export interface CheckInRangeInterface {
  value: number;
  min: number;
  max: number;
  minCondition: ConditionsMarkerType;
  maxCondition: ConditionsMarkerType;
}

export const checkInRange = ({ value, min, max, minCondition, maxCondition }: CheckInRangeInterface): boolean => {
  const conditions: Record<ConditionsMarkerType, (a: number, b: number) => boolean> = {
    more: (a, b) => a > b,
    moreOrEqual: (a, b) => a >= b,
    equal: (a, b) => a === b,
    lessOrEqual: (a, b) => a <= b,
    less: (a, b) => a < b,
  };

  return conditions[minCondition](value, min) && conditions[maxCondition](value, max);
};

interface LinkInterface {
  type: 'link';
  link: string;
  description: string;
}

interface TextInterface {
  type: 'text';
  text: string;
}

export const findLinksAndText = (str?: string): Array<LinkInterface | TextInterface> => {
  if (!str) {
    return [];
  }

  const regex =
    /#([a-zA-Zа-яА-Я\ 0-9\-\_]+)\[([(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]\%@!\$&'\(\)\*\+,;=.\{\}]+)\]/g;
  const matches = [...str.matchAll(regex)];

  const results = [];
  let lastIndex = 0;

  for (const match of matches) {
    const [fullMatch, description, link] = match;

    const startIndex = str.indexOf(fullMatch, lastIndex);
    const endIndex = startIndex + fullMatch.length;
    const text = str.slice(lastIndex, startIndex);

    if (text.length > 0) {
      results.push({ type: 'text', text } as TextInterface);
    }

    results.push({ type: 'link', link, description } as LinkInterface);

    lastIndex = endIndex;
  }

  if (lastIndex < str.length) {
    const text = str.slice(lastIndex);
    results.push({ type: 'text', text } as TextInterface);
  }

  return results;
};

export const addProtocolToLink = (link: string) => {
  if (!link) {
    return '';
  }

  link = link.trim().replace(/ /g, '%20');

  if (!/^(ftp|http[s]?):\/\//.test(link)) {
    link = 'http://' + link;
  }

  return link;
};
export const isURL = (str: string | number | null): boolean => {
  if (typeof str !== 'string' || !str) return false;

  const regex = /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)/gi;
  return regex.test(str);
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const getMapObject = <T extends Object>(array: T[] | undefined, key: keyof T) =>
  (array || []).reduce<Record<string, T>>((result, data) => ({ ...result, [data[key] as string]: data }), {});

export const getLastPath = (pathname: string) => {
  const slicedPath = pathname.split('/');

  return slicedPath[slicedPath.length - 1];
};

export const getValueByKeys = <T>(targetObject: MapRecordType<T>, keys: string[]) => {
  const key = keys.find((key) => !!targetObject[key]) || '';

  return targetObject[key];
};

export const isPositiveNumber = (number: number) => number >= 0;

export const createLinearScale = (domain: number[], range: number[]) =>
  scaleLinear().domain(domain).range(range) as (value: number) => number;

export const isEqualProps = <Props>(prevProps: Readonly<Props>, nextProps: Readonly<Props>) => isEqual(prevProps, nextProps);

export const getSomeArrays = <T>(currentArray: T[], targetArray: T[], pieceLength: number) => {
  const start = currentArray.length,
    end = start + pieceLength,
    endLength = end > targetArray.length ? targetArray.length : end;

  return [...currentArray, ...targetArray.slice(start, endLength)];
};

export const sortByYCoordinateFn = <T extends PositionConfigSettingsInterface>(a: T, b: T) =>
  a.positionConfig.y - b.positionConfig.y;

export const getFileExtension = (name: string): string => {
  if (!name) {
    return 'auto';
  }
  const dotIndex = name.lastIndexOf('.');

  if (dotIndex > 0) {
    return name.substring(dotIndex + 1) === 'xls' ? 'xlsx' : name.substring(dotIndex + 1);
  }

  return 'none';
};

export type FindVariablesAndLinks = {
  type: TextVariablesType;
  text: string;
  description?: string;
  origin?: string;
};

export const findVariablesAndLinks = (str?: string): FindVariablesAndLinks[] => {
  if (!str) {
    return [];
  }

  const regex =
    /\{\{([^}]+)\}\}|#([a-zA-Zа-яА-Я 0-9\-_]+)\[((?:https?:\/\/)?[a-zA-Zа-яА-Я0-9.-]+(?:\.[a-zA-Zа-яА-Я0-9.-]+)*[a-zA-Zа-яА-Я0-9\-._~:\/?%#[\]@!\$&'()*+,;=.\{\}]+|\{\{([^}]+)\}\})\]/g;

  const results: FindVariablesAndLinks[] = [];
  let lastIndex = 0;

  str.replace(regex, (match, variable, description, link, linkVariable, index) => {
    if (index > lastIndex) {
      results.push({ type: 'text', text: str.slice(lastIndex, index) });
    }

    if (variable) {
      results.push({ type: 'variable', text: variable, origin: match });
    } else if (linkVariable) {
      results.push({ type: 'linkVariables', text: linkVariable, description });
    } else if (link && description) {
      results.push({ type: 'link', text: link, description });
    }

    lastIndex = index + match.length;
    return match;
  });

  if (lastIndex < str.length) {
    results.push({ type: 'text', text: str.slice(lastIndex) });
  }

  return results;
};

export const isArrayEquals = <T, K>(a: T[], b: K[]) => {
  const diffAAndB = differenceWith(a, b, isEqual);
  const diffBAndA = differenceWith(b, a, isEqual);

  return diffAAndB.length === 0 && diffBAndA.length === 0;
};

export const replaceValueIfPresent = (text: string, newValue: string): string => {
  return text.includes('value') ? text.replace(/value/g, newValue) : text;
};

export const daysUntilDate = (targetDate: Date): string => {
  const currentDate = new Date();

  const timeDiff = targetDate.getTime() - currentDate.getTime();

  return String(Math.ceil(timeDiff / (1000 * 3600 * 24)));
};

export const calculateUsagePercentage = (used?: number, total?: number): number => {
  if (!used || !total) {
    return 0;
  }

  if (total === 0) {
    return 0;
  }
  return (used / total) * 100;
};

export const dataURLtoFile = (dataUrl: string, filename: string) => {
  const arr = dataUrl.split(',');
  const mime = (arr?.[0] || '').match(/:(.*?);/)?.[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const getNodeScreenFile = async (selector: string, name: string, options?: Options): Promise<File | null> => {
  try {
    const workspace = document.querySelector(selector);

    if (!workspace) return null;
    const imageInb64 = await domtoimage.toPng(workspace, options);
    return dataURLtoFile(imageInb64, name);
  } catch (error) {
    /*TODO тк ловится ошибка, скриншот работает некорректно*/
    return null;
  }
};

export const handleInfluenceStatus = (object: MapRecordType<boolean> | null, key: string) => {
  if (!object) {
    return true;
  }

  if (object?.hasOwnProperty(key)) {
    return object[key];
  } else {
    return true;
  }
};

export const applyToAllInfluences = (data: any, value: boolean, activeId: string) => {
  const filteredData: MapRecordType<boolean> = {};
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      if (key === activeId) {
        continue;
      }
      filteredData[key] = value;
    }
  }
  return filteredData;
};

export const truncateFilename = (filename: string, maxLength = 25): string => {
  const extensionIndex = filename.lastIndexOf('.');
  const namePart = filename.substring(0, extensionIndex);
  const extensionPart = filename.substring(extensionIndex);

  if (filename.length <= maxLength) {
    return filename;
  }

  const maxNameLength = maxLength - extensionPart.length;
  if (maxNameLength < 5) {
    return filename;
  }

  const prefixLength = Math.floor(maxNameLength / 2);
  const suffixLength = maxNameLength - prefixLength - 3;
  return namePart.substring(0, prefixLength) + '...' + namePart.substring(namePart.length - suffixLength) + extensionPart;
};

export const isSafari = () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return isSafari;
};

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)].join(', ') : null;
};

export const hasName = <T extends { name: string }>(list: T[], name: string): boolean => list.some((obj) => obj.name === name);

export const replaceEmptyValues = (values: any, emptyValue: string) => {
  if (Array.isArray(values)) {
    return values.map((val) => (isNull(val) || isUndefined(val) ? emptyValue : val));
  } else {
    return isNull(values) || isUndefined(values) ? emptyValue : values;
  }
};

export const replaceEmptyValuesForTableData = (
  dataSettings: TableDataSettings,
  data: { [x: string]: (string | null)[] | (number | null)[] | undefined },
) => {
  const emptyValuesMap: { [key: string]: string | number } = {};

  const processArrayWithSettings = (array: TableIncisionInterface[]) => {
    array.forEach((item) => {
      const {
        name,
        fieldName,
        settings: {
          emptyValues: { isEmptyValue, value },
          nameFromDatabase,
        },
      } = item;

      /* TODO: Fix - try to use getVisualisationFieldName func and check all tests */
      const nameKey = nameFromDatabase ? fieldName || '' : name;

      if (isEmptyValue) {
        emptyValuesMap[nameKey] = value;
      }
    });
  };

  const processArrayWithoutSettings = (array: TableIndicatorInterface[]) => {
    array.forEach((item) => {
      const {
        name,
        fieldName,
        emptyValues: { isEmptyValue, value },
        settings: { nameFromDatabase },
      } = item;

      /* TODO: Fix - try to use getVisualisationFieldName func and check all tests */
      const nameKey = nameFromDatabase ? fieldName || '' : name;

      if (isEmptyValue) {
        emptyValuesMap[nameKey] = value;
      }
    });
  };

  processArrayWithSettings(dataSettings.incisionsInHeader);
  processArrayWithSettings(dataSettings.incisions);
  processArrayWithoutSettings(dataSettings.indicators);

  const updatedData: { [x: string]: (string | null)[] | (number | null)[] | undefined } = { ...data };
  Object.keys(updatedData).forEach((key) => {
    const emptyValue = emptyValuesMap[key];
    if (!isUndefined(emptyValue)) {
      const value = updatedData[key];
      if (Array.isArray(value)) {
        if (isString(emptyValue)) {
          updatedData[key] = value.map((item) => (isNull(item) ? emptyValue : item)) as (string | null)[];
        } else if (isNumber(emptyValue)) {
          updatedData[key] = value.map((item) => (isNull(item) ? emptyValue : item)) as (number | null)[];
        }
      }
    }
  });

  return updatedData;
};

export const replaceUndefinedWithEmptyValues = (
  textObjects: (TransformationTextVariablesElementInterface | null)[],
  variables: TextVariablesInterface[],
) => {
  return textObjects.map((obj) => {
    if (
      obj?.type === TextVariablesEnum.VARIABLE &&
      obj.origin &&
      (isUndefined(obj?.text) || isNull(obj?.text) || obj?.text === 'undefined')
    ) {
      const variableNames = obj.origin.match(/{{\s*[^}]+\s*}}/g);

      if (variableNames) {
        let newText = obj.origin;

        variableNames.forEach((variableWithBraces) => {
          const variableName = variableWithBraces.replace('{{', '').replace('}}', '').trim();
          const variable = variables.find((varObj) => varObj.name === variableName);

          if (variable && variable.emptyValues?.isEmptyValue) {
            newText = newText.replace(variableWithBraces, variable.emptyValues.value);
          } else {
            newText = newText.replace(variableWithBraces, obj?.text);
          }
        });

        obj.text = newText;
      }
    }
    return obj;
  });
};

export const generateFilterSqlString = ({
  sqlData,
  whereQuery,
  searchString,
  limit,
  sortingStatus,
  fromQuery,
  fieldName,
  isDataFilter,
  withoutValueAndCount,
  originLimit,
}: GenerateFilterSqlStringInterface) => {
  const { incisionRequest } = sqlData;
  const whereString = whereQuery && whereQuery !== '' ? whereQuery : '',
    { where } = sqlParser.astify(`SELECT * ${whereString}`) as Select;

  const filterString = searchString && searchString !== '' ? `%${searchString.toLowerCase()}%` : null;

  const sqlLimit = limit?.isActive ? limit?.value : '2000';
  // Запрашиваем на 1 больше лимита, что бы знать количество в базе больше чем лимит или нет
  const finalLimit = originLimit ? Number(sqlLimit) : Number(sqlLimit) + 1;

  let unionWhere: AST.UnionAndExpressions | AST.FunctionType | null = null;

  if (filterString) {
    unionWhere = generateILike({ fieldName: 'value', filterString });
  }

  if (where) {
    unionWhere =
      unionWhere !== null
        ? {
            type: 'binary_expr',
            left: where,
            operator: 'AND',
            right: unionWhere,
          }
        : where;
  }

  const unionWhereQuery = unionWhere !== null ? getWhereString(unionWhere) : undefined;

  const sortOrder = sortingStatus ? sortingStatus : SortingValueEnum.ASC;

  const finalFieldName = incisionRequest || fieldName;

  const sql =
    generateSqlFullQuery({
      selectQuery: `SELECT ${finalFieldName} ${!withoutValueAndCount ? 'as value, COUNT(*) as count' : ''}`,
      fromQuery,
      orderByQuery: `ORDER BY ${finalFieldName} ${sortOrder}`,
      whereQuery: unionWhereQuery,
      groupByQuery: `GROUP BY ${finalFieldName}`,
      limitQuery: `LIMIT ${finalLimit}`,
    }) || '';

  const selectQueryDataFilter = `SELECT '"' || toString(toDate(Min(${fieldName}))) || '", "' || toString(toDate(Max(${fieldName}))) || '"' ${
    !withoutValueAndCount ? 'as value' : ''
  }`;

  const sqlDataFilter =
    generateSqlFullQuery({
      selectQuery: selectQueryDataFilter,
      fromQuery,
      whereQuery: unionWhereQuery,
    }) || '';

  const finalSql = combineSqlStrings(isDataFilter ? sqlDataFilter : sql, sqlData, sortingStatus, isDataFilter);

  return finalSql;
};

export const findFirstFieldNameInRequest = (modelMetaData: ModelFromMetaType, request?: string | null) => {
  if (request) {
    const alias = modelMetaData.alias;
    const columns = modelMetaData.columns;

    for (const column of columns) {
      const columnName = column.name;
      const variable = `${alias}.${columnName}`;
      if (request.includes(variable)) {
        return variable;
      }
    }
  }
  return null;
};

export const generateWidgetName = (widgets: (FilterDataType | DefaultVisualisationOptionsType)[], targetType: string): string => {
  const ruWidgetName = WidgetRuNameEnum[targetType as keyof typeof WidgetRuNameEnum];

  const count = widgets.reduce((acc, widget) => {
    const type = (widget as DefaultVisualisationOptionsType).visualisationType || (widget as FilterDataType).type;

    return type === targetType ? acc + 1 : acc;
  }, 0);

  return `${ruWidgetName} ${count + 1}`;
};

const parseSqlStringForIncisionsInFilter = (inputString: string) => {
  if (!inputString) return null;

  const selectIndex = inputString.toLowerCase().indexOf('select ');
  const asIndex = inputString.toLowerCase().indexOf(' as ', selectIndex);

  if (selectIndex === -1) return inputString;

  const selectSubstring =
    asIndex !== -1 ? inputString.substring(selectIndex + 7, asIndex) : inputString.substring(selectIndex + 7);

  return selectSubstring.trim();
};

export const findNextIndexForColorGroup = (names: string[], defaultGroupName: string) => {
  const indices = names
    .map((name) => {
      const match = name.match(new RegExp(`^${defaultGroupName}(\\d+)$`));
      return match ? parseInt(match[1], 10) : null;
    })
    .filter((index) => index !== null)
    .sort((a, b) => a! - b!);

  let nextIndex = 1;

  for (const index of indices) {
    if (index !== nextIndex) {
      break;
    }
    nextIndex++;
  }

  return nextIndex;
};

export const getSqlStringIncisionsForEnabledFilter = (data: {
  incisions: IncisionsAstType[];
  activeIncisionIndex: number | null;
}) => {
  const { incisions, activeIncisionIndex } = data;
  const columns = activeIncisionIndex !== null ? [incisions[activeIncisionIndex]] : incisions;
  const sqlRequest = sqlParser.sqlify({
    ...defaultSelectAST,
    columns,
  });

  return parseSqlStringForIncisionsInFilter(sqlRequest);
};

export const parsePeriodFilterDisplayName = (value: string | null) => {
  const dateString = String(value);

  const processQuarter = (input: string) => (input.includes('квартал') ? input.replace(/квартал/g, 'кв.') : input);

  const dates = dateString.split(' — ');
  if (dates.length === 1 || dates[0] === dates[1]) {
    const title = processQuarter(dates[0]);
    return title;
  }

  const processedTitle = processQuarter(dateString);
  return processedTitle;
};

export const filterColumns = ({
  columnsArray,
  activeAliasInColumns,
}: {
  columnsArray: (AST.BasicColumn | Column | AST.BasicFunctionColumn | AST.ValueCondition)[];
  activeAliasInColumns: (string | null)[];
}) => {
  return columnsArray.filter((column) => {
    if (isNull(column.as)) return false;

    const { nameIncision } = parseCustomAliasName(column.as);

    return activeAliasInColumns.includes(column.as) || activeAliasInColumns.includes(nameIncision);
  });
};
