import { pxUnit } from 'constants/global';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { locationOptions, positionOptions, typeOptions } from 'modules/settingsContainer/common/view/ExtendedSettings/constants';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import React from 'react';
import { ExtendedSettingsInterface } from 'store/reducers/visualisations/types';
import { OnValueChange } from 'types/global';
import SubSection from 'shared/ui/subSection';
import { PropertiesSettings } from 'modules/settingsContainer/common/data/PropertiesSettings';

type ExtendedSettingsProps = OnValueChange<ExtendedSettingsInterface> & { title: string };

export const ExtendedSettings = ({ value, onChange, title }: ExtendedSettingsProps) => {
  const {
      type,
      isShow,
      position: { type: positionType, value: positionValue },
      location: { type: locationType, value: locationValue },
      properties,
      width,
    } = value,
    isHorizontalLocation = locationType === 'left' || locationType === 'right';

  const positionOptionsValue = positionOptions(isHorizontalLocation);

  return (
    <MainContainerSettings
      titleText={title}
      switcherState={isShow}
      switcherChange={() => onChange({ ...value, isShow: !isShow })}
    >
      <SubSection>
        <GroupContainerSettings>
          <ElementContainerSettings>
            <SettingsRadio
              onChange={({ value: type }) => onChange({ ...value, type })}
              activeValue={getActiveRadioValue(typeOptions, type)}
              options={typeOptions}
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
      </SubSection>
      <>
        <SubSection>
          <GroupContainerSettings titleText="Расположение">
            <ElementContainerSettings>
              <SettingsRadio
                onChange={({ value: type }) =>
                  onChange({
                    ...value,
                    location: { ...value.location, type, value: locationValue },
                    position: { type: 'center', value: positionValue },
                  })
                }
                activeValue={getActiveRadioValue(locationOptions, locationType)}
                options={locationOptions}
              />
            </ElementContainerSettings>
            {type === 'manual' && (
              <ElementContainerSettings>
                <NumberPropertiesBlock
                  properties="Отступ"
                  name="width"
                  unit={pxUnit}
                  value={locationValue}
                  onChange={(locationValue) =>
                    onChange({
                      ...value,
                      location: { ...value.position, type: locationType, value: locationValue },
                    })
                  }
                />
              </ElementContainerSettings>
            )}
          </GroupContainerSettings>
        </SubSection>
        <SubSection>
          <GroupContainerSettings titleText="Позиционирование">
            <SettingsRadio
              onChange={({ value: type }) => onChange({ ...value, position: { type, value: positionValue } })}
              activeValue={getActiveRadioValue(positionOptionsValue, positionType)}
              options={positionOptionsValue}
            />
            {type === 'manual' && positionType !== 'center' && (
              <ElementContainerSettings>
                <NumberPropertiesBlock
                  properties="Отступ"
                  name="width"
                  unit={pxUnit}
                  value={positionValue}
                  onChange={(positionValue) =>
                    onChange({
                      ...value,
                      position: { ...value.position, type: positionType, value: positionValue },
                    })
                  }
                />
              </ElementContainerSettings>
            )}
          </GroupContainerSettings>
        </SubSection>
        {isHorizontalLocation && (
          <SubSection>
            <GroupContainerSettings>
              <ElementContainerSettings>
                <NumberPropertiesBlock
                  properties="Ширина"
                  name="width"
                  unit={pxUnit}
                  value={width}
                  onChange={(width) => onChange({ ...value, width })}
                />
              </ElementContainerSettings>
            </GroupContainerSettings>
          </SubSection>
        )}
        <SubSection>
          <GroupContainerSettings>
            <ElementContainerSettings>
              <PropertiesSettings
                title="Свойства текста"
                value={properties}
                onChange={(properties) => onChange({ ...value, properties })}
                isMainContainerSettings
                disabledOpacity
                disabledBackgroundColorBy
                disabledComponentColorByValue
                disabledPadding
                disabledLineHeight
                disabledUnderline
                disabledFontColorByBlock
                disabledLetterSpacing
              />
            </ElementContainerSettings>
          </GroupContainerSettings>
        </SubSection>
      </>
    </MainContainerSettings>
  );
};
