import React from 'react';

import { FlexContainer } from 'styles/FlexContainer';
import { Checkbox } from 'modules/ui';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { CircleActivator, ColorPicker } from 'modules/settingsContainer/ColorPicker';
import { ColorValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { OnValueChange } from 'types/global';
import { ColorBySettings } from 'modules/settingsContainer/common/ColorBySettings';
import { BackgroundSettingsInterface, IsShowInterface } from 'store/reducers/visualisations/types';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';

interface ColorPickerBlockProps extends Partial<IsShowInterface> {
  onShowChange?: (isShow: boolean) => void;
  onChangeColors: (value: ColorValuesByThemeType | null) => void;
  colorsValue: ColorValuesByThemeType | null;
  description: string;
  defaultActivatorBackgroundColor: string;
}

export const ColorPickerBlock = ({
  onShowChange,
  isShow = false,
  description,
  onChangeColors,
  colorsValue,
  defaultActivatorBackgroundColor,
}: ColorPickerBlockProps) => (
  <ElementContainerSettings>
    <FlexContainer alignItems="center" position="relative">
      <FlexContainer width="34px" alignItems="center" justifyContent="center">
        {onShowChange && (
          <Checkbox
            name="colorShow"
            onChange={() => onShowChange(!isShow)}
            checked={isShow}
            label=""
            id="beat_column_background"
          />
        )}
      </FlexContainer>
      <ColorPicker
        defaultActivatorBackgroundColor={defaultActivatorBackgroundColor}
        Activator={CircleActivator}
        closeOnClick
        type="color"
        onChange={onChangeColors}
        value={colorsValue}
      />
      <PrimaryTextSpan lineHeight="10px" color={`var(${ColorVarsEnum.Level_3})`} padding="0 0 0 8px">
        {description}
      </PrimaryTextSpan>
    </FlexContainer>
  </ElementContainerSettings>
);

type TextBackgroundSettingsProps = OnValueChange<BackgroundSettingsInterface>;

export const TextBackgroundSettings = ({
  value: backgroundSettings,
  onChange,
  activeBackgraund,
}: TextBackgroundSettingsProps) => {
  const { activeThemeSchema } = useColorValues();
  const { colorSettings } = backgroundSettings;

  return (
    /* TODO: ColorBySettings Временно выключаем из за того что не работает фон пр условию */
    // <ColorBySettings
    //   disabledComponentBySpecificValue
    //   disabledComponentByValue
    //   isMainContainerSettings
    //   titleMainContainerText="Фон"
    //   value={colorSettings.backgroundColorBy}
    //   onChange={(backgroundColorBy) =>
    //     onChange({ ...backgroundSettings, colorSettings: { ...colorSettings, backgroundColorBy } })
    //   }
    //   showBottomLine
    // >
    <GroupContainerSettings titleText="Фон">
      <ColorPickerBlock
        defaultActivatorBackgroundColor={activeThemeSchema[ColorVarsEnum.Level_4_widget]}
        onShowChange={(isShow) =>
          onChange({
            ...backgroundSettings,
            colorSettings: {
              ...colorSettings,
              background: { ...colorSettings.background, isShow },
            },
          })
        }
        onChangeColors={(color) =>
          onChange({
            ...backgroundSettings,
            colorSettings: {
              ...colorSettings,
              background: { ...colorSettings.background, color },
            },
          })
        }
        colorsValue={colorSettings.background.color}
        isShow={colorSettings.background.isShow}
        description="Цвет заливки"
      />

      <ColorPickerBlock
        defaultActivatorBackgroundColor={activeThemeSchema[ColorVarsEnum.Level_4_widget]}
        onShowChange={(isShow) =>
          onChange({
            ...backgroundSettings,
            colorSettings: {
              ...colorSettings,
              hover: { ...colorSettings.hover, isShow },
            },
          })
        }
        onChangeColors={(color) =>
          onChange({
            ...backgroundSettings,
            colorSettings: {
              ...colorSettings,
              hover: { ...colorSettings.hover, color },
            },
          })
        }
        colorsValue={colorSettings.hover.color}
        isShow={colorSettings.hover.isShow}
        description="Цвет при наведении"
      />

      {activeBackgraund && (
        <ColorPickerBlock
          defaultActivatorBackgroundColor={activeThemeSchema[ColorVarsEnum.Level_4_widget]}
          onShowChange={(isShow) =>
            onChange({
              ...backgroundSettings,
              colorSettings: {
                ...colorSettings,
                active: { ...colorSettings.active, isShow },
              },
            })
          }
          onChangeColors={(color) =>
            onChange({
              ...backgroundSettings,
              colorSettings: {
                ...colorSettings,
                active: { ...colorSettings.active, color },
              },
            })
          }
          colorsValue={colorSettings.active.color}
          isShow={colorSettings.active.isShow}
          description="Цвет активного состояния"
        />
      )}
    </GroupContainerSettings>
    // </ColorBySettings>
  );
};
