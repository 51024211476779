import { PageIdParam, ProjectIdParam, RoutesURL } from 'constants/Routes';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { FC, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useBeforeUnload, useBlocker, useNavigate, useParams } from 'react-router-dom';
import Snackbar from 'services/Snackbar';
import { NotViewerWrapper } from 'shared/accessWrapper';
import { useAppDispatch } from 'store';
import { updateAstOfSourcesActions } from 'store/reducers/ast/actions';
import { unsetInfluenceFiltersMode } from 'store/reducers/board';
import { getInfluenceFiltersMode } from 'store/reducers/board/getters';
import { openConfirmationModalAction } from 'store/reducers/modals/actions';
import { getModelsFromAsArray } from 'store/reducers/models/getters';
import { getActivePageSize } from 'store/reducers/projectPages/getters';
import {
  clearWorkspaceDataAction,
  deleteSettingSnapshotFromLocalStorage,
  loadPageDataAction,
  loadProjectFiltersAction,
  loadWorkspaceDataAction,
  loadWorkspaceDataFromSnapshotAction,
  saveSettingSnapshotToLocalStorage,
  uploadProjectScreenAction,
  uploadWorkspaceDataAction,
} from 'store/reducers/projectSettings/actions';
import { getDashboardComponentsLoaded, getLastSettingTimestamp } from 'store/reducers/projectSettings/getters';
import { getHasChangesOfSnapshot, getTimestampSnapshotFromLS } from 'store/reducers/projectSettings/settingsSnapshotService';
import { getActiveThemeColors } from 'store/reducers/themes/getters';
import { FlexContainer } from 'styles/FlexContainer';
import { isAfter, isBefore } from 'utils/dates';
import { useRole } from 'utils/hooks/useRole';
import { getNodeScreenFile } from 'utils/utils';
import { Board, LeftBar, RightBar, WorkSpaceHeader } from './components';
import { FilterInfluenceSettingsPopup } from './components/FilterInfluenceSettingsPopup';
import { loadAllPreviewTemplateVisualisationsAction } from 'store/reducers/visualisations/actions';

let saveRequest: Promise<any> & { abort: (reason?: string) => void };

export const WorkSpace: FC = () => {
  const dispatch = useAppDispatch();

  const themeColors = useSelector(getActiveThemeColors);
  const pageSize = useSelector(getActivePageSize);

  const modelsFrom = useSelector(getModelsFromAsArray);
  const dashboardComponentsLoaded = useSelector(getDashboardComponentsLoaded);
  const influenceFiltersMode = useSelector(getInfluenceFiltersMode);

  const { projectId } = useParams<ProjectIdParam>();
  const { pageId } = useParams<PageIdParam>();
  const navigate = useNavigate();
  const { isViewer } = useRole();

  const lastSettingTimestamp = useSelector(getLastSettingTimestamp);
  const hasChanges = useSelector(getHasChangesOfSnapshot);
  const timestamp = useSelector(getTimestampSnapshotFromLS(projectId || ''));
  const changesRef = useRef<boolean>(false);

  if (!projectId) {
    navigate(RoutesURL.projectsList);
  }

  const unsetInfluenceMode = () => {
    dispatch(unsetInfluenceFiltersMode());
  };

  useEffect(() => {
    if (projectId) {
      dispatch(loadWorkspaceDataAction(projectId));
    }

    return () => {
      dispatch(clearWorkspaceDataAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*TODO: Comment on RLS and wait for the server */

  // useEffect(
  //   () => {
  //     if (projectId && !isViewer) {
  //       dispatch(loadRLSUserAndGroupAction(projectId));
  //     }
  //   }, // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [isViewer, projectId],
  // );

  useEffect(() => {
    if (!isViewer) dispatch(loadAllPreviewTemplateVisualisationsAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewer]);

  const saveProjectScreen = useCallback(async () => {
    const file = await getNodeScreenFile('#scroll-to-top', 'avatar.png', {
      ...pageSize,
      bgcolor: themeColors['--color-l_4_widget'],
    });

    if (!file || !projectId) return;

    const screen = new FormData();
    screen.append('screen', file);

    await dispatch(uploadProjectScreenAction({ projectId, screen }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, pageSize, themeColors]);

  const saveWorkspaceSettings = useCallback(async () => {
    let error = null;

    if (!projectId) {
      Snackbar.show('Id проекта не обнаружено', 'error');
      return;
    }

    await saveProjectScreen();

    saveRequest = dispatch(uploadWorkspaceDataAction(projectId));

    saveRequest.then((value) => {
      error = value.payload?.error;

      if (!error) {
        Snackbar.show('Изменения успешно сохранены', 'success');
      }
    });

    if (!error) {
      changesRef.current = false;
    }

    return saveRequest;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, saveProjectScreen]);

  useEffect(() => {
    dispatch(updateAstOfSourcesActions(modelsFrom));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(modelsFrom)]);

  useEffect(() => {
    let request: { abort: (reason?: string) => void };

    if (dashboardComponentsLoaded) {
      request = dispatch(loadPageDataAction({ pageId: pageId || '', projectId: projectId || '' }));
    }

    return () => {
      request?.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, projectId, dashboardComponentsLoaded]);

  useEffect(() => {
    let request: { abort: (reason?: string) => void };

    if (dashboardComponentsLoaded) {
      request = dispatch(loadProjectFiltersAction({ projectId: projectId || '' }));
    }

    return () => {
      request?.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, dashboardComponentsLoaded]);

  useEffect(() => {
    if (hasChanges) {
      changesRef.current = true;
    }
  }, [hasChanges]);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (isViewer) {
      return false;
    }

    const notCurrent = currentLocation.pathname !== nextLocation.pathname,
      notBoard = nextLocation.pathname.search(RoutesURL.board) === -1;

    return (hasChanges || changesRef.current) && notCurrent && notBoard;
  });

  useEffect(() => {
    if (blocker.state === 'blocked') {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Да',
          cancelButtonText: 'Нет',
          subTitleText: 'На странице остались несохраненные данные. Сохранить их перед уходом?',
          onConfirm: async () => {
            await saveWorkspaceSettings();
            blocker.proceed();
          },
          onCancel: () => {
            dispatch(saveSettingSnapshotToLocalStorage(projectId || ''));
            blocker.proceed();
          },
          titleText: 'Сохранение данных',
        }),
      );
      changesRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker, saveWorkspaceSettings]);

  useEffect(() => {
    return () => {
      saveRequest?.abort();
    };
  }, []);

  useEffect(() => {
    if (lastSettingTimestamp && timestamp && isBefore(lastSettingTimestamp, timestamp)) {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Да',
          cancelButtonText: 'Нет',
          subTitleText: 'В памяти вашего браузера обнаружена более новая версия проекта. Восстановить?',
          onConfirm: async () => {
            dispatch(loadWorkspaceDataFromSnapshotAction(projectId || ''));
            dispatch(deleteSettingSnapshotFromLocalStorage(projectId || ''));
          },
          onCancel: () => {
            dispatch(deleteSettingSnapshotFromLocalStorage(projectId || ''));
          },
          titleText: 'Сохранение данных',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSettingTimestamp, timestamp]);

  useEffect(() => {
    if (lastSettingTimestamp && timestamp && isAfter(lastSettingTimestamp, timestamp)) {
      dispatch(deleteSettingSnapshotFromLocalStorage(projectId || ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSettingTimestamp, timestamp]);

  useBeforeUnload(
    useCallback(() => {
      if (hasChanges && !isViewer) {
        dispatch(saveSettingSnapshotToLocalStorage(projectId || ''));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId, hasChanges, isViewer]),
  );

  return (
    <FlexContainer position="fixed" top="0" height="100%" flexDirection="column" width="100%">
      <WorkSpaceHeader />
      <FlexContainer width="100%" flex="1" backgroundColor={`var(${ColorVarsEnum.Level_5_application})`}>
        <NotViewerWrapper>
          <LeftBar />
        </NotViewerWrapper>
        <Board />
        <NotViewerWrapper>
          <RightBar onClickSave={saveWorkspaceSettings} hasChanges={hasChanges || changesRef.current} />
        </NotViewerWrapper>
      </FlexContainer>
      <FilterInfluenceSettingsPopup
        isInfluenceFiltersMode={influenceFiltersMode}
        unsetInfluenceFiltersMode={unsetInfluenceMode}
      />
    </FlexContainer>
  );
};
