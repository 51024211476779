import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { enableFilterAction, updateEnabledFilterAction } from 'store/reducers/filters/actions';
import { disableFilterById } from 'store/reducers/filters';
import { usePrimitiveHaveBeenChanged } from 'utils/hooks/primitiveHaveBeenChanged';
import { PrimaryTextSpan } from 'styles/TextsElements';
import {
  UpdateEnableFilterDataFnType,
  useChangedFilterData,
  useEnabledFilter,
  useFilterQuery,
} from 'modules/filters/hooks/filter';
import { SingleEnabledFilterInterface, SingleFilterInterface } from 'store/reducers/filters/types';
import { FilterViewInterface } from 'modules/workspace/components/FilterArea/types';
import { SingleFilterWrapper } from './styles';
import { SingleFilterRadioSelector } from 'modules/ui/SingleFilterRadioSelector';
import isEqual from 'lodash/isEqual';

export const SingleFilterComponent: FilterViewInterface<SingleFilterInterface> = ({ data, whereQuery }) => {
  const {
    type,
    isGlobal,
    selectedData,
    position,
    isRealData,
    fictionalData,
    paddings,
    id,
    nameSettings,
    modelId,
    filterInfluences,
    sqlData,
    limit,
    overflowPosition,
    isMultipleMode,
    sortingStatus,
  } = data;

  const { incisionRequest } = sqlData;

  const [activeValues, setActiveValues] = useState<string[]>([]);

  const { dispatch, filterValues, modelIdValue } = useFilterQuery({
    nameSettings,
    id,
    modelId,
    whereQuery,
    sqlData,
    sortingStatus,
    limit,
    incisionRequest,
  });

  const updateEnableFilterDataFn: UpdateEnableFilterDataFnType<SingleEnabledFilterInterface> = (enableFilterData) => {
    if (enabledFilter?.id && enableFilterData.selectedValues.length) {
      dispatch(
        updateEnabledFilterAction<SingleEnabledFilterInterface>({
          id: enabledFilter?.id,
          data: { ...enableFilterData },
        }),
      );
    }
  };

  const { enabledFilter, enableFilterData } = useEnabledFilter({
    id,
    type,
    modelIdValue,
    filterInfluences,
    selectedValues: activeValues,
    isRealData,
    isGlobal,
    nameSettings,
    updateEnableFilterDataFn,
    incisionRequest,
  });

  const { isRealDataIsChanged, fieldNameIsChanged } = useChangedFilterData({ nameSettings, isRealData });

  const { value: overflowValue } = overflowPosition;

  const filterOptions = useMemo(() => {
    const data = isRealData ? selectedData : fictionalData;

    return data.reduce<SettingsRadioItem<string>[]>((result, value) => {
      if (value) {
        const notIncludesToValues = isRealData && !filterValues.find(({ value: filterValue }) => filterValue === value);

        return [...result, { value, label: value, disabled: notIncludesToValues }];
      }

      return result;
    }, []);
  }, [selectedData, isRealData, fictionalData, filterValues]);

  const filterGap = useMemo(() => {
    if (!paddings) return undefined;

    const { vertical, horizontal } = paddings;

    return `${vertical}px ${horizontal}px`;
  }, [paddings]);

  const activeRadioValues = useMemo(() => {
    return activeValues.map((value) => ({ value, label: value }));
  }, [activeValues]);

  const activeValuesAreIncluded = useMemo(
    () => activeValues.every((value) => selectedData.includes(value)),
    [selectedData, activeValues],
  );
  const activeValuesAreIncludedIsChanged = usePrimitiveHaveBeenChanged(activeValuesAreIncluded);

  /* To clear the filter after changing isRealData or nameSettings.fieldName */
  useEffect(() => {
    if (
      isRealDataIsChanged ||
      fieldNameIsChanged ||
      (activeValuesAreIncludedIsChanged && !activeValuesAreIncluded && isRealData)
    ) {
      setActiveValues([]);
      if (enabledFilter) {
        dispatch(disableFilterById(enabledFilter.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRealDataIsChanged, fieldNameIsChanged, activeValuesAreIncludedIsChanged, activeValuesAreIncluded]);

  const initialActiveValues = useMemo(() => {
    if (enabledFilter && enabledFilter?.selectedValues?.length > 0) {
      return enabledFilter.selectedValues;
    }
    return [];
  }, [enabledFilter]);

  // /* Restore active filter state */
  useMemo(() => {
    if (!isEqual(initialActiveValues, activeValues)) {
      setActiveValues(initialActiveValues);
    }

    if (!enabledFilter?.selectedValues?.length && !isEqual(enabledFilter?.selectedValues, activeValues)) {
      setActiveValues([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialActiveValues]);

  /* Enable Filter and Disable by click to Save button */
  const onEnableFilter = useCallback(
    ({ value }: SettingsRadioItem<string>) => {
      setActiveValues((currentActiveValues) => {
        let newActiveValues;

        if (isMultipleMode) {
          const valueExists = currentActiveValues.includes(value);
          newActiveValues = valueExists ? currentActiveValues.filter((v) => v !== value) : [...currentActiveValues, value];
        } else {
          newActiveValues = currentActiveValues.includes(value) ? [] : [value];
        }

        if (!newActiveValues.length && enabledFilter) {
          dispatch(disableFilterById(enabledFilter.id));
        } else {
          const filterData = { ...enableFilterData, selectedValues: newActiveValues };
          enabledFilter
            ? dispatch(updateEnabledFilterAction({ id: enabledFilter.id, data: filterData }))
            : dispatch(enableFilterAction({ ...filterData }));
        }

        return newActiveValues;
      });
    },
    [isMultipleMode, enabledFilter, enableFilterData, dispatch],
  );

  return (
    <SingleFilterWrapper position={position}>
      {filterOptions.length === 0 ? (
        <PrimaryTextSpan lineHeight="12px" marginBottom="8px">
          Выберите фильтр
        </PrimaryTextSpan>
      ) : (
        <SingleFilterRadioSelector
          overflowStyles={overflowValue}
          gapStyles={filterGap}
          position={position}
          size="large"
          onChange={onEnableFilter}
          options={filterOptions}
          activeValues={activeRadioValues}
        />
      )}
    </SingleFilterWrapper>
  );
};

export const SingleFilter = memo(SingleFilterComponent) as FilterViewInterface;
