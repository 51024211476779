import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { DefaultVisualisationsTypeProps } from 'assets/types';

export const LineAndBarWithDeviation: FC<DefaultVisualisationsTypeProps> = ({
  backgroundFill = `var(${ColorVarsEnum.Level_4_widget})`,
  fillDefault = `var(${ColorVarsEnum.Level_1})`,
  stroke = `var(${ColorVarsEnum.Accent})`,
  symbolSize = 70,
}) => {
  return (
    <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
      <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="70" height="70" rx="5" fill={backgroundFill} />
        <rect opacity="0.75" x="18" y="43" width="6" height="10" fill={fillDefault} />
        <rect opacity="0.35" x="12" y="36" width="6" height="17" fill={fillDefault} />
        <rect opacity="0.35" x="46" y="36" width="6" height="17" fill={fillDefault} />
        <rect opacity="0.75" x="52" y="26" width="6" height="27" fill={fillDefault} />
        <rect opacity="0.75" x="35" y="33" width="6" height="20" fill={fillDefault} />
        <rect opacity="0.35" x="29" y="40" width="6" height="13" fill={fillDefault} />
        <rect opacity="0.15" x="8" y="57" width="54" height="3" fill={fillDefault} />
        <path d="M11.5 20H25.5V25H44V14H57" stroke={stroke} strokeWidth="3" strokeLinecap="round" />
      </svg>
    </FlexContainer>
  );
};
