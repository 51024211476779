import React from 'react';
import { useGetActiveFilter } from 'modules/filters/hooks/getActiveFilter';
import {
  onFilterElementsChange,
  onModelIdChange,
  onRealDataChange,
  onSqlDataChange,
  onPositionChange,
} from 'modules/settingsContainer/common/data/DefaultFilterEventSettings/constants';
import { FilterIncisionsSettings } from 'modules/settingsContainer/FIlterIncisionsSettings';
import { useDataSettingsMetric } from 'modules/visualisations/hooks/dataSettingsMetric';
import { sqlParser } from 'utils/SQL/genereteAst';
import { RealDataSettings } from 'modules/settingsContainer/common/data/RealDataSettings';
import { ModelSelectorSettings } from 'modules/settingsContainer/common/ModelSelectorSettings';
import { SqlDataRequestInterface } from 'types/store';
import { SqlFilterSettings } from 'modules/settingsContainer/common/data/SqlFilterSettings';
import { NameFilterSettings } from 'modules/settingsContainer/NameFilterSettings';
import { updateFilterAction } from 'store/reducers/filters/actions';
import { FilterNameSettingsInterface } from 'store/reducers/filters/types';
import { useAppDispatch } from 'store';
import { PositionSettings } from '../../../../settingsContainer/common/view/PositionSettings';

export const DataTab = () => {
  const dispatch = useAppDispatch();
  const { data, tableFields, modelIdValue } = useGetActiveFilter({ type: 'date' });
  const filteredTableFields = tableFields.filter(({ type }) => type.indexOf('Date') !== -1);
  const { modelMetaData } = useDataSettingsMetric(data.modelId);

  const { isRealData, nameSettings, fictionalData, sqlData, position } = data;
  const { fieldName } = nameSettings;

  const onSaveSql = ({ incisionRequest, filterAndGroupRequest }: SqlDataRequestInterface) => {
    const isValidIncisionAst = !incisionRequest ? true : sqlParser.astify(`SELECT ${incisionRequest}`);
    const isValidFilterAst = sqlParser.astify(`SELECT * ${filterAndGroupRequest}`);

    if (isValidIncisionAst && isValidFilterAst) {
      const fieldNameMatch = filteredTableFields.some((field) => field.value === incisionRequest);

      if (fieldNameMatch) {
        onFilterElementsChange({ fictionalData, nameSettings: { ...nameSettings, fieldName: incisionRequest } });
      }

      if (!incisionRequest && incisionRequest !== fieldName) {
        onFilterElementsChange({ fictionalData, nameSettings: { ...nameSettings, fieldName: null } });
      }

      onSqlDataChange({
        sqlData: {
          incisionRequest,
          filterAndGroupRequest,
        },
      });
    }
  };

  const onNameSettingsChange = (nameSettings: FilterNameSettingsInterface['nameSettings']) =>
    dispatch(updateFilterAction({ nameSettings }));

  return (
    <>
      <RealDataSettings value={isRealData} onChange={onRealDataChange} />
      <ModelSelectorSettings value={modelIdValue} onChange={onModelIdChange} />
      {data.isRealData && (
        <FilterIncisionsSettings
          onChange={onFilterElementsChange}
          isRealData={isRealData}
          options={filteredTableFields}
          value={{ fictionalData, nameSettings }}
          onChangeSqlData={onSaveSql}
          modelMetaData={modelMetaData}
          data={data}
        />
      )}
      <NameFilterSettings isRealData={isRealData} value={nameSettings} onChange={onNameSettingsChange} />
      <SqlFilterSettings sqlData={sqlData} onSave={onSaveSql} modelMetaData={modelMetaData} fieldName={fieldName} />
    </>
  );
};
