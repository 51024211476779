import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DefaultVisualisationsTypeProps } from 'assets/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const HyperlinkText: FC<DefaultVisualisationsTypeProps> = ({
  backgroundFill = `var(${ColorVarsEnum.Level_4_widget})`,
  fill = `var(${ColorVarsEnum.Accent})`,
  fillDefault = `var(${ColorVarsEnum.Level_1})`,
  symbolSize = 70,
}) => {
  return (
    <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
      <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="70" height="70" rx="5" fill={backgroundFill} />
        <path d="M12.0987 27.0043V26H8V32.2222H9.28326V27.0043H12.0987Z" fill={fill} />
        <path
          d="M14.8283 27.5983L13.2704 30.5043V27.5983H12.0343V32.2222H13.2704L14.8283 29.312V32.2222H16.0644V27.5983H14.8283Z"
          fill={fill}
        />
        <path d="M18.2489 28.5641H19.8026V32.2222H21.0429V27.5983H17.0129V32.2222H18.2489V28.5641Z" fill={fill} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.1631 32.1368C23.4492 32.2507 23.7725 32.3077 24.133 32.3077C24.4335 32.3077 24.701 32.2678 24.9356 32.188C25.1703 32.1054 25.3705 32 25.5365 31.8718C25.7053 31.7436 25.8383 31.6096 25.9356 31.4701L25.3348 30.8205C25.1945 30.9972 25.0272 31.1297 24.8326 31.2179C24.6409 31.3034 24.4335 31.3462 24.2103 31.3462C24.03 31.3462 23.8684 31.3148 23.7253 31.2521C23.5851 31.1895 23.4649 31.1011 23.3648 30.9872C23.2675 30.8733 23.1917 30.7378 23.1373 30.5812C23.1131 30.5072 23.0945 30.4302 23.0816 30.3499C23.0785 30.3303 23.0756 30.3105 23.0732 30.2906H26.0386V29.7778C26.0386 29.4387 25.9943 29.131 25.9056 28.8547C25.8169 28.5755 25.6853 28.3361 25.5107 28.1368C25.3391 27.9374 25.1259 27.7835 24.8712 27.6752C24.6166 27.5669 24.3248 27.5128 23.9957 27.5128C23.6581 27.5128 23.3548 27.5712 23.0858 27.688C22.8169 27.8019 22.588 27.9658 22.3991 28.1795C22.2132 28.3932 22.0701 28.6453 21.97 28.9359C21.8698 29.2265 21.8197 29.547 21.8197 29.8974V30.0684C21.8197 30.3761 21.8727 30.6667 21.9785 30.9402C22.0844 31.2109 22.2361 31.4487 22.4335 31.6538C22.6338 31.859 22.8769 32.0199 23.1631 32.1368ZM23.0883 29.4701C23.0949 29.4233 23.1027 29.3778 23.1116 29.3333C23.1488 29.1596 23.2031 29.0085 23.2747 28.8803C23.349 28.7521 23.4449 28.6524 23.5622 28.5812C23.6795 28.51 23.8197 28.4744 23.9828 28.4744C24.0677 28.4744 24.1457 28.4821 24.2171 28.4977C24.3071 28.5175 24.3863 28.5495 24.4549 28.594C24.578 28.6737 24.6695 28.7821 24.7296 28.9188C24.7926 29.0528 24.824 29.2051 24.824 29.3761V29.4701H23.0883Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.9785 31.8206V34H26.7425V27.5983H27.8884L27.9345 28.0534C28.0602 27.8993 28.2064 27.776 28.3734 27.6838C28.5822 27.5698 28.8269 27.5128 29.1073 27.5128C29.402 27.5128 29.6609 27.5684 29.8841 27.6795C30.1101 27.7906 30.299 27.9502 30.4506 28.1581C30.6052 28.3661 30.721 28.6139 30.7983 28.9017C30.8784 29.1895 30.9185 29.5085 30.9185 29.859V29.9487C30.9185 30.2849 30.8784 30.5968 30.7983 30.8846C30.721 31.1724 30.6066 31.4231 30.4549 31.6368C30.3033 31.8476 30.1144 32.0128 29.8884 32.1325C29.6652 32.2493 29.4077 32.3077 29.1159 32.3077C28.8326 32.3077 28.5865 32.2507 28.3777 32.1368C28.2277 32.0549 28.0946 31.9495 27.9785 31.8206ZM27.9785 30.8667C28.044 31.0044 28.137 31.1159 28.2575 31.2009C28.3948 31.2977 28.5751 31.3462 28.7983 31.3462C28.9614 31.3462 29.0987 31.3105 29.2103 31.2393C29.3219 31.1652 29.412 31.0641 29.4807 30.9359C29.5522 30.8077 29.6023 30.6596 29.6309 30.4915C29.6457 30.4127 29.657 30.3311 29.6648 30.2467C29.6737 30.1509 29.6781 30.0517 29.6781 29.9487V29.859C29.6781 29.777 29.6751 29.6976 29.6692 29.6211C29.6608 29.5142 29.6466 29.4125 29.6266 29.3162C29.5951 29.1481 29.5436 29.0015 29.4721 28.8761C29.4034 28.7507 29.3119 28.6538 29.1974 28.5855C29.0858 28.5143 28.9499 28.4786 28.7897 28.4786C28.6209 28.4786 28.4764 28.5057 28.3562 28.5598C28.2389 28.6139 28.1431 28.6923 28.0687 28.7949C28.0348 28.8416 28.0047 28.8924 27.9785 28.9474V30.8667Z"
          fill={fill}
        />
        <path
          d="M33.7209 31.3403C33.6835 31.3442 33.6449 31.3462 33.6052 31.3462C33.422 31.3462 33.2732 31.3092 33.1588 31.235C33.0444 31.1609 32.9542 31.0598 32.8884 30.9316C32.8545 30.8624 32.8263 30.7879 32.8041 30.7079C32.785 30.6397 32.7702 30.5676 32.7597 30.4915C32.7368 30.3263 32.7253 30.1538 32.7253 29.9744V29.8504C32.7253 29.6709 32.7368 29.5 32.7597 29.3376C32.7854 29.1724 32.8298 29.0256 32.8927 28.8974C32.9556 28.7664 33.0444 28.6639 33.1588 28.5897C33.2732 28.5128 33.4206 28.4744 33.6009 28.4744C33.7697 28.4744 33.9113 28.5128 34.0258 28.5897C34.1431 28.6639 34.2318 28.7635 34.2918 28.8889C34.3548 29.0143 34.3877 29.1538 34.3906 29.3077H35.5536C35.5508 28.9402 35.4664 28.6225 35.3004 28.3547C35.1373 28.084 34.9113 27.8761 34.6223 27.7308C34.3362 27.5855 34.0028 27.5128 33.6223 27.5128C33.2647 27.5128 32.9528 27.5726 32.6867 27.6923C32.4206 27.812 32.1989 27.9786 32.0215 28.1923C31.8441 28.4032 31.711 28.651 31.6223 28.9359C31.5336 29.2179 31.4893 29.5228 31.4893 29.8504V29.9744C31.4893 30.2991 31.5336 30.604 31.6223 30.8889C31.711 31.1709 31.8441 31.4188 32.0215 31.6325C32.1989 31.8433 32.4206 32.0085 32.6867 32.1282C32.9556 32.2479 33.2704 32.3077 33.6309 32.3077C33.9886 32.3077 34.3119 32.2365 34.6009 32.094C34.8899 31.9487 35.1202 31.7507 35.2918 31.5C35.4635 31.2464 35.5508 30.9573 35.5536 30.6325H34.3906C34.3877 30.7778 34.3519 30.9045 34.2833 31.0128C34.2174 31.1182 34.1259 31.2009 34.0086 31.2607C33.922 31.3028 33.8261 31.3292 33.7209 31.3403Z"
          fill={fill}
        />
        <path
          d="M38.5923 31.2607C38.475 31.3177 38.3405 31.3462 38.1888 31.3462C38.0057 31.3462 37.8569 31.3092 37.7425 31.235C37.6281 31.1609 37.5379 31.0598 37.4721 30.9316C37.4354 30.857 37.4055 30.776 37.3825 30.6887C37.3659 30.6262 37.3529 30.5604 37.3433 30.4915C37.3204 30.3263 37.309 30.1538 37.309 29.9744V29.8504C37.309 29.6709 37.3204 29.5 37.3433 29.3376C37.3691 29.1724 37.4135 29.0256 37.4764 28.8974C37.5174 28.8121 37.5694 28.7388 37.6323 28.6775C37.6659 28.6448 37.7027 28.6155 37.7425 28.5897C37.8569 28.5128 38.0043 28.4744 38.1845 28.4744C38.3534 28.4744 38.495 28.5128 38.6094 28.5897C38.7268 28.6639 38.8155 28.7635 38.8755 28.8889C38.9385 29.0143 38.9714 29.1538 38.9742 29.3077H40.1373C40.1345 28.9402 40.0501 28.6225 39.8841 28.3547C39.721 28.084 39.495 27.8761 39.206 27.7308C38.9199 27.5855 38.5865 27.5128 38.206 27.5128C37.8483 27.5128 37.5365 27.5726 37.2704 27.6923C37.0043 27.812 36.7826 27.9786 36.6052 28.1923C36.4277 28.4032 36.2947 28.651 36.206 28.9359C36.1173 29.2179 36.073 29.5228 36.073 29.8504V29.9744C36.073 30.2991 36.1173 30.604 36.206 30.8889C36.2947 31.1709 36.4277 31.4188 36.6052 31.6325C36.7826 31.8433 37.0043 32.0085 37.2704 32.1282C37.5393 32.2479 37.8541 32.3077 38.2146 32.3077C38.5723 32.3077 38.8956 32.2365 39.1845 32.094C39.4735 31.9487 39.7039 31.7507 39.8755 31.5C40.0472 31.2464 40.1345 30.9573 40.1373 30.6325H38.9742C38.9731 30.6928 38.9662 30.75 38.9536 30.804C38.936 30.8799 38.9071 30.9495 38.867 31.0128C38.8011 31.1182 38.7096 31.2009 38.5923 31.2607Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.0558 28.9701H42.176V27.5983H40.9356V32.2222H43.0558C43.3734 32.2222 43.6567 32.1823 43.9056 32.1026C44.1545 32.0228 44.3648 31.9103 44.5365 31.765C44.7082 31.6197 44.8384 31.4487 44.927 31.2521C45.0186 31.0528 45.0644 30.8361 45.0644 30.6026C45.0644 30.2806 44.9843 29.9972 44.824 29.7521C44.6667 29.5071 44.4378 29.3162 44.1373 29.1795C43.8398 29.0399 43.4792 28.9701 43.0558 28.9701ZM42.176 29.9316V31.265H43.0558C43.2418 31.265 43.3906 31.235 43.5021 31.1752C43.6166 31.1126 43.6996 31.0314 43.7511 30.9316C43.8054 30.8319 43.8326 30.7237 43.8326 30.6068C43.8326 30.4844 43.8054 30.3733 43.7511 30.2735C43.6996 30.1709 43.6166 30.0883 43.5021 30.0256C43.3906 29.963 43.2418 29.9316 43.0558 29.9316H42.176Z"
          fill={fill}
        />
        <path d="M47.0472 32.2222V27.5983H45.8112V32.2222H47.0472Z" fill={fill} />
        <path
          d="M49.8283 28.5641H50.927V32.2222H52.1631V27.5983H48.6652L48.5794 29.5726C48.5708 29.8034 48.5551 30.0071 48.5322 30.1838C48.5122 30.3604 48.485 30.5143 48.4506 30.6453C48.4306 30.7202 48.4076 30.7878 48.3817 30.848C48.3633 30.891 48.3433 30.9303 48.3219 30.9658C48.2732 31.0485 48.2132 31.1126 48.1416 31.1581C48.073 31.2009 47.9929 31.2265 47.9013 31.235L47.7425 31.2521L47.7554 32.2222H48.133C48.3305 32.2222 48.5107 32.1966 48.6738 32.1453C48.8398 32.094 48.9843 32.0128 49.1073 31.9017C49.2332 31.7878 49.3405 31.641 49.4292 31.4615C49.5208 31.2821 49.5937 31.0656 49.6481 30.812C49.7024 30.5584 49.7382 30.265 49.7554 29.9316L49.8283 28.5641Z"
          fill={fill}
        />
        <path
          d="M54.4378 29.3547V27.5983H53.2017V32.2222H54.4378V30.4915H54.9325L55.9442 32.2222H57.6009L55.9487 29.7771L57.4549 27.5983H55.8884L54.8884 29.3547H54.4378Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.62 31.7881C60.5761 31.8387 60.5283 31.888 60.4764 31.9359C60.3562 32.0442 60.2117 32.1339 60.0429 32.2051C59.8741 32.2735 59.6738 32.3077 59.4421 32.3077C59.1474 32.3077 58.8798 32.2493 58.6395 32.1325C58.402 32.0128 58.2132 31.849 58.073 31.641C57.9356 31.4302 57.867 31.1908 57.867 30.9231C57.867 30.681 57.9127 30.4658 58.0043 30.2778C58.0959 30.0897 58.2303 29.9316 58.4077 29.8034C58.4961 29.7392 58.5952 29.6828 58.705 29.6343C58.819 29.5839 58.9445 29.5419 59.0815 29.5085C59.3505 29.4402 59.6624 29.406 60.0172 29.406H60.5708V29.1197C60.5708 28.9716 60.5465 28.8447 60.4979 28.7393C60.4492 28.631 60.3734 28.547 60.2704 28.4872C60.1702 28.4274 60.0401 28.3974 59.8798 28.3974C59.7425 28.3974 59.6238 28.4216 59.5236 28.4701C59.4235 28.5156 59.3462 28.5827 59.2918 28.6709C59.2713 28.7033 59.2546 28.7377 59.2419 28.7746C59.2208 28.8351 59.2103 28.9017 59.2103 28.9744H57.9742C57.9742 28.7778 58.02 28.5912 58.1116 28.4145C58.2032 28.2378 58.3362 28.0827 58.5107 27.9487C58.6853 27.812 58.8927 27.7051 59.133 27.6282C59.3762 27.5513 59.6481 27.5128 59.9485 27.5128C60.309 27.5128 60.6295 27.5726 60.9099 27.6923C61.1903 27.812 61.4106 27.9915 61.5708 28.2308C61.7339 28.4701 61.8154 28.7692 61.8154 29.1282V31.1068C61.8154 31.3604 61.8312 31.5684 61.8627 31.7308C61.8941 31.8904 61.9399 32.0299 62 32.1496V32.2222H60.7511C60.6948 32.1048 60.6512 31.9602 60.62 31.7881ZM60.5708 30.9153C60.5551 30.9446 60.5365 30.9742 60.515 31.0043C60.4406 31.1126 60.3348 31.2037 60.1974 31.2778C60.0601 31.3519 59.8984 31.3889 59.7124 31.3889C59.658 31.3889 59.6067 31.3843 59.5584 31.3754C59.4976 31.3642 59.4416 31.3459 59.3906 31.3205C59.299 31.272 59.2289 31.2066 59.1803 31.1239C59.1316 31.0385 59.1073 30.943 59.1073 30.8376C59.1073 30.7322 59.1245 30.6353 59.1588 30.547C59.196 30.4587 59.2532 30.3818 59.3305 30.3162C59.4077 30.2479 59.5064 30.1951 59.6266 30.1581C59.7468 30.1211 59.8884 30.1026 60.0515 30.1026H60.5708V30.9153Z"
          fill={fill}
        />
        <rect x="8" y="35" width="23" height="2" fill={fill} />
        <rect x="37" y="35" width="25" height="2" fill={fill} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.3307 31C31.1984 31 29.4698 32.7286 29.4698 34.8609V42.8134C28.1894 42.2279 26.6338 42.3664 25.4567 43.3032C23.7883 44.6311 23.5123 47.0601 24.8402 48.7285L30.2812 55.5648C30.7352 56.1352 31.3203 56.5445 31.9599 56.7811C32.3872 56.9434 32.8498 57.0318 33.3307 57.0318C33.3538 57.0318 33.3768 57.0316 33.3997 57.0312C33.423 57.0316 33.4464 57.0318 33.4698 57.0318L46.5431 57.0312C48.7522 57.0312 50.5431 55.2403 50.5431 53.0312V43.01C50.5431 40.8162 48.7771 39.035 46.5892 39.0103L37.1917 38.0932V34.8609C37.1917 32.7286 35.4631 31 33.3307 31ZM37.1917 45.0078V40.1006L40 40.343V45H42V40.5156L44.5 40.7313V45H46.5V40.9039V41.01H46.5431C47.6477 41.01 48.5431 41.9054 48.5431 43.01V53.0312C48.5431 54.1358 47.6477 55.0312 46.5431 55.0312L33.4698 55.0318C33.454 55.0318 33.4382 55.0316 33.4225 55.0313L33.3992 55.0307L33.3759 55.0313C33.3609 55.0316 33.3459 55.0318 33.3307 55.0318C33.0952 55.0318 32.8721 54.9885 32.6674 54.9104L32.6564 54.9063C32.3485 54.7929 32.0669 54.5969 31.8461 54.3194L26.405 47.483C25.765 46.6789 25.8981 45.5081 26.7022 44.8681C27.5064 44.2281 28.6771 44.3611 29.3171 45.1653L31.4698 47.87V34.8609C31.4698 33.8332 32.303 33 33.3307 33C34.3585 33 35.1917 33.8332 35.1917 34.8609V45.0078H37.1917Z"
          fill={fillDefault}
        />
        <path d="M49 59H32V61H49V59Z" fill={fillDefault} />
      </svg>
    </FlexContainer>
  );
};
