import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { ColorAndImageByType } from 'store/reducers/visualisations/types';

export const conditionDefault: SettingsRadioItem<ColorAndImageByType> = {
  value: 'default',
  label: 'Вручную',
};

export const conditionValue: SettingsRadioItem<ColorAndImageByType> = {
  value: 'value',
  label: 'По значению',
};

export const conditionByCondition: SettingsRadioItem<ColorAndImageByType> = {
  value: 'condition',
  label: 'По условию',
};

export const conditionIncision = [conditionDefault, conditionByCondition];
export const conditionIncisionForPivot = [conditionDefault];

export const conditionIndicator = [conditionDefault, conditionValue, conditionByCondition];
export const conditionIndicatorForPivot = [conditionDefault, conditionValue];
