import { useLocalValues } from 'utils/hooks/localValues';
import React, { useEffect } from 'react';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { FlexContainer } from 'styles/FlexContainer';
import { AddColorButton } from 'modules/ui/buttons/AddColorButton';
import { SQLTextArea } from 'components/shared/SQLTextArea';
import { OnValueChange } from 'types/global';
import { DynamicMarkerByConditionInterface, MarkersEnum, MarkersType } from 'store/reducers/visualisations/types';
import Select from 'modules/ui/Select';
import { markersOptions } from '../../constants';
import { PickerWrapper } from 'components/shared/PickerWrapper';

export const FormByCondition = ({ value, onChange }: OnValueChange<DynamicMarkerByConditionInterface>) => {
  const { localValues, setLocalValues, onSave, onCancel, hasChanges } = useLocalValues({ value, onChange });

  const { markers, sqlCondition } = localValues;

  useEffect(() => {
    setLocalValues(value);
  }, [setLocalValues, value]);

  const onAddMarker = () =>
    setLocalValues((prev) => ({
      ...prev,
      markers: [...prev.markers, MarkersEnum.SIMSMARKER],
    }));

  const onDeleteMarker = (deletedIndex: number) => () =>
    setLocalValues((prev) => ({
      ...prev,
      markers: prev.markers.filter((_, index) => index !== deletedIndex),
    }));

  const onChangeMarker = (markerIndex: number, markerType: MarkersType) => {
    setLocalValues((prev) => {
      const newListMarkers = prev.markers.map((marker, index) => {
        if (markerIndex === index) {
          return markerType;
        }
        return marker;
      });

      return {
        ...prev,
        markers: newListMarkers,
      };
    });
  };

  const onSqlChange = (sql: string) =>
    setLocalValues((localValues) => ({
      ...localValues,
      sqlCondition: sql || null,
    }));

  return (
    <>
      <GroupContainerSettings>
        <ElementContainerSettings>
          <FlexContainer padding="24px 8px" gap="20px" width="100%" flexWrap="wrap">
            {markers.map((markerName, index) => (
              <PickerWrapper key={`MarkerSQL${markerName}${index}`} name={index + 1} onDelete={() => onDeleteMarker(index)}>
                <Select
                  name={`MarkerSQL${markerName}${index}`}
                  needBackground={false}
                  width="auto"
                  value={markerName}
                  options={markersOptions}
                  onChange={(marker) => onChangeMarker(index, marker)}
                  needBorder={false}
                />
              </PickerWrapper>
            ))}
            <AddColorButton onAdd={onAddMarker} />
          </FlexContainer>
        </ElementContainerSettings>
      </GroupContainerSettings>
      <SQLTextArea
        sqlCondition={sqlCondition}
        onChange={onSqlChange}
        onSave={onSave}
        onCancel={onCancel}
        hasChanges={hasChanges}
      />
    </>
  );
};
