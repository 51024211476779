import { TaskRadio } from 'components/TaskRadio';
import { defaultCronDate } from 'constants/constants';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { Button, Switcher } from 'modules/ui';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import React, { useEffect, useId, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch } from 'store';
import { TaskInterface } from 'store/reducers/adminTasks/types';
import { ModalComponentType } from 'store/reducers/modals/types';
import { createProjectTaskAction, setProjectTaskAction, updateProjectTaskAction } from 'store/reducers/projectSettings/actions';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { ModeForm } from 'types/store';
import { Form } from './styles';
import { v4 } from 'uuid';

type WorkSpaceTaskModalProps = {
  mode: ModeForm;
  projectId: string;
  task?: TaskInterface;
};

export const WorkSpaceTaskModal: ModalComponentType<WorkSpaceTaskModalProps> = ({ mode, task, onClose, projectId }) => {
  const uniqueId = useId();
  const dispatch = useAppDispatch();

  const [uniqueIdForCron, setUniqueIdForCron] = useState<string>(v4());
  const isAdd = mode === 'add';

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, isSubmitting },
  } = useForm<FastBoard.API.CronJobCreateDTO>({
    mode: 'onSubmit',
    defaultValues: {
      isActive: true,
      cron: defaultCronDate,
    },
  });

  useEffect(() => {
    setUniqueIdForCron(v4());
    setValue('cron', task?.cron || defaultCronDate);
    setValue('isActive', task?.isActive || false);
  }, [task, setValue]);

  const onSubmit: SubmitHandler<FastBoard.API.CronJobCreateDTO> = async ({ cron, isActive }) => {
    if (!isDirty || isSubmitting) {
      return null;
    }

    let actionTaskRes;
    const params = { isActive, cron, id: projectId };

    try {
      if (isAdd) {
        actionTaskRes = await dispatch(createProjectTaskAction(params)).unwrap();
      }

      if (!isAdd) {
        actionTaskRes = await dispatch(updateProjectTaskAction(params)).unwrap();
      }

      actionTaskRes && dispatch(setProjectTaskAction(actionTaskRes));
      onClose && onClose();
    } catch (e) {
      return;
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay loading={isSubmitting} />

      <FlexContainer width="100%" alignItems="flex-start" flexDirection="column" height="100%" gap="32px" padding="12px">
        <Controller
          name="isActive"
          control={control}
          render={({ field }) => (
            <FlexContainer flexDirection="row" alignItems="center" gap="8px">
              <Switcher id={uniqueId} value={field.value} onClick={field.onChange} />

              <PrimaryTextParagraph lineHeight="14px" fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
                Активно
              </PrimaryTextParagraph>
            </FlexContainer>
          )}
        />

        <FlexContainer width="100%" flexDirection="column" height="100%" gap="32px">
          <Controller
            key={uniqueIdForCron}
            name="cron"
            control={control}
            render={({ field }) => <TaskRadio onChange={field.onChange} value={field.value} />}
          />
          <FlexContainer width="100%" justifyContent="flex-end" gap="8px">
            <Button text="Отмена" iconSize="normal" heightSize="normal" needBackground={false} onClick={onClose} />
            <Button
              text={isAdd ? 'Создать' : 'Изменить'}
              iconSize="normal"
              needBackground={true}
              heightSize="normal"
              type="submit"
            />
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </Form>
  );
};
