import React from 'react';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { sortOptions } from 'modules/settingsContainer/common/data/SortByColumnSettings/constants';
import { SortingInterface, SortType } from 'modules/visualisations/Table/visualisation/types';
import { NoopValueType } from 'types/global';
import { SortingValueEnum } from 'components/shared/SortingPanel/types';

type onChangeType = NoopValueType<{ type: SortType; isActive: boolean }>;

type SortByColumnSettingsProps = {
  onChange: onChangeType;
  value: SortingInterface;
  isActive: boolean;
};

export const SortByColumnSettings = ({ onChange, value, isActive }: SortByColumnSettingsProps) => {
  return (
    <MainContainerSettings
      titleText="Сортировать по этому столбцу"
      switcherState={isActive}
      switcherChange={() => onChange({ type: SortingValueEnum.DESC, isActive: !isActive })}
    >
      <GroupContainerSettings>
        <ElementContainerSettings>
          <SettingsRadio
            onChange={({ value: type }) => onChange({ type, isActive: true })}
            activeValue={getActiveRadioValue(sortOptions, value?.type || SortingValueEnum.DESC)}
            options={sortOptions}
          />
        </ElementContainerSettings>
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
