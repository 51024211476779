import { SettingsLayout } from 'modules/settingsContainer/SettingsLayout';
import { ViewTab } from 'modules/visualisations/Text/settings/ViewTab';
import { BackgroundTab } from '../BackgroundTab';
import { DataTab } from '../DataTab';

import { TextEventsComponent } from '../../../components/TextEventsComponent';
export const TextMainSettings = () => {
  return (
    <SettingsLayout
      ViewComponent={DataTab}
      BackgroundComponent={BackgroundTab}
      TextComponent={ViewTab}
      EventComponent={TextEventsComponent}
    />
  );
};
