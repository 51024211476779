import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DefaultVisualisationsTypeProps } from 'assets/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const MonthlyLineAndBar: FC<DefaultVisualisationsTypeProps> = ({
  backgroundFill = `var(${ColorVarsEnum.Level_4_widget})`,
  fillDefault = `var(${ColorVarsEnum.Level_1})`,
  symbolSize = 70,
}) => {
  return (
    <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
      <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="70" height="70" rx="5" fill={backgroundFill} />
        <rect opacity="0.75" x="12" y="39" width="6" height="14" fill={fillDefault} />
        <rect opacity="0.15" x="12" y="16" width="6" height="23" fill={fillDefault} />
        <rect opacity="0.15" x="22" y="16" width="6" height="10" fill={fillDefault} />
        <rect opacity="0.15" x="32" y="16" width="6" height="16" fill={fillDefault} />
        <rect opacity="0.15" x="42" y="16" width="6" height="5" fill={fillDefault} />
        <rect opacity="0.15" x="52" y="16" width="6" height="12" fill={fillDefault} />
        <rect opacity="0.75" x="22" y="26" width="6" height="27" fill={fillDefault} />
        <rect opacity="0.75" x="42" y="21" width="6" height="32" fill={fillDefault} />
        <rect opacity="0.75" x="52" y="28" width="6" height="25" fill={fillDefault} />
        <rect opacity="0.75" x="32" y="32" width="6" height="21" fill={fillDefault} />
        <rect opacity="0.15" x="8" y="57" width="54" height="3" fill={fillDefault} />
      </svg>
    </FlexContainer>
  );
};
