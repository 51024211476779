import { BooleanSettings } from 'modules/settingsContainer/common/BooleanSettings';
import { DefaultViewSettings } from 'modules/settingsContainer/common/view/DefaultViewSettings';
import {
  onAdaptiveChange,
  onBodySettingsChange,
  onHeaderSettingsChange,
  onSubtotalsSettingsChange,
  onTotalRowChange,
} from 'modules/visualisations/Table/settings/ViewTab/constants';
import React from 'react';
import { defaultTableViewSettings } from 'store/reducers/visualisations/constants';
import { TableDataSettings } from 'store/reducers/visualisations/types';
import { useGetActiveVisualisationSettings } from 'utils/hooks/visualisation/getActiveVisualisationSettings';
import { BodyStylesSettings } from './BodyStylesSettings';
import { GlobalStylesSettings } from './GlobalStylesSettings';
import { HeaderStylesSettings } from './HeaderStylesSettings';
import { TextBackgroundSettings } from '../../../../settingsContainer/common/view/TextBackgroundSettings';
import { onChangeBackground } from '../../../Text/settings/ViewTab/constants';

export const ViewTab = () => {
  const { viewSettings, positionConfig, dataSettings } = useGetActiveVisualisationSettings({
    defaultView: defaultTableViewSettings,
  });
  const { headerSettings, bodySettings, subtotalsSettings, totalRowSettings } = viewSettings;

  return (
    <>
      <DefaultViewSettings
        ExternalBackgroundSettings={
          <TextBackgroundSettings
            value={viewSettings.backgroundSettings}
            onChange={onChangeBackground}
            activeBackgraund={false}
          />
        }
        viewSettings={viewSettings}
        positionConfig={positionConfig}
        ThirdSlot={
          <>
            <BooleanSettings titleText="Адаптивная таблица" value={viewSettings.isAdaptive} onChange={onAdaptiveChange} />
            <GlobalStylesSettings title="Строка подитогов" value={subtotalsSettings} onChange={onSubtotalsSettingsChange} />
            <GlobalStylesSettings title="Строка итогов" value={totalRowSettings} onChange={onTotalRowChange} />
            <HeaderStylesSettings value={headerSettings} onChange={onHeaderSettingsChange} />
            <BodyStylesSettings
              dataSettings={dataSettings as TableDataSettings}
              value={bodySettings}
              onChange={onBodySettingsChange}
            />
          </>
        }
      />
    </>
  );
};
