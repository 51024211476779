import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { RightAndLeftType } from 'types/styles';
import {
  ConditionsMarkerEnum,
  ConditionsMarkerType,
  DynamicMarkerModeType,
  MarkersEnum,
  MarkersType,
} from 'store/reducers/visualisations/types';
import { SelectItemInterface } from 'modules/ui/Select';
import {
  CheckMarker,
  CircleMarker,
  CrossMarker,
  DownMarker,
  MinusMarker,
  PlusMarker,
  SimSMarker,
  SquareMarker,
  UpMarker,
} from 'assets/icons/markers';

export const dynamicsMarkerPositionOptions: SettingsRadioItem<RightAndLeftType>[] = [
  { value: 'left', label: 'Слева' },
  { value: 'right', label: 'Справа' },
];

export const dynamicsMarkerModeOptions: SettingsRadioItem<DynamicMarkerModeType>[] = [
  { value: 'manual', label: 'По диапазонам' },
  { value: 'sql', label: 'Раздельно' },
];

export const markersOptions: SelectItemInterface<MarkersType>[] = [
  {
    name: '',
    Icon: UpMarker,
    value: MarkersEnum.UPMARKER,
  },
  {
    name: '',
    Icon: DownMarker,
    value: MarkersEnum.DOWNMARKER,
  },
  {
    name: '',
    Icon: SquareMarker,
    value: MarkersEnum.SQUAREMARKER,
  },
  {
    name: '',
    Icon: CircleMarker,
    value: MarkersEnum.CIRCLEMARKER,
  },
  {
    name: '',
    Icon: SimSMarker,
    value: MarkersEnum.SIMSMARKER,
  },
  {
    name: '',
    Icon: PlusMarker,
    value: MarkersEnum.PLUSMARKER,
  },
  {
    name: '',
    Icon: MinusMarker,
    value: MarkersEnum.MINUSMARKER,
  },
  {
    name: '',
    Icon: CheckMarker,
    value: MarkersEnum.CHECKMARKER,
  },
  {
    name: '',
    Icon: CrossMarker,
    value: MarkersEnum.CROSSMARKER,
  },
];

export const conditionsMarkerDynamicOptions: SelectItemInterface<ConditionsMarkerType>[] = [
  {
    name: '=',
    value: ConditionsMarkerEnum.EQUAL,
  },
  {
    name: '<',
    value: ConditionsMarkerEnum.LESS,
  },
  {
    name: '<=',
    value: ConditionsMarkerEnum.LESSOREQUAL,
  },
  {
    name: '>=',
    value: ConditionsMarkerEnum.MOREOREQUAL,
  },
  {
    name: '>',
    value: ConditionsMarkerEnum.MORE,
  },
];
