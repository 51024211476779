import { Currency, VisualisationOperationTypesType } from 'store/reducers/visualisations/types';
import { SelectItemInterface } from 'modules/ui/Select';
import { JoinTypeType, OperatorRelationType } from 'store/reducers/models/types';

export const MOCK_GRAPHIC_OPERATION_TYPE_EXTENDED: Array<{ name: string; value: VisualisationOperationTypesType }> = [
  { name: 'Сумма', value: 'sum' },
  { name: 'Количество', value: 'count' },
  { name: 'Среднее', value: 'avg' },
  { name: 'Минимальное', value: 'min' },
  { name: 'Максимальное', value: 'max' },
  { name: 'По выражению', value: 'other' },
];

export const MOCK_JOIN_TYPE: Array<SelectItemInterface<JoinTypeType>> = [
  { name: 'INNER', value: 'INNER' },
  { name: 'FULL', value: 'FULL' },
  { name: 'LEFT', value: 'LEFT' },
  { name: 'RIGHT', value: 'RIGHT' },
];

export const MOCK_OPERATION_TYPE: Array<SelectItemInterface<OperatorRelationType>> = [
  { name: '=', value: '=' },
  { name: '>', value: '>' },
  { name: '≥', value: '≥' },
  { name: '<', value: '<' },
  { name: '≤', value: '≤' },
  { name: '≠', value: '≠' },
];

export const MOCK_MONTH_NAME = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export interface IDictionary {
  [key: number]: string;
}

export const DICTIONARY_OF_WEEK_LONG: IDictionary = {
  1: 'Понедельник',
  2: 'Вторник',
  3: 'Среда',
  4: 'Четверг',
  5: 'Пятница',
  6: 'Суббота',
  7: 'Воскресение',
};

export const DICTIONARY_OF_WEEK_SHORT: IDictionary = {
  1: 'Пн',
  2: 'Вт',
  3: 'Ср',
  4: 'Чт',
  5: 'Пт',
  6: 'Сб',
  7: 'Вс',
};

export const DICTIONARY_OF_MONTH_LONG: IDictionary = {
  1: 'Январь',
  2: 'Февраль',
  3: 'Март',
  4: 'Апрель',
  5: 'Май',
  6: 'Июнь',
  7: 'Июль',
  8: 'Август',
  9: 'Сентябрь',
  10: 'Октябрь',
  11: 'Ноябрь',
  12: 'Декабрь',
};

export const DICTIONARY_OF_MONTH_SHORT: IDictionary = {
  1: 'Янв',
  2: 'Фев',
  3: 'Март',
  4: 'Апр',
  5: 'Май',
  6: 'Июнь',
  7: 'Июль',
  8: 'Авг',
  9: 'Сен',
  10: 'Окт',
  11: 'Ноя',
  12: 'Дек',
};

export const DICTIONARY_OF_QUARTER_LONG: IDictionary = {
  1: '1 квартал',
  2: '2 квартал',
  3: '3 квартал',
  4: '4 квартал',
};

export const DICTIONARY_OF_QUARTER_SHORT: IDictionary = {
  1: '1 кв',
  2: '2 кв',
  3: '3 кв',
  4: '4 кв',
};

export const CURRENCY: Array<{ name: string; value: Currency }> = [
  { name: '₽', value: 'RUB' },
  { name: '$', value: 'USD' },
  { name: '€', value: 'EUR' },
];
