import Select, { SelectItemInterface } from 'modules/ui/Select';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { FlexContainer } from 'styles/FlexContainer';
import React from 'react';
import { NoopValueType } from 'types/global';
import { ConditionsMarkerType } from 'store/reducers/visualisations/types';

interface ConditionAndValueMarkerProps {
  value: number;
  condition: ConditionsMarkerType;
  onChangeCondition: NoopValueType<ConditionsMarkerType>;
  onChangeValue: NoopValueType<number>;
  options: SelectItemInterface[];
}

export const ConditionAndValueMarker = ({
  value,
  condition,
  onChangeCondition,
  onChangeValue,
  options,
}: ConditionAndValueMarkerProps) => {
  return (
    <FlexContainer gap="4px">
      <Select
        name="condition"
        needBackground={false}
        width="100%"
        value={condition}
        options={options}
        onChange={onChangeCondition}
      />
      <NumberPropertiesBlock name="value" value={value} onChange={onChangeValue} width="50px" padding="0" />
    </FlexContainer>
  );
};
