import { HeaderSettingsAreaLayout, HeaderSettingsAreaLayoutProps } from 'modules/settingsContainer/FieldSettingsArea';
import Popper, { PopperProps } from '@mui/material/Popper/Popper';
import React from 'react';
import { FC } from 'types/global';

interface FixedAreaSettingsProps extends HeaderSettingsAreaLayoutProps, Pick<PopperProps, 'anchorEl' | 'open'> {}

export const FixedAreaSettings: FC<FixedAreaSettingsProps> = ({
  children,
  width,
  headerText,
  onClose,
  open,
  anchorEl,
  ...props
}) => (
  <Popper disablePortal open={open} anchorEl={anchorEl} popperOptions={{ placement: 'left-start', strategy: 'fixed' }}>
    <HeaderSettingsAreaLayout width={width} {...props} headerText={headerText} onClose={onClose}>
      {children}
    </HeaderSettingsAreaLayout>
  </Popper>
);
