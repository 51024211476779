import { SQLTextArea } from 'components/shared/SQLTextArea';
import { ColorValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { TRenderByConditionType } from 'modules/settingsContainer/common/WrapperBySettings/types';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { AddColorButton } from 'modules/ui/buttons/AddColorButton';
import { ColorByPicker } from 'modules/ui/ColorByPicker';
import React, { useEffect } from 'react';
import { ColorByConditionInterface } from 'store/reducers/visualisations/types';
import { FlexContainer } from 'styles/FlexContainer';
import { useLocalValues } from 'utils/hooks/localValues';

export const ColorByCondition: TRenderByConditionType<ColorByConditionInterface> = ({ metric: value, onChange }) => {
  const { localValues, setLocalValues, onSave, onCancel, hasChanges } = useLocalValues({ value, onChange });

  const { colors, sqlCondition } = localValues;

  useEffect(() => {
    setLocalValues(value);
  }, [setLocalValues, value]);

  const onAddColor = () =>
    setLocalValues((localValues) => ({
      ...localValues,
      colors: [
        ...colors,
        {
          value: null,
        },
      ],
    }));

  const onDeleteColor = (deletedIndex: number) => () =>
    setLocalValues((localValues) => ({
      ...localValues,
      colors: colors.filter((_, index) => index !== deletedIndex),
    }));

  const onChangeColor = (colorIndex: number) => (colorValue: ColorValuesByThemeType | null) => {
    setLocalValues((localValues) => ({
      ...localValues,
      colors: colors.map((color, index) => (index === colorIndex ? { ...color, value: colorValue } : color)),
    }));
  };

  const onSqlChange = (sql: string) =>
    setLocalValues((localValues) => ({
      ...localValues,
      sqlCondition: sql || null,
    }));

  return (
    <>
      <GroupContainerSettings>
        <ElementContainerSettings>
          <FlexContainer padding="24px 8px" gap="20px" width="100%" flexWrap="wrap">
            {colors.map(({ value }, index) => (
              <ColorByPicker
                value={value}
                onChange={onChangeColor(index)}
                name={index + 1}
                onDelete={onDeleteColor(index)}
                key={index}
              />
            ))}
            <AddColorButton onAdd={onAddColor} />
          </FlexContainer>
        </ElementContainerSettings>
      </GroupContainerSettings>
      <SQLTextArea
        sqlCondition={sqlCondition}
        onChange={onSqlChange}
        onSave={onSave}
        onCancel={onCancel}
        hasChanges={hasChanges}
      />
    </>
  );
};
