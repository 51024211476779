import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { goToBoardUrl } from 'constants/Routes';
import { MenuItemInterface } from 'modules/ui/menu/ContextMenu/types';
import { exportProjectAction, uploadProjectAvatarAction } from 'store/reducers/projectManager/actions';
import { useModalState } from 'utils/hooks/modalState';
import { openConfirmationModalAction } from 'store/reducers/modals/actions';
import { ProjectsSearchUI } from 'modules/ui/ManagerOrAdminComponentsUI/ProjectsSearchUI';
import { useModalActions } from 'utils/hooks/useModalActions';
import { useRole } from 'utils/hooks/useRole';
import { SortProject } from 'store/reducers/projectManager/types';
import { InformationBar } from 'modules/ui/ManagerOrAdminComponentsUI/InformationBarUI';
import { Tab } from 'modules/ui/tabs/types';
import { FlexContainer } from 'styles/FlexContainer';
import { loadSortSettings, saveSortSettings } from 'utils/SortSettingsStorage';
import {
  modalConfigs,
  SHORT_USER_DRAFT_PROJECT_SETTINGS,
} from 'components/admin/usersPage/elemets/DraftProjectsSearchList/constants';
import { getActiveUser, getActiveUserDraftProjectId, getUserDraftShortProject } from 'store/reducers/adminUsers/getters';
import {
  deleteByIdUserDraftProjectAction,
  deleteUserDraftProjectProjects,
  protectUserDraftProjectAction,
  renameDraftUserProjectAction,
  updateUserDraftProjectByIdAction,
} from 'store/reducers/adminUsers/actions';
import { setActiveUserDraftProjectId } from 'store/reducers/adminUsers';
import { useUserDraftProjectsList } from './hook/useUserDraftProjectsList';
import { InfoUserDraftProject } from 'components/admin/usersPage/elemets/DraftProjectsSearchList/InfoUserDraftProject';
import { HistoryVersionUserDraftProject } from 'components/admin/usersPage/elemets/DraftProjectsSearchList/HistoryVersionUserDraftProject';
import { ModalNameType } from 'components/admin/usersPage/elemets/DraftProjectsSearchList/types';
import { copyingUserDraftProject } from './Modals/CopyUserDraftProjectModal/constants';
import { moveDraftUserProject } from 'components/admin/usersPage/elemets/DraftProjectsSearchList/Modals/MoveDraftUserProjectModal/constants';
import { rollBackUserProjectProject } from './Modals/RollBackUserDraftProjectModal/constants';
import { infoUserDraftProject } from './Modals/InfoUserDraftProjectModal/constants';
import Snackbar from 'services/Snackbar';
import { AvatarProjectInterface } from 'types/types';

export const DraftProjectsSearchList = () => {
  const activeUserDraftProjectId = useSelector(getActiveUserDraftProjectId);

  const activeUser = useSelector(getActiveUser);
  const activeUserId = activeUser?.id || '';
  const shortUserDraftProject = useSelector(getUserDraftShortProject);
  const { isViewer } = useRole();

  const { openModal } = useModalActions<ModalNameType>(modalConfigs);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useModalState();
  const { flowProjectsList, loading } = useUserDraftProjectsList({ id: activeUserId, onClose });
  const [informationProject, setInformation] = useState<{ id: string; name: string } | null>(null);
  const [sortSettings, setSortSettings] = useState<SortProject>(shortUserDraftProject);

  const activeProject = useMemo(
    () => flowProjectsList?.find(({ id }) => id === activeUserDraftProjectId),
    [flowProjectsList, activeUserDraftProjectId],
  );
  const activeUserDraftProjectName = activeProject?.title;
  const isProtected = activeProject?.isProtected || false;

  const openFlowProject = useCallback(
    ({ projectId, pageId }: { projectId: string; pageId: string | null }) => navigate(goToBoardUrl(projectId, pageId || '0')),
    [navigate],
  );

  const onBoardAreaClick = (id: string | null) => {
    dispatch(setActiveUserDraftProjectId(id));
  };

  const onProtectProject = useCallback(
    async () => {
      try {
        if (activeUserDraftProjectId) {
          const resProtect = await dispatch(
            protectUserDraftProjectAction({ projectId: activeUserDraftProjectId, isProtected: !isProtected }),
          ).unwrap();

          if (resProtect && activeProject) {
            dispatch(updateUserDraftProjectByIdAction({ userDraftProject: { ...activeProject, isProtected: !isProtected } }));
          }
        }
      } catch (error) {
        console.error('Error ProtectProject:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeUserDraftProjectId, isProtected, activeProject],
  );

  const hasName = (name: string) => flowProjectsList.find(({ title }) => title === name);

  const onRenameProject = useCallback(
    async (title: string) => {
      if (!title) {
        return Snackbar.show('Поле «Название» обязательно для заполнения', 'error');
      }

      if (hasName(title)) {
        return Snackbar.show('У пользователя уже есть проект с таким названием. Измените название и повторите попытку', 'error');
      }

      try {
        if (activeUserDraftProjectId && activeUserDraftProjectName) {
          const resProtect = await dispatch(renameDraftUserProjectAction({ id: activeUserDraftProjectId, name: title })).unwrap();

          if (resProtect && activeProject) {
            dispatch(updateUserDraftProjectByIdAction({ userDraftProject: resProtect }));
          }
        }
      } catch (error) {
        console.error('Error onRenameProject', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeUserDraftProjectId, activeUserDraftProjectName, activeProject, isProtected],
  );

  const onRenameProjectModal = useCallback(
    async () => {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Переименовать',
          onConfirm: (title) => onRenameProject(title?.trim() || ''),
          valueInput: activeUserDraftProjectName,
          isRenamable: true,
          renamableTitle: 'Новое название проекта',
          titleText: 'Переименование проекта',
          width: '320px',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeUserDraftProjectName, onRenameProject],
  );

  const onDeleteProject = useCallback(
    async () => {
      try {
        if (activeUserDraftProjectId && activeUserDraftProjectName) {
          const resProtect = await dispatch(deleteUserDraftProjectProjects({ projectId: activeUserDraftProjectId })).unwrap();

          if (resProtect) {
            dispatch(deleteByIdUserDraftProjectAction(activeUserDraftProjectId));

            onClose();
          }
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeUserDraftProjectId, onClose, activeUserDraftProjectName],
  );

  const onDeleteProjectModal = useCallback(
    async () => {
      dispatch(
        openConfirmationModalAction({
          titleText: 'Удаление пректа',
          onConfirm: onDeleteProject,
          subTitleText: `Действительно удалить проект «${activeUserDraftProjectName}»?`,
          confirmationButtonText: 'Удалить',
          width: '320px',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, onDeleteProject, activeUserDraftProjectName],
  );

  const onChangeAvatar = useCallback(
    async ({ avatar, projectId }: AvatarProjectInterface) => {
      if (!projectId) return;

      const image = new FormData();
      image.append('avatar', avatar || '');

      const response = await dispatch(uploadProjectAvatarAction({ projectId, avatar: image })).unwrap();

      if (!response) return;

      dispatch(updateUserDraftProjectByIdAction({ userDraftProject: response }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleOpenModal = useCallback(
    (modalName: ModalNameType) => {
      openModal(modalName, {
        projectId: activeUserDraftProjectId || '',
        projectName: activeUserDraftProjectName || '',
        activeProject,
        activeFlowId: activeUserId || '',
        flowId: activeUserId || '',
        isProtected,
        onChangeAvatar,
      });
    },
    [openModal, onChangeAvatar, activeUserDraftProjectId, activeUserDraftProjectName, activeProject, activeUserId, isProtected],
  );

  const menuList: MenuItemInterface[] = useMemo(() => {
    if (isViewer) {
      return [
        {
          title: 'О проекте',
          onClick: () => handleOpenModal('infoUserDraftProject'),
        },
      ];
    }

    return [
      {
        title: 'Копировать',
        onClick: () => handleOpenModal(copyingUserDraftProject),
      },
      {
        disabled: isProtected,
        title: 'Переместить',
        onClick: () => handleOpenModal(moveDraftUserProject),
      },
      {
        disabled: isProtected,
        title: 'Откатить',
        onClick: () => handleOpenModal(rollBackUserProjectProject),
      },
      {
        title: isProtected ? 'Снять защиту' : 'Защитить',
        onClick: onProtectProject,
      },
      {
        disabled: isProtected,
        title: 'Переименовать',
        onClick: onRenameProjectModal,
      },
      {
        title: 'О проекте',
        onClick: () => handleOpenModal(infoUserDraftProject),
      },
      {
        disabled: isProtected,
        title: 'Удалить',
        onClick: onDeleteProjectModal,
      },
    ];
  }, [isViewer, handleOpenModal, isProtected, onDeleteProjectModal, onProtectProject, onRenameProjectModal]);

  const exportProject: MenuItemInterface[] | undefined = useMemo(() => {
    if (isViewer) {
      return;
    }
    return [
      {
        title: 'Экспортировать проект',
        onClick: () =>
          activeUserDraftProjectId &&
          dispatch(
            exportProjectAction({
              projectId: activeUserDraftProjectId,
              projectName: activeUserDraftProjectName,
            }),
          ),
      },
    ];
  }, [dispatch, isViewer, activeUserDraftProjectId, activeUserDraftProjectName]);

  const onOpenInformationModal = ({ id, name }: { id: string; name: string }) => {
    setInformation({ id, name });
    !isOpen && onOpen();
  };

  const onCloseInformationModal = () => {
    setInformation(null);
    onClose();
  };

  const modelMetaDataList = flowProjectsList.map(({ title, id, firstPage, createdAt, isProtected, avatar }) => ({
    items: [],
    title: title,
    type: id,
    meta: firstPage,
    image: avatar,
    createdAt,
    isProtected,
  }));

  const onChangeShortProject = useCallback(
    ({ sortOrder, sortType }: SortProject) => {
      setSortSettings({ sortOrder, sortType });

      saveSortSettings({
        SHORT_KEY: SHORT_USER_DRAFT_PROJECT_SETTINGS,
        streamId: activeUserId,
        settings: { sortOrder, sortType },
      });
    },
    [activeUserId],
  );

  const onCloseMenu = () => {
    dispatch(setActiveUserDraftProjectId(null));
  };

  const tabs: Tab[] = useMemo(
    () =>
      [
        {
          content: 'Инфо',
          prompt: 'Инфо',
          type: 'text',
          id: 'info',
          Component: () => (
            <InfoUserDraftProject activeProjectId={informationProject?.id || ''} onChangeAvatar={onChangeAvatar} />
          ),
        },

        {
          content: 'Версии',
          prompt: 'Версии',
          type: 'text',
          id: 'version',
          Component: () => (
            <HistoryVersionUserDraftProject
              flowId={activeUserId}
              projectId={informationProject?.id || ''}
              projectName={informationProject?.name}
              padding="0"
            />
          ),
        },
      ].filter(({ Component }) => Component) as Tab[],
    [activeUserId, informationProject?.id, informationProject?.name, onChangeAvatar],
  );

  useEffect(() => {
    const settings = loadSortSettings({ streamId: activeUserId, SHORT_KEY: SHORT_USER_DRAFT_PROJECT_SETTINGS });

    if (settings) {
      setSortSettings(settings);
    }
  }, [activeUserId]);

  return (
    <FlexContainer flexDirection="row" width="100%" height="100Vh">
      <ProjectsSearchUI
        modelMetaDataList={modelMetaDataList || []}
        onCloseMenu={onCloseMenu}
        onAddItem={() => {}}
        isViewer={isViewer}
        projectsLoading={loading}
        activeProjectId={activeUserDraftProjectId || ''}
        onBoardAreaClick={onBoardAreaClick}
        onOpenInformationModal={onOpenInformationModal}
        menuList={menuList}
        exportProject={exportProject}
        informationOpenId={informationProject?.id || ''}
        isOpenInformationBar={isOpen}
        onCloseInformationModal={onCloseInformationModal}
        onOpenFlowProject={openFlowProject}
        isDraftFlow={false}
        onChangeShortProject={onChangeShortProject}
        shortProject={sortSettings}
      />
      <InformationBar open={isOpen} onClose={onCloseInformationModal} tabs={tabs} />
    </FlexContainer>
  );
};
