import { ReactFCNoop } from 'types/global';

import { SettingsLayout } from 'modules/settingsContainer/SettingsLayout';
import { DataTab } from '../DataTab';
import { ViewTab } from 'modules/filters/Multiple/settings/ViewTab';
import { EventsTabMultiple } from '../EventsTabMultiple';

export const MultipleFilterMainSettings = () => {
  return (
    <SettingsLayout
      DataComponent={DataTab as ReactFCNoop}
      ViewComponent={ViewTab}
      EventComponent={() => EventsTabMultiple(true)}
    />
  );
};
