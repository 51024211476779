import isArray from 'lodash/isArray';
import { ColorByConditionUtilsInterface } from 'modules/visualisations/Table/visualisation/types';
import {
  ColorAndImageByEnum,
  SourceEnum,
  TableIncisionInterface,
  TableIndicatorInterface,
  VisualisationOperationTypesEnum,
  VisualisationValuesInterface,
} from 'store/reducers/visualisations/types';
import { v4 } from 'uuid';
import { getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import { getCustomAliasName } from './formatting';

export const ColorByConditionUtils = ({
  visualisationValues,
  colorBy,
  index,
  indexAccessor,
  alias,
}: ColorByConditionUtilsInterface) => {
  const value = visualisationValues[alias] as Array<Array<string | null> | null>;
  const rowColorByCondition = colorBy.type === ColorAndImageByEnum.Condition ? value[index] : null;
  const colorsIndex = isArray(rowColorByCondition) && rowColorByCondition?.[indexAccessor];
  const colorByCondition = colorsIndex ? colorBy.byCondition.colors[+colorsIndex - 1]?.value : null;

  return colorByCondition;
};

export const GetSqlRequestForHeaderPivotTable = ({
  incisionsInHeader,
  valuesWithHeader,
  indicators,
}: {
  incisionsInHeader: TableIncisionInterface[];
  valuesWithHeader: VisualisationValuesInterface;
  indicators: TableIndicatorInterface[];
}) => {
  let sqlRequest = '';
  const hashNames: string[] = [];

  const keys = Object.keys(valuesWithHeader);
  const maxLength = valuesWithHeader[keys[0]]?.length;

  if (!maxLength) {
    return { sqlRequest, hashNames };
  }

  for (let i = 0; i < maxLength; i++) {
    indicators.forEach((indicator) => {
      const {
        fieldName,
        name,
        customRequest,
        operationType,
        settings: { nameFromDatabase },
      } = indicator;
      const nameIndicator = getVisualisationFieldName({ name, fieldName, nameFromDatabase });
      const hashName = `fastboard_${nameIndicator}_${v4().replace(/-/g, '')}_hash`;
      const arrayConditions: string[] = [];

      hashNames.push(hashName);

      if (operationType !== VisualisationOperationTypesEnum.OTHER) {
        sqlRequest += `${operationType}(case when `;
      }

      for (let key = 0; key < keys.length; key++) {
        const valueHeader = keys[key];
        const incisionInHeader = incisionsInHeader.find(
          (incision) => incision.fieldName === valueHeader || incision.name === valueHeader,
        );

        if (incisionInHeader?.fieldName == null) {
          continue;
        }
        if (operationType === VisualisationOperationTypesEnum.OTHER) {
          arrayConditions.push(`${incisionInHeader.fieldName} = '${valuesWithHeader[valueHeader]?.[i]}'`);
          continue;
        }
        if (valuesWithHeader[valueHeader]?.[i] !== undefined) {
          sqlRequest += `${incisionInHeader.fieldName} = '${valuesWithHeader[valueHeader]?.[i]}'`;
        }
        if (key < keys.length - 1) {
          sqlRequest += ' and ';
        }
      }

      if (i <= maxLength - 1) {
        if (operationType === VisualisationOperationTypesEnum.OTHER && customRequest) {
          sqlRequest += transformSQLForPivotRequests({ customSQL: customRequest, conditions: arrayConditions });
          sqlRequest += ` as "${hashName}", `;
        } else if (operationType !== VisualisationOperationTypesEnum.OTHER) {
          sqlRequest += ` then ${fieldName} end) as "${hashName}", `;
        }
      }
    });
  }
  return { sqlRequest, hashNames };
};

/* TODO разнести/отрефачить в более читабельное */
export const GetSqlRequestForHyperOrImageLinksPivotTable = ({
  incisionsInHeader,
  valuesWithHeader,
  indicators,
  keyLinks,
  alias,
}: {
  incisionsInHeader: TableIncisionInterface[];
  valuesWithHeader: VisualisationValuesInterface;
  indicators: TableIndicatorInterface[];
  keyLinks: 'hyperLink' | 'imagesSettings';
  alias: 'imageLinksAlias' | 'hyperLinksAlias';
}) => {
  let sqlRequest = '';
  const hashNames: Record<string, string[]> = {};

  const keys = Object.keys(valuesWithHeader);
  const maxLength = valuesWithHeader[keys[0]]?.length;

  if (!maxLength) {
    return { sqlRequest, hashNames };
  }

  for (let i = 0; i < maxLength; i++) {
    indicators.forEach((indicator) => {
      const {
        fieldName,
        name,
        settings: { nameFromDatabase },
      } = indicator;
      const linkSettings = indicator.settings[keyLinks];
      const {
        isActive,
        source: {
          type,
          value: { fieldName: fieldNameLink, sqlCondition },
        },
      } = linkSettings;

      if ((type === SourceEnum.DATABASE && !fieldNameLink) || (type === SourceEnum.BYCONDITION && !sqlCondition)) {
        return;
      }

      if (!isActive || type === SourceEnum.MANUAL) {
        return;
      }

      const nameIndicator = getVisualisationFieldName({ name, fieldName, nameFromDatabase });
      const aliasImageLink = getCustomAliasName({ nameIncision: nameIndicator, alias });
      const arrayConditions: string[] = [];

      const hashName = `fastboard_${aliasImageLink}_${v4().replace(/-/g, '')}_hash`;

      hashNames[nameIndicator] ? hashNames[nameIndicator].push(hashName) : (hashNames[nameIndicator] = [hashName]);

      if (type === SourceEnum.DATABASE) {
        sqlRequest += `any(case when `;
      }

      for (let key = 0; key < keys.length; key++) {
        const valueHeader = keys[key];
        const incisionInHeader = incisionsInHeader.find(
          (incision) => incision.fieldName === valueHeader || incision.name === valueHeader,
        );

        if (incisionInHeader?.fieldName == null) {
          continue;
        }
        if (type === SourceEnum.BYCONDITION) {
          arrayConditions.push(`${incisionInHeader.fieldName} = '${valuesWithHeader[valueHeader]?.[i]}'`);
          continue;
        }
        if (valuesWithHeader[valueHeader]?.[i] !== undefined) {
          sqlRequest += `${incisionInHeader.fieldName} = '${valuesWithHeader[valueHeader]?.[i]}'`;
        }
        if (key < keys.length - 1) {
          sqlRequest += ' and ';
        }
      }

      if (i <= maxLength - 1) {
        if (type === SourceEnum.BYCONDITION && sqlCondition) {
          sqlRequest += transformSQLForPivotRequests({ customSQL: sqlCondition, conditions: arrayConditions });
          sqlRequest += ` as "${hashName}", `;
        } else if (type === SourceEnum.DATABASE && fieldNameLink) {
          sqlRequest += ` then ${fieldNameLink} end) as "${hashName}", `;
        }
      }
    });
  }
  return { sqlRequest, hashNames };
};

export const transformSQLForPivotRequests = ({ customSQL, conditions }: { customSQL: string; conditions: string[] }) => {
  const regexAllCustomSQL = /([a-zA-Z]+)\(([^)(]*(?:\([^)(]*\))*[^)(]*)\)(\.[^)(]*)?/g;
  const regexOneCustomSQL = /(\w+)\(([^,]+)(,[^,]+)*\)$/;

  return customSQL.replace(regexAllCustomSQL, (match: string) => {
    const arrayValuesSQL = match.match(regexOneCustomSQL);

    if (arrayValuesSQL) {
      const [_, aggrType, fieldName, condition] = arrayValuesSQL;
      let sql = `${aggrType}(case when `;

      conditions.forEach((condition, index) => {
        sql += `${condition}`;
        if (index < conditions.length - 1) {
          sql += ' and ';
        }
      });

      sql += ` then ${fieldName} end${condition ? `${condition}` : ''})`;
      return sql;
    }

    return match;
  });
};
