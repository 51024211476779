import { SliderDataZoomOption } from 'echarts/types/dist/shared';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { TooltipFormatterCallback, TooltipFormatterParams } from 'modules/visualisations/Bubble/visualisation/types';
import { FormattingFunctionReturnType, FormattingRecordType } from 'modules/visualisations/hooks/useFormat';
import { LineAndBarEChartsOption } from 'modules/visualisations/LineAndBar/visualisation/types';
import { parseToRgb, rgba } from 'polished';
import { store } from 'store';
import { disableFilterByIdAction } from 'store/reducers/filters/actions';
import { EnabledFilterDataType } from 'store/reducers/filters/types';
import { ThemeColorsInterface } from 'store/reducers/themes/types';
import { updateDataSettingsById, updateLineAndBarDataSettingsById } from 'store/reducers/visualisations/actions';
import { getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import {
  ActiveIncisionIdInterface,
  AxisSettingsInterface,
  BarIndicatorInterface,
  BarValuePositionType,
  DefaultIndicatorInterface,
  DefaultPropertiesInterface,
  EventsSettingsInterface,
  LabelsOrientationType,
  LegendSettingsInterface,
  MinAndMaxInterface,
  PositionValueType,
  ZoomSettingsInterface,
} from 'store/reducers/visualisations/types';
import { BarEChartsOption, ConfigWithGridDimensionsInterface, DataZoomType, GridDimensionsInterface } from 'types/echarts';
import { DefaultDataSettingsProps } from 'types/global';
import { IdInterface, PositionSettingType, StartAndEndInterface } from 'types/store';
import { AbsolutePositionType } from 'types/styles';
import {
  calculateGridDimension,
  getLengthOfGraphicByLength,
  getLengthOfGraphicString,
  initialDimensions,
} from 'utils/generateConfigGraphic';
import { getMaximumStringLengthOfArrayData } from 'utils/utils';
import { ColorsLegendInterface } from 'utils/hooks/visualisation/useColorsLegendData';

export const createBarPropertiesData = <T extends BarIndicatorInterface>(
  indicators: T[],
): Record<string, DefaultPropertiesInterface> => {
  return indicators.reduce<Record<string, DefaultPropertiesInterface>>((propertiesData, indicator) => {
    const {
      name,
      fieldName,
      settings: {
        showValue: { properties },
        nameFromDatabase,
      },
    } = indicator;
    const indicatorFieldName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });
    propertiesData[indicatorFieldName] = properties;
    return { ...propertiesData, [name]: properties };
  }, {});
};

interface MinMaxValuesInterface {
  settings: MinAndMaxInterface;
  isNominated: boolean;
  activeIncisionSum: Record<string, number>;
}

export const getMinMaxValues = ({ settings, isNominated, activeIncisionSum }: MinMaxValuesInterface) => {
  const { isShow, max, min } = settings,
    maxValue = isNominated ? 100 : isShow ? max : undefined,
    minValue = isShow ? min : undefined,
    maxIndicatorsValue = isShow ? maxValue || 0 : Math.max(...Object.values(activeIncisionSum || []));

  return { minValue, maxValue, maxIndicatorLength: getLengthOfGraphicString(maxIndicatorsValue) };
};

export const getLegendLocation: (
  legendIsAuto: boolean,
  location: number,
) => Record<AbsolutePositionType, BarEChartsOption['legend']> = (legendIsAuto, location) => ({
  top: {
    top: legendIsAuto ? 'top' : location,
  },
  bottom: {
    bottom: legendIsAuto ? 'bottom' : location,
  },
  right: {
    right: legendIsAuto ? 'right' : location,
  },
  left: {
    left: legendIsAuto ? 'left' : location,
  },
});

export const getVerticalLegendPosition: (
  legendIsAuto: boolean,
  position: PositionValueType,
) => Record<PositionSettingType, BarEChartsOption['legend']> = (legendIsAuto, position) =>
  legendIsAuto
    ? {
        'flex-start': {
          top: 'top',
        },
        center: {
          top: 'center',
        },
        'flex-end': {
          top: 'bottom',
        },
      }
    : {
        'flex-start': {
          top: position.type === 'flex-start' ? position.value : undefined,
        },
        center: {
          top: 'center',
        },
        'flex-end': {
          bottom: position.type === 'flex-end' ? position.value : undefined,
        },
      };

export const getHorizontalLegendPosition: (
  legendIsAuto: boolean,
  position: PositionValueType,
) => Record<PositionSettingType, BarEChartsOption['legend']> = (legendIsAuto, position) =>
  legendIsAuto
    ? {
        'flex-start': {
          left: 'left',
        },
        center: {
          left: 'center',
        },
        'flex-end': {
          left: 'right',
        },
      }
    : {
        'flex-start': {
          left: position.type === 'flex-start' ? position.value : undefined,
        },
        center: {
          left: 'center',
        },
        'flex-end': {
          right: position.type === 'flex-end' ? position.value : undefined,
        },
      };

interface GetLegendConfigParams {
  indicators: DefaultIndicatorInterface[];
  legendSettings: LegendSettingsInterface;
  defaultColor: string;
  colorsLegendData?: ColorsLegendInterface[];
}

export const getLegendConfig: (params: GetLegendConfigParams) => BarEChartsOption['legend'] = ({
  indicators,
  legendSettings: { type, isShow, location, position, width, properties },
  defaultColor,
  colorsLegendData,
}) => {
  const legendIsAuto = type === 'auto';
  const isHorizontalLocation = location.type === 'right' || location.type === 'left';

  const fontWeight = properties.fontStyle.bold ? 'bold' : 'normal',
    fontSize = properties.fontSize,
    fontStyle = properties.fontStyle.italic ? 'italic' : 'normal';

  return {
    data: indicators?.map((indicator) => {
      const itemColor = colorsLegendData && {
        color: colorsLegendData?.find(({ id }: { id: string }) => id === indicator.id)?.createLinearGradientFromColors,
      };

      return {
        name: getVisualisationFieldName({
          name: indicator?.name,
          fieldName: indicator?.fieldName,
          nameFromDatabase: indicator?.settings.nameFromDatabase,
        }),
        itemStyle: itemColor,
      };
    }),
    show: isShow,
    type: 'scroll',
    textStyle: {
      color: defaultColor,

      fontSize,
      fontWeight,
      fontStyle,
      overflow: 'breakAll',
      width: isHorizontalLocation ? width - 32 : undefined,
    },
    pageTextStyle: {
      color: defaultColor,
    },

    orient: isHorizontalLocation ? 'vertical' : 'horizontal',
    ...getLegendLocation(legendIsAuto, location.value)[location.type],
    ...(isHorizontalLocation
      ? getVerticalLegendPosition(legendIsAuto, position)
      : getHorizontalLegendPosition(legendIsAuto, position))[position.type],
  };
};

export const getLegendGridDimension: (legendSettings: LegendSettingsInterface) => GridDimensionsInterface = ({
  location,
  width,
  isShow,
}) => {
  const dimensionByLocation = { left: width, right: width, bottom: 40, top: 40 };

  return { ...initialDimensions, ...(isShow ? { [location.type]: dimensionByLocation[location.type] } : {}) };
};

export const getLegendConfigWithGridDimensions: (
  params: GetLegendConfigParams,
) => ConfigWithGridDimensionsInterface<BarEChartsOption['legend']> = ({
  indicators,
  defaultColor,
  colorsLegendData,
  legendSettings,
}) => ({
  config: getLegendConfig({ indicators, colorsLegendData, legendSettings, defaultColor }),
  gridDimensions: getLegendGridDimension(legendSettings),
});

const dispatch = store.dispatch;

export const onActiveIncisionIdChange = ({ id, activeIncisionId }: ActiveIncisionIdInterface & IdInterface) =>
  dispatch(updateLineAndBarDataSettingsById({ dataSettings: { activeIncisionId }, id }));

export const onActiveNextIncisionIdChange = ({
  id,
  dataSettings,
  events,
  activeIncisionId,
  onChange,
}: IdInterface &
  DefaultDataSettingsProps &
  ActiveIncisionIdInterface & {
    events: EventsSettingsInterface;
    onChange: ({ id, activeIncisionId }: ActiveIncisionIdInterface & IdInterface) => void;
  }) => {
  const { isInfluenceItself, isFiltering } = events.filterSettings;

  if (isFiltering && isInfluenceItself) {
    const indexActiveIncision = dataSettings.incisions.findIndex(({ id }) => id === activeIncisionId);
    const nextIncision = dataSettings.incisions[indexActiveIncision + 1];

    nextIncision &&
      dispatch(
        updateDataSettingsById({
          dataSettings: { isDrillDown: true },
          id,
        }),
      );
    nextIncision && onChange({ activeIncisionId: nextIncision.id, id });
  }
};

export interface DeleteFiltersForDrillDownInterface {
  enabledFilters: EnabledFilterDataType[];
  lastActiveIncisionId: string;
  nextActiveIncisionId: string;
}

export const onDeleteFiltersForDrillDownVisualisation = ({
  dataSettings,
  enabledFilters,
  lastActiveIncisionId,
  nextActiveIncisionId,
}: DefaultDataSettingsProps & DeleteFiltersForDrillDownInterface) => {
  const indexLastActiveIncision = dataSettings.incisions.findIndex(({ id }) => id === lastActiveIncisionId);
  const indexNextActiveIncision = dataSettings.incisions.findIndex(({ id }) => id === nextActiveIncisionId);
  if (indexNextActiveIncision <= indexLastActiveIncision) {
    for (let i = indexNextActiveIncision; i < enabledFilters.length; i++) {
      dispatch(disableFilterByIdAction(enabledFilters[i].id));
    }
  }
};

export const defaultGridDimension: GridDimensionsInterface = { left: 40, right: 40, top: 20, bottom: 20 };

export const getAxisNameLocation: Record<PositionSettingType, 'start' | 'middle' | 'end'> = {
  'flex-start': 'start',
  center: 'middle',
  'flex-end': 'end',
};

export const getAxisLabelRotation: Record<LabelsOrientationType, number> = {
  horizontal: 0,
  vertical: 90,
  incline: 45,
};

type NameIndicatorTextPaddingArgs = {
  isRotated?: boolean;
  isAutoType: boolean;
  positionValue: number;
  isLeft: boolean;
  maxStringLength?: number;
};

export const getNameIndicatorTextPadding = ({
  isRotated = false,
  isAutoType,
  positionValue,
  isLeft,
  maxStringLength = 0,
}: NameIndicatorTextPaddingArgs): Record<PositionSettingType, [number, number, number, number]> =>
  isRotated
    ? {
        'flex-start': [0, isAutoType ? 0 : positionValue, 0, 0],
        center: [10, 0, isAutoType ? 0 : positionValue, 0],
        'flex-end': [10, 0, 0, isAutoType ? 0 : positionValue],
      }
    : {
        'flex-start': isLeft
          ? [0, isAutoType ? 0 : positionValue, 0, -30]
          : [0, isAutoType ? 0 : positionValue, 0, isAutoType ? 0 : positionValue],
        center: isLeft
          ? [0, 0, 30 + maxStringLength, isAutoType ? 0 : positionValue]
          : [isAutoType ? 30 + maxStringLength : positionValue, 0, 0, 0],
        'flex-end': isLeft ? [0, isAutoType ? 0 : positionValue, 0, -30] : [0, 0, 0, isAutoType ? 0 : positionValue],
      };

export const getNameNormalIncisionTextPadding: (
  isBottom: boolean,
  isShowLabel: boolean,
) => Record<PositionSettingType, Record<LabelsOrientationType, [number, number, number, number]>> = (isBottom, isShowLabel) =>
  isBottom
    ? {
        'flex-start': {
          horizontal: [0, 20, 0, 0],
          incline: [0, 20, 0, 0],
          vertical: [0, 20, 0, 0],
        },
        center: {
          horizontal: [isShowLabel ? 20 : 0, 0, 0, 0],
          incline: [isShowLabel ? 40 : 0, 0, 0, 0],
          vertical: [isShowLabel ? 60 : 0, 0, 0, 0],
        },
        'flex-end': {
          horizontal: [0, 0, 0, 20],
          incline: [0, 0, 0, 20],
          vertical: [0, 0, 0, 20],
        },
      }
    : {
        'flex-start': {
          horizontal: [0, 20, 0, 0],
          incline: [0, 20, 0, 0],
          vertical: [0, 20, 0, 0],
        },
        center: {
          horizontal: [0, 0, isShowLabel ? 20 : 0, 0],
          incline: [0, 0, isShowLabel ? 40 : 0, 0],
          vertical: [0, 0, isShowLabel ? 60 : 0, 0],
        },
        'flex-end': {
          horizontal: [0, 0, 0, 20],
          incline: [0, 0, 0, 20],
          vertical: [0, 0, 0, 20],
        },
      };

export const getNameRotatedIncisionTextPadding: (
  isShowLabel: boolean,
) => Record<PositionSettingType, Record<LabelsOrientationType, [number, number, number, number]>> = (isShowLabel) => ({
  'flex-start': {
    horizontal: [20, 0, 0, 0],
    incline: [20, 0, 0, 0],
    vertical: [20, 0, 0, 0],
  },
  center: {
    horizontal: [0, 0, isShowLabel ? 70 : 0, 0],
    incline: [0, 0, isShowLabel ? 50 : 0, 0],
    vertical: [0, 0, isShowLabel ? 30 : 0, 0],
  },
  'flex-end': {
    horizontal: [0, 0, 20, 0],
    incline: [0, 0, 20, 0],
    vertical: [0, 0, 20, 0],
  },
});

interface GetIncisionAxisConfigParams {
  axisIncisionSettings: AxisSettingsInterface;
  rotateTo90?: boolean;
  disabledTypeCategory?: boolean;
  fieldName: string;
  minAndMax?: MinAndMaxInterface;
  formatting?: FormattingFunctionReturnType;
  activeThemeSchema: ThemeColorsInterface;
  isTriggerEvent?: boolean;
  data?: Array<string | null> | Array<number | null>;
}

export const getIncisionAxisConfig: (params: GetIncisionAxisConfigParams) => BarEChartsOption['xAxis'] = ({
  axisIncisionSettings,
  fieldName,
  activeThemeSchema,
  rotateTo90 = false,
  minAndMax,
  disabledTypeCategory = false,
  formatting,
  data,
  isTriggerEvent = false,
}) => {
  const {
    isShow,
    showAxis,
    position,
    name,
    label: { isActive: isActiveLabel, value: valueLabel, properties },
    labelOrientation,
    labelOrderValues,
    labelSize,
    stepSize,
    showGrid,
    tickLabel,
  } = axisIncisionSettings;

  const paddingGetter = rotateTo90
    ? getNameRotatedIncisionTextPadding(isActiveLabel)
    : getNameNormalIncisionTextPadding(position === 'bottom', isActiveLabel);

  const minAndMaxOptions = minAndMax?.isShow ? { min: minAndMax.min, max: minAndMax.max } : {};

  return {
    type: !disabledTypeCategory ? 'category' : 'value',
    ...minAndMaxOptions,
    show: isShow,
    triggerEvent: isTriggerEvent,
    splitNumber: stepSize.type === 'auto' ? undefined : stepSize.value,
    data,
    inverse: rotateTo90 || labelOrderValues === 'topDown',
    position,
    name: name.isShow ? fieldName : undefined,
    nameLocation: getAxisNameLocation[name.position.type],
    nameTextStyle: {
      padding: paddingGetter[name.position.type][labelOrientation],
      align: rotateTo90 && name.position.type !== 'center' ? 'left' : undefined,
    },
    axisLine: {
      show: showAxis,
      lineStyle: {
        color: activeThemeSchema[ColorVarsEnum.Level_4],
      },
    },
    axisLabel: {
      show: isActiveLabel,
      margin: isActiveLabel ? valueLabel : undefined,
      rotate: getAxisLabelRotation[labelOrientation],
      textStyle: {
        color: activeThemeSchema[ColorVarsEnum.Level_2],
        fontSize: properties?.fontSize,
        fontWeight: properties?.fontStyle?.bold ? 'bold' : 'normal',
        fontStyle: properties?.fontStyle?.italic ? 'italic' : 'normal',
      },
      formatter: (value: string) => {
        if (labelSize.type === 'manual') {
          const valueLabelSize = value.slice(0, labelSize.value);
          return formatting ? formatting?.(valueLabelSize) : valueLabelSize;
        }

        return formatting ? formatting?.(value) : value;
      },
      interval: stepSize.type === 'auto' ? 'auto' : stepSize.value,
    },
    splitLine: {
      show: showGrid,
      lineStyle: {
        type: 'dashed',
        color: activeThemeSchema[ColorVarsEnum.Level_4],
      },
    },
    axisTick: {
      show: tickLabel.isShow,
      alignWithLabel: tickLabel.position === 'center',
      lineStyle: {
        color: activeThemeSchema[ColorVarsEnum.Level_4],
      },
    },
  };
};

export const getIncisionAxisGridDimensions: (params: GetIncisionAxisConfigParams) => GridDimensionsInterface = ({
  fieldName,
  data,
  axisIncisionSettings,
  rotateTo90 = false,
}) => {
  const maxIncisionString = getMaximumStringLengthOfArrayData(data);

  const {
    isShow,
    position,
    name,
    label: { isActive: isActiveLabel },
    labelOrientation,
    labelSize,
  } = axisIncisionSettings;

  if (!isShow) {
    return initialDimensions;
  }

  const baseStringLength = 10,
    highStringLength =
      labelSize.type === 'auto' ? getLengthOfGraphicString(maxIncisionString) : getLengthOfGraphicByLength(labelSize.value),
    inclineStringLength = highStringLength / 2;

  if (rotateTo90) {
    const baseGrid = { ...initialDimensions, left: 10 };

    const namePositionGridDimensions = {
      'flex-start': {
        ...initialDimensions,
        bottom: 30,
      },
      center: {
        ...initialDimensions,
        left: 20,
      },
      'flex-end': {
        ...initialDimensions,
        top: 30,
      },
    };

    const namePositionGrid = name.isShow ? namePositionGridDimensions[name.position.type] : initialDimensions;

    const labelOrientationGridDimensions = {
      horizontal: { ...initialDimensions, left: highStringLength },
      incline: { ...initialDimensions, left: inclineStringLength },
      vertical: { ...initialDimensions, left: baseStringLength },
    };

    const labelOrientationGrid = isActiveLabel ? labelOrientationGridDimensions[labelOrientation] : initialDimensions;

    return calculateGridDimension([baseGrid, namePositionGrid, labelOrientationGrid]);
  } else {
    const basePositionGridDimensions = {
      top: { ...initialDimensions, top: 25 },
      bottom: { ...initialDimensions, bottom: 25 },
      right: { ...initialDimensions, right: 25 },
      left: { ...initialDimensions, left: 25 },
    };

    const baseGrid = basePositionGridDimensions[position];

    const isBottom = position === 'bottom',
      [, rightPadding, , leftPadding] = getNameNormalIncisionTextPadding(isBottom, isActiveLabel)[name.position.type][
        'horizontal'
      ],
      fieldNameLength = getLengthOfGraphicString(fieldName),
      getHorizontalLength = (basePadding: number) => basePadding + 30 + fieldNameLength;

    const namePositionGridDimensions = {
      'flex-start': {
        ...initialDimensions,
        left: getHorizontalLength(rightPadding),
      },
      center: isBottom ? { ...initialDimensions, bottom: 10 } : { ...initialDimensions, top: 10 },
      'flex-end': {
        ...initialDimensions,
        right: getHorizontalLength(leftPadding),
      },
    };

    const namePositionGrid = name.isShow ? namePositionGridDimensions[name.position.type] : initialDimensions;

    const labelOrientationGridDimensions = (position: AbsolutePositionType) => ({
      horizontal: { ...initialDimensions, [position]: baseStringLength },
      incline: { ...initialDimensions, [position]: inclineStringLength },
      vertical: { ...initialDimensions, [position]: highStringLength },
    });

    const labelOrientationGrid = isActiveLabel ? labelOrientationGridDimensions(position)[labelOrientation] : initialDimensions;

    return calculateGridDimension([baseGrid, namePositionGrid, labelOrientationGrid]);
  }
};

export const getIncisionAxisConfigWithGridDimensions: (
  params: GetIncisionAxisConfigParams,
) => ConfigWithGridDimensionsInterface<LineAndBarEChartsOption['xAxis']> = (params) => ({
  config: getIncisionAxisConfig(params),
  gridDimensions: getIncisionAxisGridDimensions(params),
});

export const getAxisIndex: (
  rotateTo90?: GetSliderZoomConfigParams['rotateTo90'],
) => Record<GetSliderZoomConfigParams['position'], Record<string, [number, number]>> = (rotateTo90 = false) =>
  rotateTo90
    ? {
        bottom: {
          yAxisIndex: [1, 1],
          xAxisIndex: [0, 1],
        },
        right: {
          yAxisIndex: [0, 0],
          xAxisIndex: [-1, -1],
        },
      }
    : {
        bottom: {
          yAxisIndex: [-1, -1],
          xAxisIndex: [0, 1],
        },
        right: {
          yAxisIndex: [1, 0],
          xAxisIndex: [-1, -1],
        },
      };

interface GetSliderZoomConfigParams {
  id: DataZoomType;
  type: 'inside' | 'slider';
  activeThemeSchema: ThemeColorsInterface;
  isShow: boolean;
  rotateTo90?: boolean;
  position: 'bottom' | 'right';
  isActiveLegend?: boolean;
  maxIndicatorStringLength?: number;
  horizontal: StartAndEndInterface;
  vertical: StartAndEndInterface;
  horizontalZoom: ZoomSettingsInterface;
  verticalZoom: ZoomSettingsInterface;
}

export const getPositionZoom: (
  isActiveLegend: GetSliderZoomConfigParams['isActiveLegend'],
  horizontalZoom: ZoomSettingsInterface,
  verticalZoom: ZoomSettingsInterface,
  maxIndicatorStringLength: GetSliderZoomConfigParams['maxIndicatorStringLength'],
) => Record<GetSliderZoomConfigParams['position'], Record<string, number>> = (
  isActiveLegend,
  horizontalZoom,
  verticalZoom,
  maxIndicatorStringLength = 0,
) => {
  const horizontalZoomIsAuto = horizontalZoom.padding.type === 'auto';
  const verticalZoomIsAuto = verticalZoom.padding.type === 'auto';

  const bottom = { bottom: horizontalZoomIsAuto ? (isActiveLegend ? 40 : 20) : horizontalZoom.padding.value };
  const right = {
    right: verticalZoomIsAuto ? (isActiveLegend ? 120 + maxIndicatorStringLength : 20) : verticalZoom.padding.value,
  };
  return { bottom, right };
};

export const getSliderZoomConfig: (params: GetSliderZoomConfigParams) => SliderDataZoomOption = ({
  id,
  type,
  activeThemeSchema,
  isShow,
  rotateTo90,
  position,
  isActiveLegend,
  maxIndicatorStringLength = 0,
  horizontal,
  vertical,
  horizontalZoom,
  verticalZoom,
}) => {
  const { start: startHorizontal, end: endHorizontal } = horizontal;
  const { start: startVertical, end: endVertical } = vertical;

  const fillerColor = rgba({
    ...parseToRgb(activeThemeSchema[ColorVarsEnum.Accent]),
    alpha: 0.3,
  });
  const areaStyleColor = rgba({
    ...parseToRgb(activeThemeSchema[ColorVarsEnum.Level_1]),
    alpha: 0.5,
  });

  const optionAxisIndex = getAxisIndex(rotateTo90)[position];
  const optionPositionZoom = getPositionZoom(isActiveLegend, horizontalZoom, verticalZoom, maxIndicatorStringLength)[position];

  return {
    moveOnMouseWheel: true,
    id,
    disabled: !isShow,
    show: isShow,
    start: ['horizontalSliderX', 'horizontalInsideY'].some((value) => value === id) ? startHorizontal : startVertical,
    end: ['horizontalSliderX', 'horizontalInsideY'].some((value) => value === id) ? endHorizontal : endVertical,
    filterMode: 'filter',
    ...optionPositionZoom,
    type: type === 'slider' ? 'slider' : 'inside',
    ...optionAxisIndex,
    borderColor: activeThemeSchema[ColorVarsEnum.Level_3],
    fillerColor,
    handleStyle: {
      color: activeThemeSchema[ColorVarsEnum.Level_4_widget],
      borderColor: activeThemeSchema[ColorVarsEnum.Level_4],
    },
    moveHandleStyle: {
      color: activeThemeSchema[ColorVarsEnum.Level_5],
    },
    dataBackground: {
      lineStyle: {
        color: activeThemeSchema[ColorVarsEnum.Level_4],
        width: 1,
      },
      areaStyle: {
        color: areaStyleColor,
      },
    },
    selectedDataBackground: {
      lineStyle: {
        width: 1,
        color: activeThemeSchema[ColorVarsEnum.Level_4],
      },
      areaStyle: {
        color: areaStyleColor,
      },
    },
    silent: false,
  };
};

const positionDimensions: Record<GetSliderZoomConfigParams['position'], GridDimensionsInterface> = {
  bottom: { left: 0, right: 0, top: 0, bottom: 60 },
  right: { left: 0, right: 60, top: 0, bottom: 0 },
};

export const getSliderZoomGridDimension: (
  position: GetSliderZoomConfigParams['position'],
  isShow: boolean,
) => GridDimensionsInterface = (position, isShow) => (isShow ? positionDimensions[position] : initialDimensions);

export const getSliderZoomWithGridDimensions: (
  params: GetSliderZoomConfigParams,
) => ConfigWithGridDimensionsInterface<SliderDataZoomOption> = ({
  id,
  type,
  activeThemeSchema,
  isShow,
  rotateTo90,
  position,
  isActiveLegend,
  maxIndicatorStringLength,
  horizontal,
  vertical,
  horizontalZoom,
  verticalZoom,
}) => ({
  config: getSliderZoomConfig({
    id,
    type,
    isShow,
    position,
    rotateTo90,
    activeThemeSchema,
    isActiveLegend,
    maxIndicatorStringLength,
    horizontal,
    vertical,
    horizontalZoom,
    verticalZoom,
  }),
  gridDimensions: getSliderZoomGridDimension(position, isShow),
});

export const validateData = <T>(data: T[], minValue: number, maxValue?: number) => {
  const isHighLimit = maxValue === undefined || data.length <= maxValue;
  const isLowLimit = data.length >= minValue;

  return isLowLimit && isHighLimit ? data : null;
};

export const getPositionLabel: <T extends BarValuePositionType>(
  isPositiveNumber: boolean,
  orientation: boolean,
) => Record<T, Record<'position' | 'align' | 'distance' | 'verticalAlign', string | number | [string, string]>> = (
  isPositiveNumber,
  isOrientationHorizontal,
) => ({
  'flex-start': {
    position: isPositiveNumber ? 'top' : 'bottom',
    align: !isOrientationHorizontal ? (isPositiveNumber ? 'right' : 'left') : 'center',
    distance: -5,
    verticalAlign: isOrientationHorizontal ? (isPositiveNumber ? 'top' : 'bottom') : 'middle',
  },
  'flex-end': {
    position: isPositiveNumber ? 'bottom' : 'top',
    align: !isOrientationHorizontal ? (isPositiveNumber ? 'left' : 'right') : 'center',
    distance: -5,
    verticalAlign: isOrientationHorizontal ? (isPositiveNumber ? 'bottom' : 'top') : 'middle',
  },
  outside: {
    position: isPositiveNumber ? 'outside' : 'bottom',
    align: !isOrientationHorizontal ? (isPositiveNumber ? 'left' : 'right') : 'center',
    distance: 5,
    verticalAlign: isOrientationHorizontal ? (isPositiveNumber ? 'bottom' : 'top') : 'middle',
  },
  center: {
    position: ['50%', '50%'],
    align: 'center',
    distance: 5,
    verticalAlign: 'middle',
  },
});

interface CreateTooltipFormatterParams {
  formatting: FormattingRecordType;
  seriesPhantomName?: string;
}

export const createTooltipFormatter = ({
  seriesPhantomName,
  formatting,
}: CreateTooltipFormatterParams): TooltipFormatterCallback => {
  return (params: TooltipFormatterParams) => {
    if (Array.isArray(params) && params.length > 0) {
      const incisionName = params[0].name;
      const formattedLines: string[] = [incisionName];

      params.forEach((param) => {
        const seriesName = param.seriesName;

        if (seriesPhantomName === seriesName) {
          return '';
        }

        const data = param.data as { value: number; color: string },
          indicatorValue = data.value,
          indicatorMarker = param.marker;

        const formattingFunction = formatting[seriesName || ''];

        const indicatorValueFormatting = formattingFunction && formatting ? formattingFunction(indicatorValue) : indicatorValue;

        const text = `<span style="width:100%;vertical-align: center;">${seriesName}  <span style="font-weight:bold"> ${indicatorValueFormatting}</span></span>`;
        indicatorValue ? formattedLines.push(`${indicatorMarker} ${text}`) : formattedLines;
      });

      return formattedLines.join('<br>');
    }

    return '';
  };
};

export const unitOptions: Array<{ value: 'px' | '%'; label: string }> = [
  { value: 'px', label: 'Пикс' },
  { value: '%', label: '%' },
];
