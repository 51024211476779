import { ElementContainerSettings, GroupContainerSettings } from 'modules/settingsContainer';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { SelectItemInterface } from 'modules/ui/Select';
import React from 'react';
import { MakeHyperLinksInterface } from 'store/reducers/visualisations/types';
import { OnValueChange } from 'types/global';
import { useLocalValues } from 'utils/hooks/localValues';
import { HyperLinkTransitionModeOptions } from './constants';
import { SourceSelection } from '../SourceSelection';

interface HyperLinkSettingsProps extends OnValueChange<MakeHyperLinksInterface> {
  options: SelectItemInterface[];
}

export const HyperLinkSettings = ({ value, onChange, options }: HyperLinkSettingsProps) => {
  const { isFiltering, isActive, transitionMode } = value;

  const { localValues, setLocalValues, onSave, onCancel, hasChanges } = useLocalValues({ value, onChange });
  const { source } = localValues;

  const onSqlChange = (sql: string) =>
    setLocalValues((localValues) => ({
      ...localValues,
      source: {
        ...source,
        value: {
          ...source.value,
          sqlCondition: sql || null,
        },
      },
    }));

  return (
    <>
      <GroupContainerSettings
        titleText="Сделать гиперссылкой"
        switcherState={isActive}
        switcherChange={() => onChange({ ...value, isActive: !isActive })}
      />
      <GroupContainerSettings
        titleText="Фильтрация при переходе"
        switcherState={isFiltering}
        switcherChange={() => onChange({ ...value, isFiltering: !isFiltering })}
      />
      <GroupContainerSettings titleText="Режим перехода">
        <ElementContainerSettings>
          <SettingsRadio
            onChange={(mode) =>
              onChange({
                ...value,
                transitionMode: mode.value,
              })
            }
            activeValue={getActiveRadioValue(HyperLinkTransitionModeOptions, transitionMode)}
            options={HyperLinkTransitionModeOptions}
          />
        </ElementContainerSettings>
      </GroupContainerSettings>
      <SourceSelection
        value={source}
        onChange={(source) => onChange({ ...value, source })}
        options={options}
        onChangeSqlByCondition={onSqlChange}
        onSave={onSave}
        onCancel={onCancel}
        hasChanges={hasChanges}
      />
    </>
  );
};
