import ColorTheme from 'constants/ColorTheme';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { cssVar, rgba } from 'polished';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IProps {
  id: string;
  value?: boolean;
  onClick?: (e: any) => void;
}

const Switcher: FC<IProps> = ({ id, value, onClick }) => {
  return (
    <CheckBoxWrapper>
      <CheckBox id={id} type="checkbox" checked={value} onChange={() => {}} onClick={onClick} />
      <CheckBoxLabel htmlFor={id} />
    </CheckBoxWrapper>
  );
};

export default Switcher;

const CheckBoxWrapper = styled.div`
  position: relative;
  width: 30px;
  height: 16px;
  z-index: 2;
`;

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 16px;
  border-radius: 15px;
  background: ${rgba(cssVar(`${ColorVarsEnum.Level_3}`, ColorTheme.default.level_3), 0.3)};
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin: 2px;
    background: ${`var(${ColorVarsEnum.Level_3})`};
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  z-index: 1;
  border-radius: 15px;
  width: 30px;
  height: 16px;
  opacity: 0;
  margin: 0;

  &:checked + ${CheckBoxLabel} {
    background: ${rgba(cssVar(`${ColorVarsEnum.Accent}`, ColorTheme.default.accent), 0.3)};

    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin-left: 16px;
      transition: 0.2s;
      background: ${`var(${ColorVarsEnum.Accent})`};
      z-index: 123;
    }
  }
`;
