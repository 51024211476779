import { ActiveModelItemAliasInterface, ModelFromItemType, ModelItemType, ModelTabInterface } from 'store/reducers/models/types';
import { Model } from 'models/model/Model';
import { ModelItem } from 'models/model/ModelItem';
import { Relation } from 'models/model/Relation';
import { SelectItemInterface } from 'modules/ui/Select';
import { AdviceEditor } from 'modules/ui/SqlAutocomplete/types';
import { createSelector } from 'reselect';
import { getState } from 'store/utils';

export const getModelsStore = createSelector(getState, (state) => state.models);

export const getModelsFrom = createSelector(getModelsStore, (state) => state.modelsFrom);

export const getAllModelRules = createSelector(getModelsStore, (state) => state.allModelRules);

export const getModelRuleDataUsersAndGroups = createSelector(getModelsStore, (state) => state.modelRuleDataUsersAndGroups);

export const getModelRule = createSelector(getModelRuleDataUsersAndGroups, (state) => state.modelRule);

export const getModelRuleUsers = createSelector(getModelRuleDataUsersAndGroups, (state) => state.modelRuleUsers);

export const getModelRuleGroups = createSelector(getModelRuleDataUsersAndGroups, (state) => state.modelRuleGroups);

export const getAllVariables = createSelector(getModelRuleDataUsersAndGroups, (state) => state.allVariables);

export const getUsers = createSelector(getModelRuleDataUsersAndGroups, (state) => state.users);

export const getGroups = createSelector(getModelRuleDataUsersAndGroups, (state) => state.groups);

export const getActiveModelRuleGroup = createSelector(getModelRuleDataUsersAndGroups, (state) => state.activeModelRuleGroup);

export const getActiveModelRuleUser = createSelector(getModelRuleDataUsersAndGroups, (state) => state.activeModelRuleUser);

export const getModelsFromAsArray = createSelector(
  getModelsFrom,
  (modelsFrom) => Object.values(modelsFrom) as ModelFromItemType[],
);

export const getModelFromById = (id: string) => createSelector(getModelsFrom, (modelsFrom) => modelsFrom[id]);

export const getModelMetaById = (id: string) =>
  createSelector(getState, (state) => {
    const model = getModelFromById(id)(state);

    return model?.meta || [];
  });

export const getTableColumnsByModelId = (id: string) =>
  createSelector(getState, (state) => {
    const meta = getModelMetaById(id)(state);

    return meta.reduce<Array<SelectItemInterface & { type: string }>>(
      (result, { columns, alias }) => [
        ...result,
        ...columns.map<SelectItemInterface & { type: string }>(({ name, type }) => ({
          value: `${alias}.${name}`,
          name: `${alias}.${name}`,
          type,
        })),
      ],
      [],
    );
  });

export const getCompletionsByModelId = (id: string) =>
  createSelector(getState, (state) => {
    const model = getModelFromById(id)(state);

    if (model) {
      return model.meta.reduce<AdviceEditor[]>(
        (result, { columns, alias }) => [
          ...result,
          ...columns.map<AdviceEditor>(({ name }) => ({ caption: `${alias}.${name}`, value: `${alias}.${name}` })),
        ],
        [],
      );
    }

    return [];
  });

export const getModelsFromLoading = createSelector(getModelsStore, (state) => state.modelsFromLoading);

export const getActiveModelItemAlias = createSelector(getModelsStore, (state) => state.activeModelItemAlias);

export const getModelItemDataByAlias = (alias: string) =>
  createSelector(getActiveTab, (activeTab) => {
    let model: Model | null = null,
      modelItem: ModelItem | null = null,
      relations: Relation[] = [];

    if (activeTab) {
      const { models } = activeTab;

      models.forEach((currentModel) => {
        const currentModelItem = currentModel.modelItems.find((modelItem) => modelItem.alias === alias) || null;

        if (currentModelItem) {
          relations = currentModel.getRelationsByAlias(currentModelItem.alias);
          modelItem = currentModelItem;
          model = currentModel;
        }
      });
    }

    return { model, modelItem, relations } as { model: null | Model; modelItem: ModelItem | null; relations: Relation[] };
  });

export const getActiveTabId = createSelector(getModelsStore, (state) => state.activeTabId);

export const getActiveTab = createSelector([getState, getActiveTabId], (state, activeTabId) => {
  const activeTab = getTabById(activeTabId || '')(state);

  if (activeTab) {
    return activeTab;
  }

  return null;
});

export const getTableAliasesOnActiveTab = createSelector(getActiveTab, (activeTab) => {
  if (activeTab) {
    const { models } = activeTab;

    return models.reduce<Array<Pick<ModelItemType, 'alias' | 'table' | 'db' | 'isHead'>>>((tableAliases, { modelItems }) => {
      const tableAliasesData = modelItems.map(({ alias, table, db, isHead }) => ({ alias, table, db, isHead }));

      return [...tableAliases, ...tableAliasesData];
    }, []);
  }
  return [];
});

export const getTableAliasesByAlias = (activeModelItemAlias: ActiveModelItemAliasInterface | null) =>
  createSelector(getActiveTab, (activeTab) => {
    const tableAliases: Array<{ alias: string; isHead: boolean; isSelf: boolean }> = [];

    const notAddedYet = (alias: string) => !tableAliases.some((table) => alias === table.alias);

    if (activeModelItemAlias && activeTab) {
      const { models } = activeTab,
        alias = activeModelItemAlias.alias;

      models.forEach((model) => {
        const modelItem = model.getModelItemByAlias(alias),
          head = model.modelHead;

        head &&
          notAddedYet(head.alias) &&
          tableAliases.push({ alias: head.alias, isHead: head.isHead, isSelf: head.alias === alias });

        if (modelItem) {
          notAddedYet(modelItem.alias) && tableAliases.push({ alias: modelItem.alias, isHead: modelItem.isHead, isSelf: true });

          model.getRelationsByAlias(alias).forEach((relation) => {
            if (!relation.isSelf) {
              const outerAlias = relation.getOuterLinkByAlias(alias)?.table || '',
                outerModelItem = model.getModelItemByAlias(outerAlias);

              outerModelItem &&
                notAddedYet(outerModelItem.alias) &&
                tableAliases.push({ alias: outerModelItem.alias, isHead: outerModelItem.isHead, isSelf: false });
            }
          });
        }
      });
    }
    return tableAliases;
  });

export const getColumnsByAlias = (alias: string) =>
  createSelector([getState, getTableAliasesOnActiveTab], (state, tableAliasesOnActiveTab) => {
    const tableName = tableAliasesOnActiveTab.find((table) => table.alias === alias)?.table,
      table = getTableByName(tableName || '')(state);

    if (table) {
      return Object.keys(table);
    }

    return [];
  });

export const getTabs = createSelector(getModelsStore, (state) => state.tabs);

export const getTabsAsArray = createSelector(getTabs, (tabs) => Object.values(tabs) as ModelTabInterface[]);

export const getTabById = (id: string) => createSelector(getTabs, (tabs) => tabs[id]);

export const getTabsLoading = createSelector(getModelsStore, (state) => state.tabsLoading);

export const getTables = createSelector(getModelsStore, (state) => state.tables);

export const getTablesAsArray = createSelector(getTables, (tables) =>
  Object.entries(tables).map(([name, value]) => ({ name, fields: value })),
);

export const getTableByName = (name: string) => createSelector(getTables, (tables) => tables[name]);

export const getTablesLoading = createSelector(getModelsStore, (state) => state.tablesLoading);

export const getTablePreviews = createSelector(getModelsStore, (state) => state.tablePreviews);

export const getTablePreviewByName = (name: string) => createSelector(getTablePreviews, (tablePreviews) => tablePreviews[name]);

export const getTablePreviewLoading = createSelector(getModelsStore, (state) => state.tablePreviewLoading);

export const getTablePreviewLoadingByName = (name: string) =>
  createSelector(getTablePreviewLoading, (tablePreviewLoading) => tablePreviewLoading.has(name));

export const getTablePreviewDataByName = (name: string) =>
  createSelector(getState, (state) => ({
    tablePreviewLoading: getTablePreviewLoadingByName(name)(state),
    tablePreview: getTablePreviewByName(name)(state),
  }));
