import { ColorValuesByThemeType } from 'store/reducers/visualisations/types';

export type CursorType = 'pointer' | 'default' | 'not-allowed' | 'no-drop';

export interface HeightInterface {
  height: string;
}

export type TopAndBottomType = 'top' | 'bottom';
export type RightAndLeftType = 'right' | 'left';
export type AbsolutePositionType = TopAndBottomType | RightAndLeftType;

export type BorderStyleType = 'dashed' | 'dotted' | 'solid';

export enum TopAndBottomEnum {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export interface TextStylesInterface {
  fontStyle: {
    bold: boolean;
    italic: boolean;
    underline: boolean;
  };
  fontSize: number;
  letterSpacing: number;
  lineHeight: number;
  fontColor: ColorValuesByThemeType | null;
  backgroundColor: ColorValuesByThemeType | null;
  backgroundOpacity: number;
}
