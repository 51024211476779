import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getActivePageId, getPages } from 'store/reducers/projectPages/getters';
import { useDataSettingsMetric } from 'modules/visualisations/hooks/dataSettingsMetric';
import { MetricsRenderType } from 'modules/settingsContainer/common/data/MetricSettings/types';
import { PageInterface, UpdatePageByIdPayload } from 'store/reducers/projectPages/types';
import { useAppDispatch } from 'store';
import { pxUnit } from 'constants/global';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { FlexContainer } from 'styles/FlexContainer';
import {
  addPageAction,
  copyPageAction,
  deletePageByIdAction,
  updatePageByIdAction,
  updatePagePositionAction,
} from 'store/reducers/projectPages/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { goToBoardUrl, ProjectIdParam } from 'constants/Routes';
import { SettingsPagesFieldEntry } from 'modules/settingsContainer/SettingsPagesFieldEntry';
import { SizeSVGWrapper } from 'modules/settingsContainer/common/data/PropertiesSettings/styles';
import { openConfirmationModalAction } from 'store/reducers/modals/actions';
import { HeaderSettingsAreaLayout } from 'modules/settingsContainer/FieldSettingsArea';
import { BoardMenuItemProps } from 'modules/workspace/components/BoardMenuItem';
import { ListWrapper, PageWrapper, VisualizationItem } from './styles';
import { ClosedEyeIcon, EyeIcon as DefaultEyeIcon, Lock, SizeHIcon, SizeWIcon, Unlock } from 'assets/icons/withContainer';
import { getFilterByProject } from 'store/reducers/filters/getters';
import { getVisualisations } from 'store/reducers/visualisations/getters';
import { getActiveBoardElement, getPageLayers } from 'store/reducers/board/getters';
import { setActiveBoardElement } from 'store/reducers/board';
import {
  onDisableDraggingChange,
  onFilterNameSettingsChange,
  onVisibleSettingsChange,
} from 'modules/settingsContainer/common/data/DefaultFilterEventSettings/constants';
import { updateViewSettingsAction, updateVisibleConfigAction } from 'store/reducers/visualisations/actions';
import { MENU_PAGE_LIST_COMPONENT } from 'modules/workspace/constans';
import { HandlerPayload, ListRenderProps, NameHandlerPayload, WidgetItemWrapperProps, WidgetType } from './types';
import { MetricListSettings } from 'modules/settingsContainer/common/data/MetricListSettings';
import { TextField } from 'modules/ui';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';

const WidgetItemWrapper = ({
  widget,
  onWidgetClick,
  onBlockClick,
  onVisibleClick,
  activePageId,
  activeBoardElementId,
  onWidgetNameChange,
}: WidgetItemWrapperProps) => {
  const [isHoverVisible, setIsHoverVisible] = useState(false);

  const pageId = widget.pageId;
  const id = widget.id;
  const isVisible = widget.isVisible;
  const isBlock = widget.disableDragging || widget.viewSettings?.disableDragging;
  const filterName = widget?.name;
  const visualizationName = widget?.viewSettings?.name;
  const isFilterWidget = !!widget?.type;
  const isSelected = activeBoardElementId === id;
  const isShowVisibleIcon = isHoverVisible || isSelected || isVisible === false;
  const isShowBlockIcon = isHoverVisible || isSelected || isBlock === true;

  if (activePageId !== pageId) {
    return null;
  }

  return (
    <VisualizationItem
      key={id}
      onClick={onWidgetClick(id)}
      selected={isSelected}
      onMouseEnter={() => setIsHoverVisible(true)}
      onMouseLeave={() => setIsHoverVisible(false)}
    >
      <TextField
        onChange={(e) => onWidgetNameChange({ name: e.target.value, isFilterWidget })}
        value={filterName || visualizationName}
        name={id}
        needBackground={false}
        needBorderBottom={false}
      />

      <FlexContainer alignItems="center" gap="5px">
        <FlexContainer width="16px" height="16px">
          {isShowBlockIcon && (
            <IconWrapper
              onClick={(event) => onBlockClick({ event, isSelected, isFlag: !isBlock, isFilterWidget })}
              iconWidth="100%"
              iconHeight="100%"
              Icon={isBlock ? Lock : Unlock}
            />
          )}
        </FlexContainer>
        <FlexContainer width="16px" height="16px">
          {isShowVisibleIcon && (
            <IconWrapper
              onClick={(event) => onVisibleClick({ event, isSelected, isFlag: !isVisible, isFilterWidget })}
              iconWidth="100%"
              iconHeight="100%"
              Icon={isVisible ? DefaultEyeIcon : ClosedEyeIcon}
            />
          )}
        </FlexContainer>
      </FlexContainer>
    </VisualizationItem>
  );
};

export const PagesModal: BoardMenuItemProps['ModalData'] = ({ onClose }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pages = useSelector(getPages);
  const activePageId = useSelector(getActivePageId);
  const activeBoardElementId = useSelector(getActiveBoardElement);
  const { projectId } = useParams<ProjectIdParam>();
  const layers = useSelector(getPageLayers);
  const filters = useSelector(getFilterByProject);
  const visualizations = useSelector(getVisualisations);

  const combinedObjects = useMemo(
    () => ({ ...filters, ...visualizations } as { [x: string]: WidgetType }),
    [filters, visualizations],
  );

  const objectOrderedByLayers = useMemo(() => {
    if (!layers) {
      return combinedObjects;
    }
    const orderedObjects: { [x: string]: WidgetType } = {};
    for (let i = layers.length - 1; i >= 0; i--) {
      const id = layers[i];
      const obj = combinedObjects[id];
      if (obj) {
        orderedObjects[id] = obj;
      }
    }
    return orderedObjects;
  }, [layers, combinedObjects]);

  const { controls, onMetricClick, onSetupClick, setupIsOpen, metricIsSelected } = useDataSettingsMetric();
  const onPageChange = (params: UpdatePageByIdPayload) => dispatch(updatePageByIdAction(params));
  const onAddNewPage = () => dispatch(addPageAction());
  const onDeletePage = (pagesId: string) => {
    const namePage = pages.find((page) => page.id === pagesId)?.name;

    dispatch(
      openConfirmationModalAction({
        confirmationButtonText: 'Да',
        cancelButtonText: 'Отмена',
        description: `Вы собираетесь удалить страницу "${namePage}". Это действие нельзя будет отменить. Продолжить?`,
        onConfirm: () => {
          const nextPagesId = pages.find((el) => el.id !== pagesId)?.id;

          dispatch(deletePageByIdAction(pagesId));
          navigate(goToBoardUrl(projectId, nextPagesId));
        },
        onCancel: () => {},
        titleText: 'Удалить страницу',
      }),
    );
  };

  const onWidgetClick = (id: string) => () => {
    dispatch(setActiveBoardElement(id));
  };

  const onBlockClick = ({ event, isSelected, isFlag, isFilterWidget }: HandlerPayload) => {
    isSelected && event.stopPropagation();

    if (!isFilterWidget) {
      return dispatch(updateViewSettingsAction({ disableDragging: isFlag }));
    }

    onDisableDraggingChange(isFlag);
  };

  const onVisibleClick = ({ event, isSelected, isFlag, isFilterWidget }: HandlerPayload) => {
    isSelected && event.stopPropagation();

    if (!isFilterWidget) {
      return dispatch(updateVisibleConfigAction(isFlag));
    }

    onVisibleSettingsChange(isFlag);
  };

  const onWidgetNameChange = ({ name, isFilterWidget }: NameHandlerPayload) => {
    if (!isFilterWidget) {
      dispatch(updateViewSettingsAction({ name }));
    }

    onFilterNameSettingsChange(name);
  };

  const listRender = ({ widgets, activePageId }: ListRenderProps) => (
    <ListWrapper>
      {widgets &&
        Object.values(widgets).map((widget, index) => (
          <WidgetItemWrapper
            onWidgetClick={onWidgetClick}
            onBlockClick={onBlockClick}
            onVisibleClick={onVisibleClick}
            onWidgetNameChange={onWidgetNameChange}
            key={index}
            widget={widget}
            activePageId={activePageId}
            activeBoardElementId={activeBoardElementId}
          />
        ))}
    </ListWrapper>
  );

  const pageRender: MetricsRenderType<PageInterface> = ({ metrics, setSelectedMetricId }) => {
    const isLastVisibleMetric = metrics.filter((metric) => !metric.isHidden).length === 1;

    return (
      <PageWrapper>
        {metrics.map((page) => {
          const {
            name,
            id,
            isHidden,
            boardSettings: {
              sizes: { width, height },
            },
          } = page;

          const onUpClick = () => dispatch(updatePagePositionAction({ id, moveTo: 'up' }));
          const onDownClick = () => dispatch(updatePagePositionAction({ id, moveTo: 'down' }));
          const onCopiedClick = () => dispatch(copyPageAction());
          const onClickMetric = () => {
            onMetricClick(id);
            setSelectedMetricId(id);
            navigate(goToBoardUrl(projectId, id));
          };
          const isActivePages = activePageId === id;

          return (
            <SettingsPagesFieldEntry
              setupIsOpen={setupIsOpen(id)}
              onSetupClick={onSetupClick(id)}
              onClick={onClickMetric}
              onCopyClick={onCopiedClick}
              isSelected={metricIsSelected(id) || isActivePages}
              isActive={isActivePages}
              isHidden={isHidden}
              fieldValue={name}
              onFieldChange={(name) => onPageChange({ id, page: { ...page, name } })}
              onDownClick={onDownClick}
              onUpClick={onUpClick}
              onClickHidden={() =>
                (!isLastVisibleMetric || isHidden) &&
                onPageChange({
                  id,
                  page: { ...page, isHidden: !isHidden },
                })
              }
              key={id}
            >
              <FlexContainer justifyContent="space-between" width="100%">
                <NumberPropertiesBlock
                  properties={
                    <SizeSVGWrapper>
                      <SizeWIcon />
                    </SizeSVGWrapper>
                  }
                  name="horizontal"
                  unit={pxUnit}
                  value={width}
                  onChange={(width) =>
                    onPageChange({
                      id,
                      page: { ...page, boardSettings: { sizes: { width: width, height } } },
                    })
                  }
                />
                <NumberPropertiesBlock
                  properties={
                    <SizeSVGWrapper>
                      <SizeHIcon />
                    </SizeSVGWrapper>
                  }
                  name="vertical"
                  unit={pxUnit}
                  value={height}
                  onChange={(height) =>
                    onPageChange({
                      id,
                      page: { ...page, boardSettings: { sizes: { width: width, height } } },
                    })
                  }
                />
              </FlexContainer>
            </SettingsPagesFieldEntry>
          );
        })}
      </PageWrapper>
    );
  };

  return (
    <HeaderSettingsAreaLayout id={MENU_PAGE_LIST_COMPONENT} overflow="hidden" headerText="Страницы" onClose={onClose}>
      <MetricListSettings
        addButtonText="Создать страницу"
        metricRender={pageRender}
        listRender={listRender}
        metrics={pages}
        listObjects={objectOrderedByLayers}
        onAdd={() => onAddNewPage()}
        onDelete={(id) => id && onDeletePage(id)}
        controls={controls}
        activePageId={activePageId}
        maxMetricListHeight="calc(100vh - 248px)"
      />
    </HeaderSettingsAreaLayout>
  );
};
