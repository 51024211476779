import React from 'react';
import { useGetActiveVisualisationSettings } from 'utils/hooks/visualisation/getActiveVisualisationSettings';
import { defaultTreeViewSettings } from 'store/reducers/visualisations/constants';
import { DefaultViewSettings } from 'modules/settingsContainer/common/view/DefaultViewSettings';
import { BooleanTipsSettings } from 'modules/settingsContainer/common/view/BooleanTipsSettings';
import { SignaturesSettings } from 'modules/settingsContainer/common/data/SignaturesSettings';
import {
  onChangeSignatures,
  onShowNameIncisionChange,
  onShowTipsChange,
} from 'modules/visualisations/Tree/settings/ViewTab/constants';
import { ShowNameIncIncisionSettings } from 'modules/settingsContainer/common/view/ShowNameIncisionSettings';
import { TextBackgroundSettings } from '../../../../settingsContainer/common/view/TextBackgroundSettings';
import { onChangeBackground } from '../../../Text/settings/ViewTab/constants';

export const ViewTab = () => {
  const { viewSettings, positionConfig } = useGetActiveVisualisationSettings({
    defaultView: defaultTreeViewSettings,
  });

  return (
    <>
      <DefaultViewSettings
        ExternalBackgroundSettings={
          <TextBackgroundSettings
            value={viewSettings.backgroundSettings}
            onChange={onChangeBackground}
            activeBackgraund={false}
          />
        }
        SecondSlot={
          <>
            <ShowNameIncIncisionSettings value={viewSettings.showNameIncision} onChange={onShowNameIncisionChange} />
            <SignaturesSettings onChange={(signatures) => onChangeSignatures(signatures)} value={viewSettings.signatures} />
          </>
        }
        ThirdSlot={<BooleanTipsSettings value={viewSettings.showTips} onChange={onShowTipsChange} />}
        viewSettings={viewSettings}
        positionConfig={positionConfig}
      />
    </>
  );
};
