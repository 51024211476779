import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { UserForm } from 'components/admin/usersPage/Forms/UserForm';

import { UserInfo } from 'store/reducers/adminUsers/types';
import { Button } from 'modules/ui';

interface DataProps {
  editUser: UserInfo | null;
}

export const Data = ({ editUser }: DataProps) => {
  const renderFooter = (): JSX.Element => (
    <FlexContainer width="100%" justifyContent="flex-start" gap="10px" marginTop="32px" flexDirection="row">
      <Button text="Сохранить" iconSize="normal" heightSize="normal" type="submit" />
    </FlexContainer>
  );

  return (
    <FlexContainer width="100%" height="100%">
      <FlexContainer gap="2px" flexWrap="wrap" position="relative" width="250px" height="100%">
        <UserForm mode="edit" data={editUser} footer={renderFooter()} />
      </FlexContainer>
    </FlexContainer>
  );
};
