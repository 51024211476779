import { v4 } from 'uuid';
import { store } from 'store';
import {
  BackgroundSettingsInterface,
  DefaultPropertiesInterface,
  FormattingInterface,
  IndicatorEmptyValuesInterface,
  TextDataSettings,
  TextPositionInterface,
  TextPropertiesSettingsInterface,
  TextVariablesInterface,
} from 'store/reducers/visualisations/types';
import {
  updateSqlSettingsAction,
  updateTextDataSettingsAction,
  updateTextViewSettingsAction,
} from 'store/reducers/visualisations/actions';
import { defaultVariablesName, getTextVariables } from 'store/reducers/visualisations/constants';
import { findNextIndex } from 'utils/utils';
import { Changing } from 'types/changing';
import { SqlSettingsChangesInterface } from 'modules/settingsContainer/common/data/SqlSettings/types';
import { debounce } from 'lodash';
import { TextSettingInterface } from 'types/types';

const dispatch = store.dispatch;

export const propertiesSettingsTitle = 'Изменить свойства текста';

export type OnChangeViewSettingsValue<T> = (dataSettings: TextDataSettings, value: T, id: string) => void;

const getNewVariable = (dataSettings: TextDataSettings, name?: string | null) => {
  const nextIndex = findNextIndex(
      dataSettings.variables.map(({ name }) => name),
      defaultVariablesName,
    ),
    variableName = defaultVariablesName + nextIndex;
  const nameRes = name || variableName;

  return getTextVariables({ name: nameRes, id: v4(), alias: `fontColor${nameRes}` });
};

const variablesActions: Record<
  Changing.ServiceActionType['type'],
  (params: {
    changes: Changing.ServiceActionType;
    dataSettings: TextDataSettings;
    index: number;
  }) => TextVariablesInterface | void
> = {
  change: ({ changes, index, dataSettings: { variables } }) => {
    const { customRequest, order } = changes as Changing.ChangeService;

    let variable = { ...variables[typeof order?.from === 'number' ? order?.from : index] };

    if (customRequest) {
      variable = { ...variable, customRequest: customRequest.to };
    }

    return variable;
  },
  create: ({ changes, dataSettings }) => {
    const { customRequest, alias: name } = changes as Changing.CreateService;

    const variable = getNewVariable(dataSettings, name);

    return { ...variable, customRequest };
  },
  pass: () => {},
  delete: () => {},
};

export const onSqlSettingsSave = (dataSettings: TextDataSettings, sqlSettingsChanges: SqlSettingsChangesInterface) => {
  const { servicesChanges, filterAndGroupRequest } = sqlSettingsChanges;

  const variables = servicesChanges.reduce<TextVariablesInterface[]>((result, changes, index) => {
    const variable = variablesActions[changes.type]({ changes, dataSettings, index });

    if (variable) {
      return [...result, variable];
    }

    return result;
  }, []);

  dispatch(
    updateTextDataSettingsAction({
      ...dataSettings,
      variables,
    }),
  );
  dispatch(updateSqlSettingsAction({ filterAndGroupRequest }));
};

// TODO перенести debounce в сам компонент и добавить управление delay
export const onChangeText = debounce((text: TextSettingInterface) => {
  dispatch(
    updateTextViewSettingsAction({
      text,
    }),
  );
}, 300);

export const onChangePosition = (position: TextPositionInterface) =>
  dispatch(
    updateTextViewSettingsAction({
      position,
    }),
  );

export const onChangeTextProperties = (textProperties: DefaultPropertiesInterface) =>
  dispatch(
    updateTextViewSettingsAction({
      textProperties,
    }),
  );

export const onChangeBackground = (backgroundSettings: BackgroundSettingsInterface) =>
  dispatch(
    updateTextViewSettingsAction({
      backgroundSettings,
    }),
  );

export const onAddNewVariables = (dataSettings: TextDataSettings) => {
  const variable = getNewVariable(dataSettings);

  dispatch(
    updateTextDataSettingsAction({
      ...dataSettings,
      variables: [...dataSettings.variables, variable],
    }),
  );
};

export const onChangeVariablesName: OnChangeViewSettingsValue<string> = (dataSettings, name, id) =>
  dispatch(
    updateTextDataSettingsAction({
      ...dataSettings,
      variables: dataSettings.variables.map((variable) => (id === variable.id ? { ...variable, name } : variable)),
    }),
  );

export const onChangeCustomRequest: OnChangeViewSettingsValue<string | null> = (dataSettings, customRequest, id) =>
  dispatch(
    updateTextDataSettingsAction({
      ...dataSettings,
      variables: dataSettings.variables.map((variable) => (id === variable.id ? { ...variable, customRequest } : variable)),
    }),
  );

export const onDeleteVariables = (dataSettings: TextDataSettings, variablesId: string) => {
  dispatch(
    updateTextDataSettingsAction({
      ...dataSettings,
      variables: dataSettings.variables.filter(({ id }) => id !== variablesId),
    }),
  );
};

export const onChangeVariableFormatting: OnChangeViewSettingsValue<FormattingInterface> = (dataSettings, formatting, id) =>
  dispatch(
    updateTextDataSettingsAction({
      variables: dataSettings.variables.map((variable) =>
        id === variable.id ? { ...variable, settings: { ...variable.settings, formatting } } : variable,
      ),
    }),
  );

export const onChangeVariableEmptyValue: OnChangeViewSettingsValue<IndicatorEmptyValuesInterface> = (
  dataSettings,
  emptyValues,
  id,
) =>
  dispatch(
    updateTextDataSettingsAction({
      variables: dataSettings.variables.map((variable) => (id === variable.id ? { ...variable, emptyValues } : variable)),
    }),
  );

export const onChangePropertiesSettings: OnChangeViewSettingsValue<TextPropertiesSettingsInterface> = (
  dataSettings,
  textPropertiesSettings,
  id,
) =>
  dispatch(
    updateTextDataSettingsAction({
      variables: dataSettings.variables.map((variable) =>
        id === variable.id ? { ...variable, settings: { ...variable.settings, textPropertiesSettings } } : variable,
      ),
    }),
  );

export const onShowVerticalScrollChange = (showVerticalScroll: boolean) =>
  dispatch(updateTextViewSettingsAction({ showVerticalScroll }));

export const onShowHorizontalScrollChange = (showHorizontalScroll: boolean) =>
  dispatch(updateTextViewSettingsAction({ showHorizontalScroll }));
