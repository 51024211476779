import { AddAndDeleteButtons, AddAndDeleteButtonsProps } from 'modules/settingsContainer/AddAndDeleteButtons';
import { MetricWrapper } from 'modules/settingsContainer/common/data/MetricSettings/styles';
import {
  ControlsInterface,
  FieldSettingsRenderType,
  MetricsRenderType,
} from 'modules/settingsContainer/common/data/MetricSettings/types';
import { FieldSettingsArea } from 'modules/settingsContainer/FieldSettingsArea';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React from 'react';
import { VisualisationIdInterface } from 'store/reducers/visualisations/types';
import { FlexContainer } from 'styles/FlexContainer';
import useControls from 'utils/hooks/useControl';

interface IncisionSettingsProps<Metric extends VisualisationIdInterface>
  extends Pick<AddAndDeleteButtonsProps, 'onAdd' | 'addButtonText'> {
  titleText?: string;
  metrics: Metric[];
  metricRender: MetricsRenderType<Metric>;
  fieldSettingsRender?: FieldSettingsRenderType<Metric>;
  disableAddingMetric?: boolean;
  disableDeleteButton?: boolean;
  onDelete?: (id: string | null) => void;
  controls?: ControlsInterface;
  maxMetricListHeight?: string;
}

export const MetricSettings = <Metric extends VisualisationIdInterface>({
  metrics,
  metricRender,
  fieldSettingsRender,
  onDelete,
  onAdd,
  disableAddingMetric,
  titleText,
  addButtonText,
  controls,
  disableDeleteButton = metrics.length === 1,
  maxMetricListHeight,
}: IncisionSettingsProps<Metric>) => {
  const { activeMetricId, setActiveMetricId, selectedMetricId, setSelectedMetricId, onCloseFieldSettings, onUnselectMetric } =
    useControls({ controls });

  const activeMetric = metrics?.find(({ id }) => id === activeMetricId);
  const selectMetric = metrics?.find(({ id }) => id === selectedMetricId);

  const onLocalDelete = () => {
    onUnselectMetric();
    onCloseFieldSettings();
    onDelete && onDelete(selectedMetricId);
  };

  const onDropDownClick = () => {
    selectMetric && onUnselectMetric();
    activeMetric && onCloseFieldSettings();
  };

  return (
    <>
      <MainContainerSettings
        onDropDownClick={titleText ? onDropDownClick : undefined}
        titleText={titleText || ''}
        customPadding="12px 8px 12px"
        isOpenDefault={true}
      >
        <FlexContainer flexDirection="column" gap="12px">
          <MetricWrapper maxHeight={maxMetricListHeight}>
            {metricRender({
              metrics: metrics,
              activeMetricId,
              setActiveMetricId,
              selectedMetricId,
              setSelectedMetricId,
            })}
          </MetricWrapper>

          {!disableAddingMetric && (
            <AddAndDeleteButtons
              addButtonText={addButtonText}
              disable={{ deleteButton: !selectedMetricId || disableDeleteButton }}
              onDelete={onLocalDelete}
              onAdd={onAdd}
            />
          )}
        </FlexContainer>
      </MainContainerSettings>
      {activeMetric && fieldSettingsRender && (
        <FieldSettingsArea onClose={onCloseFieldSettings}>
          {fieldSettingsRender({
            metric: activeMetric,
            metrics,
          })}
        </FieldSettingsArea>
      )}
    </>
  );
};
