import { FilterContainer } from 'modules/workspace/components/FilterContainer';
import { DateEnabledFilterInterface } from 'store/reducers/filters/types';
import React from 'react';
import { EnabledFilterViewInterface } from 'modules/workspace/components/FilterRow/types';
import { getDateByFormat, getStringDateByFormat } from 'utils/dates';
import { byTypeFormat, defaultDateFormat } from 'constants/dates';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { PeriodEnum } from 'modules/ui/inputs/DatePicker/types';
import { parsePeriodFilterDisplayName } from 'utils/utils';

export const DateEnabledFilter: EnabledFilterViewInterface<DateEnabledFilterInterface> = ({
  onDelete,
  selectedValues: { startDate, endDate, byType },
}) => {
  const start = getDateByFormat(startDate, defaultDateFormat),
    end = getDateByFormat(endDate, defaultDateFormat),
    format = byTypeFormat[byType];

  const formattedDate =
    byType === PeriodEnum.TODAY
      ? getStringDateByFormat(new Date(), format)
      : `${getStringDateByFormat(start, format)} — ${getStringDateByFormat(end, format)}`;

  const displayName = parsePeriodFilterDisplayName(formattedDate);

  return (
    <FilterContainer onDelete={onDelete}>
      <PrimaryTextSpan
        cursor="default"
        lineHeight="14px"
        fontSize="14px"
        color={`var(${ColorVarsEnum.Level_1})`}
        whiteSpace="nowrap"
      >
        {displayName}
      </PrimaryTextSpan>
    </FilterContainer>
  );
};
