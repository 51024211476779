import { store } from 'store';
import { LegendSettingsInterface, PieDesignInterface } from 'store/reducers/visualisations/types';
import { updatePieViewSettingsAction } from 'store/reducers/visualisations/actions';
import { PositionSettingType } from 'types/store';

const dispatch = store.dispatch;

export const onLegendSettingsChange = (legendSettings: LegendSettingsInterface) =>
  dispatch(updatePieViewSettingsAction({ legendSettings }));

export const onShowTipsChange = (showTips: boolean) => dispatch(updatePieViewSettingsAction({ showTips }));

export const onIncisionSelectorPositionChange = (incisionSelectorPosition: PositionSettingType) =>
  dispatch(updatePieViewSettingsAction({ incisionSelectorPosition }));

export const onPieStyleSettings = (styleSettings: PieDesignInterface) => dispatch(updatePieViewSettingsAction({ styleSettings }));
