import React, { useCallback } from 'react';
import { MenuItemInterface } from 'modules/ui/menu/ContextMenu/types';
import { PositionConfigInterface } from 'types/store';
import { ContextMenu } from 'modules/ui/menu/ContextMenu';
import { ContextMenuContainer } from 'modules/settingsContainer/ContextMenuWrapper/styles';
import { SQLRequestInterface } from './types';
import { NoopType } from 'types/global';

interface ContextMenuWrapperProps {
  children: React.ReactNode;
  activeWidgetId: string | null;
  isViewer: boolean;
  additionalMenuList: MenuItemInterface[];
  adminMenuList?: MenuItemInterface[];
  menuList?: MenuItemInterface[];
  menuStepInHistoryList?: MenuItemInterface[];
  exportMenu?: MenuItemInterface[];
  exportProject?: MenuItemInterface[];
  sqlRequest?: SQLRequestInterface;
  widgetIdInViewerMode?: boolean;
  onCloseMenu?: NoopType;
}

const ContextMenuWrapperComponents = ({
  children,
  menuList,
  additionalMenuList,
  isViewer,
  activeWidgetId,
  sqlRequest,
  widgetIdInViewerMode = false,
  menuStepInHistoryList,
  exportMenu,
  exportProject,
  adminMenuList,
  onCloseMenu,
}: ContextMenuWrapperProps) => {
  const [menuCoordinate, setMenuCoordinate] = React.useState<PositionConfigInterface | null>(null);

  const onClose = useCallback(() => {
    setMenuCoordinate(null);
    onCloseMenu && onCloseMenu();
  }, [onCloseMenu]);

  const onContextMenuClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setMenuCoordinate((menuCoordinate) => {
      if (menuCoordinate !== null) {
        setTimeout(() => onContextMenuClick(event), 0);

        return null;
      }

      return {
        x: event.clientX + 2,
        y: event.clientY - 6,
      };
    });
  };

  const isWidgetActive = !!activeWidgetId;

  const hasAdditionalMenuList = isWidgetActive ? additionalMenuList : undefined;
  const hasAdminMenuList = isWidgetActive ? adminMenuList : undefined;
  const hasExportProject = isWidgetActive ? exportProject : undefined;
  const hasMenuList = !isViewer ? menuList : [];
  const hasMenuStepInHistoryList = !isViewer ? menuStepInHistoryList : [];
  const hasExportMenu = widgetIdInViewerMode && sqlRequest ? exportMenu : [];
  const isDisabled = isViewer && !sqlRequest;

  return (
    <>
      <ContextMenuContainer onClick={onClose} onContextMenu={onContextMenuClick}>
        {children}
      </ContextMenuContainer>
      <ContextMenu
        onContextMenu={onContextMenuClick}
        additionalMenuList={hasAdditionalMenuList}
        menuList={hasMenuList}
        adminMenuList={hasAdminMenuList}
        menuStepInHistoryList={hasMenuStepInHistoryList}
        exportMenu={hasExportMenu}
        exportProject={hasExportProject}
        onClose={onClose}
        menuCoordinate={menuCoordinate}
        isOpen={!!menuCoordinate}
        disabled={isDisabled}
      />
    </>
  );
};

export const ContextMenuWrapper = React.memo(ContextMenuWrapperComponents);
