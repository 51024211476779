import ReactDatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import { DateOriginType, DatePickerValue, OnChangeType, PeriodEnum } from 'modules/ui/inputs/DatePicker/types';
import React, { forwardRef, memo, MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ru from 'date-fns/locale/ru';
import { DatePickerWrapper } from './styles';
import { addDays, endOfQuarter, getMonth, getYear, isSameDay, startOfQuarter } from 'date-fns';
import { findMonthByYear, generateYearOptions, getMonthOptions, lastDayOfWeek, startOfWeek } from 'utils/dates';
import { byTypeOptions, months } from 'modules/ui/inputs/DatePicker/constants';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { LeftArrow, RightArrow } from 'assets/icons/withContainer';
import { FlexContainer } from 'styles/FlexContainer';
import { NoopType, NoopValueType } from 'types/global';
import { ByType } from 'types/store';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { parseToRgb, rgba } from 'polished';
import { useSelector } from 'react-redux';
import { getActiveThemeColors } from 'store/reducers/themes/getters';
import { DateIcon } from 'assets/icons/editor';
import { Close8px } from 'assets/icons/forDelete';
import CustomSelect from 'components/shared/CustomSelect';
import { byTypeFormat } from 'constants/dates';
import { getActiveBoardElement, getActiveBoardElementInViewMode } from 'store/reducers/board/getters';

let DISABLED_CLICK_OUTSIDE = false;

registerLocale('ru', {
  ...ru,
  //@ts-ignore
  localize: {
    ...ru.localize,
    month: (n) =>
      ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'][n],
  },
});

interface CustomInputProps {
  value?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  onOpen?: MouseEventHandler<HTMLElement>;
  onClear?: MouseEventHandler<HTMLElement>;
  setTitleValue: NoopValueType<string>;
  byType: ByType;
  datePickerName: string;
  isOpen: boolean;
}

// eslint-disable-next-line react/display-name
const CustomInput = forwardRef<HTMLDivElement, CustomInputProps>(
  ({ value, onOpen, onClear, datePickerName, setTitleValue, isOpen }, ref) => {
    const selectedValue = value !== '';

    const displayValue = useMemo(() => {
      if (!value) {
        setTitleValue(datePickerName);
        return datePickerName;
      }

      const processQuarter = (input: string) => (input.includes('квартал') ? input.replace(/квартал/g, 'кв.') : input);

      const dates = value.split(' - ');
      if (dates.length === 1 || dates[0] === dates[1]) {
        const title = processQuarter(dates[0]);

        setTitleValue(title);
        return title;
      }

      const processedTitle = processQuarter(value);

      setTitleValue(processedTitle);
      return processedTitle;
    }, [value, datePickerName, setTitleValue]);

    return (
      <FlexContainer display={isOpen ? 'none' : 'flex'} alignItems="center" gap="8px" ref={ref}>
        <IconWrapper iconWidth="23px" iconHeight="23px" Icon={DateIcon} onClick={onOpen} />
        <PrimaryTextSpan
          cursor="pointer"
          onClick={onOpen}
          color={`var(${ColorVarsEnum.Accent})`}
          fontSize="18px"
          lineHeight="18px"
        >
          {selectedValue ? displayValue : datePickerName}
        </PrimaryTextSpan>
        {selectedValue && (
          <IconWrapper
            iconWidth="10px"
            iconHeight="10px"
            containerWidth="20px"
            containerHeight="24px"
            Icon={Close8px}
            onClick={onClear}
          />
        )}
      </FlexContainer>
    );
  },
);

interface CustomHeaderProps {
  date: Date;
  decreaseMonth: NoopType;
  increaseMonth: NoopType;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  prevYearButtonDisabled: boolean;
  nextYearButtonDisabled: boolean;
  decreaseYear: NoopValueType<any>;
  increaseYear: NoopType;
  changeYear: NoopValueType<number>;
  changeMonth: NoopValueType<number>;
  onByTypeChange: NoopValueType<ByType>;
  byType: ByType;
  isRealData: boolean;
  oldestAndNewestDates: string[];
  monthDate: Date;
  titleValue: string | null;
}

const CustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  nextYearButtonDisabled,
  decreaseYear,
  prevYearButtonDisabled,
  increaseYear,
  changeYear,
  changeMonth,
  onByTypeChange,
  byType,
  isRealData,
  oldestAndNewestDates,
  titleValue,
}: CustomHeaderProps) => {
  const oldestAndNewestMin = oldestAndNewestDates[0];
  const oldestAndNewestMax = oldestAndNewestDates[oldestAndNewestDates.length - 1];
  const onNextMonth = nextMonthButtonDisabled ? undefined : increaseMonth;
  const onPrevMonth = prevMonthButtonDisabled ? undefined : decreaseMonth;
  const onNextYear = nextYearButtonDisabled ? undefined : increaseYear;
  const onPrevYear = prevYearButtonDisabled ? undefined : decreaseYear;

  const byMonth = byType === 'byDay' || byType === 'byWeek';

  const onChangeMonth = useCallback(
    (value: string) => {
      changeMonth(Number(value));
    },
    [changeMonth],
  );

  const onChangeYear = useCallback(
    (value: string) => {
      const monthFound = findMonthByYear(oldestAndNewestDates, Number(value));

      if (monthFound) {
        changeMonth(monthFound);
      }
      changeYear(Number(value));
    },
    [changeMonth, changeYear, oldestAndNewestDates],
  );

  const onChangeType = (value: string) => {
    onByTypeChange(value as ByType);
  };

  const yearsOptions = generateYearOptions(isRealData, [oldestAndNewestMin, oldestAndNewestMax]);

  const monthsOptions = getMonthOptions(isRealData, date, oldestAndNewestDates);

  const valueTypeTitle = byTypeOptions(isRealData, oldestAndNewestMax).filter((el) => el.value === byType)[0]?.title;

  /* TODO:After closing the task (9140) you can delete it */
  // useEffect(() => {
  //   if (isRealData && oldestAndNewestMax) {
  //     const dateFull = new Date(oldestAndNewestMax);
  //
  //     const year = getYear(dateFull);
  //     const month = getMonth(dateFull);
  //
  //     onChangeMonth(String(month));
  //     onChangeYear(String(year));
  //   }
  // }, [isRealData, oldestAndNewestDates, oldestAndNewestMax, onChangeMonth, onChangeYear]);

  return (
    <FlexContainer flexDirection="column" width="100%" padding="0 12px">
      <FlexContainer justifyContent="center" marginBottom="12px">
        <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_1})`} fontSize="14px" fontWeight="bold">
          {titleValue || 'Выбрать период'}
        </PrimaryTextSpan>
      </FlexContainer>
      <FlexContainer justifyContent="center" width="100%" marginBottom="8px">
        <CustomSelect value={valueTypeTitle} options={byTypeOptions(isRealData, oldestAndNewestMax)} onChange={onChangeType} />
      </FlexContainer>
      {byType !== PeriodEnum.TODAY && byType !== PeriodEnum.YEAR && (
        <FlexContainer gap="5px" justifyContent="space-between" width="100%" marginBottom="8px">
          <IconWrapper containerWidth="24px" Icon={LeftArrow} onClick={byMonth ? onPrevMonth : onPrevYear} />
          <FlexContainer gap="5px" width="100%">
            {byMonth && <CustomSelect value={months[getMonth(date)]} options={monthsOptions} onChange={onChangeMonth} />}
            <CustomSelect value={String(getYear(date))} options={yearsOptions} onChange={onChangeYear} width={100} />
          </FlexContainer>
          <IconWrapper containerWidth="24px" Icon={RightArrow} onClick={byMonth ? onNextMonth : onNextYear} />
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

interface DatePickerProps<Type extends ByType, DateType extends DateOriginType = Date, WithRange extends boolean = boolean> {
  disabled?: boolean;
  value?: DatePickerValue<WithRange, DateType>;
  onChange?: OnChangeType<WithRange, DateType>;
  byType?: Type;
  isInline?: boolean;
  name: string;
  id: string;
  oldestAndNewestDates: string[];
  isRealData: boolean;
}

export const DatePickerComponent = <
  Type extends ByType = 'byDay',
  DateType extends DateOriginType = Date,
  WithRange extends boolean = boolean,
>({
  value,
  onChange,
  disabled,
  isInline = false,
  byType = 'byDay' as Type,
  name,
  id,
  oldestAndNewestDates,
  isRealData,
}: DatePickerProps<Type, DateType, WithRange>) => {
  const datePickerRef = useRef<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const colors = useSelector(getActiveThemeColors);
  const activeElementId = useSelector(getActiveBoardElement);
  const activeElementIdInViewMode = useSelector(getActiveBoardElementInViewMode);

  const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [byLocalType, setByLocalType] = useState<ByType>(byType);
  const [titleValue, setTitleValue] = useState<string | null>(null);
  const localSelectsRange = useMemo<WithRange>(() => (byLocalType !== PeriodEnum.TODAY) as WithRange, [byLocalType]);

  const datesRange = useMemo(() => {
    return oldestAndNewestDates.map((date) => new Date(date));
  }, [oldestAndNewestDates]);

  const minDateQuarter = datesRange.length > 0 ? startOfQuarter(datesRange[0]) : new Date();
  const maxDateQuarter = datesRange.length > 0 ? endOfQuarter(datesRange[1]) : new Date();
  const minDate = datesRange.length > 0 ? datesRange[0] : new Date();
  const maxDate = useMemo(() => (datesRange.length > 0 ? datesRange[1] : new Date()), [datesRange]);

  const excludeDates = useMemo(() => {
    if (!datesRange.length) {
      return [new Date()];
    }

    const dates: Date[] = [];
    const now = new Date();

    for (let i = 1; i < 31; i++) {
      dates.push(addDays(now, i));
      dates.push(addDays(now, -i));
    }

    return byLocalType === PeriodEnum.TODAY ? dates : undefined;
  }, [byLocalType, datesRange]);

  const popperModifiers = useMemo(
    () => [
      {
        name: 'offset',
        options: {
          offset: [-8, -18],
        },
      },
    ],
    [],
  );

  const localValue = useMemo(() => {
    let value: DatePickerValue<WithRange, DateType>;

    if (localSelectsRange) {
      let defaultEndDate = startDate;

      if (byLocalType === PeriodEnum.WEEK && startDate) {
        defaultEndDate = lastDayOfWeek(startDate);
      }

      value = [startDate, endDate || defaultEndDate] as DatePickerValue<WithRange, DateType>;
    } else {
      value = startDate as DatePickerValue<WithRange, DateType>;
    }

    return value;
  }, [localSelectsRange, byLocalType, startDate, endDate]);

  const setValue = (value?: DatePickerValue<WithRange, DateType>) => {
    if (Array.isArray(value)) {
      const [start, end] = value;
      setStartDate(start);
      setEndDate(end);
    } else {
      value && setStartDate(value);
    }
  };

  const onLocalChange = useCallback<ReactDatePickerProps<never, WithRange>['onChange']>(
    (dates) => {
      let value = dates as DatePickerValue<WithRange, DateType>;

      if (byLocalType === 'byWeek' && Array.isArray(dates)) {
        const [start, end] = dates;
        const startDate = start ? startOfWeek(start) : null,
          endDate = end ? lastDayOfWeek(end) : null;

        value = [startDate, endDate] as DatePickerValue<WithRange, DateType>;
      }

      setValue(value);

      if (isInline && onChange) {
        onChange({ dates: value, byType: byLocalType }, 'change');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [byLocalType, isInline, onChange],
  );

  const onLocalByTypeChange = useCallback<NoopValueType<ByType>>(
    (byValue) => {
      let dates: Date | null | [Date | null, Date | null] = [null, null];
      if (byValue === PeriodEnum.TODAY) {
        dates = new Date();

        if (!isRealData || (isRealData && isSameDay(maxDate, new Date()))) {
          datePickerRef?.current?.calendar.instanceRef.changeMonth(getMonth(dates));
        }
      }

      setByLocalType(byValue);
      onLocalChange(dates as WithRange extends false | undefined ? Date | null : [Date | null, Date | null], undefined);
    },
    [isRealData, maxDate, onLocalChange],
  );

  const onClear = useCallback(() => {
    const newValue = [null, null] as DatePickerValue<WithRange, DateType>;
    setValue(newValue);
    onChange && onChange({ dates: newValue, byType: byLocalType }, 'clear');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange, byLocalType]);

  const onCancel = useCallback(() => {
    setValue(value);
    setByLocalType(byType);
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [byType, onClose, value]);

  const onOk = useCallback(() => {
    onChange && onChange({ dates: localValue, byType: byLocalType }, 'change');
    onClose();
  }, [onChange, localValue, byLocalType, onClose]);

  useEffect(() => {
    setValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, datesRange]);

  useEffect(() => {
    if ((byType === PeriodEnum.TODAY && !isRealData) || (isRealData && isSameDay(maxDate, new Date()))) {
      datePickerRef?.current?.calendar?.instanceRef?.changeMonth(getMonth(new Date()));
    }
  }, [byType, isRealData, maxDate, setByLocalType]);

  useEffect(() => {
    const footer = document.createElement('div');
    footer.classList.add('react-datepicker__footer');

    const clearButton = document.createElement('button'),
      controlButtonWrapper = document.createElement('div'),
      okButton = document.createElement('button'),
      cancelButton = document.createElement('button');

    if (isOpen) {
      const reactDatePicker =
        document.querySelector('.react-datepicker')?.querySelector('.react-datepicker__month-container') ||
        document.querySelector('.react-datepicker')?.querySelector('.react-datepicker__year--container');

      if (reactDatePicker) {
        const oldFooter = reactDatePicker.querySelector('.react-datepicker__footer');
        oldFooter?.remove();

        clearButton.innerText = 'Сбросить';
        clearButton.classList.add('react-datepicker__clear-button');
        clearButton.addEventListener('click', onClear);

        controlButtonWrapper.classList.add('react-datepicker__control-buttons');

        okButton.innerText = 'Готово';
        okButton.classList.add('react-datepicker__ok-button');
        okButton.addEventListener('click', onOk);

        cancelButton.innerText = 'Отмена';
        cancelButton.classList.add('react-datepicker__cancel-button');
        cancelButton.addEventListener('click', onCancel);

        controlButtonWrapper.append(cancelButton, okButton);
        footer.append(clearButton, controlButtonWrapper);

        reactDatePicker.append(footer);
      }
    }

    return () => {
      clearButton.removeEventListener('click', onClear);
      okButton.removeEventListener('click', onOk);
      cancelButton.removeEventListener('click', onCancel);
    };
  }, [isOpen, onCancel, onClear, onOk, isInline]);

  useEffect(() => {
    if (isOpen && !DISABLED_CLICK_OUTSIDE) {
      datePickerRef.current.calendar.disableOnClickOutside();
      DISABLED_CLICK_OUTSIDE = true;
    }
  }, [isOpen]);

  useEffect(() => {
    if (activeElementId !== id || activeElementIdInViewMode !== id) {
      onCancel();
    }
  }, [activeElementId, id, onCancel, activeElementIdInViewMode]);

  useEffect(() => {
    if (!datesRange.length && isRealData) {
      setStartDate(null);
    }
  }, [isRealData, datesRange]);

  const maxDatePicker = isRealData ? (byLocalType === PeriodEnum.QUARTER ? maxDateQuarter : maxDate) : undefined;
  const minDatePicker = isRealData ? (byLocalType === PeriodEnum.QUARTER ? minDateQuarter : minDate) : undefined;

  return (
    <DatePickerWrapper
      isInline={isInline}
      byLocalType={byLocalType}
      outsideMonthBackgroundColor={rgba({
        ...parseToRgb(colors[ColorVarsEnum.Level_2_btn]),
        alpha: 0.3,
      })}
    >
      <ReactDatePicker
        ref={datePickerRef}
        open={isOpen}
        renderCustomHeader={(props) => (
          <CustomHeader
            {...props}
            onByTypeChange={onLocalByTypeChange}
            byType={byLocalType}
            isRealData={isRealData}
            oldestAndNewestDates={oldestAndNewestDates}
            titleValue={titleValue}
          />
        )}
        maxDate={maxDatePicker}
        minDate={minDatePicker}
        customInput={
          <CustomInput
            datePickerName={name}
            byType={byLocalType}
            onClear={onClear}
            setTitleValue={setTitleValue}
            isOpen={isOpen}
            onOpen={() => setIsOpen((value) => !value)}
          />
        }
        selectsRange={localSelectsRange}
        inline={isInline}
        selected={startDate || maxDate}
        onChange={onLocalChange}
        locale="ru"
        startDate={localSelectsRange ? startDate : undefined}
        endDate={localSelectsRange ? endDate : undefined}
        disabled={disabled}
        showQuarterYearPicker={byLocalType === PeriodEnum.QUARTER}
        showMonthYearPicker={byLocalType === PeriodEnum.MONTH}
        showWeekNumbers={byLocalType === PeriodEnum.WEEK}
        showYearPicker={byLocalType === PeriodEnum.YEAR}
        excludeDates={excludeDates}
        disabledKeyboardNavigation
        showPopperArrow={false}
        popperModifiers={popperModifiers}
        dateFormat={byTypeFormat[byLocalType]}
        //@ts-ignore
        renderQuarterContent={(quarter) => `${quarter} квартал`}
      />
    </DatePickerWrapper>
  );
};

export const DatePicker = memo(DatePickerComponent);
