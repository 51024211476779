import { FlexContainer } from 'styles/FlexContainer';
import { NoopType, NoopValueType } from 'types/global';
import { MenuListComponent } from '../ui/menu/MenuIconList';
import { Option } from 'components/shared/ui/menu/MenuIconList/types';
import { ShortIcon } from 'assets/icons/filter';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { SelectAll, SelectInvertion, SelectNone } from 'assets/icons/withContainer';
import { StyledTooltip } from 'modules/ui/StyledTooltip';
import { WrapperContainer } from '../ui/menu/MenuIconList/styles';
import { SortingValueEnum } from './types';
import React from 'react';

const defaultOptions: Option<string>[] = [
  { name: 'По возрастанию', value: SortingValueEnum.ASC },
  { name: 'По убыванию', value: SortingValueEnum.DESC },
];

interface SortingPanelProps {
  onSelectAllClick?: NoopType;
  onSelectNoneClick?: NoopType;
  onSelectInvertClick?: NoopType;
  onSortingClick?: NoopValueType<SortingValueEnum | string>;
  selectedSorting?: string | null;
  options?: Option<string>[];
}

export const SortingPanel = ({
  onSelectAllClick,
  onSelectNoneClick,
  onSelectInvertClick,
  onSortingClick,
  selectedSorting,
  options,
}: SortingPanelProps) => {
  const sortingOptions = [
    { title: 'Выбрать все', handler: onSelectAllClick, icon: SelectAll },
    { title: 'Инвертировать выделение', handler: onSelectInvertClick, icon: SelectInvertion },
    { title: 'Отменить выделение', handler: onSelectNoneClick, icon: SelectNone },
  ];

  return (
    <FlexContainer display="flex" alignItems="center" gap="2px" maxHeight="20px">
      {onSortingClick && (
        <>
          <MenuListComponent
            options={options || defaultOptions}
            Icon={ShortIcon}
            onSelect={onSortingClick}
            selected={selectedSorting}
            allowToggle
            sortingStatus={selectedSorting as SortingValueEnum}
            onSortingClick={(value) => onSortingClick(value as SortingValueEnum)}
            sortWithClick
          />
        </>
      )}
      {sortingOptions.map(({ handler, icon, title }) => (
        <>
          {handler && (
            <StyledTooltip placement="top" title={title}>
              <div>
                <WrapperContainer>
                  <IconWrapper onClick={handler} Icon={icon} disableDefaultStyles />
                </WrapperContainer>
              </div>
            </StyledTooltip>
          )}
        </>
      ))}
    </FlexContainer>
  );
};
