import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { SortingValueEnum } from 'components/shared/SortingPanel/types';
import { SingleFilterSortingStatusInterface } from 'store/reducers/filters/types';
import { NoopValueType } from 'types/global';
import { FlexContainer } from 'styles/FlexContainer';
import { ShortSvg } from './ShortSvg';

export interface ShortFilterProps extends SingleFilterSortingStatusInterface {
  onSortingClick?: NoopValueType<SortingValueEnum | ''>;
}

export const ShortFilter = ({ sortingStatus, onSortingClick }: ShortFilterProps) => {
  const onClick = () => {
    if (!onSortingClick) return;
    if (!sortingStatus) return onSortingClick(SortingValueEnum.ASC);
    if (sortingStatus === SortingValueEnum.ASC) return onSortingClick(SortingValueEnum.DESC);
    else onSortingClick('');
  };

  const getStrokeColor = (status: SortingValueEnum) =>
    sortingStatus === status ? `var(${ColorVarsEnum.Accent})` : `var(${ColorVarsEnum.Level_1})`;

  return (
    <FlexContainer height="100%" onClick={onClick} cursor="pointer">
      <ShortSvg strokeUpArrow={getStrokeColor(SortingValueEnum.DESC)} strokeDownArrow={getStrokeColor(SortingValueEnum.ASC)} />
    </FlexContainer>
  );
};
