import { ElementContainerSettings, GroupContainerSettings } from 'modules/settingsContainer';
import { SqlAutocomplete } from 'modules/ui';
import { SaveCancelButtons } from 'modules/ui/blocks/SaveCancelButtons';
import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { NoopType, NoopValueType } from 'types/global';

interface SQLTextAreaProps {
  sqlCondition: string | null;
  onChange: NoopValueType<string>;
  onSave: NoopType;
  onCancel: NoopType;
  hasChanges: boolean;
}

export const SQLTextArea = ({ sqlCondition, onChange, onSave, onCancel, hasChanges }: SQLTextAreaProps) => {
  return (
    <GroupContainerSettings>
      <ElementContainerSettings>
        <FlexContainer padding="0 10px 10px 2px" flexDirection="column" width="100%">
          <SqlAutocomplete height="270px" handleChange={onChange} value={sqlCondition || ''} />
          <FlexContainer justifyContent="flex-end" margin="10px 8px 0 0">
            <SaveCancelButtons onCancel={onCancel} onSave={onSave} hasChanges={hasChanges} />
          </FlexContainer>
        </FlexContainer>
      </ElementContainerSettings>
    </GroupContainerSettings>
  );
};
