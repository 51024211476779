import { Autocomplete } from '@mui/material';
import styled from 'styled-components';
import { IStyle, SelectItemInterface } from 'modules/ui/Select';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const StyledCombobox = styled(Autocomplete<SelectItemInterface, boolean, boolean>)<
  Pick<IStyle, '$needBackground' | '$heightSize' | '$width' | '$visibleElements'>
>`
  background: ${({ $needBackground }) =>
    $needBackground ? `var(${ColorVarsEnum.Level_5_application})` : `var(${ColorVarsEnum.Level_3_menu})`};
  height: ${(props) => (props.$heightSize === 'normal' ? '30px' : '24px')};
  cursor: pointer !important;
  border-radius: 3px !important;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  .MuiInputBase-root {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 200%;
    -webkit-text-fill-color: ${`var(${ColorVarsEnum.Level_1})`};
    height: ${(props) => (props.$heightSize === 'normal' ? '30px !important' : '24px !important')};
    padding: 0 39px 0 8px !important;
    border-style: solid !important;
    border-color: ${(props) =>
      props.$needBackground
        ? `var(${ColorVarsEnum.Level_4}) !important`
        : props.value
        ? `var(${ColorVarsEnum.Level_4}) !important`
        : `var(${ColorVarsEnum.Level_3_menu}) !important`};
    border-width: 1px !important;

    input {
      caret-color: white;
      padding-left: 0 !important;
    }
  }

  .MuiAutocomplete-popupIndicator,
  .MuiAutocomplete-clearIndicator {
    svg {
      height: 16px;
      width: 16px;
      path {
        stroke: ${`var(${ColorVarsEnum.Level_3}) !important`};
      }
    }
  }

  .MuiAutocomplete-clearIndicator {
    svg {
      height: 16px;
      width: 16px;
      path {
        stroke: ${`var(${ColorVarsEnum.Level_3}) !important`};
        fill: ${`var(${ColorVarsEnum.Level_3}) !important`};
      }
    }
  }

  :hover {
    fieldset {
      border: unset !important;
    }
    background: ${`var(${ColorVarsEnum.Level_5_application})`};

    .MuiInputBase-root {
      border-color: ${`var(${ColorVarsEnum.Level_4}) !important`};
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  .MuiPaper-root.MuiAutocomplete-paper {
    position: inherit !important;
    background: ${`var(${ColorVarsEnum.Level_3_menu})`};
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    border: 1px solid ${`var(${ColorVarsEnum.Level_4}) !important`};
    border-top-width: 0;
    width: ${(props) => props.$width};
    z-index: 9999 !important;

    ul {
      cursor: pointer;
      padding: 0;
      max-height: ${({ $visibleElements, $heightSize }) =>
        $heightSize === 'normal' ? `calc(30px * ${$visibleElements})` : `calc(24px * ${$visibleElements})`};

      li {
        color: ${`var(${ColorVarsEnum.Level_1})`};
        transition: all 200ms;
        padding: 8px;
        height: ${(props) => (props.$heightSize === 'normal' ? '30px' : '24px')};
        background: ${`var(${ColorVarsEnum.Level_3_menu})`};
        text-overflow: ellipsis;
        white-space: nowrap;

        &.Mui-focused {
          background-color: ${`var(${ColorVarsEnum.Level_3_menu})`};
        }

        div,
        span {
          z-index: 2;
        }
      }

      li:hover {
        background-color: ${`var(${ColorVarsEnum.Level_3_menu})`};
        :before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: ${`var(${ColorVarsEnum.Level_5})`};
          z-index: 1;
        }
      }

      .Mui-selected {
        color: ${`var(${ColorVarsEnum.Accent})`};
      }
    }

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      transform: rotate(90deg);
      top: 20px;
      right: 20px;
      position: absolute;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: ${`var(${ColorVarsEnum.Level_5})`};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: ${`var(${ColorVarsEnum.Level_4})`};
    }
  }
`;
