import { useContainerColors } from 'modules/visualisations/hooks/containerColors';
import { BoardContainer } from 'modules/workspace/components/BoardContainer';
import { FilterRender } from 'modules/workspace/components/FilterArea/constants';
import { AreaInterface } from 'modules/workspace/components/WorkAreaSpace/types';
import { memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { updateFilterActionById } from 'store/reducers/filters/actions';
import { getActiveFilter, getFiltersValuesStateByFilterId } from 'store/reducers/filters/getters';
import { FilterDataType, FilterTypeEnum } from 'store/reducers/filters/types';
import { getActiveVisualisationSettings } from 'store/reducers/visualisations/getters';
import { BoardPositionConfigInterface, FilterElementModeEnum } from 'types/store';
import { FilterInfluencePopup } from '../FilterInfluencePopup';
import { onSelectedDataByIdChange } from 'modules/filters/Single/settings/DataTab/constants';
import { FlexContainer } from 'styles/FlexContainer';
import { StyledTooltip } from 'modules/ui/StyledTooltip';
import { InfoIcon } from 'assets/icons/withContainer';

export const Filter: AreaInterface<FilterDataType> = ({
  data,
  scale,
  isShowMode,
  isActive,
  whereQuery,
  isLoading,
  isError,
  isInfluenceEditing,
  onInfluenceChange,
}) => {
  const dispatch = useAppDispatch();

  const {
    id,
    positionConfig,
    type,
    disableDragging,
    borderSettings,
    showBackground,
    shadowSettings,
    isVisible,
    isBlock,
    nameSettings: { filterElementMode },
    paddingSettings,
  } = data;
  const visualizationData = useSelector(getActiveVisualisationSettings);
  const filterData = useSelector(getActiveFilter);
  const { filterValues, loadingFilterValue } = useSelector(getFiltersValuesStateByFilterId(id));

  const isShowHint = !!data?.hint?.isShow;

  const containerColors = useContainerColors({ borderSettings, shadowSettings, showBackground });

  const { Filter, MainSettings } = FilterRender[type];

  const visualizationInfluences = visualizationData?.events?.filterSettings?.filterInfluences;
  const filterVisualizationInfluences = filterData?.filterInfluences;
  const filterInfluences = visualizationInfluences || filterVisualizationInfluences || {};

  const onPositionConfigChange = (config: Partial<BoardPositionConfigInterface>) => {
    dispatch(
      updateFilterActionById({
        id,
        data: { positionConfig: { ...positionConfig, ...config } },
      }),
    );
  };

  const handleFilterInfluence = (payload: boolean) => () => {
    const events = visualizationData?.events;
    const filterSettings = events?.filterSettings;

    const data = {
      visualizationInfluences: {
        ...events,
        filterSettings: { ...filterSettings, filterInfluences: { ...filterInfluences, [id]: payload } },
      },
      filterInfluences: { ...filterInfluences, [id]: payload },
      isVisualization: !!visualizationData?.visualisationType,
    };

    onInfluenceChange(data);
  };

  const saveDynamicSingleFilterValues = useCallback(() => {
    if (filterElementMode === FilterElementModeEnum.DYNAMICALLY && type === FilterTypeEnum.SINGLE) {
      const selectedValues = filterValues.map(({ value }) => value);

      onSelectedDataByIdChange(id, selectedValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterElementMode, type, filterValues, id, onSelectedDataByIdChange]);

  useEffect(() => {
    if (!loadingFilterValue) {
      saveDynamicSingleFilterValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, saveDynamicSingleFilterValues, filterElementMode]);

  if (!isVisible) {
    return null;
  }

  return (
    /* TODO: Remove ts-ignore */
    //@ts-ignore
    <BoardContainer
      isLoading={isLoading}
      isShowMode={isShowMode}
      isActive={isActive}
      isError={isError}
      isBlock={isBlock}
      isInfluenceEditing={isInfluenceEditing}
      scale={scale}
      id={id}
      SettingComponent={MainSettings}
      config={positionConfig}
      onChange={onPositionConfigChange}
      disableDragging={disableDragging}
      shadowSettings={shadowSettings}
      borderSettings={borderSettings}
      {...containerColors}
      paddingSettings={paddingSettings}
    >
      {isInfluenceEditing && (
        <FilterInfluencePopup
          isActive={isActive}
          id={id}
          isInfluenceEditing={isInfluenceEditing}
          handleFilterInfluence={handleFilterInfluence}
          filterInfluences={filterInfluences}
        />
      )}
      {isShowHint && (
        <FlexContainer position="absolute" right="9px" top="9px" zIndex="10">
          <FlexContainer>
            <StyledTooltip title={data.hint.text}>
              <InfoIcon />
            </StyledTooltip>
          </FlexContainer>
        </FlexContainer>
      )}
      <Filter data={data} whereQuery={whereQuery} isLoading={isLoading} />
    </BoardContainer>
  );
};

export const FilterArea = memo(Filter);
