import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { SourceEnum, SourceTypes } from 'store/reducers/visualisations/types';
import { RadioSelectedItem } from 'components/RadioSelected';
import { AbsolutePositionSettingType, PositionSettingEnum, PositionSettingType } from 'types/store';
import {
  HorizontalCenterImageIcon,
  HorizontalEndImageIcon,
  HorizontalStartImageIcon,
  PositionBottomIcon,
  PositionLeftIcon,
  PositionRightIcon,
  PositionTopIcon,
  VerticalCenterImageIcon,
  VerticalEndImageIcon,
  VerticalStartImageIcon,
} from 'assets/icons/fontStyle';

export const ImagesSourceModeOptions: SettingsRadioItem<SourceTypes>[] = [
  { value: SourceEnum.DATABASE, label: 'База данных' },
  { value: SourceEnum.BYCONDITION, label: 'По условию' },
  { value: SourceEnum.MANUAL, label: 'Вручную' },
];

export const alignmentHorizontalImageOptions: RadioSelectedItem<PositionSettingType>[] = [
  {
    Component: HorizontalStartImageIcon,
    value: PositionSettingEnum.FLEXSTART,
  },
  {
    Component: HorizontalCenterImageIcon,
    value: PositionSettingEnum.CENTER,
  },
  {
    Component: HorizontalEndImageIcon,
    value: PositionSettingEnum.FLEXEND,
  },
];

export const alignmentVerticalImageOptions: RadioSelectedItem<PositionSettingType>[] = [
  {
    Component: VerticalStartImageIcon,
    value: PositionSettingEnum.FLEXSTART,
  },
  {
    Component: VerticalCenterImageIcon,
    value: PositionSettingEnum.CENTER,
  },
  {
    Component: VerticalEndImageIcon,
    value: PositionSettingEnum.FLEXEND,
  },
];

export const positionImageOptions: RadioSelectedItem<AbsolutePositionSettingType>[] = [
  {
    Component: PositionTopIcon,
    value: 'column',
  },
  {
    Component: PositionLeftIcon,
    value: 'row',
  },
  {
    Component: PositionRightIcon,
    value: 'row-reverse',
  },
  {
    Component: PositionBottomIcon,
    value: 'column-reverse',
  },
];
