import { store } from 'store';
import { updateFilterAction, updateSingleFilterAction } from 'store/reducers/filters/actions';
import {
  BoardPositionConfigInterface,
  BorderStyleSettingsInterface,
  ShadowSettingInterface,
  SqlDataInterface,
  PositionSettingType,
} from 'types/store';
import { MapRecordType } from 'types/global';
import { FilterElementsInterface } from 'modules/settingsContainer/FIlterIncisionsSettings/types';
import { TextSettingInterface } from 'types/types';

const dispatch = store.dispatch;

export const onRealDataChange = (isRealData: boolean) => dispatch(updateFilterAction({ isRealData }));
export const onDisableDraggingChange = (disableDragging: boolean) => dispatch(updateFilterAction({ disableDragging }));
export const onShadowSettingsChange = (shadowSettings: ShadowSettingInterface) =>
  dispatch(updateFilterAction({ shadowSettings }));
export const onShowBackgroundChange = (showBackground: boolean) => dispatch(updateFilterAction({ showBackground }));
export const onBorderSettingsChange = (borderSettings: BorderStyleSettingsInterface) =>
  dispatch(updateFilterAction({ borderSettings }));
export const onPositionConfigChange = (positionConfig: BoardPositionConfigInterface) =>
  dispatch(updateFilterAction({ positionConfig }));
export const onModelIdChange = (modelId: string | null) => dispatch(updateFilterAction({ modelId }));
export const onGlobalChange = (isGlobal: boolean) => dispatch(updateFilterAction({ isGlobal }));
export const onFilterInfluencesChange = (filterInfluences: MapRecordType<boolean>) =>
  dispatch(updateFilterAction({ filterInfluences }));
export const onSqlDataChange = (sqlData: SqlDataInterface) => dispatch(updateFilterAction({ ...sqlData }));
export const onFilterElementsChange = (filterElements: FilterElementsInterface) =>
  dispatch(updateFilterAction({ ...filterElements }));

export const onHintChange = (hint: TextSettingInterface) => dispatch(updateSingleFilterAction({ hint }));

export const onFilterNameSettingsChange = (name: string) => dispatch(updateFilterAction({ name }));
export const onVisibleSettingsChange = (isVisible: boolean) => dispatch(updateFilterAction({ isVisible }));
export const onPositionChange = (position: PositionSettingType) => dispatch(updateFilterAction({ position }));
