import { createSlice, isAllOf, PayloadAction } from '@reduxjs/toolkit';
import {
  getInitialVisualisation,
  initialVisualisationStoreState,
  updateBackgroundSettingsDataFunc,
  updateBlockSettingsFunc,
  updateDataSettingsDataFunc,
  updateEventsDataFunc,
  updatePositionConfigFunc,
  updateViewSettingsDataFunc,
  updateVisibleSettingsFunc,
} from 'store/reducers/visualisations/constants';
import {
  AddNewVisualisationByDataPayload,
  AddNewVisualisationPayload,
  UpdateSqlDataPayload,
  UpdateVisualisationsErrorList,
  UpdateVisualisationsLoadingList,
  VisualisationValuesPayload,
} from 'store/reducers/visualisations/types';
import {
  getVisualisationValuesAction,
  loadAllPreviewTemplateVisualisationsAction,
  loadVisualisationsByPageIdAction,
  loadVisualisationsFromSnapshotAction,
} from 'store/reducers/visualisations/actions';
import { setSliceFn } from 'constants/store';
import { mergeRightMapOfSetWithLeft } from 'utils/utils';
import { uploadDashboardSettingsAction } from 'store/reducers/projectSettings/actions';
import { NameInterface } from 'types/store';

export const visualisationsSlice = createSlice({
  name: 'visualisations',
  initialState: initialVisualisationStoreState,
  reducers: {
    addNewVisualisation: (state, { payload: { visualisationType, data } }: PayloadAction<AddNewVisualisationPayload>) => {
      const { id, pageId } = data;

      state.visualisations = {
        ...state.visualisations,
        [id]: getInitialVisualisation({ visualisationType, data }),
      };

      const setOfIds = state.visualisationsByPages[pageId] || new Set<string>();
      setOfIds.add(id);

      state.visualisationsByPages = { ...state.visualisationsByPages, [pageId]: setOfIds };
    },

    addNewVisualisationByData: (state, { payload: data }: PayloadAction<AddNewVisualisationByDataPayload>) => {
      const { id, pageId } = data;

      state.visualisations = {
        ...state.visualisations,
        [id]: data,
      };

      const setOfIds = state.visualisationsByPages[pageId] || new Set<string>();
      setOfIds.add(id);

      state.visualisationsByPages = { ...state.visualisationsByPages, [pageId]: setOfIds };
    },

    addNewTemplateVisualisation: (state, { payload: data }: PayloadAction<AddNewVisualisationByDataPayload>) => {
      const { id, pageId } = data;

      state.visualisations = {
        ...state.visualisations,
        [id]: data,
      };

      const setOfIds = state.visualisationsByPages[pageId] || new Set<string>();
      setOfIds.add(id);

      state.visualisationsByPages = { ...state.visualisationsByPages, [pageId]: setOfIds };
    },

    updateSqlData: (state, { payload: { id, data } }: PayloadAction<UpdateSqlDataPayload>) => {
      const visualisation = state.visualisations[id];

      if (visualisation && visualisation?.sqlData) {
        visualisation.sqlData = { ...visualisation.sqlData, ...data };
      }
    },

    updateLoadingList: (state, { payload: { id: visualisationId } }: PayloadAction<UpdateVisualisationsLoadingList>) => {
      state.visualisationsLoadingList = { ...state.visualisationsLoadingList, [visualisationId]: false };
    },

    updateEventsData: updateEventsDataFunc,

    updateViewSettingsData: updateViewSettingsDataFunc,

    updateBackgroundSettingsData: updateBackgroundSettingsDataFunc,

    updateDataSettingsData: updateDataSettingsDataFunc,

    updateDataSettingsIndicatorStackSumData: updateDataSettingsDataFunc,

    updatePositionConfig: updatePositionConfigFunc,

    updateVisibleSettings: updateVisibleSettingsFunc,

    updateBlockSettings: updateBlockSettingsFunc,

    removeVisualisationById: (state, { payload: id }: PayloadAction<string>) => {
      const pageId = state.visualisations[id]?.pageId;

      if (pageId) {
        delete state.visualisations[id];

        const setOfIds = state.visualisationsByPages[pageId] || new Set<string>();
        setOfIds.delete(id);

        state.visualisationsByPages = { ...state.visualisationsByPages, [pageId]: setOfIds };
      }
    },

    updateVisualisationValuesById: (state, { payload: { data, id } }: PayloadAction<VisualisationValuesPayload>) => {
      state.visualisationData = {
        ...state.visualisationData,
        [id]: {
          ...state.visualisationData[id],
          visualisationValues: data,
        },
      };
    },

    removeVisualisationValuesById: (state, { payload: id }: PayloadAction<string>) => {
      if (state.visualisationData[id]) {
        delete state.visualisationData[id];
      }
    },

    removeFromVisualisationLoadingListById: (state, { payload: id }: PayloadAction<string>) => {
      if (state.visualisationsLoadingList.hasOwnProperty(id)) {
        delete state.visualisationsLoadingList[id];
      }
    },

    addToAlreadyLoadedByPageId: (state, { payload: pageId }: PayloadAction<string>) => {
      const currentState = state.alreadyLoadedContent.visualisations || new Set<string>();
      currentState.add(pageId);

      state.alreadyLoadedContent.visualisations = currentState;
    },

    addToVisualizationErrorList: (
      state,
      { payload: { id: visualisationId, text } }: PayloadAction<UpdateVisualisationsErrorList>,
    ) => {
      state.visualisationsErrorsList = { ...state.visualisationsErrorsList, [visualisationId]: text };
    },

    removeFromVisualizationErrorList: (
      state,
      { payload: { id: visualisationId } }: PayloadAction<UpdateVisualisationsErrorList>,
    ) => {
      state.visualisationsErrorsList = { ...state.visualisationsErrorsList, [visualisationId]: null };
    },

    //----------------------RETURN FOR HISTORY----------------------

    returnEventsData: updateEventsDataFunc,

    returnViewSettingsData: updateViewSettingsDataFunc,

    returnBackgroundSettingsData: updateBackgroundSettingsDataFunc,

    returnDataSettingsData: updateDataSettingsDataFunc,

    returnPositionConfig: updatePositionConfigFunc,

    setSlice: setSliceFn,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getVisualisationValuesAction.pending,
      (
        state,
        {
          meta: {
            arg: { visualisationId },
          },
        },
      ) => {
        state.visualisationsLoadingList = { ...state.visualisationsLoadingList, [visualisationId]: true };
      },
    );
    builder.addCase(
      getVisualisationValuesAction.rejected,
      (
        state,
        {
          meta: {
            arg: { visualisationId },
          },
        },
      ) => {
        state.visualisationsLoadingList = { ...state.visualisationsLoadingList, [visualisationId]: false };
      },
    );

    builder.addCase(
      loadVisualisationsByPageIdAction.fulfilled,
      (
        state,
        {
          payload: { visualisations, visualisationsByPages },
          meta: {
            arg: { pageId },
          },
        },
      ) => {
        state.visualisations = { ...state.visualisations, ...visualisations };
        state.serverStateOfVisualisations = { ...state.visualisations, ...visualisations };

        const mergedVisualisationsByPages = mergeRightMapOfSetWithLeft(state.visualisationsByPages, visualisationsByPages);

        state.visualisationsByPages = { ...mergedVisualisationsByPages };

        const newAlreadyLoadedContent = state.alreadyLoadedContent.visualisations;
        newAlreadyLoadedContent.add(pageId);
        state.alreadyLoadedContent.visualisations = newAlreadyLoadedContent;
      },
    );

    builder.addCase(loadAllPreviewTemplateVisualisationsAction.pending, (state) => {
      state.allPreviewTemplateVisualisations.loading = true;
      state.allPreviewTemplateVisualisations.allPreviewTemplateVisualisationsList = [];
    });
    builder.addCase(loadAllPreviewTemplateVisualisationsAction.fulfilled, (state, { payload }) => {
      state.allPreviewTemplateVisualisations.loading = false;
      state.allPreviewTemplateVisualisations.allPreviewTemplateVisualisationsList = payload;
    });
    builder.addCase(loadAllPreviewTemplateVisualisationsAction.rejected, (state) => {
      state.allPreviewTemplateVisualisations.loading = false;
      state.allPreviewTemplateVisualisations.allPreviewTemplateVisualisationsList = [];
    });

    builder.addCase(
      loadVisualisationsFromSnapshotAction.fulfilled,
      (state, { payload: { visualisations, visualisationsByPages } }) => {
        state.visualisations = visualisations;
        state.visualisationsByPages = visualisationsByPages;
      },
    );
    builder.addMatcher(isAllOf(uploadDashboardSettingsAction.fulfilled), (state) => {
      if (state.serverStateOfVisualisations) {
        state.serverStateOfVisualisations = state.visualisations;
      }
    });
  },
});

export const {
  addNewVisualisation,
  addNewTemplateVisualisation,
  addNewVisualisationByData,
  updateVisualisationValuesById,
  removeVisualisationValuesById,
  removeFromVisualisationLoadingListById,
  updateLoadingList,
  addToVisualizationErrorList,
  removeFromVisualizationErrorList,
  updatePositionConfig,
  returnPositionConfig,
  removeVisualisationById,
  updateViewSettingsData,
  returnViewSettingsData,
  returnEventsData,
  updateBackgroundSettingsData,
  updateVisibleSettings,
  updateBlockSettings,
  updateDataSettingsData,
  updateDataSettingsIndicatorStackSumData,
  returnDataSettingsData,
  updateSqlData,
  updateEventsData,
  addToAlreadyLoadedByPageId,
  setSlice,
  returnBackgroundSettingsData,
} = visualisationsSlice.actions;

export default visualisationsSlice.reducer;
