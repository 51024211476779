import { OnValueChange } from 'types/global';
import { DynamicMarkerModeEnum, DynamicMarkerSettingsInterface } from 'store/reducers/visualisations/types';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import React, { useRef } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { pxUnit } from 'constants/global';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import SubSection from 'shared/ui/subSection';
import { dynamicsMarkerModeOptions, dynamicsMarkerPositionOptions } from './constants';
import Button from 'modules/ui/Button';
import { useModalState } from 'utils/hooks/modalState';
import { ManualSettingsDynamicMarker } from './ManualSettingsDynamicMarker';
import { SQLSettingsDynamicMarker } from './SQLSettingsDynamicMarker';

interface DynamicsMarkerSettingsProps extends OnValueChange<DynamicMarkerSettingsInterface> {
  isPivot?: boolean;
}

export const DynamicMarkerSettings = ({ value, onChange, isPivot }: DynamicsMarkerSettingsProps) => {
  const mainContainerRef = useRef<HTMLDivElement | null>(null);
  const { isOpen, onOpen, onClose } = useModalState();

  const { isShow, position, indent, sizeMarker, settingsMarker } = value;

  return (
    <MainContainerSettings
      titleText="Маркер динамики"
      switcherState={isShow}
      switcherChange={() => onChange({ ...value, isShow: !isShow })}
      ref={mainContainerRef}
    >
      <SubSection>
        <GroupContainerSettings titleText="Позиционирование">
          <ElementContainerSettings>
            <FlexContainer justifyContent="space-between" width="100%">
              <SettingsRadio
                onChange={({ value: position }) => onChange({ ...value, position })}
                activeValue={getActiveRadioValue(dynamicsMarkerPositionOptions, position)}
                options={dynamicsMarkerPositionOptions}
              />
              <NumberPropertiesBlock
                properties="Отступ"
                name="indent dynamics marker"
                value={indent}
                unit={pxUnit}
                onChange={(indent) => onChange({ ...value, indent: indent || 0 })}
              />
            </FlexContainer>
          </ElementContainerSettings>
        </GroupContainerSettings>
      </SubSection>
      <SubSection>
        <GroupContainerSettings>
          <ElementContainerSettings>
            <NumberPropertiesBlock
              properties="Размер"
              name="size dynamics marker"
              value={sizeMarker}
              unit={pxUnit}
              onChange={(sizeMarker) => onChange({ ...value, sizeMarker: sizeMarker || 0 })}
              isFirstLevel
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
      </SubSection>
      <SubSection>
        <GroupContainerSettings titleText="Режим настройки">
          <ElementContainerSettings>
            <FlexContainer justifyContent="space-between" width="100%">
              <SettingsRadio
                onChange={({ value: mode }) => onChange({ ...value, settingsMarker: { ...settingsMarker, mode: mode } })}
                activeValue={getActiveRadioValue(dynamicsMarkerModeOptions, settingsMarker.mode)}
                options={dynamicsMarkerModeOptions}
              />
            </FlexContainer>
          </ElementContainerSettings>
          {settingsMarker.mode === DynamicMarkerModeEnum.MANUAL && (
            <ElementContainerSettings>
              <Button text="Настроить" onClick={isOpen ? onClose : onOpen} heightSize="small" />
            </ElementContainerSettings>
          )}
        </GroupContainerSettings>
        {settingsMarker.mode === DynamicMarkerModeEnum.MANUAL && (
          <ManualSettingsDynamicMarker
            isOpen={isOpen}
            onClose={onClose}
            ref={mainContainerRef}
            value={settingsMarker.manual}
            onChange={(manualSettings) => onChange({ ...value, settingsMarker: { ...settingsMarker, manual: manualSettings } })}
          />
        )}
        {settingsMarker.mode === DynamicMarkerModeEnum.SQL && (
          <SQLSettingsDynamicMarker
            value={settingsMarker.sql}
            onChange={(sqlSettings) =>
              onChange({
                ...value,
                settingsMarker: {
                  ...settingsMarker,
                  sql: sqlSettings,
                },
              })
            }
            isPivot={isPivot}
          />
        )}
      </SubSection>
    </MainContainerSettings>
  );
};
