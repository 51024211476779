import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import styled, { css } from 'styled-components';

type IconContainerType = {
  activeHover?: boolean;
};

export const IconContainer = styled.div<IconContainerType>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: all 200ms;

  ${({ activeHover }) =>
    activeHover &&
    css`
      border: 1px solid var(${ColorVarsEnum.Accent});
    `};

  ${({ activeHover }) =>
    !activeHover &&
    css`
      &:hover {
        border: 1px solid var(${ColorVarsEnum.Accent});
      }
    `};
`;
