import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import React from 'react';
import { ElementDesignInterface } from 'store/reducers/visualisations/types';
import { OnValueChange } from 'types/global';
import SubSection from 'shared/ui/subSection';
import { PropertiesSettings } from 'modules/settingsContainer/common/data/PropertiesSettings';
import {
  getColorSettings,
  getColorSettingsProperty,
  getPropertiesSettings,
  getPropertiesSettingsProperty,
  typeStrategyOptions,
} from 'modules/settingsContainer/common/view/ElementDesignSettings/constants';
import { ColorBySettings } from 'modules/settingsContainer/common/ColorBySettings';
import { CircleActivator, ColorPicker } from 'modules/settingsContainer/ColorPicker';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { ColorStrategyEnum } from 'store/reducers/visualisations/constants';

type ExtendedSettingsProps = OnValueChange<ElementDesignInterface>;

export const ElementDesignSettings = ({ value, onChange }: ExtendedSettingsProps) => {
  const { defaultColor } = useColorValues();

  const { colorStrategyType } = value;

  const colorElementSettings = getColorSettings(colorStrategyType, value);
  const colorElementSettingsProperty = getColorSettingsProperty(colorStrategyType);

  const colorValueSettings = getPropertiesSettings(colorStrategyType, value);
  const colorValueSettingsProperty = getPropertiesSettingsProperty(colorStrategyType);

  return (
    <MainContainerSettings titleText="Оформление элементов">
      <SubSection>
        <GroupContainerSettings titleText="Стратегия цвета">
          <ElementContainerSettings>
            <SettingsRadio
              onChange={({ value: colorStrategyType }) => onChange({ ...value, colorStrategyType })}
              activeValue={getActiveRadioValue(typeStrategyOptions, colorStrategyType)}
              options={typeStrategyOptions}
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
      </SubSection>
      <SubSection>
        <ColorBySettings
          titleMainContainerText="Цвет элементов"
          isMainContainerSettings
          disabledComponentByValue
          disabledComponentBySpecificValue={ColorStrategyEnum.ByIncision === colorStrategyType}
          value={colorElementSettings.elementColorBy}
          onChange={(elementColorBy) =>
            onChange({ ...value, [colorElementSettingsProperty]: { ...colorElementSettings, elementColorBy } })
          }
        >
          <ColorPicker
            Activator={colorStrategyType === ColorStrategyEnum.ByIndicator ? CircleActivator : undefined}
            closeOnClick
            type={colorStrategyType === ColorStrategyEnum.ByIndicator ? 'color' : 'palette'}
            onChange={(elementColor) =>
              onChange({ ...value, [colorElementSettingsProperty]: { ...colorElementSettings, elementColor } })
            }
            value={colorElementSettings.elementColor}
            defaultActivatorBackgroundColor={colorStrategyType === ColorStrategyEnum.ByIndicator ? defaultColor : undefined}
          />
        </ColorBySettings>
      </SubSection>

      <SubSection>
        <PropertiesSettings
          title="Свойства текста"
          value={value.properties}
          onChange={(properties) => onChange({ ...value, properties })}
          disabledComponentBySpecificValue={ColorStrategyEnum.ByIncision === colorStrategyType}
          isMainContainerSettings
          isMainContainerColorSettings
          disabledOpacity
          disabledFontColorByBlock
          disabledFontColorBy
          disabledBackgroundColorBy
          disabledComponentColorByValue
          disabledPadding
          disabledLineHeight
          disabledUnderline
          disabledLetterSpacing
        />

        <ColorBySettings
          titleMainContainerText="Цвет текста"
          isMainContainerSettings
          disabledComponentByValue
          disabledComponentBySpecificValue={ColorStrategyEnum.ByIncision === colorStrategyType}
          value={colorValueSettings.elementColorBy}
          onChange={(elementColorBy) =>
            onChange({ ...value, [colorValueSettingsProperty]: { ...colorValueSettings, elementColorBy } })
          }
        >
          <ColorPicker
            Activator={colorStrategyType === ColorStrategyEnum.ByIndicator ? CircleActivator : undefined}
            closeOnClick
            type={colorStrategyType === ColorStrategyEnum.ByIndicator ? 'color' : 'palette'}
            onChange={(elementColor) =>
              onChange({ ...value, [colorValueSettingsProperty]: { ...colorValueSettings, elementColor } })
            }
            value={colorValueSettings.elementColor}
            defaultActivatorBackgroundColor={colorStrategyType === ColorStrategyEnum.ByIndicator ? defaultColor : undefined}
          />
        </ColorBySettings>
      </SubSection>
    </MainContainerSettings>
  );
};
