import { AxiosResponse } from 'axios';
import { axiosClient } from 'services/ApiClient';
import {
  AddUsersGroupsFlowAccessPayload,
  CopyProjectVersionAnotherPayload,
  CreateProjectVersionAnotherPayload,
  DeleteGroupFlowAccessByIdPayload,
  DeleteUserFlowAccessByIdPayload,
  LoadUsersGroupsPayload,
  MoveProjectPayload,
  ProtectProjectPayload,
  RenameProjectPayload,
  RestoreHistoryProjectPayload,
  UpdateFlowNamePayload,
  UpdateGroupFlowAccessByIdPayload,
  UpdateUserFlowAccessByIdPayload,
  UploadProjectAvatarPayload,
} from 'store/reducers/projectManager/types';

export const loadFlows = (): Promise<AxiosResponse<FastBoard.API.FlowsListResponseDTO>> => axiosClient.get(`/api/v1/flows`);

export const loadFlow = (flowId: string): Promise<AxiosResponse<FastBoard.API.ProjectsListResponseDTO>> =>
  axiosClient.get(`/api/v1/projects-flows/flow/${flowId}`);

export const loadShortFlow = (flowId: string): Promise<AxiosResponse<FastBoard.API.ProjectsShortListResponseDTO>> =>
  axiosClient.get(`/api/v1/projects-flows/flow/${flowId}/short`);

export const loadDraftFlow = (): Promise<AxiosResponse<FastBoard.API.ProjectsListResponseDTO>> =>
  axiosClient.get(`/api/v1/projects/draft`);

export const deleteFlow = (flowId: string) =>
  axiosClient.delete<string, AxiosResponse<string>, string>(`/api/v1/flows/${flowId}`);

export const updateFlowName = ({ flowId, name }: UpdateFlowNamePayload) =>
  axiosClient.put<FastBoard.API.FlowResponseDTO>(`/api/v1/flows/${flowId}/rename`, { name });

export const createFlow = (name: string) =>
  axiosClient.post<string, AxiosResponse<FastBoard.API.FlowResponseDTO>, FastBoard.API.FlowCreateDTO>(`/api/v1/flows/create`, {
    name,
  });

export const createFlowProject = (name: string) =>
  axiosClient.post<string, AxiosResponse<FastBoard.API.ProjectResponseDTO>, { name: string }>(`/api/v1/projects`, {
    name,
  });

export const restoreHistoryProject = ({ projectId, dateTimeHistory }: RestoreHistoryProjectPayload) =>
  axiosClient.post<
    string,
    AxiosResponse<FastBoard.API.ProjectVersionIncomingsResponseDTO>,
    FastBoard.API.ProjectVersionRollbackDTO
  >(`/api/v1/project/${projectId}/rollback`, {
    ts: dateTimeHistory,
  });

export const createProjectVersionAnother = ({
  projectId,
  dateTimeHistory,
  name,
  flowId,
  runImport,
}: CreateProjectVersionAnotherPayload) =>
  axiosClient.post<
    string,
    AxiosResponse<FastBoard.API.ProjectVersionCreateFromResponseDTO>,
    FastBoard.API.ProjectVersionCreateFromDTO
  >(`/api/v1/ops/project/create`, {
    from: { ts: dateTimeHistory, projectId },
    to: { name, flowId },
    runImport,
  });

export const copyProjectVersionAnother = ({ projectToId, projectFromId, runImport }: CopyProjectVersionAnotherPayload) =>
  axiosClient.post<string, AxiosResponse<FastBoard.API.ProjectVersionIncomingsResponseDTO>, FastBoard.API.ProjectVersionCopyDTO>(
    `/api/v1/ops/project/copy`,
    {
      from: { projectId: projectFromId },
      to: { projectId: projectToId },
      runImport,
    },
  );

export const moveProject = ({ projectId, flowId }: MoveProjectPayload) =>
  axiosClient.post<string, AxiosResponse<FastBoard.API.ProjectTransferToFlowResponseDTO>>(
    `/api/v1/projects-flows/flow/${flowId}/project/${projectId}/transfer`,
  );

export const loadFlowProjectInfo = (projectId: string): Promise<AxiosResponse<FastBoard.API.ApiProjectMetaResponseDTO>> =>
  axiosClient.get(`/api/v1/projects/${projectId}/meta`);

export const loadFlowDraftInfo = (): Promise<AxiosResponse<FastBoard.API.FlowResponseDTO>> =>
  axiosClient.get(`/api/v1/flows/user/draft`);

export const loadFlowProjectHistoryVersion = (
  projectId: string,
): Promise<AxiosResponse<FastBoard.API.ProjectVersionHistoryResponseDTO>> =>
  axiosClient.get(`/api/v1/project/${projectId}/history/50/0`);

export const protectProject = ({
  projectId,
  isProtected,
}: ProtectProjectPayload): Promise<AxiosResponse<FastBoard.API.ProjectUpdateProtectResponseDTO>> =>
  axiosClient.put(`/api/v1/projects/${projectId}/protect`, { isProtected });

export const renameProject = ({ id, name }: RenameProjectPayload): Promise<AxiosResponse<FastBoard.API.ProjectResponseDTO>> =>
  axiosClient.put<string, AxiosResponse<FastBoard.API.ProjectResponseDTO>, FastBoard.API.ProjectsRenameDTO>(
    `/api/v1/projects/rename`,
    { id, name },
  );

export const deleteFlowProject = (projectId: string): Promise<string> => axiosClient.delete(`/api/v1/projects/${projectId}`);

export const loadUsersGroups = (params: LoadUsersGroupsPayload): Promise<AxiosResponse<FastBoard.API.UserGroupListResponseDTO>> =>
  axiosClient.get(`/api/v1/user-groups`, { params });

export const loadFlowAccesses = (flowId: string): Promise<AxiosResponse<FastBoard.API.FlowUsersAndGroupsListItemResponseDTO>> =>
  axiosClient.get(`/api/v1/flow/${flowId}/groups-and-users`);

export const addUsersGroupsFlowAccess = ({ flowId, groups, users, type }: AddUsersGroupsFlowAccessPayload) =>
  axiosClient.post<string, AxiosResponse<FastBoard.API.FlowAddGroupsAndUsersResponseDTO>, FastBoard.API.FlowAddGroupsAndUsersDTO>(
    `/api/v1/flow/${flowId}/groups-and-users/add`,
    {
      groups: groups || [],
      users: users || [],
      type,
    },
  );

export const updateFlowAccessGroup = ({ flowId, type, groupId }: UpdateGroupFlowAccessByIdPayload) =>
  axiosClient.put<FastBoard.API.FlowUserResponseDTO>(`/api/v1/flows/${flowId}/group/${groupId}/type`, { type });

export const updateFlowAccessUser = ({ flowId, type, userId }: UpdateUserFlowAccessByIdPayload) =>
  axiosClient.put<FastBoard.API.FlowUserResponseDTO>(`/api/v1/flows/${flowId}/user/${userId}/type`, { type });

export const deleteFlowAccessGroup = ({ flowId, groupId }: DeleteGroupFlowAccessByIdPayload) =>
  axiosClient.delete<string, AxiosResponse<string>, string>(`/api/v1/flows/${flowId}/group/${groupId}/`);

export const deleteFlowAccessUser = ({ flowId, userId }: DeleteUserFlowAccessByIdPayload) =>
  axiosClient.delete<string, AxiosResponse<string>, string>(`/api/v1/flows/${flowId}/user/${userId}/`);

export const uploadProjectAvatar = ({ projectId, avatar }: UploadProjectAvatarPayload) =>
  axiosClient.put<string, AxiosResponse<FastBoard.API.ProjectResponseDTO>>(`/api/v1/projects/image/${projectId}`, avatar);

export const exportProject = ({ projectId }: { projectId: string }) =>
  axiosClient.get<string, AxiosResponse<unknown>>(`/api/v2/project/${projectId}/export`);

export const importProject = ({ file }: { file: File }) => {
  const newFormData = new FormData();
  newFormData.append('file', file);
  return axiosClient.post<string, AxiosResponse<string>, FormData>(`/api/v2/project/import`, newFormData);
};
