import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DefaultVisualisationsTypeProps } from 'assets/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const MonthlyLine: FC<DefaultVisualisationsTypeProps> = ({
  backgroundFill = `var(${ColorVarsEnum.Level_4_widget})`,
  fillDefault = `var(${ColorVarsEnum.Level_1})`,
  fill = `var(${ColorVarsEnum.Accent})`,
  symbolSize = 70,
}) => {
  return (
    <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
      <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="70" height="70" rx="5" fill={backgroundFill} />
        <rect opacity="0.15" x="8" y="56" width="54" height="3" fill={fillDefault} />
        <path d="M9 47H26.1892L44.0946 15L62 25.8308" stroke={fillDefault} strokeWidth="3" />
        <path d="M9 32.732L26.1892 27.982L44.0946 35L62 16" stroke={fill} strokeWidth="3" strokeLinecap="round" />
        <circle cx="26" cy="28" r="3" fill={fill} />
        <circle cx="43" cy="35" r="3" fill={fill} />
        <circle cx="61" cy="17" r="3" fill={fill} />
        <circle cx="9" cy="33" r="3" fill={fill} />
      </svg>
    </FlexContainer>
  );
};
