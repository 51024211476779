import React, { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { NoopType, NoopValueType } from 'types/global';
import { FileInput, FileInputLabel, FileInputWrapper } from './styles';
import { getFileExtension } from 'utils/utils';
import { FlexContainer } from 'styles/FlexContainer';

interface FileUploadInputProps {
  allowedFileTypes?: string[];
  onFileUpload?: NoopValueType<File>;
  onDriver?: NoopValueType<string>;
  onClear?: NoopType;
  onCustomClick?: NoopType;
  label?: string;
  fileType?: string;
  fileName?: string;
  maxSizeMB?: number;
  isManyFiles?: boolean;
}

export const FileUploadInput: FC<FileUploadInputProps> = ({
  allowedFileTypes = [],
  onFileUpload,
  onClear,
  onCustomClick,
  label = 'Файл',
  onDriver,
  fileName,
  maxSizeMB = 25,
  isManyFiles,
}) => {
  const [inValidFileName, setInValidFileName] = useState<string>();
  const [isValidSize, setIsValidSize] = useState<boolean>(true);
  const [isValidType, setIsValidType] = useState<boolean>(true);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const onFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      const maxFileSize = maxSizeMB * 1024 * 1024;

      if (file) {
        const originalFileType = getFileExtension(file.name);
        let fileType = originalFileType;

        if (fileType === 'xls') {
          fileType = 'xlsx';
        }

        const isAllowed = allowedFileTypes?.includes(fileType);
        const isSizeValid = file.size <= maxFileSize;

        setIsValidSize(isSizeValid);
        setIsValidType(isAllowed);

        let driverValue;
        if (isAllowed && isSizeValid) {
          onFileUpload && onFileUpload(file);

          driverValue = originalFileType;
        } else {
          setInValidFileName(file?.name);
          onClear && onClear();
          driverValue = 'auto';
        }
        onDriver && onDriver(driverValue);
      }
    },
    [onFileUpload, allowedFileTypes, onDriver, onClear, maxSizeMB],
  );

  const onClick = useCallback(
    (event: React.MouseEvent) => {
      if (onCustomClick) {
        event.preventDefault();
        onCustomClick();
      } else {
        fileInputRef.current?.click();
      }
    },
    [onCustomClick],
  );

  const descriptionFile = isManyFiles ? 'не выбрана' : 'не выбран';

  return (
    <FileInputWrapper>
      <FlexContainer alignItems="center">
        <FileInput type="file" id="fileUpload" onChange={onFileChange} />
        <FileInputLabel htmlFor="fileUpload" onClick={onClick}>
          {label}
        </FileInputLabel>
        <PrimaryTextSpan
          marginLeft="8px"
          color={`var(${ColorVarsEnum.Level_3})`}
          fontSize="12px"
          lineHeight="120%"
          fontWeight="bold"
          width="160px"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {fileName || inValidFileName || descriptionFile}
        </PrimaryTextSpan>
      </FlexContainer>
      <FlexContainer marginTop="4px" flexDirection="column" gap="4px">
        {!isValidSize && (
          <PrimaryTextSpan lineHeight="12px" color={`var(${ColorVarsEnum.Alert})`} fontSize="12px">
            Превышен допустимый размер 25Мб
          </PrimaryTextSpan>
        )}
        {!isValidType && (
          <PrimaryTextSpan lineHeight="12px" color={`var(${ColorVarsEnum.Alert})`} fontSize="12px">
            Неизвестный формат файла
          </PrimaryTextSpan>
        )}
      </FlexContainer>
    </FileInputWrapper>
  );
};
