import React, { memo, useCallback, useEffect, useState } from 'react';
import { FlexContainerProps } from 'styles/FlexContainer';
import { useAppDispatch } from 'store';
import { loadFlowProjectHistoryVersionAction, restoreHistoryProjectAction } from 'store/reducers/projectManager/actions';
import { useSelector } from 'react-redux';
import { getFlowProjectHistoryVersion } from 'store/reducers/projectManager/getters';
import { NoopType } from 'types/global';
import { HistoryVersionProjectUI } from 'modules/ui/ManagerOrAdminComponentsUI/HistoryVersionProjectUI';
import { closeModalAction, openConfirmationModalAction, openModalTypedAction } from 'store/reducers/modals/actions';
import { ProjectHistoryVersionList } from 'modules/ui/ManagerOrAdminComponentsUI/HistoryVersionProjectUI/types';
import { createCopyOfVersion } from '../constants';
import { CopyFlowProjectModal } from 'components/admin/flows/elements/Projects/Modals/CopyFlowProjectModal';

interface HistoryVersionProjectProps {
  projectId: string;
  flowId: string;
  projectName?: string;
  padding?: string;
  footerPosition?: FlexContainerProps['justifyContent'];
  onClose?: NoopType;
}

export const HistoryVersionProjectComponent = ({
  projectId,
  flowId,
  projectName,
  footerPosition,
  padding = '0 24px',
  onClose,
}: HistoryVersionProjectProps) => {
  const dispatch = useAppDispatch();
  const { flowProjectHistoryVersionList: data, loading: isLoading } = useSelector(getFlowProjectHistoryVersion);
  const [selectedVersion, setSelectedVersion] = useState<ProjectHistoryVersionList | null>(null);

  const onRestoreVersion = useCallback(
    async (time: string) => {
      try {
        const action = await dispatch(restoreHistoryProjectAction({ projectId: projectId, dateTimeHistory: time })).unwrap();
        if (action) {
          await dispatch(loadFlowProjectHistoryVersionAction(projectId));
        }
      } catch (e) {
        console.error(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId],
  );

  useEffect(() => {
    if (projectId) {
      dispatch(loadFlowProjectHistoryVersionAction(projectId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const onRestoreVersionModal = useCallback(
    async () => {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Откатить',
          subTitleText: `Откатить проект «${projectName}» до выбранной версии?`,
          onConfirm: () => onRestoreVersion(selectedVersion?.dateTimeHistory || ''),
          titleText: 'Откат проекта',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onRestoreVersion, projectName, selectedVersion?.dateTimeHistory],
  );

  const onCloseCreateCopyOfVersion = () => dispatch(closeModalAction(createCopyOfVersion));

  const onCreateCopyOfVersion = useCallback(
    () =>
      dispatch(
        openModalTypedAction({
          Component: CopyFlowProjectModal,
          componentProps: {
            onClose: () => {
              onCloseCreateCopyOfVersion();
              onClose && onClose();
            },
            projectId: projectId || '',
            projectName: projectName || '',
            flowId,
          },
          modalSettings: {
            position: 'static',
            width: '320px',
            headerText: 'Создание копии',
          },
          name: createCopyOfVersion,
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, projectName, selectedVersion?.dateTimeHistory, onCloseCreateCopyOfVersion, onClose, flowId],
  );

  return (
    <HistoryVersionProjectUI
      data={data}
      isLoading={isLoading}
      onRestoreVersionModal={onRestoreVersionModal}
      onItemSelect={setSelectedVersion}
      onCreateCopyOfVersion={onCreateCopyOfVersion}
      selectedVersion={selectedVersion}
      padding={padding}
      footerPosition={footerPosition}
    />
  );
};
export const HistoryVersionProject = memo(HistoryVersionProjectComponent);
