import { ElementContainerSettings, GroupContainerSettings } from 'modules/settingsContainer';
import React from 'react';
import { ImagesSettingsInterface } from 'store/reducers/visualisations/types';
import { OnValueChange } from 'types/global';
import { useLocalValues } from 'utils/hooks/localValues';
import { alignmentHorizontalImageOptions, alignmentVerticalImageOptions, positionImageOptions } from './constants';
import { SelectItemInterface } from 'modules/ui/Select';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { pxUnit } from 'constants/global';
import SubSection from 'shared/ui/subSection';
import { getActiveRadio, RadioSelected } from 'components/RadioSelected';
import { SourceSelection } from '../SourceSelection';

interface ImagesSettingsProps extends OnValueChange<ImagesSettingsInterface> {
  options: SelectItemInterface[];
}

export const ImagesSettings = ({ value, onChange, options }: ImagesSettingsProps) => {
  const { size, alignmentAndPosition, textIndent } = value;
  const { horizontal, vertical } = size;

  const { localValues, setLocalValues, onSave, onCancel, hasChanges } = useLocalValues({ value, onChange });
  const { source } = localValues;

  const onSqlChange = (sql: string) =>
    setLocalValues((localValues) => ({
      ...localValues,
      source: {
        ...source,
        value: {
          ...source.value,
          sqlCondition: sql || null,
        },
      },
    }));

  return (
    <>
      <SubSection>
        <SourceSelection
          value={source}
          onChange={(source) => onChange({ ...value, source })}
          options={options}
          onChangeSqlByCondition={onSqlChange}
          onSave={onSave}
          onCancel={onCancel}
          hasChanges={hasChanges}
        />
      </SubSection>
      <SubSection>
        <GroupContainerSettings titleText="Максимальный размер">
          <ElementContainerSettings gap="16px">
            <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_3})`}>{'По горизонали'}</PrimaryTextSpan>
            <NumberPropertiesBlock
              name="countUnit"
              unit={pxUnit}
              value={horizontal || 0}
              onChange={(countUnit) =>
                onChange({
                  ...value,
                  size: { ...size, horizontal: countUnit },
                })
              }
              heightSize="small"
            />
          </ElementContainerSettings>
          <ElementContainerSettings gap="16px">
            <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_3})`}>{'По вертикали'}</PrimaryTextSpan>
            <NumberPropertiesBlock
              name="countUnit"
              unit={pxUnit}
              value={vertical || 0}
              onChange={(countUnit) =>
                onChange({
                  ...value,
                  size: { ...size, vertical: countUnit },
                })
              }
              heightSize="small"
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
      </SubSection>
      <SubSection>
        <GroupContainerSettings titleText="Выравнивание и позиционирование">
          <ElementContainerSettings gap="16px">
            <RadioSelected
              onChange={(option) =>
                onChange({
                  ...value,
                  alignmentAndPosition: {
                    ...alignmentAndPosition,
                    horizontal: option.value,
                  },
                })
              }
              activeValue={getActiveRadio(alignmentHorizontalImageOptions, alignmentAndPosition.horizontal)}
              options={alignmentHorizontalImageOptions}
              gap="4px"
            />
            <RadioSelected
              onChange={(option) =>
                onChange({
                  ...value,
                  alignmentAndPosition: {
                    ...alignmentAndPosition,
                    vertical: option.value,
                  },
                })
              }
              activeValue={getActiveRadio(alignmentVerticalImageOptions, alignmentAndPosition.vertical)}
              options={alignmentVerticalImageOptions}
              gap="4px"
            />
            <RadioSelected
              onChange={(option) =>
                onChange({
                  ...value,
                  alignmentAndPosition: {
                    ...alignmentAndPosition,
                    absolutePositionSetting: option.value,
                  },
                })
              }
              activeValue={getActiveRadio(positionImageOptions, alignmentAndPosition.absolutePositionSetting)}
              options={positionImageOptions}
              gap="4px"
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
      </SubSection>
      <SubSection>
        <GroupContainerSettings
          titleText="Отступ от текста"
          countUnit={textIndent}
          thicknessBorderChange={(indent) => onChange({ ...value, textIndent: indent })}
        />
      </SubSection>
    </>
  );
};
