import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DefaultVisualisationsTypeProps } from 'assets/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const ImageText: FC<DefaultVisualisationsTypeProps> = ({
  backgroundFill = `var(${ColorVarsEnum.Level_4_widget})`,
  symbolSize = 70,
}) => {
  return (
    <FlexContainer height={symbolSize + 'px'} width={symbolSize + 'px'}>
      <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="70" height="70" rx="5" fill={backgroundFill} />
        <path
          d="M58.6067 34.2427C60.4788 32.078 62 29.0943 62 26.052C62 24.8234 61.8245 23.5948 61.4734 22.4247C60.7714 19.7335 59.1332 17.5688 56.793 16.0477C54.7454 14.7021 52.2296 14 49.3044 14H34.1516L32.923 19.8505H49.2459C51.001 19.8505 52.4052 20.2015 53.3997 20.9036L53.5167 21.0206C54.6283 21.7227 55.3889 22.7172 55.7399 24.0044L55.7984 24.1799C55.974 24.8234 56.091 25.467 56.091 26.1105C56.091 27.9827 54.3943 30.5569 52.7562 31.7855L49.9479 33.8917L52.5807 36.2904C53.1657 36.817 53.6923 37.4605 54.0433 38.1041L54.1018 38.1626C54.8624 39.4497 55.2719 40.9708 55.2719 42.4334C55.2719 47.0553 51.4691 50.8582 46.8472 50.8582C46.5547 50.8582 45.7356 50.8582 44.9165 50.8582C43.9804 50.8582 43.1029 50.8582 42.7518 50.8582H26.4289L25.2003 56.7087H42.7518C43.1029 56.7087 43.9804 56.7087 44.9165 56.7087C45.7941 56.7087 46.6132 56.7087 46.9057 56.7087C54.7454 56.7087 61.1809 50.3316 61.1809 42.4334C61.1809 39.9177 60.4788 37.402 59.1917 35.1788C58.9577 34.8278 58.7822 34.5353 58.6067 34.2427Z"
          fill="url(#paint0_linear_19503_45595)"
        />
        <path
          d="M31.285 45.0077H28.0672C25.727 45.0077 23.6208 43.311 23.1528 41.0293L22.5677 38.3966H29.8809L28.6523 32.5461H20.7541C18.4139 32.5461 16.3077 30.8494 15.8397 28.5677L15.2546 25.701H31.9871C34.3273 25.701 36.4334 27.3976 36.9015 29.6793L37.4865 32.4291H43.454L42.635 28.4507C41.5819 23.4193 37.1355 19.792 31.9871 19.792H8L10.1062 29.7378C10.8667 33.2481 13.2655 36.0564 16.3662 37.402L17.4193 42.1994C18.4724 47.2309 22.9188 50.8582 28.0087 50.8582H32.4551L31.285 45.0077Z"
          fill="url(#paint1_linear_19503_45595)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_19503_45595"
            x1="57.1171"
            y1="52.3092"
            x2="26.4937"
            y2="21.6858"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00EFFD" />
            <stop offset="0.0109202" stopColor="#00ECFD" />
            <stop offset="0.2676" stopColor="#0BB2F2" />
            <stop offset="0.5038" stopColor="#1483EA" />
            <stop offset="0.7121" stopColor="#1A62E5" />
            <stop offset="0.8846" stopColor="#1E4DE1" />
            <stop offset="1" stopColor="#1F46E0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_19503_45595"
            x1="38.7497"
            y1="47.658"
            x2="17.326"
            y2="19.2823"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00EFFD" />
            <stop offset="0.0109202" stopColor="#00ECFD" />
            <stop offset="0.2676" stopColor="#0BB2F2" />
            <stop offset="0.5038" stopColor="#1483EA" />
            <stop offset="0.7121" stopColor="#1A62E5" />
            <stop offset="0.8846" stopColor="#1E4DE1" />
            <stop offset="1" stopColor="#1F46E0" />
          </linearGradient>
        </defs>
      </svg>
    </FlexContainer>
  );
};
