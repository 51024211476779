import { store } from 'store';
import { updateLineAndBarViewSettingsAction } from 'store/reducers/visualisations/actions';
import {
  AxisSettingsInterface,
  LegendSettingsInterface,
  PaddingVisualisationInterface,
  LineAndBarViewSettings,
  ZoomSettingsInterface,
  ElementDesignInterface,
} from 'store/reducers/visualisations/types';
import { PositionSettingType, StartAndEndInterface } from 'types/store';
import { AbsolutePositionType } from 'types/styles';

const dispatch = store.dispatch;

const getAxisAdditionalIndicatorSettingsPositionType: Record<string, AbsolutePositionType> = {
  right: 'left',
  left: 'right',
  top: 'bottom',
  bottom: 'top',
};

export const onAxisIncisionChange = (axisIncisionSettings: AxisSettingsInterface) =>
  dispatch(updateLineAndBarViewSettingsAction({ axisIncisionSettings }));

export const onAxisIndicatorChange = (
  axisIndicatorSettings: AxisSettingsInterface<'indicator'>,
  { axisAdditionalIndicatorSettings }: LineAndBarViewSettings,
) =>
  dispatch(
    updateLineAndBarViewSettingsAction({
      axisIndicatorSettings,
      axisAdditionalIndicatorSettings: {
        ...axisAdditionalIndicatorSettings,
        position: getAxisAdditionalIndicatorSettingsPositionType[axisIndicatorSettings.position],
      },
    }),
  );

export const onAxisAdditionalIndicatorChange = (
  axisAdditionalIndicatorSettings: AxisSettingsInterface<'indicator'>,
  { axisIndicatorSettings }: LineAndBarViewSettings,
) =>
  dispatch(
    updateLineAndBarViewSettingsAction({
      axisAdditionalIndicatorSettings,
      axisIndicatorSettings: {
        ...axisIndicatorSettings,
        position: getAxisAdditionalIndicatorSettingsPositionType[axisAdditionalIndicatorSettings.position],
      },
    }),
  );

export const onLegendSettingsChange = (legendSettings: LegendSettingsInterface) =>
  dispatch(updateLineAndBarViewSettingsAction({ legendSettings }));

export const onDataZoomHorizontalStartAndEndSettingsChange = (dataZoomHorizontalStartAndEnd: StartAndEndInterface) =>
  dispatch(updateLineAndBarViewSettingsAction({ dataZoomHorizontalStartAndEnd }));

export const onDataZoomVerticalStartAndEndSettingsChange = (dataZoomVerticalStartAndEnd: StartAndEndInterface) =>
  dispatch(updateLineAndBarViewSettingsAction({ dataZoomVerticalStartAndEnd }));

export const onShowTipsChange = (showTips: boolean) => dispatch(updateLineAndBarViewSettingsAction({ showTips }));

export const onElementDesignChange = (elementDesign: ElementDesignInterface) =>
  dispatch(updateLineAndBarViewSettingsAction({ elementDesign }));

export const onIncisionSelectorPositionChange = (incisionSelectorPosition: PositionSettingType) =>
  dispatch(updateLineAndBarViewSettingsAction({ incisionSelectorPosition }));

export const onChangePaddingVisualisation = (visualisationPaddings: PaddingVisualisationInterface) =>
  dispatch(
    updateLineAndBarViewSettingsAction({
      visualisationPaddings,
    }),
  );

export const onChangeHorizontalZoomChange = (horizontalZoom: ZoomSettingsInterface) =>
  dispatch(updateLineAndBarViewSettingsAction({ horizontalZoom }));

export const onChangeVerticalZoomChange = (verticalZoom: ZoomSettingsInterface) =>
  dispatch(updateLineAndBarViewSettingsAction({ verticalZoom }));
