import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { MenuItemInterface } from 'modules/ui/menu/ContextMenu/types';
import { ContextMenuWrapper } from 'modules/settingsContainer/ContextMenuWrapper';
import { ListFlowSearch } from 'modules/settingsContainer/SearchList/ListFlowSearch';
import { FlowListInterface } from 'modules/settingsContainer/FlowList/types';
import { NoopType, NoopValueType } from 'types/global';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ShortProjectInterface } from './types';

type ProjectsSearchUIProps = {
  projectsLoading: boolean;
  modelMetaDataList: FlowListInterface[];
  menuList: MenuItemInterface[];
  exportProject?: MenuItemInterface[];
  isOpenInformationBar: boolean;
  onOpenInformationModal: NoopValueType<{ id: string; name: string }>;
  onCloseInformationModal: NoopType;
  onOpenFlowProject: NoopValueType<{ projectId: string; pageId: string }>;
  onChangeShortProject: NoopValueType<ShortProjectInterface>;
  shortProject: ShortProjectInterface;
  onBoardAreaClick: NoopValueType<string>;
  activeProjectId: string;
  informationOpenId: string;
  isDraftFlow?: boolean;
  isViewer: boolean;
  onAddItem: NoopType;
  onCloseMenu?: NoopType;
  widgetIdInViewerMode?: boolean;
};

export const ProjectsSearchUI = ({
  projectsLoading,
  modelMetaDataList,
  menuList,
  onOpenInformationModal,
  onOpenFlowProject,
  onBoardAreaClick,
  activeProjectId,
  informationOpenId,
  isDraftFlow = false,
  isViewer,
  onChangeShortProject,
  shortProject,
  onCloseMenu,
  onAddItem,
  exportProject,
  widgetIdInViewerMode,
}: ProjectsSearchUIProps) => {
  return (
    <FlexContainer width="100%">
      <LoadingOverlay loading={projectsLoading} backgroundColor={`var(${ColorVarsEnum.Level_5_application})`} />
      <FlexContainer gap="2px" flexWrap="wrap" position="relative" width="100%">
        <ContextMenuWrapper
          onCloseMenu={onCloseMenu}
          additionalMenuList={[]}
          adminMenuList={menuList}
          exportProject={exportProject}
          isViewer={!activeProjectId}
          activeWidgetId={activeProjectId}
          widgetIdInViewerMode={widgetIdInViewerMode}
        >
          <ListFlowSearch
            data={modelMetaDataList}
            openFlowProject={({ infoItem, id }) => infoItem && onOpenFlowProject({ projectId: id, pageId: infoItem })}
            onActiveFlow={onBoardAreaClick}
            activeFlowProject={activeProjectId || ''}
            onOpenInformationModal={onOpenInformationModal}
            selectedInfoId={informationOpenId}
            isDraftFlow={!isDraftFlow}
            isViewer={isViewer}
            onAddItem={onAddItem}
            onChangeShortProject={onChangeShortProject}
            shortProject={shortProject}
          />
        </ContextMenuWrapper>
      </FlexContainer>
    </FlexContainer>
  );
};
