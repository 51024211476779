import React from 'react';
import { OnValueChange } from 'types/global';
import { ColorBySettingsValueAndElementInterface } from 'store/reducers/visualisations/types';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { ColorBySettings } from 'modules/settingsContainer/common/ColorBySettings';
import { ColorPicker } from 'modules/settingsContainer/ColorPicker';
import SubSection from 'shared/ui/subSection';

type LineAndBarIncisionColorSettingsProps = OnValueChange<ColorBySettingsValueAndElementInterface>;

export const LineAndBarIncisionColorSettings = ({ value, onChange }: LineAndBarIncisionColorSettingsProps) => {
  const { colorBySettingsElement, colorBySettingsValue } = value;

  return (
    <MainContainerSettings titleText="Настроить цвет">
      <SubSection>
        <ColorBySettings
          titleMainContainerText="Изменить цвет значений"
          isMainContainerSettings
          disabledComponentByValue
          disabledComponentBySpecificValue
          value={colorBySettingsValue.elementColorBy}
          onChange={(elementColorBy) => onChange({ ...value, colorBySettingsValue: { ...colorBySettingsValue, elementColorBy } })}
          switcherColorBy={{
            switcherStateColorBy: colorBySettingsValue.isActive,
            switcherChangeColorBy: () =>
              onChange({ ...value, colorBySettingsValue: { ...colorBySettingsValue, isActive: !colorBySettingsValue.isActive } }),
          }}
        >
          <ColorPicker
            closeOnClick
            type="palette"
            onChange={(elementColor) => onChange({ ...value, colorBySettingsValue: { ...colorBySettingsValue, elementColor } })}
            value={colorBySettingsValue.elementColor}
          />
        </ColorBySettings>
      </SubSection>
      <SubSection>
        <ColorBySettings
          titleMainContainerText="Изменить цвет элемента"
          isMainContainerSettings
          disabledComponentByValue
          disabledComponentBySpecificValue
          value={colorBySettingsElement.elementColorBy}
          onChange={(elementColorBy) =>
            onChange({ ...value, colorBySettingsElement: { ...colorBySettingsElement, elementColorBy } })
          }
          switcherColorBy={{
            switcherStateColorBy: colorBySettingsElement.isActive,
            switcherChangeColorBy: () =>
              onChange({
                ...value,
                colorBySettingsElement: { ...colorBySettingsElement, isActive: !colorBySettingsElement.isActive },
              }),
          }}
        >
          <ColorPicker
            closeOnClick
            type="palette"
            onChange={(elementColor) =>
              onChange({ ...value, colorBySettingsElement: { ...colorBySettingsElement, elementColor } })
            }
            value={colorBySettingsElement.elementColor}
          />
        </ColorBySettings>
      </SubSection>
    </MainContainerSettings>
  );
};
