import { NoopType, OnValueChange } from 'types/global';
import { ModalUniversal } from 'modules/ui/ModalUniversal';
import { codeEditorTitle } from 'modules/settingsContainer/common/data/SqlSettings/cosntants';
import React from 'react';
import { SqlSection } from 'modules/settingsContainer/common/data/SqlSettings';
import { formatSql } from 'utils/SQL/formatSQL';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { SqlSectionProps } from '../SqlSettings/types';
import { SqlAutocomplete } from 'modules/ui';
import { SqlContainer, SqlEditorContainer } from '../SqlSettings/styles';
import { useSelector } from 'react-redux';
import { getActiveThemeCodeEditorTheme } from 'store/reducers/themes/getters';

export interface ModalCustomRequestProps extends OnValueChange<string | null>, Partial<Pick<SqlSectionProps, 'modelMetaData'>> {
  isOpen: boolean;
  onClose: NoopType;
  onSave: NoopType;
  onCancel: NoopType;
  hasChanges: boolean;
}

export const ModalCustomRequest = ({
  isOpen,
  onClose,
  onChange,
  onCancel,
  value,
  hasChanges,
  onSave,
  modelMetaData,
}: ModalCustomRequestProps) => {
  const codeEditorTheme = useSelector(getActiveThemeCodeEditorTheme);

  const onFormatClick = () => {
    if (value) {
      const formattedSql = formatSql(value);
      formattedSql && onChange(formattedSql);
    }
  };

  return (
    <ModalUniversal open={isOpen} onClose={onClose} headerText={codeEditorTitle} width="95vw" maxHeight="95vh">
      <SqlSection
        hasChanges={hasChanges}
        modelMetaData={modelMetaData}
        onFormatClick={onFormatClick}
        sqlEditorSlot={({ onEditorFocus }) => (
          <>
            <PrimaryTextSpan fontSize="12px" lineHeight="10px" color={`var(${ColorVarsEnum.Level_3})`} marginBottom="12px">
              Выражение
            </PrimaryTextSpan>
            <SqlContainer codeEditorTheme={codeEditorTheme}>
              <SqlEditorContainer>
                <SqlAutocomplete handleChange={onChange} value={value || ''} onFocus={onEditorFocus} autoHeight />
              </SqlEditorContainer>
            </SqlContainer>
          </>
        )}
        onCloseEditor={() => {
          onCancel();
          onClose();
        }}
        onSaveEditor={onSave}
      />
    </ModalUniversal>
  );
};
