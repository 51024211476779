import { useEffect } from 'react';
import {
  onAddNewIndicatorsStackSum,
  onDeleteIndicatorsStackSum,
} from 'modules/visualisations/LineAndBar/settings/DataTab/constants';
import { LineAndBarDataSettings } from 'store/reducers/visualisations/types';

type UseIndicatorsStackSumProps = {
  dataSettings: LineAndBarDataSettings;
};

export const useIndicatorsStackSum = ({ dataSettings }: UseIndicatorsStackSumProps) => {
  useEffect(() => {
    const stackCounts: Record<number, number> = dataSettings.indicators.reduce((acc: Record<number, number>, indicator) => {
      const stackNumber = indicator.settings.elementSettings.parametersBar.stackNumber;
      if (!acc[stackNumber]) {
        acc[stackNumber] = 0;
      }
      acc[stackNumber] += 1;
      return acc;
    }, {});

    const currentStackSumIds = new Set(
      dataSettings.indicatorsStackSum.map((indicator) => indicator.settings.elementSettings.parametersBar.stackNumber),
    );

    Object.entries(stackCounts).forEach(([stackNumber, count]) => {
      if (count > 1 && !currentStackSumIds.has(Number(stackNumber))) {
        onAddNewIndicatorsStackSum(dataSettings, Number(stackNumber));
      }
    });

    dataSettings.indicatorsStackSum.forEach((indicatorStackSum) => {
      const stackNumber = indicatorStackSum.settings.elementSettings.parametersBar.stackNumber;
      if (stackCounts[stackNumber] <= 1) {
        onDeleteIndicatorsStackSum(dataSettings, indicatorStackSum.id);
      }
    });
  }, [dataSettings]);
};
