import { NoopType, OnValueChange } from 'types/global';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { GroupContainerSettings } from 'modules/settingsContainer';
import React, { MutableRefObject, useCallback } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DefaultByInterface } from 'store/reducers/visualisations/types';
import { colorNameTitle } from 'constants/global';
import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';

export type WrapperByTabsType = Record<
  string,
  { Activator: React.FC; Settings?: React.FC; settingsText?: string; width?: string; minWidth?: string }
>;

export interface WrapperBySettingsProp<Type extends string, T extends DefaultByInterface<Type>> extends OnValueChange<T> {
  isMainContainerSettings?: boolean;
  title?: string;
  titleMainContainerText?: string;
  colorByTabs: WrapperByTabsType;
  conditionOptions: SettingsRadioItem<Type>[];
  mainContainerRef: MutableRefObject<HTMLDivElement | null>;
  showBottomLine?: boolean;
  switcherColorBy?: {
    switcherChangeColorBy?: NoopType;
    switcherStateColorBy?: boolean;
  };
}

export const WrapperBySettings = <Type extends string, T extends DefaultByInterface<Type>>({
  value,
  onChange,
  isMainContainerSettings = false,
  title = colorNameTitle,
  titleMainContainerText = colorNameTitle,
  colorByTabs,
  conditionOptions,
  mainContainerRef,
  showBottomLine,
  switcherColorBy,
}: WrapperBySettingsProp<Type, T>) => {
  const { Activator, Settings } = colorByTabs[value?.type];

  const RadioOptions = useCallback(
    () => (
      <SettingsRadio
        onChange={({ value: type }) => onChange({ ...value, type })}
        activeValue={getActiveRadioValue(conditionOptions, value.type)}
        options={conditionOptions}
      />
    ),
    [conditionOptions, value, onChange],
  );

  const RadioBody = useCallback(
    () => (
      <>
        <FlexContainer width="100%">
          <Activator />
        </FlexContainer>
        {Settings && <Settings />}
      </>
    ),
    [Activator, Settings],
  );

  return (
    <>
      {!isMainContainerSettings ? (
        <MainContainerSettings
          ref={mainContainerRef}
          titleText={title}
          switcherChange={switcherColorBy?.switcherChangeColorBy}
          switcherState={switcherColorBy?.switcherStateColorBy}
        >
          <GroupContainerSettings>
            <ElementContainerSettings>
              <RadioOptions />
            </ElementContainerSettings>
          </GroupContainerSettings>
          <GroupContainerSettings>
            <RadioBody />
          </GroupContainerSettings>
        </MainContainerSettings>
      ) : (
        <GroupContainerSettings
          ref={mainContainerRef}
          switcherChange={switcherColorBy?.switcherChangeColorBy}
          switcherState={switcherColorBy?.switcherStateColorBy}
          titleText={titleMainContainerText}
          showBottomLine={showBottomLine}
        >
          <ElementContainerSettings>
            <RadioOptions />
          </ElementContainerSettings>
          <ElementContainerSettings>
            <RadioBody />
          </ElementContainerSettings>
        </GroupContainerSettings>
      )}
    </>
  );
};
