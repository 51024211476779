import React from 'react';
import { useGetActiveVisualisationSettings } from 'utils/hooks/visualisation/getActiveVisualisationSettings';
import {
  defaultSqlDataSettings,
  defaultTextDataSettings,
  defaultTextViewSettings,
} from 'store/reducers/visualisations/constants';
import { onChangeBackground } from 'modules/visualisations/Text/settings/ViewTab/constants';
import { TextBackgroundSettings } from 'modules/settingsContainer/common/view/TextBackgroundSettings';
import { DefaultViewSettings } from 'modules/settingsContainer/common/view/DefaultViewSettings';

export const DataTab = () => {
  const { viewSettings, dataSettings, positionConfig } = useGetActiveVisualisationSettings({
    defaultData: defaultTextDataSettings,
    defaultView: defaultTextViewSettings,
    defaultSqlData: defaultSqlDataSettings,
  });

  return (
    <DefaultViewSettings
      positionConfig={positionConfig}
      viewSettings={viewSettings}
      ExternalBackgroundSettings={
        <TextBackgroundSettings value={viewSettings.backgroundSettings} onChange={onChangeBackground} />
      }
    />
  );
};
