import { GroupContainerSettings } from 'modules/settingsContainer//GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { FlexContainer } from 'styles/FlexContainer';
import React, { useRef } from 'react';
import { OnValueChange } from 'types/global';
import { ColorAndImageByType, FormMarkerInterface } from 'store/reducers/visualisations/types';
import { useModalState } from 'utils/hooks/modalState';
import { WrapperByTabsType } from 'modules/settingsContainer/common/WrapperBySettings';
import { ConditionEditorField } from 'modules/settingsContainer/common/ConditionEditorField';
import { FixedAreaSettings } from 'modules/settingsContainer/common/FixedAreaSettings';
import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { FormByCondition } from '../FormByCondition';

interface FormSettingsSQLProps extends OnValueChange<FormMarkerInterface> {
  titleText: string;
  titleInHeaderModal?: string;
  options: SettingsRadioItem<ColorAndImageByType>[];
  children?: React.ReactNode;
  disabledComponentByValue?: boolean;
  disabledComponentByCondition?: boolean;
  changeFillColor?: boolean;
}

export const FormSettingsSQL = ({ value, onChange, children, options, titleText, titleInHeaderModal }: FormSettingsSQLProps) => {
  const { type, byCondition } = value;
  const ref = useRef(null);
  const { isOpen, onOpen, onClose } = useModalState();

  const tabs: WrapperByTabsType = {
    condition: {
      Activator: () => <ConditionEditorField value={byCondition.sqlCondition || ''} onOpenChange={onOpen} />,
      Settings: () => (
        <FixedAreaSettings
          onClose={onClose}
          open={isOpen}
          headerText={titleInHeaderModal ? titleInHeaderModal : 'Изменить условие'}
          width="565px"
          minWidth="200px"
          anchorEl={ref.current}
        >
          <FormByCondition value={byCondition} onChange={(formSettings) => onChange({ ...value, byCondition: formSettings })} />
        </FixedAreaSettings>
      ),
    },
    default: {
      Activator: () => <>{children}</>,
    },
  };

  const { Activator, Settings } = tabs[type];

  return (
    <GroupContainerSettings titleText={titleText} ref={ref}>
      <ElementContainerSettings>
        <SettingsRadio
          onChange={({ value: type }) => onChange({ ...value, type })}
          activeValue={getActiveRadioValue(options, type)}
          options={options}
        />
      </ElementContainerSettings>
      <FlexContainer width="100%">
        <Activator />
      </FlexContainer>
      {Settings && <Settings />}
    </GroupContainerSettings>
  );
};
