import { ReactFCNoop } from 'types/global';

import { SettingsLayout } from 'modules/settingsContainer/SettingsLayout';
import { ViewTab } from 'modules/filters/Single/settings/ViewTab';
import { EventsTab } from 'modules/filters/Single/settings/EventsTab';
import { DataTab } from 'modules/filters/Single/settings/DataTab';

export const SingleFilterMainSettings = () => {
  return <SettingsLayout DataComponent={DataTab as ReactFCNoop} ViewComponent={ViewTab} EventComponent={() => EventsTab(true)} />;
};
