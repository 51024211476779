import { pxUnit } from 'constants/global';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { BackgroundSettings } from 'modules/settingsContainer/common/view/BackgroundSettings';
import { BorderSettings } from 'modules/settingsContainer/common/view/BorderSettings';
import {
  ContainerPositionSettings,
  ContainerPositionSettingsProps,
} from 'modules/settingsContainer/common/view/ContainerPositionSettings';
import { ShadowSettings } from 'modules/settingsContainer/common/view/ShadowSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React, { useCallback } from 'react';
import { useAppDispatch } from 'store';
import { updateViewSettingsAction } from 'store/reducers/visualisations/actions';
import { NoopValueType, ReactChildrenType } from 'types/global';
import {
  BoardPositionConfigInterface,
  BorderStyleSettingsInterface,
  ShadowSettingInterface,
  PaddingStyleSettingsInterface,
} from 'types/store';
import SubSection from 'shared/ui/subSection';
import { NameSettings } from '../../data/NameSettings';

import { IndentStyledComponent } from 'shared/ui/IndentStyledComponent';
import { updateFilterAction } from 'store/reducers/filters/actions';
import { onFilterNameSettingsChange } from '../../data/DefaultFilterEventSettings/constants';

export interface ContainerSettingsProps
  extends Pick<ContainerPositionSettingsProps, 'disableDragging' | 'onExternalDisableDraggingChange'> {
  onExternalPositionChange?: NoopValueType<BoardPositionConfigInterface>;
  onExternalShowBackgroundChange?: NoopValueType<boolean>;
  onExternalNameSettingsChange?: NoopValueType<string>;
  positionConfig: BoardPositionConfigInterface;
  showBackground?: boolean;
  onExternalShadowSettingsChange?: NoopValueType<ShadowSettingInterface>;
  shadowSettings: ShadowSettingInterface;
  onExternalBorderSettingsChange?: NoopValueType<BorderStyleSettingsInterface>;
  borderSettings: BorderStyleSettingsInterface;
  ExternalBackgroundSettings?: ReactChildrenType;
  nameSettings?: string;
  paddingSettings?: PaddingStyleSettingsInterface;
  paddingSettingsBlocked?: boolean | undefined;
  disabledComponentByCondition?: boolean;
}

export const ContainerSettings = ({
  positionConfig,
  disableDragging,
  showBackground,
  borderSettings,
  shadowSettings,
  nameSettings,
  onExternalBorderSettingsChange,
  onExternalShadowSettingsChange,
  onExternalPositionChange,
  onExternalDisableDraggingChange,
  onExternalShowBackgroundChange,
  onExternalNameSettingsChange,
  ExternalBackgroundSettings,
  paddingSettings,
  paddingSettingsBlocked,
  disabledComponentByCondition,
}: ContainerSettingsProps) => {
  const dispatch = useAppDispatch();
  const onChange = useCallback(
    (newSettings: BorderStyleSettingsInterface | PaddingStyleSettingsInterface, type: string) => {
      dispatch(updateViewSettingsAction({ [type]: newSettings }));
      dispatch(updateFilterAction({ [type]: newSettings }));
    },
    [dispatch],
  );

  return (
    <MainContainerSettings titleText="Параметры контейнера">
      <SubSection>
        <NameSettings value={nameSettings} onChange={onExternalNameSettingsChange || onFilterNameSettingsChange} />
      </SubSection>
      <SubSection>
        <ContainerPositionSettings
          value={positionConfig}
          onChange={onExternalPositionChange}
          disableDragging={disableDragging}
          onExternalDisableDraggingChange={onExternalDisableDraggingChange}
        />
        {ExternalBackgroundSettings ? (
          ExternalBackgroundSettings
        ) : (
          <BackgroundSettings value={!!showBackground} onChange={onExternalShowBackgroundChange} />
        )}
      </SubSection>
      <SubSection>
        <BorderSettings value={borderSettings} onChange={onExternalBorderSettingsChange} />
      </SubSection>
      <SubSection>
        <GroupContainerSettings>
          <ElementContainerSettings>
            <NumberPropertiesBlock
              properties="Радиус скругления углов"
              name="radius"
              unit={pxUnit}
              value={borderSettings.radius}
              onChange={(radius) =>
                onChange(
                  {
                    ...borderSettings,
                    radius,
                  },
                  'borderSettings',
                )
              }
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
      </SubSection>
      <SubSection>
        <ShadowSettings
          value={shadowSettings}
          onChange={onExternalShadowSettingsChange}
          disabledComponentByCondition={disabledComponentByCondition}
        />
      </SubSection>
      {paddingSettings && !paddingSettingsBlocked && (
        <SubSection>
          <GroupContainerSettings titleText="Отступы">
            <IndentStyledComponent
              value={paddingSettings}
              onChange={(padding) =>
                onChange(
                  {
                    ...paddingSettings,
                    ...padding,
                  },
                  'paddingSettings',
                )
              }
            />
          </GroupContainerSettings>
        </SubSection>
      )}
    </MainContainerSettings>
  );
};
