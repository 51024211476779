import styled, { css } from 'styled-components';
import { DownIcon } from 'assets/icons/withContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { defaultDropDownStyleMixin, defaultScrollbarMixin } from 'constants/styles';

interface MultipleFilterWrapperProps {
  isOpen?: boolean;
  height?: number;
  isAlwaysOpen?: boolean;
}

interface FilterValuesWrapperProps {
  modalWidth?: number;
  isAlwaysOpen?: boolean;
  justifyContent?: string;
}

export const MultipleFilterWrapper = styled.div<MultipleFilterWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 9px;
  border: 1px solid transparent;
  min-width: ${({ isOpen }) => (!isOpen ? '100%' : '168px')};

  height: ${({ height, isAlwaysOpen, isOpen }) => (isAlwaysOpen || isOpen) && height && `${height}px`};
  min-height: ${({ isAlwaysOpen, isOpen }) => (isOpen ? (isAlwaysOpen ? `211px` : `424px`) : '100%')};

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${defaultDropDownStyleMixin};

      :after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(${ColorVarsEnum.Level_5_application});
        z-index: -1;
      }
    `};
`;

export const InnerContentWrapper = styled.div<MultipleFilterWrapperProps>`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 148px;

  height: ${({ height, isAlwaysOpen, isOpen }) => (isAlwaysOpen || isOpen) && height && `${height - 50}px`};
  min-height: ${({ isAlwaysOpen, isOpen }) => (isAlwaysOpen || isOpen) && `171px`};
`;

export const RotatedDownIcon = styled(DownIcon)`
  rotate: 180deg;
`;

export const FilterValuesWrapper = styled.div<FilterValuesWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 35px;
  overflow: auto;
  gap: 2px;

  ${defaultScrollbarMixin};
`;

interface FilterValueItemProps {
  selected: boolean;
}

export const FilterValueItem = styled.div<FilterValueItemProps>`
  position: relative;
  width: 100%;
  font-weight: normal;
  font-family: Roboto, sans-serif;
  transition: all 200ms ease 0s;
  font-size: 14px;
  line-height: 17px;
  min-height: 35px;
  color: var(${ColorVarsEnum.Level_1});
  padding: 8px 12px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(${ColorVarsEnum.Accent});
    opacity: 0.2;
    pointer-events: none;
    z-index: -1;
  }

  ${({ selected }) =>
    selected &&
    css`
      ::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(${ColorVarsEnum.Accent});
        opacity: 0.3;
        pointer-events: none;
        z-index: -1;
      }
    `}
`;

export const FilterSortingContainer = styled.div<FilterValuesWrapperProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};

  ${({ modalWidth }) =>
    modalWidth &&
    `
    ${modalWidth < 220 && 'flex-direction: column; gap: 6px;'}
  `}
`;
