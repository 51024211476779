import { ColorPicker, ColorPickerProps, LargeCircleActivator } from 'modules/settingsContainer/ColorPicker';
import { NoopType } from 'types/global';
import React from 'react';
import { PickerWrapper } from 'components/shared/PickerWrapper';

interface ColorAndImageByPickerProps extends Pick<ColorPickerProps, 'value' | 'onChange'> {
  name?: string | number;
  onDelete: NoopType;
}

export const ColorByPicker = ({ value, onChange, name, onDelete }: ColorAndImageByPickerProps) => (
  <PickerWrapper name={name} onDelete={onDelete}>
    <ColorPicker onChange={onChange} Activator={LargeCircleActivator} closeOnClick type="color" value={value} />
  </PickerWrapper>
);
