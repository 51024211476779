import { useSelector } from 'react-redux';
import { getActiveVisualisationSettings, getCodeEditorDataById } from 'store/reducers/visualisations/getters';
import {
  DefaultBackgroundImagesSettingsType,
  DefaultDataSettingsType,
  DefaultSqlDataInterface,
  DefaultViewSettingsInterface,
} from 'store/reducers/visualisations/types';
import {
  defaultBackgroundImagesSettings,
  defaultDataSettings,
  defaultSqlDataSettings,
  defaultViewSettings,
  initialEventsSettings,
  initialPositionConfig,
} from 'store/reducers/visualisations/constants';
import { getAstOfVisualisationById } from 'store/reducers/ast/getters';

interface DefaultDataParams<
  VisualisationDataSettings extends DefaultDataSettingsType,
  VisualisationViewSettings extends DefaultViewSettingsInterface,
  VisualisationBackgroundImagesSettings extends DefaultBackgroundImagesSettingsType,
  VisualisationSqlDataSettings extends DefaultSqlDataInterface,
> {
  defaultData?: VisualisationDataSettings;
  defaultView?: VisualisationViewSettings;
  defaultBackgroundImages?: VisualisationBackgroundImagesSettings;
  defaultSqlData?: VisualisationSqlDataSettings;
}

export const useGetActiveVisualisationSettings = <
  VisualisationDataSettings extends DefaultDataSettingsType,
  VisualisationViewSettings extends DefaultViewSettingsInterface,
  VisualisationBackgroundImagesSettings extends DefaultBackgroundImagesSettingsType,
  VisualisationSqlDataSettings extends DefaultSqlDataInterface,
>(
  params?: DefaultDataParams<
    VisualisationDataSettings,
    VisualisationViewSettings,
    VisualisationBackgroundImagesSettings,
    VisualisationSqlDataSettings
  >,
) => {
  const { defaultData, defaultView, defaultBackgroundImages, defaultSqlData } = params || {};

  const { viewSettings, dataSettings, sqlData, backgroundImagesSettings, events, id, positionConfig } = useSelector(
    getActiveVisualisationSettings,
  ) || {
    dataSettings: defaultData,
    viewSettings: defaultView,
    backgroundSettings: defaultBackgroundImages,
    sqlData: defaultSqlData,
    events: initialEventsSettings,
    positionConfig: initialPositionConfig,
  };

  const codeEditorData = useSelector(getCodeEditorDataById(id || ''));
  const astOfVisualisation = useSelector(getAstOfVisualisationById(id || ''));

  /* TODO: Refactor this code   */

  const dataSettingsValue = defaultData !== null ? { ...(defaultData || defaultDataSettings), ...dataSettings } : null,
    sqlDataValue = defaultSqlData !== null ? { ...(defaultSqlData || defaultSqlDataSettings), ...sqlData } : null,
    backgroundImagesValue =
      defaultBackgroundImages !== null
        ? { ...(defaultBackgroundImages || defaultBackgroundImagesSettings), ...backgroundImagesSettings }
        : null;

  return {
    dataSettings: dataSettingsValue as VisualisationDataSettings,
    viewSettings: { ...(defaultView || defaultViewSettings), ...viewSettings } as VisualisationViewSettings,
    backgroundImagesSettings: backgroundImagesValue as VisualisationBackgroundImagesSettings,
    sqlData: sqlDataValue as VisualisationSqlDataSettings,
    events: { ...initialEventsSettings, ...events },
    codeEditorData,
    astOfVisualisation,
    positionConfig,
    id: id || '',
  };
};

// import { useSelector } from 'react-redux';
// import { getActiveVisualisationSettings, getCodeEditorDataById } from 'store/reducers/visualisations/getters';
// import {
//   DefaultBackgroundImagesSettingsType,
//   DefaultDataSettingsType,
//   DefaultSqlDataInterface,
//   DefaultViewSettingsInterface,
//   EventsSettingsInterface,
// } from 'store/reducers/visualisations/types';
// import {
//   defaultBackgroundImagesSettings,
//   defaultDataSettings,
//   defaultSqlDataSettings,
//   defaultViewSettings,
//   initialEventsSettings,
//   initialPositionConfig,
// } from 'store/reducers/visualisations/constants';
// import { getAstOfVisualisationById } from 'store/reducers/ast/getters';
// import { useEffect, useState } from 'react';
// import { BoardPositionConfigInterface } from 'types/store';
//
// interface DefaultDataParams<
//   VisualisationDataSettings extends DefaultDataSettingsType,
//   VisualisationViewSettings extends DefaultViewSettingsInterface,
//   VisualisationBackgroundImagesSettings extends DefaultBackgroundImagesSettingsType,
//   VisualisationSqlDataSettings extends DefaultSqlDataInterface,
//   > {
//   defaultData?: VisualisationDataSettings;
//   defaultView?: VisualisationViewSettings;
//   defaultBackgroundImages?: VisualisationBackgroundImagesSettings;
//   defaultSqlData?: VisualisationSqlDataSettings;
// }
//
// export const useGetActiveVisualisationSettings = <
//   VisualisationDataSettings extends DefaultDataSettingsType,
//   VisualisationViewSettings extends DefaultViewSettingsInterface,
//   VisualisationBackgroundImagesSettings extends DefaultBackgroundImagesSettingsType,
//   VisualisationSqlDataSettings extends DefaultSqlDataInterface,
//   >(
//   params?: DefaultDataParams<
//     VisualisationDataSettings,
//     VisualisationViewSettings,
//     VisualisationBackgroundImagesSettings,
//     VisualisationSqlDataSettings
//     >,
// ) => {
//   const { defaultData, defaultView, defaultBackgroundImages, defaultSqlData } = params || {};
//
//   const [loading, setLoading] = useState(true);
//
//   const [dataSettings, setDataSettings] = useState<VisualisationDataSettings | null>(null);
//   const [viewSettings, setViewSettings] = useState<VisualisationViewSettings | null>(null);
//   const [sqlData, setSqlData] = useState<VisualisationSqlDataSettings | null>(null);
//   const [backgroundImagesSettings, setBackgroundImagesSettings] = useState<VisualisationBackgroundImagesSettings | null>(null);
//   const [events, setEvents] = useState<EventsSettingsInterface>(initialEventsSettings);
//   const [positionConfig, setPositionConfig] = useState<BoardPositionConfigInterface>(initialPositionConfig);
//   const [id, setId] = useState<string>('');
//
//   const settings = useSelector(getActiveVisualisationSettings);
//
//   useEffect(() => {
//     if (settings) {
//       setDataSettings((settings.dataSettings as VisualisationDataSettings) || defaultData || ({} as VisualisationDataSettings));
//       setViewSettings((settings.viewSettings as VisualisationViewSettings) || defaultView || ({} as VisualisationViewSettings));
//       setSqlData((settings.sqlData as VisualisationSqlDataSettings) || defaultSqlData || ({} as VisualisationSqlDataSettings));
//       setBackgroundImagesSettings(
//         (settings.backgroundImagesSettings as VisualisationBackgroundImagesSettings) ||
//         defaultBackgroundImages ||
//         ({} as VisualisationBackgroundImagesSettings),
//       );
//       setEvents(settings.events || initialEventsSettings);
//       setPositionConfig(settings.positionConfig || initialPositionConfig);
//       setId(settings.id || '');
//     }
//     setLoading(false);
//   }, [settings, defaultData, defaultView, defaultSqlData, defaultBackgroundImages]);
//
//   const codeEditorData = useSelector(getCodeEditorDataById(id || ''));
//   const astOfVisualisation = useSelector(getAstOfVisualisationById(id || ''));
//
//   const dataSettingsValue = dataSettings ? { ...(defaultData || defaultDataSettings), ...dataSettings } : defaultData;
//   const sqlDataValue = sqlData ? { ...(defaultSqlData || defaultSqlDataSettings), ...sqlData } : defaultSqlData;
//   const backgroundImagesValue = backgroundImagesSettings
//     ? { ...(defaultBackgroundImages || defaultBackgroundImagesSettings), ...backgroundImagesSettings }
//     : defaultBackgroundImages;
//
//   return {
//     loading,
//     dataSettings: dataSettingsValue as VisualisationDataSettings,
//     viewSettings: { ...(defaultView || defaultViewSettings), ...viewSettings } as VisualisationViewSettings,
//     backgroundImagesSettings: backgroundImagesValue as VisualisationBackgroundImagesSettings,
//     sqlData: sqlDataValue as VisualisationSqlDataSettings,
//     events: { ...initialEventsSettings, ...events },
//     codeEditorData,
//     astOfVisualisation,
//     positionConfig,
//     id: id || '',
//   };
// };
