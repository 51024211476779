import { OnValueChange } from 'types/global';
import { SQLModeDynamicMarkerInterface } from 'store/reducers/visualisations/types';
import { ColorManaging, conditionIncision, conditionIndicator } from 'components/ColorManaging';
import { CircleActivator, ColorPicker } from 'modules/settingsContainer/ColorPicker';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import React from 'react';
import { FormSettingsSQL } from './FormSettingsSQL';
import { markersOptions } from '../constants';
import Select from 'modules/ui/Select';
import { conditionIncisionForPivot, conditionIndicatorForPivot } from 'components/ColorManaging/constants';

interface SQLSettingsDynamicMarkerProps extends OnValueChange<SQLModeDynamicMarkerInterface> {
  isPivot?: boolean;
}

export const SQLSettingsDynamicMarker = ({ value, onChange, isPivot = false }: SQLSettingsDynamicMarkerProps) => {
  const { colorMarker, formMarker } = value;

  return (
    <>
      <ColorManaging
        titleText="Цвет символа"
        titleInHeaderModal="Изменить условие для цвета маркера"
        colorBy={colorMarker}
        onChange={(colorSettings) => onChange({ ...value, colorMarker: { ...colorMarker, ...colorSettings } })}
        options={isPivot ? conditionIndicatorForPivot : conditionIndicator}
      >
        <ColorPicker
          Activator={CircleActivator}
          closeOnClick
          type="color"
          onChange={(color) => onChange({ ...value, colorMarker: { ...colorMarker, colorManual: color } })}
          value={colorMarker.colorManual}
          defaultActivatorBackgroundColor={`var(${ColorVarsEnum.Level_1})`}
        />
      </ColorManaging>
      <FormSettingsSQL
        titleText="Форма символа"
        titleInHeaderModal="Изменить условие для формы маркера"
        value={formMarker}
        onChange={(formMarketSettings) => onChange({ ...value, formMarker: formMarketSettings })}
        options={isPivot ? conditionIncisionForPivot : conditionIncision}
      >
        <Select
          name="MarkerSQL"
          needBackground={false}
          width="auto"
          value={formMarker.manualMarker}
          options={markersOptions}
          onChange={(marker) => onChange({ ...value, formMarker: { ...formMarker, manualMarker: marker } })}
          needBorder={false}
        />
      </FormSettingsSQL>
    </>
  );
};
