export const ShortSvg = ({ strokeUpArrow, strokeDownArrow }: { strokeUpArrow: string; strokeDownArrow: string }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.4164 3.19434L15.4164 16.5277M15.4164 16.5277L12.6387 13.7499M15.4164 16.5277L18.1942 13.7499"
        stroke={strokeUpArrow}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.58344 16.5278L4.58344 3.1945M4.58344 3.1945L1.80566 5.97228M4.58344 3.1945L7.36122 5.97228"
        stroke={strokeDownArrow}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
