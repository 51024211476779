import React, { FC, useCallback, useState } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { useAppDispatch } from 'store';
import {
  addNewTemplateVisualisationAction,
  addVisualisationAction,
  loadTemplateVisualisationAction,
} from 'store/reducers/visualisations/actions';
import { BoardMenuItem, BoardMenuItemProps } from 'modules/workspace/components/BoardMenuItem';
import { HeaderSettingsAreaLayout } from 'modules/settingsContainer/FieldSettingsArea';
import { filters, visualisations, visualisationsPreviewIcons } from './constants';
import { VisualisationTypeType } from 'store/reducers/visualisations/types';
import { NoopType } from 'types/global';
import { IconContainer } from 'modules/workspace/components/leftBar/styles';
import { FilterType } from 'store/reducers/filters/types';
import { addFilterAction } from 'store/reducers/filters/actions';
import { StyledTooltip } from 'modules/ui/StyledTooltip';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { Diagram, Filter } from 'assets/icons/forDelete';
import { PagesIcon as Pages } from 'assets/pagesSettings';
import { PagesModal } from '../PagesModal';
import { useSelector } from 'react-redux';
import { getAllPreviewTemplateVisualisations } from 'store/reducers/visualisations/getters';
import { getActivePageId } from 'store/reducers/projectPages/getters';
import { unwrapResult } from '@reduxjs/toolkit';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { v4 as uuidv4 } from 'uuid';
import { TemplateVisualisationActionPayload } from 'store/reducers/visualisations/types';

const CreateVisualisationModal: BoardMenuItemProps['ModalData'] = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const pageId = useSelector(getActivePageId);
  const { loading, allPreviewTemplateVisualisationsList } = useSelector(getAllPreviewTemplateVisualisations);

  const [activeHover, setActiveHover] = useState<null | VisualisationTypeType>();

  const onCreateVisualisation = (visualisationType: VisualisationTypeType, onClose: NoopType) => () => {
    dispatch(addVisualisationAction(visualisationType));
    onClose();
  };

  /* TODO: Needs to be moved to a hook */
  const onCreateTemplateVisualisation = useCallback(
    async (templateVisualisationId: string) => {
      try {
        if (pageId) {
          const uniqueVisualisationId = uuidv4();

          const actionTemplateVisualisationResponse = await dispatch(
            loadTemplateVisualisationAction({
              templateVisualisationId,
              pageId,
              newVisualisationId: uniqueVisualisationId,
            } as TemplateVisualisationActionPayload),
          );

          const unwrappedTemplateVisualisationResult = unwrapResult(actionTemplateVisualisationResponse);

          if (unwrappedTemplateVisualisationResult) {
            dispatch(
              addNewTemplateVisualisationAction({
                ...unwrappedTemplateVisualisationResult,
                id: uniqueVisualisationId,
              }),
            );
            onClose();
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [pageId, dispatch, onClose],
  );

  const filterPreviewTemplateVisualisations = allPreviewTemplateVisualisationsList.filter(({ type }) => type === activeHover);

  const visualisationPreviewDefaultIcon = (type: VisualisationTypeType) =>
    visualisations.find(({ visualisationType }) => visualisationType === type)?.Icon;

  return (
    <HeaderSettingsAreaLayout headerText="Визуализации" onClose={onClose}>
      <FlexContainer padding="12px">
        <FlexContainer flexDirection="column" gap="12px">
          <PrimaryTextParagraph fontSize="12px" lineHeight="12px" color={`var(${ColorVarsEnum.Level_2})`} fontWeight={400}>
            Базовые
          </PrimaryTextParagraph>
          <FlexContainer gap="10px" marginBottom="14px">
            {visualisations.map(({ visualisationType, Icon, title }) => (
              <StyledTooltip key={title} title={title} placement="top">
                <IconContainer
                  activeHover={activeHover === visualisationType}
                  onClick={onCreateVisualisation(visualisationType, onClose)}
                  onMouseOver={() => setActiveHover(visualisationType)}
                >
                  <Icon />
                </IconContainer>
              </StyledTooltip>
            ))}
          </FlexContainer>

          <PrimaryTextParagraph fontSize="12px" lineHeight="12px" color={`var(${ColorVarsEnum.Level_2})`} fontWeight={400}>
            Преднастроенные
          </PrimaryTextParagraph>
          <FlexContainer gap="10px" height="72px" position="relative">
            <>
              <LoadingOverlay loading={loading} backgroundColor={`var(${ColorVarsEnum.Level_5_application})`} />
              {filterPreviewTemplateVisualisations.map(({ type, name, id, icon }) => {
                const Icon =
                  icon && visualisationsPreviewIcons[icon]
                    ? visualisationsPreviewIcons[icon]
                    : visualisationPreviewDefaultIcon(type);

                return (
                  <StyledTooltip key={name} title={name} placement="top">
                    <IconContainer onClick={() => onCreateTemplateVisualisation(id)}>{Icon && <Icon />}</IconContainer>
                  </StyledTooltip>
                );
              })}
            </>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </HeaderSettingsAreaLayout>
  );
};

const CreateFilterModal: BoardMenuItemProps['ModalData'] = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const handleCreateFilter = (type: FilterType, onClose: NoopType) => () => {
    dispatch(addFilterAction(type));
    onClose();
  };

  return (
    <HeaderSettingsAreaLayout headerText="Фильтры" onClose={onClose}>
      <FlexContainer margin="10px" gap="10px">
        {filters.map(({ type, Icon, title }) => (
          <StyledTooltip key={title} title={title} placement="top">
            <IconContainer onClick={handleCreateFilter(type, onClose)}>
              <Icon />
            </IconContainer>
          </StyledTooltip>
        ))}
      </FlexContainer>
    </HeaderSettingsAreaLayout>
  );
};

export const LeftBar: FC = () => (
  <FlexContainer
    flexDirection="column"
    maxWidth="60px"
    backgroundColor={`var(${ColorVarsEnum.Level_5_application})`}
    zIndex="2"
    alignItems="center"
    flex="1"
  >
    <BoardMenuItem Icon={Diagram} ModalData={CreateVisualisationModal} />
    <BoardMenuItem Icon={Filter} ModalData={CreateFilterModal} />
    <BoardMenuItem Icon={Pages} ModalData={PagesModal} closeOnClickAway={false} />
  </FlexContainer>
);
