import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import React from 'react';
import { OnValueChange } from 'types/global';
import Select from 'modules/ui/Select';
import { FlexContainer } from 'styles/FlexContainer';
import { BubbleIndicatorInterface, RangeIndicatorIdInterface } from 'store/reducers/visualisations/types';

interface IndicatorSelectorSettingsProps extends OnValueChange<RangeIndicatorIdInterface> {
  indicators: BubbleIndicatorInterface[];
  title?: string;
  disabledSwitcher?: boolean;
}

export const IndicatorSelectorSettings = ({
  title = 'Показатели',
  indicators,
  value,
  onChange,
  disabledSwitcher = false,
}: IndicatorSelectorSettingsProps) => {
  const handleChange = () => onChange({ ...value, isShow: !value.isShow });

  return (
    <GroupContainerSettings
      titleText={title}
      switcherState={value.isShow}
      switcherChange={!disabledSwitcher ? handleChange : undefined}
    >
      <FlexContainer width="100%" justifyContent="flex-start">
        <Select
          needBackground={false}
          placeholder="Выбрать показатель"
          name="indicator_selector"
          options={indicators.map(({ id, name }) => ({
            value: id,
            name,
          }))}
          width="60%"
          value={value.rangeIndicatorId}
          onChange={(rangeIndicatorId) => onChange({ ...value, rangeIndicatorId })}
        />
      </FlexContainer>
    </GroupContainerSettings>
  );
};
