import React from 'react';
import { useAppDispatch } from 'store';
import { updateViewSettingsAction } from 'store/reducers/visualisations/actions';
import { NoopValueType } from 'types/global';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { colorDefaultNameTitle, pxUnit } from 'constants/global';
import { ColorPickerItem } from 'modules/settingsContainer/common/data/PropertiesSettings';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { borderStyleOptions } from 'modules/settingsContainer/common/view/BorderSettings/constants';
import { BorderStyleSettingsInterface } from 'types/store';

interface BorderSettingsProps {
  onChange?: NoopValueType<BorderStyleSettingsInterface>;
  value: BorderStyleSettingsInterface;
}

export const BorderSettings = ({ value, onChange: onExternalChange }: BorderSettingsProps) => {
  const { isActive, width, style, color } = value;
  const dispatch = useAppDispatch();

  const { defaultColor } = useColorValues();

  const onLocalChange = (borderSettings: BorderStyleSettingsInterface) => {
    dispatch(updateViewSettingsAction({ borderSettings }));
  };

  const onChange = onExternalChange || onLocalChange;

  return (
    <>
      <GroupContainerSettings
        titleText="Обводка"
        switcherState={isActive}
        switcherChange={() => onChange({ ...value, isActive: !isActive })}
        showBottomLine
      >
        <ElementContainerSettings>
          <NumberPropertiesBlock
            properties="Толщина"
            name="width"
            unit={pxUnit}
            value={width}
            onChange={(width) =>
              onChange({
                ...value,
                width,
              })
            }
          />
        </ElementContainerSettings>
        <ElementContainerSettings>
          <ColorPickerItem
            title={colorDefaultNameTitle}
            colorsValue={color}
            onChangeColors={(color) =>
              onChange({
                ...value,
                color,
              })
            }
            defaultActivatorBackgroundColor={defaultColor}
          />
        </ElementContainerSettings>
      </GroupContainerSettings>
      <GroupContainerSettings titleText="Тип линии" showBottomLine>
        <ElementContainerSettings>
          <SettingsRadio
            onChange={({ value: style }) => onChange({ ...value, style })}
            activeValue={getActiveRadioValue(borderStyleOptions, style)}
            options={borderStyleOptions}
          />
        </ElementContainerSettings>
      </GroupContainerSettings>
    </>
  );
};
