import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { pxUnit } from 'constants/global';
import { IndentStyledBlock } from './styles';
import { BottomIndent, LeftIndent, RightIndent, TopIndent } from 'assets/icons/fontStyle';
import { OnValueChange } from 'types/global';
import { IndentationInterface } from 'store/reducers/visualisations/types';

type IndentStyledComponentProps = OnValueChange<IndentationInterface>;
export const IndentStyledComponent = ({
  value,
  onChange,
  disabledVertical = false,
  disabledHorizontal = false,
}: IndentStyledComponentProps & {
  disabledVertical?: boolean;
  disabledHorizontal?: boolean;
}) => {
  return (
    <IndentStyledBlock>
      {!disabledHorizontal && (
        <NumberPropertiesBlock
          properties={<LeftIndent />}
          name="left"
          value={value?.left}
          unit={pxUnit}
          onChange={(left) => onChange({ ...value, left })}
        />
      )}
      {!disabledVertical && (
        <NumberPropertiesBlock
          properties={<TopIndent />}
          name="top"
          value={value?.top}
          unit={pxUnit}
          onChange={(top) => onChange({ ...value, top })}
        />
      )}
      {!disabledHorizontal && (
        <NumberPropertiesBlock
          properties={<RightIndent />}
          name="rigth"
          value={value?.right}
          unit={pxUnit}
          onChange={(right) => onChange({ ...value, right })}
        />
      )}
      {!disabledVertical && (
        <NumberPropertiesBlock
          properties={<BottomIndent />}
          name="bottom"
          value={value?.bottom}
          unit={pxUnit}
          onChange={(bottom) => onChange({ ...value, bottom })}
        />
      )}
    </IndentStyledBlock>
  );
};
