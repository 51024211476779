import React from 'react';
import { FilterInterface, FilterType, FilterTypeEnum } from 'store/reducers/filters/types';
import { ReactChildrenType } from 'types/global';
import { FilterInfluenceSettings } from 'modules/settingsContainer/common/FilterInfluenceSettings';
import { toggleInfluenceFiltersMode } from 'store/reducers/board';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { getInfluenceFiltersMode } from 'store/reducers/board/getters';
import { getVisualisations } from 'store/reducers/visualisations/getters';
import { getFilters } from 'store/reducers/filters/getters';
import { applyToAllInfluences } from 'utils/utils';
import { onMultipleModeChange } from 'modules/filters/Single/settings/DataTab/constants';
import { MultipleChoiceSettings } from 'modules/settingsContainer/common/data/MultipleChoiceSettings';
import {
  onFilterInfluencesChange,
  onGlobalChange,
} from 'modules/settingsContainer/common/data/DefaultFilterEventSettings/constants';

interface DefaultFilterEventsTabSettingsProps<Type extends FilterType> {
  data: FilterInterface<Type>;
  children?: ReactChildrenType;
  modelIdValue: string;
  isMultipleChoiceSettings: boolean;
  defaultIsMultipleMode: boolean;
}

export const DefaultFilterEventsTabSettings = <Type extends FilterType>({
  data,
  isMultipleChoiceSettings,
  defaultIsMultipleMode,
}: DefaultFilterEventsTabSettingsProps<Type>) => {
  const dispatch = useAppDispatch();
  const influenceFiltersMode = useSelector(getInfluenceFiltersMode);
  const visualisations = useSelector(getVisualisations);
  const filters = useSelector(getFilters);
  const { id, isGlobal, filterInfluences, isMultipleMode } = data;
  const toggleInfluenceFilterMode = () => {
    dispatch(toggleInfluenceFiltersMode());
  };

  const onApplyToAllFilterInfluences = (payload: boolean) => () => {
    if (!influenceFiltersMode) {
      toggleInfluenceFilterMode();
    }

    const influenceData = applyToAllInfluences({ ...visualisations, ...filters }, payload, id);
    onFilterInfluencesChange({ ...filterInfluences, ...influenceData });
  };

  return (
    <>
      <FilterInfluenceSettings
        value={isGlobal}
        onChange={onGlobalChange}
        influenceModeValue={influenceFiltersMode}
        onToggleInfluenceMode={toggleInfluenceFilterMode}
        onApplyToAllFilterInfluences={onApplyToAllFilterInfluences}
      />
      {isMultipleChoiceSettings && (
        <MultipleChoiceSettings
          value={isMultipleMode === null ? defaultIsMultipleMode : isMultipleMode}
          onChange={onMultipleModeChange}
          type={FilterTypeEnum.SINGLE}
        />
      )}
    </>
  );
};
