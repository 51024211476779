import React from 'react';
import { updateViewSettingsAction } from 'store/reducers/visualisations/actions';
import { useAppDispatch } from 'store';
import { TextSettingsControlInterface } from 'modules/settingsContainer/common/TextSettings/types';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { TextArea } from 'modules/ui';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { TextSettingInterface } from 'types/types';

type TextHintSettingsProps = TextSettingsControlInterface;

export const TextHintSettings = ({ value, onChange: onExternalChange }: TextHintSettingsProps) => {
  const dispatch = useAppDispatch();

  const onLocalChange = (hint: TextSettingInterface) => {
    dispatch(updateViewSettingsAction({ hint }));
  };

  const onChange = onExternalChange || onLocalChange;

  return (
    <MainContainerSettings
      titleText="Инфо"
      switcherState={value.isShow}
      switcherChange={() => onChange({ ...value, isShow: !value.isShow })}
    >
      <GroupContainerSettings>
        <ElementContainerSettings>
          <TextArea
            name="textarea"
            needBackground={true}
            value={value.text}
            onChange={(events) =>
              onChange({
                ...value,
                text: events.target.value,
              })
            }
            width="100%"
            placeholder="Текст"
            rows={5}
          />
        </ElementContainerSettings>
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
