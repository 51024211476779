import { ContainerSettingsInterface } from 'types/store';
import { ColorByConditionInterface, ColorByInterface, ColorByValueInterface } from 'store/reducers/visualisations/types';

import { backgroundColorAlias, getDefaultColorBySettings } from 'store/reducers/visualisations/constants';
export const setSliceFn = <Slice extends object>(state: Slice, { payload: slice }: { payload: Slice }) => {
  (Object.keys(slice) as Array<keyof Slice>).forEach((key) => {
    (state[key] as Slice[keyof Slice]) = slice[key];
  });
};

export const defaultColorByCondition: ColorByConditionInterface = {
  colors: [],
  sqlCondition: null,
  alias: 'shadowColor',
};

export const defaultColorByValue: ColorByValueInterface = {
  alias: 'shadowColor',
  colors: null,
};

export const defaultBackgroundColorBy: ColorByInterface = {
  type: 'default',
  isActive: false,
  byCondition: {
    colors: [],
    sqlCondition: null,
    alias: 'backgroundColor',
  },
  byValue: {
    alias: 'backgroundColor',
    colors: null,
  },
  byValueSpecific: {
    alias: 'backgroundColor',
    colors: null,
  },
};
export const defaultBackgroundBySettings: ColorByInterface = {
  type: 'default',
  isActive: false,
  byCondition: {
    colors: [],
    sqlCondition: null,
    alias: 'backgroundColor',
  },
  byValue: {
    alias: 'backgroundColor',
    colors: null,
  },
  byValueSpecific: {
    alias: 'backgroundColor',
    colors: null,
  },
};
export const defaultContainerSettings: ContainerSettingsInterface = {
  disableDragging: false,
  paddingSettings: {
    unsetPaddings: true,
    top: 16,
    left: 16,
    right: 16,
    bottom: 16,
  },
  backgroundSettings: {
    isShow: false,
    colorSettings: {
      backgroundColorBy: defaultBackgroundColorBy,
      background: {
        isShow: true,
        color: null,
      },
      hover: {
        isShow: false,
        color: null,
      },
      active: {
        isShow: false,
        color: null,
      },
    },
  },
  borderSettings: {
    isActive: false,
    radius: 8,
    width: 1,
    style: 'solid',
    color: null,
  },
  shadowSettings: {
    isActive: false,
    blurRadius: 10,
    offsetX: 0,
    offsetY: 5,
    shadowColorSettings: {
      shadowColor: {
        isShow: false,
        color: null,
      },
      hover: {
        isShow: false,
        color: null,
      },
      shadowColorBy: {
        type: 'default',
        isActive: false,
        byValueSpecific: {
          alias: 'shadowSpecificColor',
          colors: null,
        },
        byCondition: defaultColorByCondition,
        byValue: defaultColorByValue,
      },
    },
  },
};
