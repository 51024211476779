import { ColorStrategyEnum } from 'store/reducers/visualisations/constants';
import { ElementDesignInterface } from 'store/reducers/visualisations/types';

export const useElementGlobalDesign = (elementDesign: ElementDesignInterface) => {
  const {
    propertiesIndicatorColorSettings,
    propertiesIncisionColorSettings,
    colorSettingsIncision,
    colorSettingsIndicator,
    colorStrategyType,
  } = elementDesign;

  const elementDesignSettings = {
    [ColorStrategyEnum.ByIndicator]: {
      propertiesColorGlobal: propertiesIndicatorColorSettings,
      colorSettingsGlobal: colorSettingsIndicator,
    },
    [ColorStrategyEnum.ByIncision]: {
      propertiesColorGlobal: propertiesIncisionColorSettings,
      colorSettingsGlobal: colorSettingsIncision,
    },
  };

  return elementDesignSettings[colorStrategyType];
};
