import { ColorManaging, conditionIncision } from 'components/ColorManaging';
import { percentUnit } from 'constants/global';
import { GroupContainerSettings, MainContainerSettings } from 'modules/settingsContainer';
import { PropertiesSettings } from 'modules/settingsContainer/common/data/PropertiesSettings';
import {
  onBackgroundByValueSettingsChange,
  onColorByValueSettingsChange,
} from 'modules/visualisations/Table/settings/DataTab/constants';
import React from 'react';
import { IndentStyledComponent } from 'shared/ui/IndentStyledComponent';
import { TableBodySettingsInterface, TableDataSettings } from 'store/reducers/visualisations/types';
import { OnValueChange } from 'types/global';
import SubSection from 'shared/ui/subSection';

type BodyStylesSettingsProps = OnValueChange<TableBodySettingsInterface>;
export const BodyStylesSettings = ({
  dataSettings,
  value,
  onChange,
}: BodyStylesSettingsProps & {
  dataSettings: TableDataSettings;
}) => {
  const { propertiesIncisions, propertiesIndicators, verticalLines, horizontalLines, rowsBeat, externalBeat, indentation } =
    value;

  return (
    <MainContainerSettings titleText="Тело таблицы">
      <SubSection>
        <PropertiesSettings
          title="Свойства ячейки разрезов в колонках"
          isMainContainerColorSettings
          isMainContainerSettings
          disabledComponentColorByValue
          disabledFontColorByBlock
          disabledPadding
          activeAlignmentHorizontal
          activeAlignmentVertical
          value={propertiesIncisions}
          onChange={(properties) => onChange({ ...value, propertiesIncisions: properties })}
          disabledOpacity
        />
      </SubSection>
      <SubSection>
        <PropertiesSettings
          title="Свойства ячейки показателей"
          isMainContainerColorSettings
          isMainContainerSettings
          disabledComponentColorByValue
          disabledFontColorByBlock
          disabledPadding
          activeAlignmentHorizontal
          activeAlignmentVertical
          value={propertiesIndicators}
          onChange={(properties) => onChange({ ...value, propertiesIndicators: properties })}
          disabledOpacity
        />
      </SubSection>
      <SubSection>
        <ColorManaging
          titleText="Цвет текста всех ячеек"
          colorBy={dataSettings.colorByValueSettings}
          onChange={(fontColorBy) => onColorByValueSettingsChange(fontColorBy)}
          options={conditionIncision}
          isRealData={dataSettings.isRealData}
        />
      </SubSection>
      <SubSection>
        <ColorManaging
          titleText="Цвет фона всех ячеек "
          colorBy={dataSettings.backgroundByValueSettings}
          onChange={(backgroundColorBy) => onBackgroundByValueSettingsChange(backgroundColorBy)}
          options={conditionIncision}
          isRealData={dataSettings.isRealData}
        />
      </SubSection>
      <SubSection>
        <GroupContainerSettings
          titleText="Вертикальные линии"
          switcherState={verticalLines.isBeat}
          switcherChange={() => onChange({ ...value, verticalLines: { ...verticalLines, isBeat: !verticalLines.isBeat } })}
          color={verticalLines.color}
          colorChange={(color) => onChange({ ...value, verticalLines: { ...verticalLines, color } })}
          countUnit={verticalLines.countUnit}
          thicknessBorderChange={(countUnit) =>
            onChange({
              ...value,
              verticalLines: { ...verticalLines, countUnit },
            })
          }
        />
      </SubSection>
      <SubSection>
        <GroupContainerSettings
          titleText="Горизонтальные линии"
          switcherState={horizontalLines.isBeat}
          switcherChange={() => onChange({ ...value, horizontalLines: { ...horizontalLines, isBeat: !horizontalLines.isBeat } })}
          color={horizontalLines.color}
          colorChange={(color) => onChange({ ...value, horizontalLines: { ...horizontalLines, color } })}
          countUnit={horizontalLines.countUnit}
          thicknessBorderChange={(countUnit) =>
            onChange({
              ...value,
              horizontalLines: { ...horizontalLines, countUnit },
            })
          }
        />
      </SubSection>
      <SubSection>
        <GroupContainerSettings
          titleText="Внешняя обводка"
          switcherState={externalBeat.isBeat}
          switcherChange={() => onChange({ ...value, externalBeat: { ...externalBeat, isBeat: !externalBeat.isBeat } })}
          color={externalBeat.color}
          colorChange={(color) => onChange({ ...value, externalBeat: { ...externalBeat, color } })}
          countUnit={externalBeat.countUnit}
          thicknessBorderChange={(countUnit) =>
            onChange({
              ...value,
              externalBeat: { ...externalBeat, countUnit },
            })
          }
        />
      </SubSection>
      <SubSection>
        <GroupContainerSettings
          titleText="Отбивка строк"
          switcherState={rowsBeat.isBeat}
          switcherChange={() => onChange({ ...value, rowsBeat: { ...rowsBeat, isBeat: !rowsBeat.isBeat } })}
          color={rowsBeat.color}
          colorChange={(color) => onChange({ ...value, rowsBeat: { ...rowsBeat, color } })}
          unitName={percentUnit}
          countUnit={rowsBeat.countUnit}
          thicknessBorderChange={(countUnit) =>
            onChange({
              ...value,
              rowsBeat: { ...rowsBeat, countUnit },
            })
          }
        />
      </SubSection>
      <SubSection>
        <GroupContainerSettings titleText="Отступы в ячейках">
          <IndentStyledComponent value={indentation} onChange={(indentation) => onChange({ ...value, indentation })} />
        </GroupContainerSettings>
      </SubSection>
    </MainContainerSettings>
  );
};
