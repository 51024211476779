import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import TextField from 'modules/ui/TextField';
import React from 'react';
import { OnValueChange } from 'types/global';

interface NumberPropertiesBlockProps extends OnValueChange<number> {
  name: string;
  properties?: string | JSX.Element;
  unit?: string;
  width?: string;
  padding?: string;
  disabled?: boolean;
  debounced?: boolean;
  heightSize?: 'small' | 'normal';
  allowZero?: boolean;
  isFirstLevel?: boolean;
}

export const NumberPropertiesBlock = ({
  properties,
  name,
  unit,
  width = '54px',
  padding,
  value,
  onChange,
  disabled = false,
  debounced = false,
  heightSize,
  allowZero = true,
  isFirstLevel = false,
}: NumberPropertiesBlockProps) => {
  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;
    if (!allowZero) {
      // Удаляем начальные нули
      newValue = newValue.replace(/^0+/, '');
    }
    if (newValue === '') {
      // Устанавливаем значение 0, если поле ввода пустое
      onChange(0);
    } else {
      onChange(Number(newValue));
    }
  };

  return (
    <FlexContainer alignItems="center" gap="5px">
      {properties &&
        (typeof properties === 'string' ? (
          <PrimaryTextSpan lineHeight="12px" color={`var(${isFirstLevel ? ColorVarsEnum.Level_1 : ColorVarsEnum.Level_3})`}>
            {properties}
          </PrimaryTextSpan>
        ) : (
          <>{properties}</>
        ))}
      <FlexContainer width={width}>
        <TextField
          onChange={onChangeText}
          value={value === 0 ? 1 : value}
          type="number"
          name={name}
          disabled={disabled}
          needBackground={false}
          needBorderBottom={false}
          width={width}
          heightSize={heightSize}
          useDebounce={debounced}
          padding={padding}
        />
      </FlexContainer>
      {unit && (
        <PrimaryTextSpan lineHeight="10px" color={`var(${ColorVarsEnum.Level_3})`}>
          {unit}
        </PrimaryTextSpan>
      )}
    </FlexContainer>
  );
};
