import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { defaultPositionConfig } from 'constants/defaults';
import { totalRowPositionOptions } from 'constants/global';
import { MOCK_GRAPHIC_OPERATION_TYPE_EXTENDED, MOCK_MONTH_NAME } from 'constants/Mock';
import { defaultColorByCondition, defaultColorByValue, defaultContainerSettings } from 'constants/store';
import { ExportType } from 'modules/settingsContainer/ContextMenuWrapper/types';
import { Select } from 'node-sql-parser/build/db2';
import { defaultAstOfFilterAndGroups } from 'store/reducers/ast/constants';
import { IncisionsAstType, IndicatorAstType, VisualisationAstInterface } from 'store/reducers/ast/types';
import {
  ActiveIncisionIdInterface,
  AlignmentInterface,
  AxisSettingsInterface,
  BackgroundByValueSettingsInterface,
  BarIncisionInterface,
  BarViewSettings,
  BubbleDataSettings,
  BubbleIncisionInterface,
  BubbleIndicatorInterface,
  BubbleViewSettings,
  ColorAndImageByEnum,
  ColorByInterface,
  ColorBySettingsInterface,
  ColorByValueSettingsInterface,
  ColumnWidthSettingsInterface,
  ConditionsMarkerEnum,
  CustomMarkersInterface,
  DefaultBackgroundImagesSettingsType,
  DefaultDataSettingsInterface,
  DefaultIncisionInterface,
  DefaultIncisionSettingsInterface,
  DefaultIndicatorInterface,
  DefaultPropertiesInterface,
  DefaultSqlDataInterface,
  DefaultVisualisationOptionsType,
  DynamicMarkerModeEnum,
  DynamicMarkerSettingsInterface,
  ElementDesignInterface,
  EventsSettingsInterface,
  ExportDataInterface,
  FormattingInterface,
  GradientColorByInterface,
  GroupByDateInterface,
  GroupByType,
  HeatmapDataSettings,
  HeatmapIndicatorInterface,
  HeatmapViewSettings,
  ImageByInterface,
  ImagesSettingsInterface,
  IndentationInterface,
  IndicatorEmptyValuesInterface,
  InitialVisualisationProps,
  InitialVisualisationsFnsType,
  InitialVisualisationsFnType,
  IsBeatInterface,
  LegendSettingsInterface,
  LimitSettingInterface,
  LineAndBarDataSettings,
  LineAndBarIndicatorInterface,
  LineAndBarTypeIndicator,
  LineAndBarViewSettings,
  MakeHyperLinksInterface,
  MarkersEnum,
  MinAndMaxInterface,
  PieDataSettings,
  PieIncisionInterface,
  PieIndicatorInterface,
  PieViewSettings,
  SettingsOneDynamicMarkerInterface,
  ShowSumInterface,
  SortSettingsInterface,
  SourceEnum,
  SourceInterface,
  SqlDependencyInterface,
  SqlValuesInterface,
  StrictDefaultViewSettingsInterface,
  TableDataSettings,
  TableIncisionInterface,
  TableIndicatorInterface,
  TableViewSettings,
  TextBackgroundSettings,
  TextDataSettings,
  TextVariablesInterface,
  TextViewSettings,
  TotalRowSettingsInterface,
  TransitionModeEnum,
  TreeDataSettings,
  TreeIncisionInterface,
  TreeIndicatorInterface,
  TreeViewSettings,
  UpdateBackgroundSettingsPayload,
  UpdateBlockSettingsPayload,
  UpdateDataSettingsPayload,
  UpdateEventsDataPayload,
  UpdatePositionConfigPayload,
  UpdateViewSettingsPayload,
  UpdateVisibleSettingsPayload,
  VisualisationIdInterface,
  VisualisationOperationTypeInterface,
  VisualisationStateInterface,
  VisualisationType,
  VisualisationTypeType,
  VisualMapSettingsInterface,
  WaterfallDataSettings,
  WaterfallIndicatorInterface,
  WaterfallIndicatorTypeInterface,
  WaterfallViewSettings,
  ZoomSettingsInterface,
} from 'store/reducers/visualisations/types';
import { AST } from 'types/ast';
import {
  BoardPositionConfigInterface,
  DesignOptionInterface,
  PaddingsVisualisationInterface,
  PositionSettingEnum,
  ProjectIdWithType,
  StartAndEndInterface,
} from 'types/store';
import { generateSqlSelectQuery, generateSqlSelectValue } from 'utils/SQL/generateSQL';
import {
  generateBasicColumn,
  generateBasicFunctionColumn,
  generateLimit,
  getSelectColumnsFromSqlString,
  sqlParser,
} from 'utils/SQL/genereteAst';
import { v4 } from 'uuid';
import { exportCsv, exportXlsx } from './api';

export enum ColorStrategyEnum {
  ByIndicator = 'byIndicator',
  ByIncision = 'byIncision',
}

export enum LineAndBarTypeIndicatorEnum {
  INDICATOR = 'indicator',
  INDICATOR_STACK_SUM = 'indicatorsStackSum',
}

export const initialVisualisationStoreState: VisualisationStateInterface = {
  visualisationData: {},
  visualisations: {},
  serverStateOfVisualisations: null,
  visualisationsByPages: {},
  visualisationsLoading: false,
  visualisationsLoadingList: {},
  visualisationsErrorsList: {},
  alreadyLoadedContent: { visualisations: new Set<string>() },
  allPreviewTemplateVisualisations: {
    allPreviewTemplateVisualisationsList: [],
    loading: false,
  },
};

export const initialPositionConfig: BoardPositionConfigInterface = {
  ...defaultPositionConfig,
  width: 750,
  height: 450,
};

export const defaultIsBeat: IsBeatInterface = {
  isBeat: true,
  color: null,
  countUnit: 1,
};

export const defaultActiveIncisionId: ActiveIncisionIdInterface = {
  activeIncisionId: null,
};

export const defaultAlignment: AlignmentInterface = {
  horizontal: PositionSettingEnum.FLEXSTART,
  vertical: PositionSettingEnum.FLEXSTART,
};
export const initialEventsSettings: EventsSettingsInterface = {
  goTo: { isActive: false, to: null },
  goToHref: { isActive: false, to: null, openWindowType: 'self' },
  activateTo: { isActive: false, to: null },
  activateObject: { filters: null, isActive: false },
  isReactingToFilter: true,
  filterSettings: {
    isFiltering: true,
    applyToAllPages: false,
    moreThanOne: true,
    isInfluenceItself: true,
    filterInfluences: null,
    responseToExternalDrillDown: true,
  },
};

export const defaultBubbleIndicatorId = v4();

export const defaultRangeIndicator = { rangeIndicatorId: defaultBubbleIndicatorId, isShow: false };

export const initialTextPositionConfig: BoardPositionConfigInterface = {
  ...initialPositionConfig,
  width: 300,
  height: 150,
};

const defaultHint = {
  hint: {
    isShow: false,
    text: 'Подсказка',
  },
};

export const defaultViewSettings: StrictDefaultViewSettingsInterface = {
  header: {
    isShow: true,
    text: 'Заголовок',
    fontStyle: {
      bold: true,
      italic: false,
      underline: false,
    },
    fontSize: 16,
    lineHeight: 140,
    letterSpacing: 0,
    fontColor: null,
    backgroundColor: null,
    backgroundOpacity: 100,
    wordSpacing: 0,
    isActiveStyle: false,
    isActiveFontColor: false,
    isActiveBackgroundColor: false,
    opacity: 100,
    padding: {
      horizontal: 22,
      vertical: 22,
      isConnection: false,
    },
    changeFillColor: false,
    alignment: {
      horizontal: 'flex-start',
      vertical: 'flex-start',
    },
    fontColorBy: {
      type: 'default',
      isActive: false,
      byCondition: {
        colors: [],
        sqlCondition: null,
        alias: 'fontColor',
      },
      byValue: {
        alias: 'fontColor',
        colors: null,
      },
      byValueSpecific: {
        alias: 'fontColor',
        colors: null,
      },
    },
    backgroundColorBy: {
      type: 'default',
      isActive: false,
      byCondition: {
        colors: [],
        sqlCondition: null,
        alias: 'fontColor',
      },
      byValue: {
        alias: 'fontColor',
        colors: null,
      },
      byValueSpecific: {
        alias: 'fontColor',
        colors: null,
      },
    },
  },
  description: {
    isShow: true,
    text: 'Подзаголовок',
    fontStyle: {
      bold: false,
      italic: false,
      underline: false,
    },
    fontSize: 14,
    lineHeight: 140,
    letterSpacing: 0,
    fontColor: null,
    backgroundColor: null,
    backgroundOpacity: 100,
    wordSpacing: 0,
    isActiveStyle: false,
    isActiveFontColor: false,
    isActiveBackgroundColor: false,
    opacity: 100,
    padding: {
      horizontal: 22,
      vertical: 22,
      isConnection: false,
    },
    changeFillColor: false,
    alignment: {
      horizontal: 'flex-start',
      vertical: 'flex-start',
    },
    fontColorBy: {
      type: 'default',
      isActive: false,
      byCondition: {
        colors: [],
        sqlCondition: null,
        alias: 'fontColor',
      },
      byValue: {
        alias: 'fontColor',
        colors: null,
      },
      byValueSpecific: {
        alias: 'fontColor',
        colors: null,
      },
    },
    backgroundColorBy: {
      type: 'default',
      isActive: false,
      byCondition: {
        colors: [],
        sqlCondition: null,
        alias: 'fontColor',
      },
      byValue: {
        alias: 'fontColor',
        colors: null,
      },
      byValueSpecific: {
        alias: 'fontColor',
        colors: null,
      },
    },
  },
  headerPosition: 'flex-start',
  hint: {
    isShow: false,
    text: 'Подсказка',
  },
  ...defaultContainerSettings,
  showBackground: true,
  name: '',
};

export const colorAlias = 'colors';
export const colorSpecificAlias = 'colorSpecificAlias';
export const colorSpecificValueAlias = 'colorSpecificValueAlias';
export const colorValueAlias = 'colorsValue';
export const colorValueByAlias = 'colorsValueBy';
export const backgroundValueAlias = 'backgroundValueAlias';
export const backgroundByValueAlias = 'backgroundByValueAlias';
export const hyperLinksAlias = 'hyperLinksAlias';
export const imageLinksAlias = 'imageLinksAlias';
export const dynamicMarkerColorAlias = 'dynamicMarkerColorAlias';
export const dynamicMarkerFormAlias = 'dynamicMarkerFormAlias';
export const imagesAlias = 'images';
export const fontColorAlias = 'fontColor';
export const backgroundColorAlias = 'backgroundColor';

export const defaultColorBySettings: ColorBySettingsInterface = {
  colorBySettings: {
    type: 'default',
    byCondition: {
      colors: [],
      sqlCondition: null,
      alias: colorAlias,
    },
    byValue: {
      alias: colorAlias,
      colors: null,
    },
    byValueSpecific: {
      alias: colorAlias,
      colors: null,
    },
  },
};

export const getDefaultColorBySettings: (alias: string) => ColorByInterface = (alias) => ({
  type: 'default',
  isActive: false,
  byCondition: {
    colors: [],
    sqlCondition: null,
    alias,
  },
  byValue: {
    alias,
    colors: null,
  },
  byValueSpecific: {
    alias,
    colors: null,
  },
});

export const defaultColorByValueSettings: ColorByValueSettingsInterface = {
  colorByValueSettings: getDefaultColorBySettings(colorValueAlias),
};

export const defaultBackgroundByValueSettings: BackgroundByValueSettingsInterface = {
  backgroundByValueSettings: getDefaultColorBySettings(backgroundValueAlias),
};

export const defaultSqlDataSettings: DefaultSqlDataInterface = {
  filterAndGroupRequest: '',
};

export const defaultBackgroundImagesSettings: DefaultBackgroundImagesSettingsType = null;

export const defaultLimitSetting: LimitSettingInterface = {
  isActive: false,
  value: 1,
};
export const defaultDataSettings: DefaultDataSettingsInterface = {
  ...defaultActiveIncisionId,
  isRealData: false,
  modelId: null,
  incisions: [],
  indicators: [],
  limit: defaultLimitSetting,
  isDrillDown: false,
};

export const defaultIndicatorEmptyValues: IndicatorEmptyValuesInterface = {
  isEmptyValue: true,
  value: '–',
};

export const defaultSqlValues: SqlValuesInterface = {
  fieldName: null,
  sqlCondition: null,
};

export const defaultSourceValues: SourceInterface = {
  type: SourceEnum.DATABASE,
  value: {
    ...defaultSqlValues,
    textLink: null,
  },
};

export const defaultHyperLickSettings: MakeHyperLinksInterface = {
  isActive: false,
  isFiltering: false,
  transitionMode: TransitionModeEnum.HERE,
  source: defaultSourceValues,
};

export const defaultImagesSettings: ImagesSettingsInterface = {
  isActive: false,
  source: defaultSourceValues,
  size: {
    horizontal: 150,
    vertical: 150,
  },
  alignmentAndPosition: {
    ...defaultAlignment,
    absolutePositionSetting: 'column',
  },
  textIndent: 5,
};

export const defaultCustomMarker: CustomMarkersInterface = {
  values: [0, 100],
  conditions: [ConditionsMarkerEnum.MORE, ConditionsMarkerEnum.LESS],
  color: null,
  marker: MarkersEnum.DOWNMARKER,
  id: v4(),
};

export const defaultDynamicMarkerSettings: DynamicMarkerSettingsInterface = {
  isShow: false,
  position: 'left',
  indent: 10,
  sizeMarker: 10,
  settingsMarker: {
    mode: DynamicMarkerModeEnum.MANUAL,
    manual: {
      customMarkers: [],
      defaultMarker: {
        isShow: true,
        color: null,
        marker: MarkersEnum.CIRCLEMARKER,
      },
    },
    sql: {
      colorMarker: {
        type: ColorAndImageByEnum.Default,
        colorManual: null,
        byCondition: defaultColorByCondition,
        byValue: defaultColorByValue,
        byValueSpecific: defaultColorByValue,
      },
      formMarker: {
        type: ColorAndImageByEnum.Default,
        manualMarker: MarkersEnum.SIMSMARKER,
        byCondition: {
          markers: [],
          sqlCondition: null,
        },
      },
    },
  },
};

export const defaultSettingsOneDynamicMarker: SettingsOneDynamicMarkerInterface = {
  type: MarkersEnum.UPMARKER,
  color: null,
  sizeMarker: 10,
  position: 'left',
  indent: 10,
};
export const defaultIncisionSettings: DefaultIncisionSettingsInterface = {
  emptyValues: defaultIndicatorEmptyValues,
  nameFromDatabase: false,
  customRequest: null,
};

const defaultOperationType: VisualisationOperationTypeInterface = {
  operationType: MOCK_GRAPHIC_OPERATION_TYPE_EXTENDED[1].value,
  customRequest: null,
};

const defaultFormattingType: FormattingInterface = {
  isShow: true,
  formats: {
    formattingItem: { formattingType: 'numerical', meta: { money: 'RUB' } },
    numberOfZeros: 0,
    editText: { text: '', isActive: false },
    numeric: false,
  },
};

const defaultColumnWidthSettings: ColumnWidthSettingsInterface = {
  columnWidthSettings: {
    isActive: false,
    width: 500,
  },
};

const defaultSortSettings: SortSettingsInterface = {
  sortSettings: {
    isActive: false,
    type: 'asc',
  },
};

export const defaultIncisionName = 'Разрез ';
export const defaultIncisionInHeaderName = 'Разрез в шапке ';
export const defaultVerticalIncisionName = 'Разрез по Y ';
export const defaultVariablesName = 'var';
export const defaultImageName = 'Фото ';
export const defaultIndicatorName = 'Показатель ';
export const defaultIndicatorSizeName = 'Размер ';
export const defaultIndicatorPlanName = 'План';
export const defaultIndicatorFactName = 'Факт';
export const defaultIndicatorPositiveName = 'Позитив';
export const defaultIndicatorNegativeName = 'Негатив';
export const defaultIndicatorPhantomName = 'Фантом';

export const defaultFictionalData = MOCK_MONTH_NAME;

export const defaultGroupByDateSettings: GroupByDateInterface<GroupByType> = {
  isShow: false,
  groupByType: 'day',
  groupByMask: '%d.%m.%Y',
};

export const getTreeIncision: (data: Pick<TreeIncisionInterface, 'id' | 'name'>) => TreeIncisionInterface = ({ id, name }) => ({
  id,
  name,
  fieldName: null,
  colors: null,
  fictionalData: defaultFictionalData,
  settings: {
    ...defaultIncisionSettings,
    isShowName: false,
  },
  propertiesIncisionName: defaultProperties,
  propertiesIncisionValue: defaultProperties,
  propertiesIncisionNameValue: defaultProperties,
});

export const defaultTextSettings = {
  fontStyle: {
    bold: false,
    italic: false,
    underline: false,
  },
  fontSize: 12,
  lineHeight: 120,
  letterSpacing: 0,
  fontColor: null,
  backgroundColor: null,
  backgroundOpacity: 100,
};

export const defaultPropertiesStyle: Omit<DefaultPropertiesInterface, 'fontColorBy' | 'backgroundColorBy'> = {
  ...defaultTextSettings,
  isActiveStyle: false,
  isActiveFontColor: false,
  isActiveBackgroundColor: false,
  opacity: 100,
  padding: {
    horizontal: 22,
    vertical: 22,
    isConnection: false,
  },
  changeFillColor: false,
  alignment: defaultAlignment,
};

export const defaultProperties: DefaultPropertiesInterface = {
  ...defaultPropertiesStyle,
  fontColorBy: getDefaultColorBySettings(fontColorAlias),
  backgroundColorBy: getDefaultColorBySettings(fontColorAlias),
};

export const defaultIndentation: IndentationInterface = {
  top: 10,
  bottom: 10,
  left: 15,
  right: 15,
};

export const defaultTotalSettings: TotalRowSettingsInterface = {
  isShow: true,
  location: {
    isShow: true,
    position: totalRowPositionOptions[1],
  },
  properties: defaultProperties,
  overfill: true,
  hasIndentation: false,
  indentation: defaultIndentation,
};

export const getTreeIndicator: (data: Pick<TreeIndicatorInterface, 'id' | 'name'>) => TreeIndicatorInterface = ({
  id,
  name,
}) => ({
  id,
  name,
  fieldName: null,
  ...defaultOperationType,
  emptyValues: defaultIndicatorEmptyValues,
  settings: {
    nameFromDatabase: false,
    isShowValue: true,
    formatting: defaultFormattingType,
    properties: defaultProperties,
  },
});

export const getTableIncision: (data: Pick<TableIncisionInterface, 'id' | 'name'>) => TableIncisionInterface = ({
  id = v4(),
  name,
}) => ({
  id,
  name,
  fieldName: null,
  color: null,
  fictionalData: defaultFictionalData,
  settings: {
    ...defaultIncisionSettings,
    ...defaultColumnWidthSettings,
    ...defaultSortSettings,
    formatting: defaultFormattingType,
    hyperLink: defaultHyperLickSettings,
    imagesSettings: defaultImagesSettings,
    hasIndentation: false,
    indentation: defaultIndentation,
    properties: defaultProperties,
    isGroup: false,
    isSort: false,
  },
});

export const getTableIndicator: (data: Pick<TableIndicatorInterface, 'id' | 'name'>) => TableIndicatorInterface = ({
  id = v4(),
  name,
}) => ({
  id,
  name,
  fieldName: null,
  color: null,
  emptyValues: defaultIndicatorEmptyValues,
  ...defaultOperationType,
  settings: {
    nameFromDatabase: false,
    ...defaultColumnWidthSettings,
    hyperLink: defaultHyperLickSettings,
    imagesSettings: defaultImagesSettings,
    ...defaultSortSettings,
    totalSettings: {
      isShow: true,
      isAutoAggregation: true,
      formatting: defaultFormattingType,
      ...defaultOperationType,
    },
    dynamicsMarkerSettings: defaultDynamicMarkerSettings,
    formatting: defaultFormattingType,
    hasIndentation: false,
    indentation: defaultIndentation,
    properties: defaultProperties,
    isSort: false,
  },
});

export const getPieIncision: (data: Pick<PieIncisionInterface, 'id' | 'name'>) => PieIncisionInterface = ({
  id = v4(),
  name,
}) => ({
  id,
  name,
  fieldName: null,
  colors: null,
  fictionalData: defaultFictionalData,
  emptyValues: defaultIndicatorEmptyValues,
  settings: {
    ...defaultIncisionSettings,
    groupByDateSettings: defaultGroupByDateSettings,
  },
  colorBySettings: {
    elementColorBy: getDefaultColorBySettings(colorAlias),
    elementColor: null,
    isActive: false,
  },
});

export const getTextIncision: (data: Pick<DefaultIncisionInterface, 'id' | 'name'>) => DefaultIncisionInterface = ({
  id = v4(),
  name,
}) => ({
  id,
  name,
  fieldName: null,
  fictionalData: defaultFictionalData,
  emptyValues: defaultIndicatorEmptyValues,
  settings: {
    ...defaultIncisionSettings,
  },
});

export const getPieIndicator: (data: Pick<PieIndicatorInterface, 'id' | 'name'>) => PieIndicatorInterface = ({
  id = v4(),
  name,
}) => ({
  id,
  name,
  fieldName: null,
  ...defaultOperationType,
  emptyValues: defaultIndicatorEmptyValues,
  settings: {
    nameFromDatabase: false,
    isShowValue: false,
    formatting: defaultFormattingType,
    properties: defaultProperties,
    valueFormat: 'percent',
  },
});

export const defaultLegendSettings: LegendSettingsInterface = {
  type: 'auto',
  isShow: true,
  location: {
    type: 'bottom',
    value: 0,
  },
  position: {
    type: 'center',
    value: 0,
  },
  width: 250,
  properties: defaultProperties,
};

const defaultPieIncisionId = v4();

export const defaultPieDataSettings: PieDataSettings = {
  ...defaultDataSettings,
  incisions: [getPieIncision({ id: defaultPieIncisionId, name: `${defaultIncisionName}0` })],
  indicators: [getPieIndicator({ id: v4(), name: `${defaultIndicatorName}0` })],
  roseType: 'pie',
  activeIncisionId: defaultPieIncisionId,
};

export const defaultTableDataSettings: TableDataSettings = {
  ...defaultDataSettings,
  ...defaultColorByValueSettings,
  ...defaultBackgroundByValueSettings,
  incisionsInHeader: [],
  incisions: [getTableIncision({ id: v4(), name: `${defaultIncisionName}0` })],
  indicators: [getTableIndicator({ id: v4(), name: `${defaultIndicatorName}0` })],
  orderBy: [],
  limitGrouping: defaultLimitSetting,
  hasAllGroupIncision: false,
};

export const defaultTreeDataSettings: TreeDataSettings = {
  ...defaultDataSettings,
  incisions: [getTreeIncision({ id: v4(), name: `${defaultIncisionName}0` })],
  indicators: [getTreeIndicator({ id: v4(), name: `${defaultIndicatorName}0` })],
};

export const defaultTableViewSettings: TableViewSettings = {
  ...defaultViewSettings,
  showVerticalLine: true,
  showHorizontalLine: true,
  isAdaptive: true,
  incisionBeat: ['background', 'line'],
  headerBeat: ['background', 'line'],
  totalBeat: ['background', 'line'],
  subtotalsSettings: defaultTotalSettings,
  headerSettings: {
    ...defaultTotalSettings,
    headerBeatLine: defaultIsBeat,
  },
  bodySettings: {
    propertiesIncisions: defaultProperties,
    propertiesIndicators: defaultProperties,
    verticalLines: defaultIsBeat,
    horizontalLines: defaultIsBeat,
    externalBeat: defaultIsBeat,
    rowsBeat: { ...defaultIsBeat, countUnit: 7 },
    indentation: defaultIndentation,
  },
  totalRowSettings: defaultTotalSettings,
};

export const defaultTreeViewSettings: TreeViewSettings = {
  ...defaultViewSettings,
  signatures: {
    isShow: true,
    position: 'flex-start',
    location: 'flex-start',
  },

  showLegend: true,
  showTips: true,
  showNameIncision: false,
};

export const getBarIncision: (data: Pick<BarIncisionInterface, 'id' | 'name'>) => BarIncisionInterface = ({ id, name }) => ({
  id,
  name,
  fieldName: null,
  colors: null,
  fictionalData: defaultFictionalData,
  emptyValues: defaultIndicatorEmptyValues,
  settings: defaultIncisionSettings,
  colorBySettingsValueAndElement: {
    colorBySettingsValue: {
      elementColorBy: getDefaultColorBySettings(colorSpecificValueAlias + id),
      elementColor: null,
      isActive: false,
    },
    colorBySettingsElement: {
      elementColorBy: getDefaultColorBySettings(colorSpecificAlias + id),
      elementColor: null,
      isActive: false,
    },
  },
});

export const getBubbleIncision: (data: Pick<BubbleIncisionInterface, 'id' | 'name'>) => BubbleIncisionInterface = ({
  id,
  name,
}) => ({
  id,
  name,
  fieldName: null,
  colors: null,
  fictionalData: defaultFictionalData,
  emptyValues: defaultIndicatorEmptyValues,
  settings: {
    ...defaultIncisionSettings,
    showValue: {
      isShow: false,
      orientation: 'horizontal',
      position: 'flex-start',
      properties: defaultProperties,
      colorBySettings: {
        elementColorBy: getDefaultColorBySettings(colorAlias),
        elementColor: null,
        isActive: false,
      },
    },
    bubbleSettings: {
      gradientValueColorSettings: {
        elementColorBy: gradientBySettings(colorAlias),
        elementColor: null,
        isActive: false,
      },
      rangeIndicator: defaultRangeIndicator,
    },
  },
});

export const getTextVariables: (
  data: Pick<TextVariablesInterface, 'id' | 'name'> & { alias: string },
) => TextVariablesInterface = ({ id, name, alias }) => ({
  id,
  name,
  customRequest: `''`,
  emptyValues: defaultIndicatorEmptyValues,
  settings: {
    formatting: defaultFormattingType,

    textPropertiesSettings: {
      text: {
        isShow: true,
        text: 'value',
      },
      ...defaultPropertiesStyle,
      fontColorBy: getDefaultColorBySettings(alias),
      backgroundColorBy: getDefaultColorBySettings(fontColorAlias),
    },
  },
});

export const defaultBarParametersDesignOption: DesignOptionInterface = {
  label: 0,
  value: '%',
};

export const getLineAndBarIndicator: (
  data: Pick<LineAndBarIndicatorInterface, 'id' | 'name'> & { type?: LineAndBarTypeIndicator; stackNumber?: number },
) => LineAndBarIndicatorInterface = ({ id, name, type, stackNumber }) => ({
  id,
  type: type || LineAndBarTypeIndicatorEnum.INDICATOR,
  name,
  fieldName: null,
  ...defaultOperationType,
  color: null,
  emptyValues: defaultIndicatorEmptyValues,
  settings: {
    additionalIndicators: false,
    nameFromDatabase: false,
    elementSettings: {
      type: 'bar',
      parameters: {
        lineWidth: 3,
        dotWidth: 3,
        areaOpacity: 0,
        isDotted: false,
        lineType: 'broken',
      },
      parametersBar: {
        barWidth: defaultBarParametersDesignOption,
        barMinWidth: defaultBarParametersDesignOption,
        barMaxWidth: defaultBarParametersDesignOption,
        stackNumber: stackNumber || 1,
        stackOffset: '20',
      },
      colorBySettings: {
        elementColorBy: getDefaultColorBySettings(colorAlias + id),
        elementColor: null,
        isActive: false,
      },
    },
    showValue: {
      isShow: false,
      orientation: 'horizontal',
      position: type !== LineAndBarTypeIndicatorEnum.INDICATOR ? 'flex-end' : 'flex-start',
      properties: defaultProperties,
      colorBySettings: {
        elementColorBy: getDefaultColorBySettings(colorSpecificValueAlias + id),
        elementColor: null,
        isActive: false,
      },
    },
    formatting: defaultFormattingType,
  },
});

export const defaultDataStartAndEnd: StartAndEndInterface = {
  start: 0,
  end: 100,
};

export const defaultVisualisationPaddings: PaddingsVisualisationInterface = {
  topPadding: 0,
  bottomPadding: 0,
  leftPadding: 0,
  rightPadding: 0,
};

export const defaultZoomPadding: ZoomSettingsInterface = {
  isShow: false,
  padding: { type: 'auto', value: 0 },
};

export const defaultMinAndMax: MinAndMaxInterface = {
  isShow: false,
  min: 0,
  max: 500,
};

export const defaultBarShowSumDataSettings: ShowSumInterface = {
  isShow: false,
  orientation: 'horizontal',
};

export const getWaterfallIndicator: (
  data: Pick<WaterfallIndicatorInterface, 'id' | 'name'>,
  indicatorType?: WaterfallIndicatorTypeInterface['type'],
) => WaterfallIndicatorInterface = ({ id, name }, indicatorType = 'default') => ({
  id,
  name,
  fieldName: null,
  ...defaultOperationType,
  color: null,
  type: indicatorType,
  emptyValues: {
    isEmptyValue: false,
    value: '-',
  },
  settings: {
    nameFromDatabase: false,
    showValue: {
      isShow: false,
      orientation: 'horizontal',
      position: 'flex-start',
      properties: defaultProperties,
      colorBySettings: {
        elementColorBy: getDefaultColorBySettings(colorAlias),
        elementColor: null,
        isActive: false,
      },
    },
    formatting: defaultFormattingType,
  },
});

export const getHeatmapIndicator: (data: Pick<HeatmapIndicatorInterface, 'id' | 'name'>) => HeatmapIndicatorInterface = ({
  id,
  name,
}) => ({
  id,
  name,
  fieldName: null,
  ...defaultOperationType,
  color: null,
  emptyValues: defaultIndicatorEmptyValues,
  settings: {
    nameFromDatabase: false,
    showValue: {
      isShow: false,
      orientation: 'horizontal',
      position: 'flex-start',
      properties: defaultProperties,
      colorBySettings: {
        elementColorBy: getDefaultColorBySettings(colorAlias),
        elementColor: null,
        isActive: false,
      },
    },
    formatting: defaultFormattingType,
  },
});

export const getBubbleIndicator: (data: Pick<BubbleIndicatorInterface, 'id' | 'name'>) => BubbleIndicatorInterface = ({
  id,
  name,
}) => ({
  id,
  name,
  fieldName: null,
  ...defaultOperationType,
  emptyValues: defaultIndicatorEmptyValues,
  settings: {
    nameFromDatabase: false,
    formatting: defaultFormattingType,
    minAndMax: defaultMinAndMax,
    sizeSettings: {
      type: 'default',
      parameters: {
        diameter: 5,
        minAndMax: {
          min: 5,
          max: 20,
        },
      },
    },
  },
});

const defaultWaterfallIncisionId = v4();

export const defaultWaterfallDataSettings: WaterfallDataSettings = {
  ...defaultDataSettings,
  type: 'group',
  showSum: defaultBarShowSumDataSettings,
  activeIncisionId: defaultWaterfallIncisionId,
  incisions: [getBarIncision({ id: defaultWaterfallIncisionId, name: `${defaultIncisionName}0` })],
  indicators: [
    getWaterfallIndicator({ id: v4(), name: defaultIndicatorPlanName }, 'plan'),
    getWaterfallIndicator({ id: v4(), name: defaultIndicatorFactName }, 'fact'),
  ],
  positiveAndNegativeIndicators: [
    getWaterfallIndicator({ id: v4(), name: defaultIndicatorPositiveName }, 'positive'),
    getWaterfallIndicator({ id: v4(), name: defaultIndicatorNegativeName }, 'negative'),
  ],
  phantomIndicators: [getWaterfallIndicator({ id: v4(), name: defaultIndicatorPhantomName }, 'phantom')],

  minAndMax: defaultMinAndMax,
};

export const gradientBySettings: (alias: string) => GradientColorByInterface = (alias) => ({
  type: 'default',
  byCondition: {
    colors: [],
    sqlCondition: null,
    alias,
  },
  byValueGradient: {
    alias,
    colors: null,
  },
  byValueSteps: {
    alias,
    colors: null,
  },
  byValueSpecificGradient: {
    alias,
    colors: null,
  },
  byValueSpecificSteps: {
    alias,
    colors: null,
  },
  byValue: {
    alias,
    colors: null,
  },
});

export const defaultBubbleIncisionId = v4();

export const defaultBubbleDataSettings: BubbleDataSettings = {
  ...defaultDataSettings,
  rangeIndicator: defaultRangeIndicator,
  type: 'group',
  showSum: defaultBarShowSumDataSettings,
  activeIncisionId: defaultBubbleIncisionId,
  incisions: [getBubbleIncision({ id: defaultBubbleIncisionId, name: `${defaultIncisionName}0` })],
  indicators: [
    getBubbleIndicator({ id: defaultBubbleIndicatorId, name: `${defaultIndicatorName} 0` }),
    getBubbleIndicator({ id: v4(), name: `${defaultIndicatorName} 1` }),
    getBubbleIndicator({ id: v4(), name: defaultIndicatorSizeName }),
  ],
};

export const defaultHeatmapVerticalIncisionId = v4();
export const defaultHeatmapHorizontalIncisionId = v4();

export const defaultHeatmapDataSettings: HeatmapDataSettings = {
  ...defaultDataSettings,
  gradientBackgroundByValueSettings: gradientBySettings(backgroundValueAlias),
  type: 'group',
  showSum: defaultBarShowSumDataSettings,

  incisions: [getBarIncision({ id: defaultHeatmapHorizontalIncisionId, name: `${defaultIncisionName}0` })],
  verticalIncisions: [getBarIncision({ id: defaultHeatmapVerticalIncisionId, name: `${defaultVerticalIncisionName}0` })],
  indicators: [getHeatmapIndicator({ id: v4(), name: `${defaultIndicatorName}0` })],
  activeHorizontalIncisionId: defaultHeatmapHorizontalIncisionId,
  activeVerticalIncisionId: defaultHeatmapVerticalIncisionId,
  minAndMax: defaultMinAndMax,
};

export const defaultAxisIndicatorSettings: AxisSettingsInterface<'indicator'> = {
  isShow: true,
  showAxis: true,
  position: 'left',
  name: {
    isShow: false,
    text: 'Ось показателей',
    type: 'auto',
    position: { type: 'center', value: 0 },
  },
  label: {
    properties: defaultProperties,
    isActive: true,
    value: 8,
  },
  stepSize: {
    type: 'auto',
    value: 5,
  },
  showGrid: true,
};

export const defaultAxisIncisionSettings: AxisSettingsInterface = {
  isShow: true,
  showAxis: true,
  position: 'bottom',
  name: {
    isShow: false,
    type: 'auto',
    position: { type: 'center', value: 0 },
  },
  label: {
    properties: defaultProperties,
    isActive: true,
    value: 8,
  },
  tickLabel: {
    isShow: false,
    position: 'center',
  },
  labelOrientation: 'horizontal',
  labelOrderValues: 'downUp',
  labelSize: {
    type: 'auto',
    value: 10,
  },
  stepSize: {
    type: 'auto',
    value: 5,
  },
  showGrid: false,
};

export const defaultBarViewSettings: BarViewSettings = {
  ...defaultViewSettings,
  incisionSelectorPosition: 'flex-end',
  isVisible: true,
  showTips: true,
  legendSettings: defaultLegendSettings,
  horizontalZoom: defaultZoomPadding,
  verticalZoom: defaultZoomPadding,
  dataZoomHorizontalStartAndEnd: defaultDataStartAndEnd,
  dataZoomVerticalStartAndEnd: defaultDataStartAndEnd,
  visualisationPaddings: {
    isShow: false,
    paddingsVisualisation: defaultVisualisationPaddings,
  },
};

export const defaultWaterfallViewSettings: WaterfallViewSettings = {
  ...defaultBarViewSettings,
  axisIndicatorSettings: defaultAxisIndicatorSettings,
  axisIncisionSettings: defaultAxisIncisionSettings,
};

export const defaultVisualMapSettings: VisualMapSettingsInterface = {
  type: 'auto',
  isShow: true,
  location: {
    type: 'bottom',
    value: 0,
  },
  position: {
    type: 'center',
    value: 0,
  },
  width: 250,
  properties: defaultProperties,
};

export const defaultHeatmapViewSettings: HeatmapViewSettings = {
  ...defaultBarViewSettings,
  dataVisualMapSelectedMinAndMax: {
    start: 0,
    end: 500,
  },
  axisYIncisionSettings: defaultAxisIncisionSettings,
  axisXIncisionSettings: defaultAxisIncisionSettings,
  visualMapSettings: defaultVisualMapSettings,
};

export const defaultBubbleViewSettings: BubbleViewSettings = {
  ...defaultBarViewSettings,
  dataVisualMapSelectedMinAndMax: {
    start: 0,
    end: 500,
  },
  axisYIncisionSettings: defaultAxisIncisionSettings,
  axisXIncisionSettings: defaultAxisIncisionSettings,
  visualMapSettings: defaultVisualMapSettings,
  elementDesign: {
    gradientElementColorSettings: {
      elementColor: null,
      elementColorBy: gradientBySettings(colorSpecificAlias),
      isActive: false,
    },
    colorValueColorSettings: {
      elementColor: null,
      elementColorBy: gradientBySettings(colorValueAlias),
      isActive: false,
    },
    properties: defaultProperties,
    rangeIndicator: defaultRangeIndicator,
  },
};

const defaultLineAndBarIncisionId = v4();

export const defaultLineAndBarDataSettings: LineAndBarDataSettings = {
  ...defaultDataSettings,
  rotateTo90: false,
  type: 'group',
  barType: {
    type: 'pile',
    activePile: false,
  },
  showSum: defaultBarShowSumDataSettings,
  activeIncisionId: defaultLineAndBarIncisionId,
  incisions: [getBarIncision({ id: defaultLineAndBarIncisionId, name: `${defaultIncisionName}0` })],
  indicatorsStackSum: [],
  indicators: [getLineAndBarIndicator({ id: v4(), name: `${defaultIndicatorName}0` })],
  minAndMax: defaultMinAndMax,
  minAndMaxAdditional: defaultMinAndMax,
};

export const defaultElementDesign: ElementDesignInterface = {
  colorSettingsIncision: {
    elementColorBy: getDefaultColorBySettings(colorSpecificAlias),
    elementColor: null,
    isActive: false,
  },
  colorSettingsIndicator: {
    elementColorBy: getDefaultColorBySettings(colorSpecificAlias),
    elementColor: null,
    isActive: false,
  },
  properties: defaultProperties,
  propertiesIncisionColorSettings: {
    elementColorBy: getDefaultColorBySettings(colorSpecificValueAlias),
    elementColor: null,
    isActive: false,
  },
  propertiesIndicatorColorSettings: {
    elementColorBy: getDefaultColorBySettings(colorSpecificValueAlias),
    elementColor: null,
    isActive: false,
  },
  colorStrategyType: 'byIndicator',
};

export const defaultLineAndBarViewSettings: LineAndBarViewSettings = {
  ...defaultBarViewSettings,
  axisIndicatorSettings: defaultAxisIndicatorSettings,
  axisIncisionSettings: defaultAxisIncisionSettings,
  elementDesign: defaultElementDesign,
  axisAdditionalIndicatorSettings: {
    isShow: false,
    showAxis: true,
    position: 'right',
    name: {
      isShow: false,
      type: 'auto',
      text: 'Ось доп. показателей',
      position: { type: 'flex-start', value: 0 },
    },
    label: {
      properties: defaultProperties,
      isActive: true,
      value: 8,
    },
    stepSize: {
      type: 'auto',
      value: 5,
    },
    showGrid: true,
  },
};

export const defaultTextViewSettings: TextViewSettings = {
  text: {
    isShow: true,
    text: 'Текст',
    fontStyle: {
      bold: false,
      italic: false,
      underline: false,
    },
    fontSize: 16,
    lineHeight: 120,
    letterSpacing: 0,
    fontColor: null,
    backgroundColor: null,
    backgroundOpacity: 100,
    wordSpacing: 0,
    isActiveStyle: false,
    isActiveFontColor: false,
    isActiveBackgroundColor: false,
    opacity: 100,
    padding: {
      horizontal: 22,
      vertical: 22,
      isConnection: false,
    },
    changeFillColor: false,
    alignment: {
      horizontal: 'flex-start',
      vertical: 'flex-start',
    },
    fontColorBy: {
      type: 'default',
      isActive: false,
      byCondition: {
        colors: [],
        sqlCondition: null,
        alias: 'fontColor',
      },
      byValue: {
        alias: 'fontColor',
        colors: null,
      },
      byValueSpecific: {
        alias: 'fontColor',
        colors: null,
      },
    },
    backgroundColorBy: {
      type: 'default',
      isActive: false,
      byCondition: {
        colors: [],
        sqlCondition: null,
        alias: 'fontColor',
      },
      byValue: {
        alias: 'fontColor',
        colors: null,
      },
      byValueSpecific: {
        alias: 'fontColor',
        colors: null,
      },
    },
  },
  position: {
    positionVertically: 'flex-start',
    positionHorizontal: 'flex-start',
  },
  textProperties: defaultProperties,
  showVerticalScroll: false,
  showHorizontalScroll: false,
  ...defaultHint,
  name: '',
  ...defaultContainerSettings,
  paddingSettings: {
    unsetPaddings: false,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
};

export const defaultTextDataSettings: TextDataSettings = {
  ...defaultActiveIncisionId,
  isRealData: true,
  incisions: [getTextIncision({ id: v4(), name: `${defaultImageName}0` })],
  modelId: null,
  indicators: [],
  variables: [
    getTextVariables({
      id: v4(),
      name: `${defaultVariablesName}0`,
      alias: `fontColor${defaultVariablesName}0`,
    }),
  ],
  limit: {
    ...defaultLimitSetting,
    isActive: true,
  },
};

export const getDefaultImageBySettings: (alias: string) => ImageByInterface = (alias) => ({
  type: 'value',
  byCondition: {
    images: [],
    sqlCondition: null,
    alias,
  },
  byValue: {
    images: [],
  },
});

export const defaultTextBackgroundImagesSettings: TextBackgroundSettings = {
  imagesLinkType: 'fromDatabase',
  proportionsImageType: 'contain',
  backgroundImages: {
    imagesSettings: {
      backgroundImagesBy: getDefaultImageBySettings(imagesAlias),
    },
  },
  carouselAutoPlay: {
    isActive: true,
    value: 6000,
  },
};

const initialTreeVisualisation: InitialVisualisationsFnType<
  TreeDataSettings,
  TreeViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'tree'
> = ({ pageId, id, name }) => ({
  id,
  positionConfig: initialPositionConfig,
  pageId,
  dataSettings: defaultTreeDataSettings,
  viewSettings: {
    ...defaultTreeViewSettings,
    name,
  },
  backgroundImagesSettings: defaultBackgroundImagesSettings,
  sqlData: defaultSqlDataSettings,
  visualisationType: 'tree',
  events: initialEventsSettings,
  isVisible: true,
  isBlock: false,
});

export const defaultPieViewSettings: PieViewSettings = {
  styleSettings: {
    properties: defaultProperties,
    labelFormat: ['value', 'name'],
    colorBySettings: {
      elementColorBy: getDefaultColorBySettings(colorSpecificAlias),
      elementColor: null,
      isActive: false,
    },
    pieAnimationElement: true,
    borderRadius: 1,
    borderWidth: 1,
    outerRadius: {
      label: 70,
      value: '%',
    },
    arcStartAngle: 90,
    arcEndAngle: -360,

    innerRadius: {
      label: 30,
      value: '%',
    },
    centerCoordinates: {
      isActive: false,
      centerXCoordinates: {
        label: 50,
        value: '%',
      },

      centerYCoordinates: {
        label: 50,
        value: '%',
      },
    },
  },
  ...defaultViewSettings,
  incisionSelectorPosition: 'flex-end',
  showTips: true,
  legendSettings: defaultLegendSettings,
  isVisible: true,
};

const initialTextVisualisation: InitialVisualisationsFnType<
  TextDataSettings,
  TextViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'text'
> = ({ pageId, id, name }) => ({
  id,
  positionConfig: initialTextPositionConfig,
  pageId,
  visualisationType: 'text',
  viewSettings: {
    ...defaultTextViewSettings,
    name,
  },
  backgroundImagesSettings: defaultTextBackgroundImagesSettings,
  dataSettings: defaultTextDataSettings,
  sqlData: defaultSqlDataSettings,
  events: initialEventsSettings,
  isVisible: true,
  isBlock: false,
});

const initialLineAndBarVisualisation: InitialVisualisationsFnType<
  LineAndBarDataSettings,
  LineAndBarViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'lineAndBar'
> = ({ pageId, id, name }) => ({
  id,
  positionConfig: initialPositionConfig,
  pageId,
  visualisationType: 'lineAndBar',
  dataSettings: defaultLineAndBarDataSettings,
  viewSettings: {
    ...defaultLineAndBarViewSettings,
    name,
  },
  backgroundImagesSettings: defaultBackgroundImagesSettings,
  sqlData: defaultSqlDataSettings,
  events: initialEventsSettings,
  isVisible: true,
  isBlock: false,
});

const initialWaterfallVisualisation: InitialVisualisationsFnType<
  WaterfallDataSettings,
  WaterfallViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'waterfall'
> = ({ pageId, id, name }) => ({
  id,
  positionConfig: initialPositionConfig,
  pageId,
  visualisationType: 'waterfall',
  dataSettings: defaultWaterfallDataSettings,
  viewSettings: {
    ...defaultWaterfallViewSettings,
    name,
  },
  backgroundImagesSettings: defaultBackgroundImagesSettings,
  sqlData: defaultSqlDataSettings,
  events: initialEventsSettings,
  isVisible: true,
  isBlock: false,
});

const initialHeatmapVisualisation: InitialVisualisationsFnType<
  HeatmapDataSettings,
  HeatmapViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'heatmap'
> = ({ pageId, id, name }) => ({
  id,
  positionConfig: initialPositionConfig,
  pageId,
  visualisationType: 'heatmap',
  dataSettings: defaultHeatmapDataSettings,
  viewSettings: {
    ...defaultHeatmapViewSettings,
    name,
  },
  backgroundImagesSettings: defaultBackgroundImagesSettings,
  sqlData: defaultSqlDataSettings,
  events: initialEventsSettings,
  isVisible: true,
  isBlock: false,
});

const initialBubbleVisualisation: InitialVisualisationsFnType<
  BubbleDataSettings,
  BubbleViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'bubble'
> = ({ pageId, id, name }) => ({
  id,
  positionConfig: initialPositionConfig,
  pageId,
  visualisationType: 'bubble',
  dataSettings: defaultBubbleDataSettings,
  viewSettings: {
    ...defaultBubbleViewSettings,
    name,
  },
  backgroundImagesSettings: defaultBackgroundImagesSettings,
  sqlData: defaultSqlDataSettings,
  events: initialEventsSettings,
  isVisible: true,
  isBlock: false,
});

const initialPieVisualisation: InitialVisualisationsFnType<
  PieDataSettings,
  PieViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'pie'
> = ({ pageId, id, name }) => ({
  id,
  positionConfig: initialPositionConfig,
  pageId,
  dataSettings: defaultPieDataSettings,
  viewSettings: {
    ...defaultPieViewSettings,
    name,
  },
  backgroundImagesSettings: defaultBackgroundImagesSettings,
  sqlData: defaultSqlDataSettings,
  visualisationType: 'pie',
  events: initialEventsSettings,
  isVisible: true,
  isBlock: false,
});

const initialTableVisualisation: InitialVisualisationsFnType<
  TableDataSettings,
  TableViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'table'
> = ({ pageId, id, name }) => ({
  id,
  positionConfig: initialPositionConfig,
  pageId,
  dataSettings: defaultTableDataSettings,
  viewSettings: {
    ...defaultTableViewSettings,
    name,
  },
  backgroundImagesSettings: defaultBackgroundImagesSettings,
  sqlData: defaultSqlDataSettings,
  visualisationType: 'table',
  events: initialEventsSettings,
  groupingTableRows: [],
  isVisible: true,
  isBlock: false,
});

const initialVisualisationsFn: InitialVisualisationsFnsType = {
  tree: initialTreeVisualisation,
  text: initialTextVisualisation,
  pie: initialPieVisualisation,
  lineAndBar: initialLineAndBarVisualisation,
  waterfall: initialWaterfallVisualisation,
  heatmap: initialHeatmapVisualisation,
  bubble: initialBubbleVisualisation,
  table: initialTableVisualisation,
};

export const getInitialVisualisation = ({ visualisationType, data: { id, pageId, name } }: InitialVisualisationProps) =>
  initialVisualisationsFn[visualisationType]({ id, pageId, name });

export const referenceVisualisations: Record<VisualisationTypeType, DefaultVisualisationOptionsType> = (
  Object.keys(initialVisualisationsFn) as VisualisationTypeType[]
).reduce(
  (references, type) => ({
    ...references,
    [type]: getInitialVisualisation({ visualisationType: type, data: { id: 'reference', pageId: 'reference', name: '' } }),
  }),
  {} as Record<VisualisationTypeType, DefaultVisualisationOptionsType>,
);

export const getIncisionFunctionByType: Record<
  VisualisationTypeType,
  (data: Pick<DefaultIncisionInterface, 'name' | 'id'>) => DefaultIncisionInterface
> = {
  pie: getPieIncision,
  tree: getTreeIncision,
  lineAndBar: getBarIncision,
  waterfall: getBarIncision,
  heatmap: getBarIncision,
  bubble: getBubbleIncision,
  table: getTableIncision,
  text: getTextIncision,
};

export const getIndicatorFunctionByType: Record<
  VisualisationType,
  (
    data: Pick<DefaultIndicatorInterface, 'name' | 'id'> & { type?: LineAndBarTypeIndicator; stackNumber?: number },
  ) => DefaultIndicatorInterface
> = {
  pie: getPieIndicator,
  tree: getTreeIndicator,
  lineAndBar: getLineAndBarIndicator,
  waterfall: getWaterfallIndicator,
  heatmap: getHeatmapIndicator,
  bubble: getBubbleIndicator,
  table: getTableIndicator,
};

interface GetVisualisationFieldNameParams {
  nameFromDatabase: boolean;
  fieldName: string | null;
  name: string;
}

export const getVisualisationFieldName = ({ nameFromDatabase, name, fieldName }: GetVisualisationFieldNameParams) =>
  nameFromDatabase ? fieldName || '' : name;

export const getActiveIncisionById: <Incisions extends VisualisationIdInterface>(
  activeIncisionId: string | null,
  incision: Incisions[],
) => Incisions | null = (activeIncisionId, incisions) =>
  incisions.find(({ id }) => id === activeIncisionId) || incisions?.[0] || null;

export const getPlanAndFactByType: <Indicators extends WaterfallIndicatorTypeInterface>(
  typeIndicators: WaterfallIndicatorTypeInterface['type'],
  indicators: Indicators[],
) => Indicators | null = (typeIndicators, indicators) =>
  indicators.find(({ type }) => type === typeIndicators) || indicators?.[0] || null;

type GetVisualisationAstParams = Pick<
  SqlDependencyInterface,
  | 'activeIncisionId'
  | 'incisions'
  | 'indicators'
  | 'serviceSelectValues'
  | 'limit'
  | 'filterAndGroupRequest'
  | 'variablesSelectValues'
  | 'imagesSelectValues'
>;

export const getVisualisationAstParts: (
  params: GetVisualisationAstParams,
) => Pick<
  VisualisationAstInterface,
  | 'incisions'
  | 'indicators'
  | 'groupBy'
  | 'serviceValues'
  | 'limit'
  | 'activeIncisionIndex'
  | 'filtersAndGroups'
  | 'variables'
  | 'images'
> = ({
  incisions,
  indicators,
  activeIncisionId,
  serviceSelectValues,
  variablesSelectValues,
  imagesSelectValues,
  limit,
  filterAndGroupRequest,
}) => {
  const groupByValues: AST.GroupByColumn[] = [];
  let activeIncisionIndex: number | null = null;

  const incisionValues = incisions.reduce<IncisionsAstType[]>(
    (values, { id, selectSql: fieldName, alias, customRequest }, index) => {
      let incisionSelectValue: IncisionsAstType;

      if (customRequest && customRequest !== '') {
        const selectQuery = generateSqlSelectValue(customRequest, alias);

        incisionSelectValue = getSelectColumnsFromSqlString(selectQuery)?.[0];
      } else {
        incisionSelectValue = generateBasicColumn({ fieldName, alias });
      }

      if (activeIncisionId ? id === activeIncisionId : true) {
        activeIncisionIndex = activeIncisionId && id === activeIncisionId ? index : null;

        /* groupby in Select interface on `node-sql-parser` (Select['groupby']) has incorrect type (only ColumnRef without AggrFunc) */
        groupByValues.push(incisionSelectValue.expr as AST.GroupByColumn);
      }

      return [...values, incisionSelectValue];
    },
    [],
  );

  const indicatorValues = indicators.reduce<IndicatorAstType[]>(
    (values, { selectSql: fieldName, alias, operationType, customRequest }) => {
      let indicatorSelectValue: IndicatorAstType;

      if (operationType === 'other') {
        const selectQuery = generateSqlSelectValue(customRequest, alias);

        indicatorSelectValue = getSelectColumnsFromSqlString(selectQuery)?.[0];
      } else {
        indicatorSelectValue = generateBasicFunctionColumn({ alias, fieldName, functionName: operationType });
      }

      if (indicatorSelectValue) {
        return [...values, indicatorSelectValue];
      }

      return values;
    },
    [],
  );

  const serviceSqlValues = serviceSelectValues.map(({ alias, selectSql }) => generateSqlSelectValue(selectSql, alias)),
    selectStringSqlService = generateSqlSelectQuery(serviceSqlValues);

  /* TODO: Refactore this */
  const { columns: serviceValues } = selectStringSqlService
    ? (sqlParser.astify(selectStringSqlService) as Select)
    : { columns: [] };

  const variableSqlValues = variablesSelectValues.map(({ alias, selectSql }) => generateSqlSelectValue(selectSql, alias)),
    selectStringSqlVariable = generateSqlSelectQuery(variableSqlValues);

  const { columns: variableValues } = selectStringSqlVariable
    ? (sqlParser.astify(selectStringSqlVariable) as Select)
    : { columns: [] };

  const imageSqlValues = imagesSelectValues.map(({ alias, selectSql }) => generateSqlSelectValue(selectSql, alias)),
    selectStringSqlImage = generateSqlSelectQuery(imageSqlValues);

  const { columns: imageValues } = selectStringSqlImage ? (sqlParser.astify(selectStringSqlImage) as Select) : { columns: [] };

  const {
    having,
    where,
    orderby,
    groupby,
    limit: filterAndGroupLimit,
  } = filterAndGroupRequest && filterAndGroupRequest !== ''
    ? (sqlParser.astify(`SELECT * ${filterAndGroupRequest}`) as Select)
    : defaultAstOfFilterAndGroups;

  const limitValue = limit ? generateLimit(limit) : null;

  return {
    indicators: indicatorValues,
    incisions: incisionValues,
    groupBy: groupByValues,
    serviceValues: serviceValues as AST.ColumnFromParser[],
    variables: variableValues as AST.ColumnFromParser[],
    images: imageValues as AST.ColumnFromParser[],
    limit: limitValue,
    activeIncisionIndex,
    filtersAndGroups: {
      having: having || null,
      where: where || null,
      orderby: orderby || null,
      groupby: groupby || null,
      limit: filterAndGroupLimit || null,
    },
  };
};

interface ResultProcessDataWaterfall {
  fantomResult: number[];
  positiveResult: number[];
  negativeResult: number[];
}

export const processDataWaterfall = (data: number[]): ResultProcessDataWaterfall => {
  const fantomResult = [0],
    positiveResult = data.map((val, index) => (val >= 0 && index !== 0 ? val : index === 0 ? 0 : 0));
  const negativeResult = data.map((val) => (val < 0 ? -val : 0));

  let sum = 0;
  for (let i = 1; i < data.length; i++) {
    sum += data[i - 1];
    fantomResult.push(data[i] < 0 ? sum + data[i] : sum);
  }

  return { fantomResult, positiveResult, negativeResult };
};

export const updateEventsDataFunc = (
  state: VisualisationStateInterface,
  { payload: { id, events } }: PayloadAction<UpdateEventsDataPayload>,
) => {
  const visualisation = state.visualisations[id];

  if (visualisation && visualisation?.events) {
    visualisation.events = { ...visualisation.events, ...events };
  }
};

export const updateViewSettingsDataFunc = (
  state: VisualisationStateInterface,
  { payload: { id, viewSettings } }: PayloadAction<UpdateViewSettingsPayload>,
) => {
  const visualisation = state.visualisations[id];

  if (visualisation) {
    visualisation.viewSettings = { ...visualisation.viewSettings, ...viewSettings };
  }
};

export const updateBackgroundSettingsDataFunc = (
  state: VisualisationStateInterface,
  { payload: { id, backgroundSettings } }: PayloadAction<UpdateBackgroundSettingsPayload>,
) => {
  const visualisation = state.visualisations[id];

  if (visualisation) {
    visualisation.backgroundImagesSettings = { ...visualisation.backgroundImagesSettings, ...backgroundSettings };
  }
};

export const updateDataSettingsDataFunc = (
  state: VisualisationStateInterface,
  { payload: { id, dataSettings } }: PayloadAction<UpdateDataSettingsPayload>,
) => {
  const visualisation = state.visualisations[id];

  if (visualisation && visualisation.dataSettings) {
    visualisation.dataSettings = { ...visualisation.dataSettings, ...dataSettings };
  }
};

export const updatePositionConfigFunc = (
  state: VisualisationStateInterface,
  { payload: { id, positionConfig } }: PayloadAction<UpdatePositionConfigPayload>,
) => {
  const visualisation = state.visualisations[id];

  if (visualisation) {
    visualisation.positionConfig = { ...visualisation.positionConfig, ...positionConfig };
  }
};

export const updateVisibleSettingsFunc = (
  state: VisualisationStateInterface,
  { payload: { id, isVisible } }: PayloadAction<UpdateVisibleSettingsPayload>,
) => {
  const visualisation = state.visualisations[id];

  if (visualisation) {
    visualisation.isVisible = isVisible;
  }
};

export const updateBlockSettingsFunc = (
  state: VisualisationStateInterface,
  { payload: { id, isBlock } }: PayloadAction<UpdateBlockSettingsPayload>,
) => {
  const visualisation = state.visualisations[id];

  if (visualisation) {
    visualisation.isBlock = isBlock;
  }
};

export const exportByType: Record<
  ExportType,
  (params: ProjectIdWithType<ExportDataInterface>) => Promise<AxiosResponse<string>>
> = {
  xlsx: (params) => exportXlsx(params),
  csv: (params) => exportCsv(params),
};
