import React from 'react';
import { OnValueChange } from 'types/global';
import {
  DefaultIndicatorWithSqlDataInterface,
  ElementSettingsInterface,
  LineAndBarIndicatorType,
  LineAndBarIndicatorTypesEnum,
} from 'store/reducers/visualisations/types';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { elementTypeOptions, lineTypeOptions } from 'modules/settingsContainer/common/data/LineAndBarElementSettings/constants';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { BooleanElementSettings } from 'modules/settingsContainer/common/BooleanElementSettings';
import { percentUnit, pxUnit } from 'constants/global';
import { NumberPropertiesSelectBlock } from 'components/shared/ui/NumberPropertieSelectBlock';
import { unitOptions } from 'modules/visualisations/common/constants';
import { ColorBySettings } from 'modules/settingsContainer/common/ColorBySettings';
import { CircleActivator, ColorPicker } from 'modules/settingsContainer/ColorPicker';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import SubSection from 'shared/ui/subSection';

type LineAndBarElementSettingsProps = OnValueChange<ElementSettingsInterface> & {
  disabledStack: boolean;
  disabledSwitcher: boolean;
  indicators: DefaultIndicatorWithSqlDataInterface[];
};

export const LineAndBarElementSettings = ({
  value,
  onChange,
  disabledStack,
  disabledSwitcher,
  indicators,
}: LineAndBarElementSettingsProps) => {
  const { defaultColor } = useColorValues();
  const {
    type,
    parameters: { lineType, lineWidth, areaOpacity, dotWidth, isDotted },
    parametersBar: { barWidth, barMinWidth, barMaxWidth, stackOffset, stackNumber },
    colorBySettings,
  } = value;

  const { parameters, parametersBar } = value;

  const elementSettingsTabs: Record<LineAndBarIndicatorType, React.FC> = {
    line: () => (
      <GroupContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesBlock
            debounced
            properties="Толщина"
            name="lineWidth"
            unit={pxUnit}
            heightSize="small"
            value={lineWidth}
            onChange={(lineWidth) => onChange({ ...value, parameters: { ...parameters, lineWidth } })}
          />
        </ElementContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesBlock
            debounced
            properties="Точка"
            name="dotWidth"
            unit={pxUnit}
            heightSize="small"
            value={dotWidth}
            onChange={(dotWidth) => onChange({ ...value, parameters: { ...parameters, dotWidth } })}
          />
        </ElementContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesBlock
            debounced
            properties="Непрозрачность области"
            name="areaOpacity"
            unit={percentUnit}
            heightSize="small"
            value={areaOpacity}
            onChange={(areaOpacity) => onChange({ ...value, parameters: { ...parameters, areaOpacity } })}
          />
        </ElementContainerSettings>
        <BooleanElementSettings
          titleText="Пунктирная"
          value={isDotted}
          onChange={(isDotted) => onChange({ ...value, parameters: { ...parameters, isDotted } })}
        />
        <SettingsRadio
          onChange={({ value: lineType }) => onChange({ ...value, parameters: { ...parameters, lineType } })}
          activeValue={getActiveRadioValue(lineTypeOptions, lineType)}
          options={lineTypeOptions}
        />
      </GroupContainerSettings>
    ),
    bar: () => (
      <GroupContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesSelectBlock
            debounced
            name="barWidth"
            properties="Ширина"
            unitOptions={unitOptions}
            value={barWidth}
            onChange={(barWidth) => onChange({ ...value, parametersBar: { ...parametersBar, barWidth } })}
            heightSize="small"
          />
        </ElementContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesSelectBlock
            debounced
            name="barMaxWidth"
            properties="Макс. ширина"
            unitOptions={unitOptions}
            value={barMaxWidth}
            onChange={(barMaxWidth) => onChange({ ...value, parametersBar: { ...parametersBar, barMaxWidth } })}
            heightSize="small"
          />
        </ElementContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesSelectBlock
            debounced
            name="barMinWidth"
            properties="Мин. ширина"
            unitOptions={unitOptions}
            value={barMinWidth}
            onChange={(barMinWidth) => onChange({ ...value, parametersBar: { ...parametersBar, barMinWidth } })}
            heightSize="small"
          />
        </ElementContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesBlock
            debounced
            properties="Номер стопки"
            name="stackNumber"
            value={stackNumber}
            disabled={disabledStack}
            heightSize="small"
            onChange={(stackNumber) => onChange({ ...value, parametersBar: { ...parametersBar, stackNumber } })}
          />
        </ElementContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesBlock
            debounced
            properties="Отступ стопки"
            name="stackOffset"
            unit={percentUnit}
            heightSize="small"
            value={Number(stackOffset)}
            onChange={(stackOffset) =>
              onChange({ ...value, parametersBar: { ...parametersBar, stackOffset: String(stackOffset) } })
            }
          />
        </ElementContainerSettings>
      </GroupContainerSettings>
    ),
  };

  const ElementSettingsContent = elementSettingsTabs[type];

  return (
    <MainContainerSettings titleText="Тип элемента">
      <SubSection>
        {!disabledSwitcher && (
          <GroupContainerSettings>
            <ElementContainerSettings>
              <SettingsRadio
                onChange={({ value: type }) => onChange({ ...value, type })}
                activeValue={getActiveRadioValue(elementTypeOptions, type)}
                options={elementTypeOptions}
              />
            </ElementContainerSettings>
          </GroupContainerSettings>
        )}
        <ElementSettingsContent />
      </SubSection>
      <SubSection>
        <ColorBySettings
          titleMainContainerText="Цвет"
          isMainContainerSettings
          disabledComponentBySpecificValue
          disabledComponentByValue={type === LineAndBarIndicatorTypesEnum.Line}
          disabledComponentByCondition={type === LineAndBarIndicatorTypesEnum.Line}
          indicators={indicators}
          value={colorBySettings.elementColorBy}
          onChange={(elementColorBy) => onChange({ ...value, colorBySettings: { ...colorBySettings, elementColorBy } })}
          switcherColorBy={{
            switcherStateColorBy: colorBySettings.isActive,
            switcherChangeColorBy: () =>
              onChange({ ...value, colorBySettings: { ...colorBySettings, isActive: !colorBySettings.isActive } }),
          }}
        >
          <ColorPicker
            Activator={CircleActivator}
            closeOnClick
            type="color"
            onChange={(elementColor) => onChange({ ...value, colorBySettings: { ...colorBySettings, elementColor } })}
            value={colorBySettings.elementColor}
            defaultActivatorBackgroundColor={defaultColor}
          />
        </ColorBySettings>
      </SubSection>
    </MainContainerSettings>
  );
};
