import { FC } from 'react';
import { FlexContainer, FlexContainerProps } from 'styles/FlexContainer';
import { ReactChildrenType } from 'types/global';

interface IProps extends FlexContainerProps {
  children: ReactChildrenType;
}

export const ElementContainerSettings: FC<IProps> = ({ children, ...props }) => {
  return (
    <FlexContainer minHeight="24px" alignItems="center" width="100%" {...props}>
      {children}
    </FlexContainer>
  );
};
