import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { ColorStrategyType, ElementDesignInterface } from 'store/reducers/visualisations/types';
import { ColorStrategyEnum } from 'store/reducers/visualisations/constants';

export const typeStrategyOptions: SettingsRadioItem<ColorStrategyType>[] = [
  { value: 'byIndicator', label: 'Показатели' },
  { value: 'byIncision', label: 'Разрезы' },
];

export const getColorSettings = (colorStrategyType: ColorStrategyType, value: ElementDesignInterface) => {
  return colorStrategyType === ColorStrategyEnum.ByIndicator ? value.colorSettingsIndicator : value.colorSettingsIncision;
};

export const getColorSettingsProperty = (
  colorStrategyType: ColorStrategyType,
): 'colorSettingsIndicator' | 'colorSettingsIncision' => {
  return colorStrategyType === ColorStrategyEnum.ByIndicator ? 'colorSettingsIndicator' : 'colorSettingsIncision';
};

export const getPropertiesSettings = (colorStrategyType: ColorStrategyType, value: ElementDesignInterface) => {
  return colorStrategyType === ColorStrategyEnum.ByIndicator
    ? value.propertiesIndicatorColorSettings
    : value.propertiesIncisionColorSettings;
};

export const getPropertiesSettingsProperty = (
  colorStrategyType: ColorStrategyType,
): 'propertiesIndicatorColorSettings' | 'propertiesIncisionColorSettings' => {
  return colorStrategyType === ColorStrategyEnum.ByIndicator
    ? 'propertiesIndicatorColorSettings'
    : 'propertiesIncisionColorSettings';
};
