import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { Column, ColumnSettingsInterface, TotalValuesType } from 'modules/visualisations/Table/visualisation/types';
import React, { useCallback } from 'react';
import { IndentationInterface, TableBodySettingsInterface, TotalRowSettingsInterface } from 'store/reducers/visualisations/types';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { formattingNameTextAlign } from 'utils/formatting';
import { AbsoluteColorBlock, TableCell, TableTextBody, TotalRow } from '../styles';

interface TotalComponentProps {
  columns: Column[];
  columnVisibility: string[];
  totalValues: TotalValuesType;
  hashName?: string[];
  countIncisions: number;
  settings: TotalRowSettingsInterface;
  bodySettings: TableBodySettingsInterface;
  columnsSettings: ColumnSettingsInterface;
}

export const TotalComponent = ({
  columns,
  columnVisibility,
  totalValues,
  hashName,
  countIncisions,
  settings,
  bodySettings,
  columnsSettings,
}: TotalComponentProps) => {
  const { getColorValues } = useColorValues();
  const { properties: settingsProperties, overfill, indentation, hasIndentation } = settings;
  const { fontSize, lineHeight, fontColor, opacity, letterSpacing, fontStyle } = settingsProperties;
  const { top, bottom } = hasIndentation ? indentation : bodySettings.indentation;

  const getPaddingString = useCallback(
    (paddings: IndentationInterface, priority: boolean, priorityColumn?: boolean) => {
      const { top, bottom, left, right } = paddings;
      const { top: defaultTop, left: defaultLeft, bottom: defaultBottom, right: defaultRight } = bodySettings.indentation;

      return priorityColumn
        ? `${top}px ${right}px ${bottom}px ${left}px`
        : `${priority ? top : defaultTop}px ${defaultRight}px ${priority ? bottom : defaultBottom}px ${defaultLeft}px`;
    },
    [bodySettings.indentation],
  );

  return (
    <TotalRow>
      {columns.map(({ dataAccessor, isIncision, Header, properties }, index) => {
        const columnSettings = columnsSettings[dataAccessor] || columnsSettings[Object.keys(columnsSettings)[0]];
        const totalValue = totalValues[hashName ? hashName[index - countIncisions] : dataAccessor];
        const { horizontal, vertical } = properties.isActiveStyle
          ? properties.alignment
          : bodySettings.propertiesIndicators.alignment;
        const { backgroundColor } = isIncision ? bodySettings.propertiesIncisions : bodySettings.propertiesIndicators;

        const { left, right } = columnSettings.hasIndentation ? columnSettings.indentation : indentation;
        const indentationCell = { left, right, top, bottom };

        if (!!columnVisibility.length && !columnVisibility.includes(Header) && isIncision) {
          return null;
        }

        return (
          <TableCell key={`${dataAccessor}>${index}`} backgroundColor={getColorValues(backgroundColor)} opacity={100}>
            {overfill && (
              <AbsoluteColorBlock
                backgroundColor={getColorValues(settingsProperties.backgroundColor)}
                opacity={settingsProperties.backgroundOpacity}
              />
            )}
            <TableTextBody
              justifyContent={horizontal}
              textAlign={formattingNameTextAlign(horizontal)}
              alignItems={vertical}
              padding={getPaddingString(indentationCell, hasIndentation, columnSettings.hasIndentation)}
            >
              <PrimaryTextSpan
                fontSize={`${fontSize}px`}
                lineHeight={`${lineHeight}%`}
                color={getColorValues(fontColor) || undefined}
                opacity={opacity}
                letterSpacing={`${letterSpacing}px`}
                fontWeight={fontStyle.bold ? 'bold' : 'normal'}
                fontStyle={fontStyle.italic ? 'italic' : 'normal'}
                textDecoration={fontStyle.underline ? 'underline' : undefined}
              >
                {index === 0 ? 'Итоги' : totalValue !== undefined ? totalValue : ''}
              </PrimaryTextSpan>
            </TableTextBody>
          </TableCell>
        );
      })}
    </TotalRow>
  );
};
