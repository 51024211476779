import { useState, useEffect } from 'react';
import { ControlsInterface } from 'modules/settingsContainer/common/data/MetricSettings/types';

interface UseControlsProps {
  controls?: ControlsInterface;
  activePageId?: string;
}

const useControls = ({ controls, activePageId }: UseControlsProps) => {
  const externalActiveMetricId = controls?.activeMetricId?.value;
  const externalSelectedMetricId = controls?.selectedMetricId?.value;

  const [activeMetricId, setActiveMetricId] = useState<string | null>(externalActiveMetricId || null);
  const [selectedMetricId, setSelectedMetricId] = useState<string | null>(externalSelectedMetricId || activePageId || null);

  useEffect(() => {
    setActiveMetricId(externalActiveMetricId || null);
  }, [externalActiveMetricId]);

  useEffect(() => {
    setSelectedMetricId(externalSelectedMetricId || activePageId || null);
  }, [externalSelectedMetricId, activePageId]);

  const onCloseFieldSettings = () => {
    setActiveMetricId(null);
    controls?.activeMetricId?.onChange && controls?.activeMetricId?.onChange(null);
  };

  const onUnselectMetric = () => {
    setSelectedMetricId(null);
    controls?.selectedMetricId?.onChange && controls?.selectedMetricId?.onChange(null);
  };

  return {
    activeMetricId,
    setActiveMetricId,
    selectedMetricId,
    setSelectedMetricId,
    onCloseFieldSettings,
    onUnselectMetric,
  };
};

export default useControls;
