import { store } from 'store';
import {
  updateDataSettingsAction,
  updateLineAndBarDataSettingsAction,
  updateLineAndBarIndicatorStackSumAction,
  updateLineAndBarViewSettingsAction,
} from 'store/reducers/visualisations/actions';
import {
  BarTypeInterface,
  ColorBySettingsValueAndElementInterface,
  ElementSettingsInterface,
  FormattingInterface,
  LineAndBarDataSettings,
  LineAndBarIndicatorInterface,
  LineAndBarViewSettings,
  MinAndMaxInterface,
  ShowSumInterface,
  ShowValuePositionGeneric,
  ShowValueSettingsInterface,
} from 'store/reducers/visualisations/types';
import { TopAndBottomType } from 'types/styles';
import { OnChangeValue } from 'modules/visualisations/LineAndBar/settings/DataTab/types';
import { moveArrayItem, MoveToType } from 'utils/utils';
import { ColorValuesByThemeType, PaletteValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { DesignOptionInterface, PositionSettingType } from 'types/store';
import {
  ColorStrategyEnum,
  defaultBarParametersDesignOption,
  getLineAndBarIndicator,
  LineAndBarTypeIndicatorEnum,
} from 'store/reducers/visualisations/constants';
import { BarAndLineTypesEnum, BarTypesEnum } from '../../visualisation/constants';
import { isUndefined } from 'lodash';
import { v4 } from 'uuid';

const dispatch = store.dispatch;

export const onRotateChange = (rotateTo90: boolean, { axisIncisionSettings, axisIndicatorSettings }: LineAndBarViewSettings) => {
  dispatch(updateLineAndBarDataSettingsAction({ rotateTo90 }));
  dispatch(
    updateLineAndBarViewSettingsAction({
      axisIncisionSettings: { ...axisIncisionSettings, position: 'bottom' },
      axisIndicatorSettings: { ...axisIndicatorSettings, position: 'left' },
    }),
  );
};

const defaultParametersBar = {
  barWidth: defaultBarParametersDesignOption,
  barMinWidth: defaultBarParametersDesignOption,
  barMaxWidth: defaultBarParametersDesignOption,
  stackOffset: '20',
};

const updateParametersBar = (
  parametersBar: {
    barWidth: DesignOptionInterface;
    barMaxWidth: DesignOptionInterface;
    barMinWidth: DesignOptionInterface;
    stackNumber: number;
    stackOffset: string;
  },
  stackNumber: number,
) => ({
  ...parametersBar,
  ...defaultParametersBar,
  stackNumber,
});

const updateIndicatorForNominated = (indicator: LineAndBarIndicatorInterface): LineAndBarIndicatorInterface => ({
  ...indicator,
  settings: {
    ...indicator.settings,
    elementSettings: {
      ...indicator.settings.elementSettings,
      type: 'bar',
      parametersBar: updateParametersBar(indicator.settings.elementSettings.parametersBar, 1),
    },
  },
});

const updateIndicatorForOther = (
  indicator: LineAndBarIndicatorInterface,
  index: number,
  activePile: boolean,
): LineAndBarIndicatorInterface => ({
  ...indicator,
  settings: {
    ...indicator.settings,
    elementSettings: {
      ...indicator.settings.elementSettings,
      parametersBar: updateParametersBar(indicator.settings.elementSettings.parametersBar, activePile ? 1 : index + 1),
    },
  },
});

export const onTypeChange = (barType: BarTypeInterface, indicators: LineAndBarIndicatorInterface[]) =>
  dispatch(
    updateLineAndBarDataSettingsAction({
      barType,
      indicators:
        barType.type === BarTypesEnum.Nominated
          ? indicators.map(updateIndicatorForNominated)
          : indicators.map((indicator, index) => updateIndicatorForOther(indicator, index, barType.activePile)),
    }),
  );

export const onShowSumChange = (showSum: ShowSumInterface) => dispatch(updateLineAndBarDataSettingsAction({ showSum }));

export const onMinAndMaxChange = (minAndMax: MinAndMaxInterface) => dispatch(updateLineAndBarDataSettingsAction({ minAndMax }));

export const onMinAndMaxAdditionalChange = (minAndMaxAdditional: MinAndMaxInterface) =>
  dispatch(updateLineAndBarDataSettingsAction({ minAndMaxAdditional }));

/* Incision change */

export const onColorIncisionChange = (
  dataSettings: LineAndBarDataSettings,
  viewSettings: LineAndBarViewSettings,
  colors: PaletteValuesByThemeType | null,
  id: string,
) => {
  dispatch(
    updateLineAndBarViewSettingsAction({
      ...viewSettings,
      elementDesign: { ...viewSettings.elementDesign, colorStrategyType: ColorStrategyEnum.ByIncision },
    }),
  );

  dispatch(
    updateLineAndBarDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) =>
        id === incision.id
          ? {
              ...incision,
              colorBySettingsValueAndElement: {
                ...incision.colorBySettingsValueAndElement,
                colorBySettingsElement: {
                  ...incision.colorBySettingsValueAndElement.colorBySettingsElement,
                  elementColor: colors,
                  isActive: true,
                },
              },
            }
          : incision,
      ),
    }),
  );
};

export const onMoveIncision = (dataSettings: LineAndBarDataSettings, incisionId: string, moveTo: MoveToType) => {
  const incisions = dataSettings.incisions,
    indexOfIncision = incisions.findIndex(({ id }) => id === incisionId),
    { newArray } = moveArrayItem(incisions, indexOfIncision, moveTo);

  dispatch(
    updateLineAndBarDataSettingsAction({
      ...dataSettings,
      incisions: newArray,
    }),
  );
};

export const onColorBySettingsValueAndElementIncision: OnChangeValue<ColorBySettingsValueAndElementInterface> = (
  dataSettings,
  colorBySettingsValueAndElement,
  id,
) =>
  dispatch(
    updateLineAndBarDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) =>
        id === incision.id ? { ...incision, colorBySettingsValueAndElement } : incision,
      ),
    }),
  );

/* Indicator change */

export const onChangeElementSettings: OnChangeValue<ElementSettingsInterface> = (dataSettings, elementSettings, id) => {
  const isLineType = elementSettings.type === BarAndLineTypesEnum.Line,
    position: PositionSettingType | TopAndBottomType = isLineType ? 'top' : 'flex-start';

  const hasColorsInIncisions = dataSettings.incisions.some(({ colors }) => colors);

  const targetIndicator = dataSettings.indicators.find((indicator) => indicator.id === id);
  if (!targetIndicator) return;

  const targetStackNumber = targetIndicator.settings.elementSettings.parametersBar.stackNumber;
  const newStackNumber = elementSettings.parametersBar?.stackNumber;

  let newStackNumberParameters: Partial<ElementSettingsInterface['parametersBar']> | null = null;

  if (!isUndefined(newStackNumber) && newStackNumber !== targetStackNumber) {
    const newStackIndicator = dataSettings.indicators.find(
      (indicator) => indicator.settings.elementSettings.parametersBar.stackNumber === newStackNumber,
    );

    if (newStackIndicator) {
      newStackNumberParameters = {
        barWidth: newStackIndicator.settings.elementSettings.parametersBar.barWidth,
        barMinWidth: newStackIndicator.settings.elementSettings.parametersBar.barMinWidth,
        barMaxWidth: newStackIndicator.settings.elementSettings.parametersBar.barMaxWidth,
        stackOffset: newStackIndicator.settings.elementSettings.parametersBar.stackOffset,
      };
    }
  }

  const updatedIndicators = dataSettings.indicators.map((indicator) => {
    const updatedParametersBar = { ...indicator.settings.elementSettings.parametersBar };

    const indicatorStackNumber = indicator.settings.elementSettings.parametersBar.stackNumber;

    if (indicatorStackNumber === targetStackNumber) {
      const { barMaxWidth, barMinWidth, barWidth, stackOffset } = elementSettings.parametersBar;

      if (!isUndefined(barWidth)) {
        updatedParametersBar.barWidth = barWidth;
      }
      if (!isUndefined(barMinWidth)) {
        updatedParametersBar.barMinWidth = barMinWidth;
      }
      if (!isUndefined(barMaxWidth)) {
        updatedParametersBar.barMaxWidth = barMaxWidth;
      }
      if (!isUndefined(stackOffset)) {
        updatedParametersBar.stackOffset = stackOffset;
      }
    }

    if (indicator.id === id) {
      if (!isUndefined(newStackNumber)) {
        updatedParametersBar.stackNumber = newStackNumber;

        if (newStackNumberParameters && newStackNumberParameters) {
          updatedParametersBar.barWidth = <DesignOptionInterface>newStackNumberParameters.barWidth;
          updatedParametersBar.barMinWidth = <DesignOptionInterface>newStackNumberParameters.barMinWidth;
          updatedParametersBar.barMaxWidth = <DesignOptionInterface>newStackNumberParameters.barMaxWidth;
          updatedParametersBar.stackOffset = <string>newStackNumberParameters.stackOffset;
        }
      }
    }

    return {
      ...indicator,
      color: !isLineType && hasColorsInIncisions ? null : indicator.color,
      settings: {
        ...indicator.settings,
        elementSettings: {
          ...elementSettings,
          colorBySettings:
            indicator.id === id ? elementSettings.colorBySettings : indicator.settings.elementSettings.colorBySettings,
          parametersBar: updatedParametersBar,
          type:
            indicator.id === id
              ? dataSettings.barType.type === BarTypesEnum.Nominated
                ? 'bar'
                : elementSettings.type
              : indicator.settings.elementSettings.type,
          parameters: indicator.id === id ? elementSettings.parameters : indicator.settings.elementSettings.parameters,
        },
        showValue:
          id === indicator.id && indicator.settings.elementSettings.type !== elementSettings.type
            ? { ...indicator.settings.showValue, position: position as ShowValuePositionGeneric }
            : indicator.settings.showValue,
      },
    };
  });

  return dispatch(
    updateLineAndBarDataSettingsAction({
      ...dataSettings,
      indicators: updatedIndicators,
    }),
  );
};

export const onChangeShowValue: OnChangeValue<ShowValueSettingsInterface> = (dataSettings, showValue, id) =>
  dispatch(
    updateLineAndBarDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, showValue } } : indicator,
      ),
    }),
  );

export const onColorIndicatorChange = (
  dataSettings: LineAndBarDataSettings,
  viewSettings: LineAndBarViewSettings,
  colors: ColorValuesByThemeType | null,
  id: string,
) => {
  dispatch(
    updateLineAndBarViewSettingsAction({
      ...viewSettings,
      elementDesign: { ...viewSettings.elementDesign, colorStrategyType: ColorStrategyEnum.ByIndicator },
    }),
  );

  dispatch(
    updateLineAndBarDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id
          ? {
              ...indicator,
              settings: {
                ...indicator.settings,
                elementSettings: {
                  ...indicator.settings.elementSettings,
                  colorBySettings: {
                    ...indicator.settings.elementSettings.colorBySettings,
                    elementColor: colors,
                    isActive: true,
                  },
                },
              },
            }
          : indicator,
      ),
    }),
  );
};

export const onMoveIndicator = (dataSettings: LineAndBarDataSettings, indicatorId: string, moveTo: MoveToType) => {
  const indicators = dataSettings.indicators,
    indexOfIndicators = indicators.findIndex(({ id }) => id === indicatorId),
    { newArray } = moveArrayItem(indicators, indexOfIndicators, moveTo);

  dispatch(
    updateLineAndBarDataSettingsAction({
      ...dataSettings,
      indicators: newArray,
    }),
  );
};

export const onChangeAdditionalIndicators: OnChangeValue<boolean> = (dataSettings, additionalIndicators, id) =>
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, additionalIndicators } } : indicator,
      ),
    }),
  );

/* indicatorsStackSum change */

export const getNewIndicatorStackSum = ({ stackNumber }: { stackNumber: number }) => {
  const nameIndicatorStackSum = `Стопка ${stackNumber}`;

  return getLineAndBarIndicator({
    id: v4(),
    name: nameIndicatorStackSum,
    stackNumber,
    type: LineAndBarTypeIndicatorEnum.INDICATOR_STACK_SUM,
  });
};

export const onAddNewIndicatorsStackSum = (dataSettings: LineAndBarDataSettings, stackNumber: number) => {
  const indicatorsStackSum = getNewIndicatorStackSum({ stackNumber });

  dispatch(
    updateLineAndBarIndicatorStackSumAction({
      ...dataSettings,
      indicatorsStackSum: [...dataSettings.indicatorsStackSum, indicatorsStackSum],
    }),
  );
};

export const onDeleteIndicatorsStackSum = (dataSettings: LineAndBarDataSettings, indicatorsStackSumId: string) => {
  dispatch(
    updateLineAndBarIndicatorStackSumAction({
      ...dataSettings,
      indicatorsStackSum: dataSettings.indicatorsStackSum.filter(({ id }) => id !== indicatorsStackSumId),
    }),
  );
};

export const onChangeIndicatorStackSumShowValue: OnChangeValue<ShowValueSettingsInterface> = (dataSettings, showValue, id) =>
  dispatch(
    updateLineAndBarDataSettingsAction({
      ...dataSettings,
      indicatorsStackSum: dataSettings.indicatorsStackSum.map((indicatorStackSum) =>
        id === indicatorStackSum.id
          ? { ...indicatorStackSum, settings: { ...indicatorStackSum.settings, showValue } }
          : indicatorStackSum,
      ),
    }),
  );

export const onChangeIndicatorStackSumFormatting: OnChangeValue<FormattingInterface> = (dataSettings, formatting, id) =>
  dispatch(
    updateLineAndBarDataSettingsAction({
      indicatorsStackSum: dataSettings.indicatorsStackSum.map((indicatorStackSum) =>
        id === indicatorStackSum.id
          ? { ...indicatorStackSum, settings: { ...indicatorStackSum.settings, formatting } }
          : indicatorStackSum,
      ),
    }),
  );
