import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { ModalComponentType } from 'store/reducers/modals/types';
import { HistoryVersionProject } from 'components/projectsManager/elements/Projects/ProjectsSearchList/HistoryVersionProject';

interface RollBackProjectModalProps {
  projectId: string;
  flowId: string;
  projectName?: string;
}

export const RollBackProjectModal: ModalComponentType<RollBackProjectModalProps> = (params) => {
  return (
    <FlexContainer width="100%" height="100%" overflowY="auto">
      <FlexContainer width="100%" flexDirection="column" height="calc(-120px + 420px)" overflowY="auto" marginBottom="60px">
        <HistoryVersionProject padding="0" footerPosition="flex-end" {...params} />
      </FlexContainer>
    </FlexContainer>
  );
};
