import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import {
  ColorAndImageByEnum,
  ColorByInterface,
  ColorByItem,
  ColorValuesByThemeType,
  LineAndBarIndicatorInterface,
} from 'store/reducers/visualisations/types';
import { createGradientOrStripesFromColors, CreateGradientOrStripesFromColorsType } from 'utils/utils';

type UseColorsLegendDataHookProps = {
  indicators: LineAndBarIndicatorInterface[];
  elementGlobalColorBy: ColorByInterface;
  elementIndicatorGlobalColor: ColorValuesByThemeType | null;
};

export interface ColorsLegendInterface {
  id: string;
  createLinearGradientFromColors: CreateGradientOrStripesFromColorsType;
}

interface UseColorsLegendDataHook {
  ({ indicators, elementGlobalColorBy, elementIndicatorGlobalColor }: UseColorsLegendDataHookProps): ColorsLegendInterface[];
}

export const useColorsLegendData: UseColorsLegendDataHook = ({
  indicators,
  elementGlobalColorBy,
  elementIndicatorGlobalColor,
}) => {
  const { getColorValues, defaultColor } = useColorValues();

  return indicators.map(({ id, settings: { elementSettings } }) => {
    const { isActive, elementColor, elementColorBy } = elementSettings.colorBySettings || {};
    const {
      type: indicatorColorType,
      byValue: { colors: indicatorValueColors },
      byCondition: { colors: indicatorConditionColors },
    } = elementColorBy || {};

    const {
      type: globalColorType,
      byCondition: { colors: globalConditionColors },
      byValueSpecific: { colors: globalValueColors },
    } = elementGlobalColorBy;

    const resolveColors = (colors: ColorByItem[], fallbackColor: string) => {
      const resolvedColors = colors.map((color) => getColorValues(color.value) || fallbackColor);
      return resolvedColors.length > 0 ? resolvedColors : [fallbackColor];
    };

    const indicatorColors = {
      [ColorAndImageByEnum.Default]: [getColorValues(elementColor) || defaultColor],
      [ColorAndImageByEnum.ValueSpecific]: resolveColors(indicatorValueColors?.[id] || [], defaultColor),
      [ColorAndImageByEnum.Condition]: resolveColors(indicatorConditionColors || [], defaultColor),
      [ColorAndImageByEnum.Value]: resolveColors(indicatorValueColors?.[id] || [], defaultColor),
    }[indicatorColorType] || [defaultColor];

    const globalColors = {
      [ColorAndImageByEnum.Default]: [getColorValues(elementIndicatorGlobalColor) || defaultColor],
      [ColorAndImageByEnum.ValueSpecific]: resolveColors(globalValueColors?.['colorSpecificAlias'] || [], defaultColor),
      [ColorAndImageByEnum.Condition]: resolveColors(globalConditionColors || [], defaultColor),
      [ColorAndImageByEnum.Value]: resolveColors(globalValueColors?.['colorSpecificAlias'] || [], defaultColor),
    }[globalColorType] || [defaultColor];

    const finalColors = isActive ? indicatorColors : globalColors;
    const useStripes = indicatorColorType === ColorAndImageByEnum.Condition;

    return {
      id,
      createLinearGradientFromColors: createGradientOrStripesFromColors(finalColors, useStripes),
    };
  });
};
